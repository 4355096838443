<template>
    <v-card v-if="loading">
        <v-card-title>
            <span class="text-h5">
                Nachforderung erstellen
            </span>
        </v-card-title>
        <v-card-text>
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-card-text>
    </v-card>
    <DocRequestForm
        v-else 
        formTitle="Nachforderung erstellen"
        :caseData="caseData"
        :prefill="prefill"
        :key="reloadDocRequestForm"
        v-on:close="closeDocRequestForm"
        v-on:safe="safeDocRequestForm"
    />
</template>
<script>
    import appConfig from "../../../config";
    import axios from "axios";
    import DocRequestForm from "./DocRequestForm.vue";

    export default {
        name: "DocRequestCreateForm",
        components: {
            DocRequestForm
        },
        props: [ "caseData", "applicationId" ],
        data() {
            return {
                reloadDocRequestForm: 0,
                prefill: {},
                loading: false
            }
        },

        async mounted() {
            this.prefill = {
                application: this.applicationId,
                case: this.caseData._id,
                owner: this.caseData.owner.id
            }

            this.reloadDocRequestForm += 1;
        },

        methods: {
            closeDocRequestForm() {
                this.$emit('close');
            },
            async safeDocRequestForm(data) {
                this.loading = true;

                const requestConfig = {
                    headers: {
                        sessiontoken: localStorage.getItem("token"),
                    },
                };

                try {
                    const documentRequestsURL = `${appConfig.apiURL}/sozialstaffel/documentrequests`;
                    const documentRequestsResponse = await axios.post(documentRequestsURL, data, requestConfig);

                    this.$emit('closesuccess', documentRequestsResponse.data);
                } catch(e) {
                    this.$emit('closeerror');
                }

            }
        }
    }
</script>