<template>
  <v-main
    class="mt-3"
    style="background-color: #ffffff; width: 100vw; height: 95vh"
  >
    <v-app-bar app elevation="1" color="white">
      <v-btn color="primary" elevation="0" @click="back()" outlined>
        <v-icon>mdi-arrow-left-thick</v-icon>
        Zurück zum Launchpad
      </v-btn>
    </v-app-bar>
      <v-snackbar
        v-model="errorSnackbar"
        timeout="3000"
        color="red"
        dark
        :absolute="true"
        :top="true"
      >
        {{ errorSnackbarText }}
      </v-snackbar>
      <v-snackbar
        v-model="successSnackbar"
        timeout="3000"
        color="blue"
        dark
        :absolute="true"
        :top="true"
      >
        {{ successSnackbarText }}
      </v-snackbar>
    <div class="ma-9">
      <v-row>
        <h1>Sozialstaffel Statistiken</h1>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-select
            class="mt-4"
            label="Intervall"
            :items="availableIntervals"
            v-model="selectedInterval"
            @change="rerenderCharts()"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="selectedTenants"
            :items="tenantNames"
            label="Ämter"
            multiple
            chips
            @change="rerenderCharts()"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <!-- Begin ActiveLettersPerWeek -->
        <v-col cols="12">
          <v-card>
            <v-card-title>
              Verlauf aktive Bescheide
            </v-card-title>
            <v-card-text>
              <div v-if="activeLettersPerWeek.loading" class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div id="chart" v-else>
                <apexchart type="area" height="350" :options="activeLettersPerWeek.chartOptions" :series="activeLettersPerWeek.series"></apexchart>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- End ActiveLettersPerWeek -->
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title class="justify-center" style="font-size: 25pt">
                  {{ numOfletters }}
                </v-card-title>
                <v-card-text class="text-center">
                  <h3>Anzahl Bescheide insgesamt</h3>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title class="justify-center" style="font-size: 25pt">
                  {{ numOfActiveCases }}
                </v-card-title>
                <v-card-text class="text-center">
                  <h3>Anzahl aktiver Fälle</h3>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title class="justify-center" style="font-size: 25pt">
                  {{ numOfActiveCases }}
                </v-card-title>
                <v-card-text class="text-center">
                  <h3>Anzahl profitierender Kinder</h3>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!-- Begin LetterByResultChart -->
        <v-col cols="12" md="4">
          <v-card>
            <v-card-title>
              Bescheide der Ämter nach Beischeidtyp
            </v-card-title>
            <v-card-text>
              <div v-if="letterByResultChart.loading" class="text-center">
                <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              </div>
              <div id="chart" v-else>
                <apexchart type="donut" :options="letterByResultChart.chartOptions" :series="letterByResultChart.series"></apexchart>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- End LetterByResultChart -->
        <!-- Begin ActiveCasesBySocialBenefitsChart -->
        <v-col cols="12" md="5">
          <v-card>
            <v-card-title>
              Aktive Bescheide mit Sozialleistungsbezug
            </v-card-title>
            <v-card-text>
              <div v-if="activeCasesBySocialBenefits.loading" class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div id="chart">
                <apexchart type="donut" :options="activeCasesBySocialBenefits.chartOptions" :series="activeCasesBySocialBenefits.series"></apexchart>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- End ActiveCasesBySocialBenefitsChart -->
      </v-row>
    </div>
  </v-main>
</template>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 12pt !important;
}

.v-data-table.v-data-table.v-data-table >>> th {
  font-size: 14pt !important;
}
</style>
<script>
import axios from "axios";
import utils from "../utils";
import appConfig from "../config";

export default {
  name: "Analytics",
  data() {
    return {
      //init data
      tenants: [],
    
      //dynamic data
      selectedTenants: [],
      selectedInterval: "",

      availableIntervals: [
        "letzte 30 Tage",
        "letzte 90 Tage",
        "letzte 6 Monate",
        "letzte 12 Monate",
      ],

      numOfletters: 0,
      numOfSocialBenefitsLetters: 0,
      numOfActiveCases: 0,
      benefitingChildrenLoading: true,
      numOfBenefitingChildren: 0,

    //donutChart 
    letterByResultChart: {
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          type: 'donut',
           toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true
            }
          }
        },
        labels: ["Teilermäßigung", "Pauschalbefreiung", "Vollermäßigung", "Ablehnung"],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    },

    activeCasesBySocialBenefits: {
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          type: 'donut',
           toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true
            }
          }
        },
        labels: Object.keys(appConfig.socialBenefitsMapping).map(socBen => socBen.replace("Leistungen ", "")),
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'top',
              horizontalAlign: 'left'
            }
          }
        }]
      }
    },

    //stacked area
    activeLettersPerWeek: {
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          type: 'area',
          height: 350,
          stacked: true,
          events: {
            selection: function (chart, e) {
              console.log(new Date(e.xaxis.min))
            }
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          }
        },
        //colors: ['#008FFB', '#00E396', '#CED4DC'],
        dataLabels: {
          enabled: false
        },    
        stroke: {
          curve: 'straight'
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
        xaxis: {
          type: 'datetime'
        },
      }    
    },
      //snackbars
      errorSnackbar: false,
      errorSnackbarText: "",
      successSnackbar: false,
      successSnackbarText: "",
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  computed: {
    tenantNames: {
      get: function () {
        return this.tenants.map(tenant => tenant.name);
      },
    },
  },
  methods: {
    /*
     * common
     */
    back() {
      this.$router.push("/launchpad");
    },
    showSnackbar(type, text) {
      if (type == "ERROR") {
        this.errorSnackbar = true;
        this.errorSnackbarText = text;
      } else {
        this.successSnackbar = true;
        this.successSnackbarText = text;
      }
    },
    rerenderCharts() {
      const tenantIds = this.getSelectedTenants().map(ten => ten._id).join(",");
      const { start, end } = this.getStartEndDate();

      this.renderLetterByResultChart(tenantIds, start, end);
      this.renderActiveLettersPerWeek(tenantIds, start, end);
      this.renderActiveCasesBySocialBenefitsChart(tenantIds, start, end);
      this.renderBenefitingChildren(tenantIds, start, end);
    },

    async renderBenefitingChildren(tenants, start, end) {
      this.benefitingChildrenLoading = true;

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/analytics/sozialstaffel/cases/benefitingchildren?tenants=${tenants}&startDate=${utils.formatDateForDateInput(start)}&endDate=${utils.formatDateForDateInput(end)}`;

      const resp = await axios.get(url, requestConfig);

      this.numOfBenefitingChildren = resp.data.count;

      this.benefitingChildrenLoading = false;
    },

    async renderLetterByResultChart(tenants, start, end) {
      this.letterByResultChart.loading = true;

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/analytics/sozialstaffel/letters/byresult?tenants=${tenants}&dateOfIssueFrom=${utils.formatDateForDateInput(start)}&dateOfIssueTo=${utils.formatDateForDateInput(end)}`;

      const resp = await axios.get(url, requestConfig);

      this.letterByResultChart.series = [resp.data.partial, resp.data.full_without_calc, resp.data.full_with_calc, resp.data.rejection]
      this.numOfletters = this.letterByResultChart.series.reduceRight((acc, cur) => acc + cur, 0);

      this.letterByResultChart.loading = false;
    },

    async renderActiveLettersPerWeek(tenants, start, end) {
      this.activeLettersPerWeek.loading = true;

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/analytics/sozialstaffel/letters/activeperweek?tenants=${tenants}&startDate=${utils.formatDateForDateInput(start)}&endDate=${utils.formatDateForDateInput(end)}`;

      const resp = await axios.get(url, requestConfig);

      const series = []

      for(let tenantId of Object.keys(resp.data)) {
        series.push({
          name: this.getTenantById(tenantId).name,
          data: resp.data[tenantId].map(d => {
            return [ new Date(d.startDate).getTime(), d.count ]
          })
        });
      }

      this.activeLettersPerWeek.series = series;

      this.activeLettersPerWeek.loading = false;
    },

    async renderActiveCasesBySocialBenefitsChart(tenants, start, end) {
      this.activeCasesBySocialBenefits.loading = true;

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/analytics/sozialstaffel/letters/activebysocialbenefittype?tenants=${tenants}&startDate=${utils.formatDateForDateInput(start)}&endDate=${utils.formatDateForDateInput(end)}`;

      const resp = await axios.get(url, requestConfig);

      this.activeCasesBySocialBenefits.series = Object.values(resp.data);
      this.numOfActiveCases = this.activeCasesBySocialBenefits.series.reduceRight((acc, cur) => acc + cur, 0);

      this.activeCasesBySocialBenefits.loading = false;
    },

    //utils
    getStartEndDate() {
      let start, end = new Date();

      const d = new Date()

      if(this.selectedInterval == "letzte 30 Tage") {
        d.setDate(new Date().getDate() - 30);

        start = d;
      } else if(this.selectedInterval == "letzte 90 Tage") {
        d.setDate(new Date().getDate() - 90);

        start = d;
      } else if(this.selectedInterval == "letzte 6 Monate") {
        d.setDate(new Date().getDate() - 180);

        start = d;
      } else if(this.selectedInterval == "letzte 12 Monate") {
        d.setDate(new Date().getDate() - 360);

        start = d;
      }

      return { start, end };
    },
    getSelectedTenants() {
      return this.tenants.filter(tenant => this.selectedTenants.includes(tenant.name));
    },
    getTenantById(id) {
      for(let tenant of this.tenants) {
        if(tenant._id == id) {
          return tenant;
        }
      }
    }
  },
  async beforeMount(){
    this.selectedInterval = this.availableIntervals[0];

    const requestConfig = {
      headers: {
        sessiontoken: localStorage.getItem("token"),
      },
    };

    const tenantUrl = `${appConfig.apiURL}/tenants`;

    const resp = await axios.get(tenantUrl, requestConfig);

    this.tenants = resp.data.data;
    this.selectedTenants = resp.data.data.map(tenant => tenant.name);

    this.rerenderCharts();
  }
};
</script>