<template>
    <v-main
      class="mt-3"
      style="background-color: #ffffff; width: 100vw; height: 95vh"
    >
    <v-snackbar
          v-model="errorSnackbar"
          timeout="3000"
          color="red"
          dark
          :absolute="true"
          :top="true"
        >
          {{ errorSnackbarText }}
        </v-snackbar>
        <v-snackbar
          v-model="successSnackbar"
          timeout="3000"
          color="blue"
          dark
          :absolute="true"
          :top="true"
        >
          {{ successSnackbarText }}
        </v-snackbar>
      <v-app-bar app elevation="1" color="white">
        <v-btn color="primary" elevation="0" @click="back()" outlined>
          <v-icon>mdi-arrow-left-thick</v-icon>
          Zurück zur Übersicht
        </v-btn>
      </v-app-bar>
      <div class="mx-9 mt-8">
        <v-row class="justify-center">
          <v-col cols="12" lg="10">
            <v-card class="pa-4">
              <div class="text-right">
                  <v-btn elevation="0" color="secondary" v-if="confirmedAllPositions" @click="confirmInvoice()">
                    Abrechnung abschließen
                  </v-btn>
              </div>
              <h1>{{ billingRun.name }} für {{ daycare.name }}</h1>
              <h4>Erstattungsbetrag: {{ getCurrencyFormatted(invoice.aggregated?.totalRefund, true) }}€</h4>
              <v-divider></v-divider>
              <v-simple-table
                  fixed-header
                  height="600px"
                  class="mt-4"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Status
                        </th>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Erstattungsbetrag
                        </th>
                        <th class="text-left">
                          Verifizierungscode
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(position, i) in invoice.positions"
                        :key="position._id"
                      >
                        <td>
                          <v-chip color="green dark-2" dark v-if="position.confirmed">
                            Verifiziert
                          </v-chip>
                          <span v-else>
                            <v-chip color="red dark-2" dark >
                              Nicht verifiziert
                            </v-chip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="ml-2"
                                  color="green darken-2"
                                  @click.stop="verifyPosition(i, position)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-check-decagram
                                </v-icon>
                              </template>
                              <span>Manuell als verifiziert markieren</span>
                            </v-tooltip>
                          </span>
                          
                        </td>
                        <td>{{ position.fullName }}</td>
                        <td>{{ }}€</td>
                        <td>{{ position.verificationCode }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-main>
</template>
<script>
  import axios from "axios";
  import appConfig from "../../config";
  
  export default {
    name: "KITABilling",
    data() {
      return {
        billingRun: {},
        daycare: {},
        invoice: {},

        //snackbars
        errorSnackbar: false,
        errorSnackbarText: "",
        successSnackbar: false,
        successSnackbarText: "",
        rules: {
          required: (value) => !!value || "Pflichtfeld, bitte ausfüllen"
        }
      }
    },
    async mounted() {
      const invoiceId = this.$route.query.invoice;
      const daycareId = this.$route.query.daycare;
      const billingRunId = this.$route.query.billingrun;

      const requestConfig = {
          headers: {
            sessiontoken: localStorage.token,
          },
      };
  
      const daycareURL = `${appConfig.apiURL}/kitabilling/daycares/${daycareId}`;
      const invoiceURL = `${appConfig.apiURL}/kitabilling/kitainvoices/${invoiceId}`;
      const billingRunURL = `${appConfig.apiURL}/kitabilling/billingruns/${billingRunId}`;

      try {
          const responses = await Promise.all([
            axios.get(daycareURL, requestConfig), axios.get(invoiceURL, requestConfig), axios.get(billingRunURL, requestConfig)
          ]);

          this.daycare = responses[0].data;
          this.invoice = responses[1].data;

          this.invoice.positions = this.invoice.positions.map(position => {
            position.fullName = `${position.firstName} ${position.lastName}`;
            position.sum = position.refundForSiblingDiscount + position.refundForOverThree + position.refundForSozialstaffelDiscount + position.refundForUnderThree;
            return position;
          })

          console.log(this.invoice)

          this.billingRun = responses[2].data;
      } catch(e) {
        this.showSnackbar("ERROR", "Es ist ein unerwarteter Fehler aufgetreten");
      }
    },
    computed: {
      confirmedAllPositions: function() {
        return this.invoice?.positions?.every(position => position.confirmed);
      }
    },
    methods: {
      /*
       * common
       */
      back() {
        this.$router.back();
      },
      showSnackbar(type, text) {
        if (type == "ERROR") {
          this.errorSnackbar = true;
          this.errorSnackbarText = text;
        } else {
          this.successSnackbar = true;
          this.successSnackbarText = text;
        }
      },
      getCurrencyFormatted(num, supressWarning) {
        if(num == undefined) {
          if(!supressWarning){ console.log("tried to convert undefined via currency formatter"); }
          return;
        }
        return num.toFixed(2).toString().replace(".", ",");
      },
      async verifyPosition(i, position) {
        this.invoice.positions[i].confirmed = true;

        const requestConfig = {
          headers: {
            sessiontoken: localStorage.token,
          },
        };

        try {
          const url = `${appConfig.apiURL}/kitabilling/kitainvoices/${this.invoice._id}/${position._id}/confirm`;

          await axios.put(url, requestConfig)
        } catch(e) {
          this.showSnackbar("ERROR", "Es ist ein unerwarteter Fehler aufgetreten");
        }
      },
      async confirmInvoice() {
        const requestConfig = {
          headers: {
            sessiontoken: localStorage.token,
          },
        };

        try {
          const url = `${appConfig.apiURL}/kitabilling/kitainvoices/${this.invoice._id}/confirm`;

          await axios.put(url, {}, requestConfig);
          this.$router.back();
        } catch(e) {
          this.showSnackbar("ERROR", "Es ist ein unerwarteter Fehler aufgetreten");
        }
      }
    }
  };
  </script>