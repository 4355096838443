<template>
  <v-card>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      :color="snackBarType == 'ERROR' ? 'red' : 'blue'"
      dark
      :absolute="true"
      :top="true"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-card-title>
      <span class="text-h5" v-if="mode == 'CREATE'">Bescheid erstellen</span>
      <span class="text-h5" v-else>Bescheid {{this.lData.verificationCode}} aktualisieren</span>
    </v-card-title>
    <v-card-text v-if="this.loading">
      <v-row>
        <v-col cols="12" align="center">
            <v-card-title style="text-align: left">
              Lade Bescheid ...
            </v-card-title>
            <v-card-text>
              <span style="font-size: 12pt; text-align: left">Dies kann einige Sekunden dauern</span>
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-card-text>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                label="Art des Bescheids*"
                :items="availableLetterTypes"
                v-model="formData.letterType"
                required
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <v-col md="6" cols="12">
              <v-select
                label="Zuständiger Sachbearbeiter*"
                :items="availableOfficials"
                v-model="formData.owner"
                required
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" v-if="formData.letterType?.includes('amendment') || formData.letterType?.includes('follow_up')">
              <v-select
                label="Vorheriger Bescheid*"
                :items="availablePreviousLetters"
                v-model="formData.previousLetter"
                no-data-text="Keine abgeschlossenen Bescheide vorhanden"
                required
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                label="Antrag*"
                :items="availableApplications"
                v-model="formData.application"
                required
                no-data-text="Keine Anträge vorhanden"
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" v-if="formData.letterType != 'rejection_other_reason'">
              <v-select
                label="Einkommensberechnung*"
                :items="availableCalculations"
                v-model="formData.calculation"
                @change="changeCalculation()"
                required
                :disabled="noIncomeCalc"
                :rules="noIncomeCalc? []: [rules.required]"
              >
              <template slot="no-data">
                <p>Keine Berechnungen vorhanden</p>
              </template>
            </v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Datum des Bescheids*"
                v-model="formData.dateOfIssue"
                type="date"
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Bescheid gültig vom*"
                v-model="formData.letterStart"
                type="date"
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Bescheid gültig bis*"
                v-model="formData.letterEnd"
                type="date"
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                  outlined
                  :label="formData?.letterType?.includes('rejection')? 'Ablehnungsgrund*': 'Anmerkung (Erscheint auf dem Bescheid)'"
                  v-model="formData.additionalComment"
                  :rules="formData?.letterType?.includes('rejection')? [rules.required]: []"
                ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <small>* Pflichtfeld</small>
    </v-card-text>
    <v-card-actions>
      <v-btn color="red darken-1" text @click="closeLetterForm()">
        Schließen
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        @click="createLetter()"
        v-if="mode == 'CREATE'"
      >
        Erstellen
      </v-btn>
      <v-btn color="blue darken-1" text @click="updateLetter()" v-else>
        Aktualisieren
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import appConfig from "../../config";
import utils from "../../utils";
import axios from "axios";
import currency from "currency.js";

export default {
  name: "LetterForm",
  props: ["caseData", "mode", "letterData"],
  data() {
    return {
      snackbarText: "",
      snackBarType: "SUCCESS",
      letterTypeSelect: "",
      calculationSelect: "",
      isRejection: false,
      snackbar: false,
      loading: false,
      formattedLetterResultType: "",
      cData: {}, //caseData
      lData: {}, //letterData
      availableLetterTypes: [],
      availableCalculations: [],
      calculations: [],
      availablePreviousLetters: [],
      availableApplications: [],
      calculationMapping: {},
      availableOfficials: [],
      formData: {
        dateOfIssue: utils.formatDateForDateInput(new Date()),
      },
      rules: {
        required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
      },
    };
  },
  computed: {
    noIncomeCalc: {
      get() {
        return this.caseData.receivingSocialBenefits != "0";
      },
    }
  },
  async mounted() {

    this.loading = true;

    const requestConfig = {
      headers: {
        sessiontoken: localStorage.getItem("token"),
      },
    };
    
    try {
      this.cData = JSON.parse(JSON.stringify(this.caseData));

      // Get available letter types
      const letterTypeMappingURL = `${appConfig.apiURL}/static/mappings/lettertypemapping`;

      const letterTypeMappingResponse = await axios.get(letterTypeMappingURL, requestConfig);

      this.availableLetterTypes = Object.keys(letterTypeMappingResponse.data).map((key) => {
        return {
          text: key,
          value: letterTypeMappingResponse.data[key]
        };
      });

      // Get available previous letters
      const previousLetterURL = `${appConfig.apiURL}/sozialstaffel/letters?$filter=case eq cast('${this.caseData._id}',ObjectId) and status eq 'finished'&$sort=-createdAt`;
      const previousLetterResponse = await axios.get(previousLetterURL, requestConfig);

      this.availablePreviousLetters = previousLetterResponse.data.data.map((letter) => {
        return {
          text: `Bescheid vom ${utils.formatDate(letter.createdAt)} (Gültig: ${utils.formatDate(letter.letterStart)} - ${utils.formatDate(letter.letterEnd)})`,
          value: letter._id
        };
      });

      // Get available applications
      const applicationsURL = `${appConfig.apiURL}/sozialstaffel/applications?$filter=case eq cast('${this.caseData._id}',ObjectId)`;
      const applicationsResponse = await axios.get(applicationsURL, requestConfig);

      this.availableApplications = applicationsResponse.data.data.filter(app => app.dateOfCompletion).map((application) => {
        return {
          text: `Antrag vom ${utils.formatDate(application.dateOfApplication)}`,
          value: application._id
        };
      });

      const officialNameUrl = `${appConfig.apiURL}/sozialstaffel/officials/names`;

      const officialNameResp = await axios.get(officialNameUrl, requestConfig);

      officialNameResp.data.forEach(official => {
        this.availableOfficials.push({
          value: official.userId,
          text: `${official.firstName} ${official.lastName}`
        });
      });

      //check if case has social benefits -> set available calculations accordingly
      if(this.noIncomeCalc) {
        this.availableCalculations = [
          {
            text: "Pauschalfreistellung wegen Sozialleistungsbezug",
            value: null
          }
        ];

        this.formData.calculation = null;
      } else {
        const calculationsURL = `${appConfig.apiURL}/sozialstaffel/calculations?$filter=status eq 'finished' and case eq cast('${this.caseData._id}',ObjectId)`;

        const calculationsResponse = await axios.get(calculationsURL, requestConfig);

        this.calculations = calculationsResponse.data.data;

        this.availableCalculations = calculationsResponse.data.data.map((calc) => {
          return {
            text: `Einkommensberechnung vom ${utils.formatDate(calc.createdAt)} (Gültig: ${utils.formatDate(calc.calculationStart)} - ${utils.formatDate(calc.calculationEnd)})`,
            value: calc._id
          };
        });
      }
    } catch(e) {
      this.showSnackbar("ERROR", appConfig.defaultErrorText);
    }

    if (this.mode == "UPDATE") {
      const letter = JSON.parse(JSON.stringify(this.letterData));
      this.lData = letter;

      this.formData = {
        letterType: letter.letterType,
        dateOfApplication: utils.formatDateForDateInput(
          letter.dateOfApplication
        ),
        dateOfIssue: utils.formatDateForDateInput(letter.dateOfIssue),
        letterStart: utils.formatDateForDateInput(letter.letterStart),
        letterEnd: utils.formatDateForDateInput(letter.letterEnd),
        reference: letter.reference,
        calculation: letter.calculation,
        previousLetter: letter.previousLetter,
        application: letter.application,
        additionalComment: letter.additionalComment,
        owner: letter.owner
      };
    } else {
      this.formData.owner = this.cData.owner._id;
    }

    this.loading = false;
  },
  methods: {
    resetForm: function () {
      this.cData = {};
      this.formData = {
        dateOfIssue: utils.formatDateForDateInput(new Date())
      };
    },
    showSnackbar(type, text) {
      if (type == "ERROR") {
        this.snackBarType = "ERROR";
        this.snackbarText = text;
      } else {
        this.snackBarType = "SUCCESS";
        this.snackbarText = text;
      }

      this.snackbar = true;
    },
    getCurrencyFormatted(num, supressWarning) {
      if(num == undefined) {
        if(!supressWarning){ console.log("tried to convert undefined via currency formatter"); }
        return;
      }
      return num.toFixed(2).toString().replace(".", ",");
    },
    getletterResultType() {
      let type = "";

      if(this.isRejection) {
        return "rejection";
      } 

      if(this.noIncomeCalc) {
        type = "full_without_calc"
      } else {
        console.log(this.formData?.calculation?.incomeToBeUsed)

        if(this.formData?.calculation?.incomeToBeUsed <= 0) {
         type = "full_with_calc"
        } else {
          type = "partial";
        }
      }

      return type;
    },

    getFormattedLetterResultType() {
        switch(this.formData?.resultType) {
          case "partial":
            return "Teilermäßigung"
          case "full_with_calc":
            return "Vollermäßigung (durch Berechnung)"
          case "full_without_calc":
            return "Vollermäßigung wegen Sozialleistungsbezug"
          case "rejection":
            return "Ablehnungsbescheid"
          default:
            return ""
        }
    },

    createLetter: async function () {
      console.log(this.formData);

      if (!this.$refs.form.validate()) {
        return;
      }

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/letters`;

      let body = this.formData;
      body.case = this.cData._id;
      
      try {
        const resp = await axios.post(url, body, requestConfig);
        this.resetForm();
        this.$refs.form.resetValidation();

        this.$emit("closesuccess", resp.data);
      } catch (e) {
        console.log(e);
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },
    updateLetter: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/letters/${this.lData._id}`;

      let body = this.formData;

      try {
        const resp = await axios.put(url, body, requestConfig);

        this.resetForm();
        this.$refs.form.resetValidation();

        this.$emit("closesuccess", resp.data);
      } catch (e) {
        console.log(e);
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },
    /* Form Controls */
    closeLetterForm: function () {
      this.resetForm();
      this.$refs.form.resetValidation();
      this.$emit("close");
    },

    changeCalculation() {
      const selectedCalculation = this.calculations.filter((calc) => {
        return calc._id == this.formData.calculation;
      })[0];

      if(selectedCalculation) {
        this.formData.letterStart = utils.formatDateForDateInput(selectedCalculation.calculationStart);
        this.formData.letterEnd = utils.formatDateForDateInput(selectedCalculation.calculationEnd);
      }
    },
  },
};
</script>