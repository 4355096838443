<template>
  <v-main
    class="mt-3"
    style="background-color: #ffffff; width: 100vw; height: 95vh"
  >
    <v-app-bar app elevation="1" color="white">
      <v-btn color="primary" elevation="0" @click="back()" outlined>
        <v-icon>mdi-arrow-left-thick</v-icon>
        Zurück zur Startseite
      </v-btn>
    </v-app-bar>
      <v-snackbar
        v-model="errorSnackbar"
        timeout="3000"
        color="red"
        dark
        :absolute="true"
        :top="true"
      >
        {{ errorSnackbarText }}
      </v-snackbar>
      <v-snackbar
        v-model="successSnackbar"
        timeout="3000"
        color="blue"
        dark
        :absolute="true"
        :top="true"
      >
        {{ successSnackbarText }}
      </v-snackbar>
    
    <v-row class="justify-center">
      <v-col cols="12" sm="12" md="6">
        <v-card>
          <v-card-title class="text-h5">
            Allgemeine Sicherheitseinstellungen
          </v-card-title>
          <v-card-text>
            <v-form ref="settingsForm">
              <v-row>
                <v-col cols="12" class="mt-3">
                  <v-alert type="info" colored-border border="left" elevation="0">
                    Die Sicherheitseinstellungen sind in Absprache mit dem Kreis Rendsburg-Eckernförde vorkonfiguriert und orientieren sich streng an den BSI-Sicherheitsstandards. Bitte unterschreiten Sie das hinterlegte Sicherheitsniveau durch abweichende Einstellungen nicht.
                  </v-alert>
                  <h2 class="mt-6">Einstellung für Anmeldung</h2>
                </v-col>
                <v-col cols="12" sm="12" md="4" class="pt-7" style="font-size: 12pt">
                    2-Faktor Authentifizierung
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-model="formData.twoFactorMode"
                    :items="availableTwoFactorModes"
                    label="2-Faktor Authentifizierung"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
               <br/>
                <v-col cols="12" sm="12" md="4" class="pt-7" style="font-size: 12pt">
                    Passwort zurücksetzen Intervall (Tage)
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="formData.passwordExpiryIntervalDays"
                    type="number"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mt-6">
                  <h2>Einstellung für Passwörter</h2>
                </v-col>
                  <v-col cols="12" sm="12" md="4" class="pt-7" style="font-size: 12pt">
                      Mindestlänge
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="formData.passwordPolicy.minLength"
                      type="number"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" class="pt-7" style="font-size: 12pt">
                      Mindestanzahl Zahlen
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="formData.passwordPolicy.minNumbers"
                      type="number"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" class="pt-7" style="font-size: 12pt">
                      Mindestanzahl Sonderzeichen
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="formData.passwordPolicy.minSpecialChars"
                      type="number"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" @click="updateLoginSettings()" elevation="0">
              Änderungen Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import axios from "axios";

import appConfig from "../config";

export default {
  name: "SecurityConfig",
  data() {
    return {
      //snackbars
      errorSnackbar: false,
      errorSnackbarText: "",
      successSnackbar: false,
      successSnackbarText: "",

      availableTwoFactorModes: [
        { text: "Bei jeder Anmeldung", value: "ALWAYS" },
        { text: "Niemals", value: "NEVER" }
      ], 
      selectedTwoFactorMode: "",

      formData: {
        passwordExpiryIntervalDays: 0,
        twoFactorMode: "ALWAYS",
        passwordPolicy: {}
      },
      rules: {
        required: (value) =>  {
          if(!!value || value === 0) {
            return true;
          }
          
          return "Pflichtfeld, bitte ausfüllen" 
        }
      },
    }
  },
  methods: {
    /*
     * common
     */
    back() {
      this.$router.push("/launchpad");
    },
    showSnackbar(type, text) {
      if (type == "ERROR") {
        this.errorSnackbar = true;
        this.errorSnackbarText = text;
      } else {
        this.successSnackbar = true;
        this.successSnackbarText = text;
      }
    },
    async updateLoginSettings() {
      if(!this.$refs.settingsForm.validate()) {
        return;
      }

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.token,
        },
      };

      const url = `${appConfig.apiURL}/settings/tenant/security`;

      try {
        this.formData.passwordExpiryIntervalDays = parseInt(this.formData.passwordExpiryIntervalDays);
        this.formData.passwordPolicy.minLength = parseInt(this.formData.passwordPolicy.minLength);
        this.formData.passwordPolicy.minNumbers = parseInt(this.formData.passwordPolicy.minNumbers);
        this.formData.passwordPolicy.minSpecialChars = parseInt(this.formData.passwordPolicy.minSpecialChars);


        const body = { value: this.formData };

        const response = await axios.put(url, body, requestConfig);

        this.showSnackbar("SUCESS", "Änderungen erfolgreich gespeichert");
      } catch(e) {
        this.showSnackbar("ERROR", "Es ist ein unerwarteter Fehler aufgetreten");
      } finally {
        window.scrollTo({ top: 0 });
      }
    }
  },
  async mounted() {
    const requestConfig = {
        headers: {
          sessiontoken: localStorage.token,
        },
    };

    const url = `${appConfig.apiURL}/settings/tenant/security`;

    try {
        const response = await axios.get(url, requestConfig);

        this.formData = response.data.value;
    } catch(e) {
      this.showSnackbar("ERROR", "Es ist ein unerwarteter Fehler aufgetreten");
    }
  }
};
</script>