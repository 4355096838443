<template>
  <div>
    <div class="pa-8">
      <v-row>
        <v-col cols="12" md="4" xs="12">
          <SozialstaffelDashboardCard
            icon="mdi-paperclip"
            header="Wiedervorlage"
            countURL="/sozialstaffel/cases/resubmission/count"
            route="/sozialstaffel/cases/fav"
          />
        </v-col>
        <v-col cols="12" md="4" xs="12">
          <SozialstaffelDashboardCard
            icon="mdi-account"
            header="Meine Fälle"
            countURL="/sozialstaffel/cases/count"
            route="/sozialstaffel/cases/my"
          />
        </v-col>
        <v-col cols="12" md="4" xs="12">
          <SozialstaffelDashboardCard
            icon="mdi-account-multiple"
            header="Alle Fälle"
            countURL="/sozialstaffel/cases/count"
            route="/sozialstaffel/cases/all"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="8" xs="12" cols="12">
          <v-card class="pa-1">
            <v-card-title>Meine aktiven Bescheide {{ (new Date()).getFullYear() }} im Zeitverlauf</v-card-title>
            <v-card-text>
              <div v-if="activeLettersPerWeek.loading" class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div id="chart" v-else>
                <apexchart type="area" height="350" :options="activeLettersPerWeek.chartOptions" :series="activeLettersPerWeek.series"></apexchart>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="4" xs="12" cols="12">
          <v-card class="px-1 pt-2 pb-4">
            <v-card-title>Hilfe benötigt? Wir helfen gerne weiter!</v-card-title>
            <v-divider class="mb-3"></v-divider>
            <v-icon class="mx-4" color="primary"> mdi-email </v-icon>
            <a href="mailto:support@verwaltungscloud.sh">Support Email</a>
            <v-divider class="my-3"></v-divider>
            <v-icon class="mx-4" color="primary"> mdi-file-document </v-icon>
            <a href="https://doku.tools.verwaltungscloud.dev/de/sozialstaffelplus">
              Online Dokumentation
            </a>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import appConfig from "../../config";
import utils from "../../utils";
import axios from "axios"

import SozialstaffelDashboardCard from "../../components/sozialstaffel/DashboardCard.vue";

export default {
  name: "SozialstaffelDashboard",
  components: {
    SozialstaffelDashboardCard,
  },
  data() {
    return {
      caseActivityHeaders: [
        {
          text: "Beschreibung",
          align: "start",
          sortable: false,
          value: "description",
        },
        { text: "Datum", value: "date", sortable: false },
      ],
      caseActivitys: [],
      caseActivitysLoading: false,
      caseActivityCount: 0,
      caseActivityTableOptions: {
        itemsPerPage: 10
      },
      //stacked area
    activeLettersPerWeek: {
        loading: true,
        series: [],
        chartOptions: {
          chart: {
            type: 'area',
            height: 350,
            events: {
              selection: function (chart, e) {
                console.log(new Date(e.xaxis.min))
              }
            },
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false
              }
            }
          },
          //colors: ['#008FFB', '#00E396', '#CED4DC'],
          dataLabels: {
            enabled: false
          },    
          stroke: {
            curve: 'straight'
          },
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 0.6,
              opacityTo: 0.8,
            }
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left'
          },
          xaxis: {
            type: 'datetime'
          },
          yaxis: {
            min: 0,
            stepSize: 1,
            labels: {
              formatter: function (val) {
                return val;
              }
            }
          }
        }    
      },
    };
  },
  methods: {
  },
  async mounted() {
    this.activeLettersPerWeek.loading = true;

    const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const year = new Date().getFullYear();

      const url = `${appConfig.apiURL}/analytics/sozialstaffel/letters/activeperweek/my?startDate=${year}-01-01&endDate=${year}-12-31`;

      const activePerWeekReponse = await axios.get(url, requestConfig)

      this.activeLettersPerWeek.series = [
        {
          "name": `Aktive Bescheide ${year}`,
          data: activePerWeekReponse.data[Object.keys(activePerWeekReponse.data)[0]].map(d => {
            return [ new Date(d.startDate).getTime(), d.count ]
          })
        }
      ];

      this.activeLettersPerWeek.loading = false;
  }
};
</script>