<template>
  <v-main
    class="mt-3"
    style="background-color: #ffffff; width: 100vw; height: 95vh"
  >
    <v-app-bar app elevation="1" color="white">
      <v-btn color="primary" elevation="0" @click="back()" outlined>
        <v-icon>mdi-arrow-left-thick</v-icon>
        Zurück zur Startseite
      </v-btn>
    </v-app-bar>
      <v-snackbar
        v-model="errorSnackbar"
        timeout="3000"
        color="red"
        dark
        :absolute="true"
        :top="true"
      >
        {{ errorSnackbarText }}
      </v-snackbar>
      <v-snackbar
        v-model="successSnackbar"
        timeout="3000"
        color="blue"
        dark
        :absolute="true"
        :top="true"
      >
        {{ successSnackbarText }}
      </v-snackbar>
    <div class="ma-9">
      <v-row>
        <h1>Sozialermäßigung Statistik (Lokal)</h1>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-alert type="error" color="red" elevation="0" v-if="intervalError">
            Bitte überprüfen Sie das angegebene Intervall. Der Startzeitpunkt muss vor dem Endzeitpunkt liegen.
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-select
            class="mt-4"
            label="Intervall"
            :items="availableIntervals"
            v-model="selectedInterval"
            @change="setInterval()"
          ></v-select>
        </v-col>
        <v-col
            cols="4" 
            style="padding-top: 25px"  
            v-if="selectedInterval == 'Eigenes Intervall'" 
        >
            <v-menu
                v-model="dateRangePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="formattedDateRange"
                        label="Zeitraum auswählen"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateRange"
                    @change="selectedDateRange()"
                    locale="de-de"
                    range
                ></v-date-picker>
            </v-menu>
            </v-col>
      </v-row>
      <v-row>
        <!-- Begin ActiveLettersPerWeek -->
        <v-col cols="12">
          <v-card>
            <v-card-title>
              Verlauf aktive Bescheide
            </v-card-title>
            <v-card-text>
              <div v-if="activeLettersPerWeek.loading" class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div id="chart" v-else>
                <apexchart type="area" height="350" :options="activeLettersPerWeek.chartOptions" :series="activeLettersPerWeek.series"></apexchart>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- End ActiveLettersPerWeek -->
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title class="justify-center" style="font-size: 25pt">
                  {{ numOfletters }}
                </v-card-title>
                <v-card-text class="text-center">
                  <h3>Anzahl Bescheide insgesamt</h3>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title class="justify-center" style="font-size: 25pt">
                  {{ numOfActiveCases }}
                </v-card-title>
                <v-card-text class="text-center">
                  <h3>Anzahl aktiver Fälle</h3>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!-- Begin LetterByResultChart -->
        <v-col cols="12" md="4">
          <v-card>
            <v-card-title>
              Aktive Bescheide nach Beischeidtyp
            </v-card-title>
            <v-card-text>
              <div v-if="letterByResultChart.loading" class="text-center">
                <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              </div>
              <div id="chart" v-else>
                <apexchart type="donut" :options="letterByResultChart.chartOptions" :series="letterByResultChart.series"></apexchart>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- End LetterByResultChart -->
        <!-- Begin ActiveCasesBySocialBenefitsChart -->
        <v-col cols="12" md="5">
          <v-card>
            <v-card-title>
              Aktive Bescheide mit Sozialleistungsbezug
            </v-card-title>
            <v-card-text>
              <div v-if="activeCasesBySocialBenefits.loading" class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div id="chart">
                <apexchart type="donut" :options="activeCasesBySocialBenefits.chartOptions" :series="activeCasesBySocialBenefits.series"></apexchart>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- End ActiveCasesBySocialBenefitsChart -->
      </v-row>
    </div>
  </v-main>
</template>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 12pt !important;
}

.v-data-table.v-data-table.v-data-table >>> th {
  font-size: 14pt !important;
}
</style>
<script>
import axios from "axios";
import utils from "../utils";
import appConfig from "../config";

export default {
  name: "Analytics",
  data() {
    return {
      dateRangePicker: false,
      intervalError: false,
      dateRange: ['2019-09-10', '2019-09-20'],

      //dynamic data
      selectedTenants: [],
      selectedInterval: "",

      availableIntervals: [
        "letzte 30 Tage",
        "letzte 90 Tage",
        "letzte 6 Monate",
        "letzte 12 Monate",
        "Eigenes Intervall"
      ],

      numOfletters: 0,
      numOfSocialBenefitsLetters: 0,
      numOfActiveCases: 0,

    //donutChart 
    letterByResultChart: {
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          type: 'donut',
           toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true
            }
          }
        },
        labels: ["Teilermäßigung", "Pauschalbefreiung", "Vollermäßigung", "Ablehnung"],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    },

    activeCasesBySocialBenefits: {
      loading: true,
      series: [],
      chartOptions: {
        colors:['#2B2D42', '#92DCE5', '#F8F7F9', '#F7EC59', '#AB2346', '#ED474A', '#0CCA4A', '#3A7D44'],
        chart: {
          type: 'donut',
           toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true
            }
          }
        },
        labels: Object.keys(appConfig.socialBenefitsMapping).map(socBen => socBen.replace("Leistungen ", "")),
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'top',
              horizontalAlign: 'left'
            }
          }
        }]
      }
    },

    //stacked area
    activeLettersPerWeek: {
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          type: 'area',
          height: 350,
          stacked: true,
          events: {
            selection: function (chart, e) {
              console.log(new Date(e.xaxis.min))
            }
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          }
        },
        //colors: ['#008FFB', '#00E396', '#CED4DC'],
        dataLabels: {
          enabled: false
        },    
        stroke: {
          curve: 'straight'
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
        xaxis: {
          type: 'datetime'
        },
      }    
    },
      //snackbars
      errorSnackbar: false,
      errorSnackbarText: "",
      successSnackbar: false,
      successSnackbarText: "",
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  computed: {
      formattedDateRange: {
        get() {
            return `${utils.formatDate(this.dateRange[0])} bis ${utils.formatDate(this.dateRange[1])}`;
        },
      }
  },
  methods: {
    /*
     * common
     */
    back() {
      this.$router.push("/launchpad");
    },
    showSnackbar(type, text) {
      if (type == "ERROR") {
        this.errorSnackbar = true;
        this.errorSnackbarText = text;
      } else {
        this.successSnackbar = true;
        this.successSnackbarText = text;
      }
    },
    rerenderCharts(start, end) {
      this.renderLetterByResultChart(start, end);
      this.renderActiveLettersPerWeek(start, end);
      this.renderActiveCasesBySocialBenefitsChart(start, end);
    },

    async renderLetterByResultChart(start, end) {
      this.letterByResultChart.loading = true;

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/analytics/sozialstaffel/letters/byresult?dateOfIssueFrom=${utils.formatDateForDateInput(start)}&dateOfIssueTo=${utils.formatDateForDateInput(end)}`;

      const resp = await axios.get(url, requestConfig);

      this.letterByResultChart.series = [resp.data.partial, resp.data.full_without_calc, resp.data.full_with_calc, resp.data.rejection]
      this.numOfletters = this.letterByResultChart.series.reduceRight((acc, cur) => acc + cur, 0);

      this.letterByResultChart.loading = false;
    },

    async renderActiveLettersPerWeek(start, end) {
      this.activeLettersPerWeek.loading = true;

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/analytics/sozialstaffel/letters/activeperweek?startDate=${utils.formatDateForDateInput(start)}&endDate=${utils.formatDateForDateInput(end)}`;

      const resp = await axios.get(url, requestConfig);

      const series = []

      for(let tenantId of Object.keys(resp.data)) {
        series.push({
          name: "Anzahl Bescheide",
          data: resp.data[tenantId].map(d => {
            return [ new Date(d.startDate).getTime(), d.count ]
          })
        });
      }

      this.activeLettersPerWeek.series = series;

      this.activeLettersPerWeek.loading = false;
    },

    async renderActiveCasesBySocialBenefitsChart(start, end) {
      this.activeCasesBySocialBenefits.loading = true;

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/analytics/sozialstaffel/letters/activebysocialbenefittype?startDate=${utils.formatDateForDateInput(start)}&endDate=${utils.formatDateForDateInput(end)}`;

      const resp = await axios.get(url, requestConfig);

      this.activeCasesBySocialBenefits.series = Object.values(resp.data);
      this.numOfActiveCases = this.activeCasesBySocialBenefits.series.reduceRight((acc, cur) => acc + cur, 0);

      this.activeCasesBySocialBenefits.loading = false;
    },

    //utils
    getStartEndDate() {
      let start, end = new Date();

      const d = new Date()

      if(this.selectedInterval == "letzte 30 Tage") {
        d.setDate(new Date().getDate() - 30);

        start = d;
      } else if(this.selectedInterval == "letzte 90 Tage") {
        d.setDate(new Date().getDate() - 90);

        start = d;
      } else if(this.selectedInterval == "letzte 6 Monate") {
        d.setDate(new Date().getDate() - 180);

        start = d;
      } else if(this.selectedInterval == "letzte 12 Monate") {
        d.setDate(new Date().getDate() - 360);

        start = d;
      } else if(this.selectedInterval == "Eigenes Intervall") {
          start = this.dateRange[0];
          end = this.dateRange[1];
      }

      return { start, end };
    },
    setInterval() {
        this.intervalError = false;

        const { start, end } = this.getStartEndDate();

        if(start > end) {
            this.showSnackbar("ERROR", "Startdatum muss vor dem Enddatum liegen");
            this.intervalError = true;
            return;
        }

        this.rerenderCharts(start, end);
    },
    selectedDateRange() {
        this.setInterval();
        this.dateRangePicker = false;
    }
  },
  async beforeMount(){
    //set selected interval o default value
    this.selectedInterval = this.availableIntervals[0];

    const d = new Date();
    d.setDate(new Date().getDate() - 30);

    this.dateRange = [d.toISOString(), new Date().toISOString()];
    this.setInterval();
  }
};
</script>