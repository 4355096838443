<template>
    <v-main class="mt-3" style="background-color: #ffffff; width: 100vw; height: 95vh">
        <v-app-bar app elevation="1" color="white">
            <v-btn color="primary" elevation="0" @click="back()" outlined :disabled="tenant === ''">
                <v-icon>mdi-arrow-left-thick</v-icon>
                Zurück zum Launchpad
            </v-btn>
        </v-app-bar>
        <div class="ma-9">
            <v-snackbar v-model="errorSnackbar" timeout="3000" color="red" dark :absolute="true" :top="true">
                {{ errorSnackbarText }}
            </v-snackbar>
            <v-snackbar v-model="successSnackbar" timeout="3000" color="blue" dark :absolute="true" :top="true">
                {{ successSnackbarText }}
            </v-snackbar>


            <v-data-table :headers="headers" :items="data" :options.sync="options" :loading="loading"
                class="elevation-0 mt-6">
                <template v-slot:[`item.supportAccess`]="{ item }">
                    <v-icon :color="item.supportAccess ? 'green' : 'red'">
                        {{ item.supportAccess ? "mdi-check-circle" : "mdi-close-circle" }}
                    </v-icon>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="mr-2" v-on:click="setTenant(item)" v-bind="attrs" v-on="on"
                                :disabled="!item.supportAccess || item.id === tenant"
                                :color="(item.id === tenant) ? 'secondary' : 'primary'">
                                {{ (item.id === tenant) ? "mdi-radiobox-marked" : "mdi-radiobox-blank" }}
                            </v-icon>
                        </template>
                        <span>Auswählen</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </div>
    </v-main>
</template>
<style lang="css" scoped>
.row-pointer>>>tbody tr :hover {
    cursor: pointer;
}

.v-data-table.v-data-table.v-data-table>>>td {
    font-size: 12pt !important;
}

.v-data-table.v-data-table.v-data-table>>>th {
    font-size: 14pt !important;
}
</style>
<script>
import axios from "axios";

import appConfig from "../config";

export default {
    name: "UsersTable",
    data() {
        return {
            //table related data
            data: [],
            tenant: "",
            loading: true,
            options: {},
            headers: [
                { text: "Id", value: "id" },
                { text: "Name", value: "name" },
                { text: "Support Access", align: "center", value: "supportAccess" },
                { text: "", value: "actions", sortable: false },
            ],

            //snackbars
            errorSnackbar: false,
            errorSnackbarText: "",
            successSnackbar: false,
            successSnackbarText: "",
        };
    },
    watch: {
        options: {
            handler() {
                this.getDataFromApi();
            },
            deep: true,
        },
    },
    methods: {
        /*
         * common
         */
        back() {
            this.$router.push("/launchpad");
        },
        showSnackbar(error, text) {
            if (error) {
                this.errorSnackbar = true;
                this.errorSnackbarText = text;
            } else {
                this.successSnackbar = true;
                this.successSnackbarText = text;
            }
        },

        /*
         * table
         */
        getDataFromApi() {
            this.loading = true;
            this.fetchData().then((data) => {
                this.tenant = data.myTenant.data?.id || ""
                this.data = data.availableTenants.data;
                this.loading = false;
            });
        },
        fetchData() {
            return new Promise(async (resolve) => {
                const config = {
                    headers: {
                        sessiontoken: localStorage.token,
                    },
                };

                const responseMyTenant = await this.getMyTenant(config)
                const responseAvailableTenants =
                    await this.getTenantsAvailableSupportAccess(config);

                resolve({ myTenant: responseMyTenant, availableTenants: responseAvailableTenants });
            });
        },
        getMyTenant: async function (config) {
            let url = `${appConfig.apiURL}/tenants/my`;

            try {
                const response = await axios.get(url, config);
                return response
            } catch (e) {
                if (!e.response.status === 404) {
                    this.showSnackbar(false, "Fehler bei Ermittlung des aktuellen Tenants.");
                }
                return e.response
            }
        },
        getTenantsAvailableSupportAccess: async function (config) {
            let url = `${appConfig.apiURL}/supportuser/tenants`;

            try {
                const response = await axios.get(url, config);
                return response
            } catch (e) {
                this.showSnackbar(false, "Fehler bei Ermittlung der verfügbaren Tenants.");
            }
        },


        setTenant: async function (item) {
            const config = {
                headers: {
                    sessiontoken: localStorage.token,
                },
            };
            const data = {
                tenant: item.id
            };

            let url = `${appConfig.apiURL}/supportuser/settenant`;

            try {
                const response = await axios.put(url, data, config);
                if (response.status === 200) {
                    this.getDataFromApi()
                }
            } catch (e) {
                this.showSnackbar(false, "Fehler bei der Auswahl des Tenants.");
            }
        },
    },
};
</script>