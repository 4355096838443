<template>
  <span>
    <SozialstaffelNavLayout v-if="hasBaseLayout" :key="reloadNav" />
    <v-main>
      <v-row justify="center">
        <v-dialog
          v-model="setupDialog"
          persistent
          max-width="800"
          overlay-color="black"
          overlay-opacity="0.8"
        >
          <v-card>
            <v-card-title class="text-h5" v-if="!setupLoading">
              Sozialstaffel PLUS Sachbearbeiterkonto einrichten
            </v-card-title>
            <v-card-title class="text-h5" v-else>
              Schließe Einrichtung ab&nbsp;&nbsp;&nbsp;
              <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
            </v-card-title>
            <v-card-text v-if="setupLoading">
            </v-card-text>
            <v-card-text v-else>
              <v-row>
                <v-col cols="12" v-if="error">
                  <v-alert
                    type="error"
                  >Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support.</v-alert>
                </v-col>
                <v-col cols="12">
                  <p style="font-size: 16px">Bevor Sie die Anwendung verwenden können benötigen wir noch folgende Angaben von Ihnen. <br /><b>Diese Daten erscheinen auf den Bescheiden.</b></p>
                </v-col>
              </v-row>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12">
                    <h3>Kontaktdaten</h3>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-select
                      label="Anrede*"
                      v-model="formData.formOfAddress"
                      :items="availableFormsOfAddress"
                      required
                      :rules="[rules.required]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-text-field
                      label="Arbeitsort*"
                      v-model="formData.location"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-text-field
                      label="Raumnummer*"
                      v-model="formData.room"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-text-field
                      label="Telefonnummer*"
                      v-model="formData.phone"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <small>* Pflichtfeld bitte ausfüllen</small>
                  </v-col>          
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions v-if="!setupLoading">
              <v-btn
                color="red darken-1"
                text
                @click="toLaunchpad()"
              >
                Zurück zur Startseite
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                @click="completeSetup()"
              >
                Einrichtung abschließen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <router-view></router-view>
    </v-main>
  </span>
</template>
<script>
import appConfig from "../../config";
import utils from "../../utils";
import axios from "axios";

import SozialstaffelNavLayout from "./../../components/sozialstaffel/SozialstaffelNavLayout.vue";

export default {
  components: {
    SozialstaffelNavLayout,
  },
  data() {
    return {
      setupDialog: false,
      setupLoading: false,
      error: false,
      formData: {
        phone: "",
        room: "",
        location: "",
        formOfAddress: ""
      },
      availableFormsOfAddress: [
          { text: "Herr", value: "Herr" },
          { text: "Frau", value: "Frau" },
          { text: "OHNE", value: " "}
      ],
      rules: {
        required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
      }
    }
  },
  computed: {
    hasBaseLayout: {
      get: function () {
        return (
          this.$router.currentRoute.meta &&
          !this.$router.currentRoute.meta.disableBaseLayout
        );
      },
    },
  },
  methods: {
    toLaunchpad: function() {
      this.$router.push("/launchpad")
    },
    completeSetup: async function() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.setupLoading = true;

      console.log()
      const body = {
        user: JSON.parse(localStorage.currentUser)._id,
        ...this.formData
      }

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/officials`;

      try {
        const MyOfficialResponse = await axios.post(url, body, requestConfig);

        this.setupLoading = false;
        this.setupDialog = false;
      } catch(e) {
        this.setupLoading = false;
        this.error = true;
      }

      
    }
  },
  async mounted() {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/officials/my`;

      try {
        const MyOfficialResponse = await axios.get(url, requestConfig);

        console.log(MyOfficialResponse.data);
      } catch(e) {
        this.setupDialog = true;
        console.log(e);
      }
  }
};
</script>