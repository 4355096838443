<template>
<v-app style="background: rgba(230, 230, 230, 0.2)">
    <router-view />
</v-app>
</template>
<script>
import axios from 'axios';
import appConfig from "./config";

export default {
  mounted() {
    setInterval(async () => {
        if(this.$route.meta.requiresAuth) {
            const requestConfig = {
                headers: {
                    sessiontoken: localStorage.getItem("token"),
                },
            };

            try {
                const url = `${appConfig.apiURL}/users/my`;

                const myUserResponse = await axios.get(url, requestConfig);

                localStorage.currentUser = JSON.stringify(myUserResponse.data);
            } catch (e) {
                if(e.response.status === 401) {
                    this.$router.push("/login");
                    return;
                }

                console.log(e);
            }
        }
    }, 30000)
  }
}
</script>