<template>
    <v-card v-if="loading">
        <v-card-title>
            <span class="text-h5">
                Nachforderung bearbeiten
            </span>
        </v-card-title>
        <v-card-text>
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-card-text>
    </v-card>
    <DocRequestForm
        v-else 
        formTitle="Nachforderung bearbeiten"
        :caseData="caseData"
        :prefill="prefill"
        :key="reloadDocRequestForm"
        v-on:close="closeDocRequestForm"
        v-on:safe="safeDocRequestForm"
    />
</template>
<script>
    import appConfig from "../../../config";
    import axios from "axios";
    import DocRequestForm from "./DocRequestForm.vue";

    export default {
        name: "DocRequestUpdateForm",
        components: {
            DocRequestForm
        },
        props: [ "documentRequestId" ],
        data() {
            return {
                reloadDocRequestForm: 0,
                prefill: {},
                caseData: {},
                loading: true
            }
        },

        async mounted() {
            this.loading = true;

            const requestConfig = {
                    headers: {
                        sessiontoken: localStorage.getItem("token"),
                    },
                };

            try {
                const documentRequestsURL = `${appConfig.apiURL}/sozialstaffel/documentrequests/?$filter=_id eq cast('${this.documentRequestId}',ObjectId)&$expand=application,case`;
                const documentRequestsResponse = await axios.get(documentRequestsURL, requestConfig);

                const documentRequest = documentRequestsResponse.data.data[0];

                this.prefill = {
                    application: documentRequest.application._id,
                    dateOfIssue: documentRequest.dateOfIssue,
                    case: documentRequest.case._id,
                    deadline: documentRequest.deadline,
                    owner: documentRequest.owner,
                    requestedDocuments: documentRequest.requestedDocuments,
                    additionalComment: documentRequest.additionalComment
                };
                this.caseData = documentRequestsResponse.data.data[0].case;

                
            } catch(e) {
                this.$emit('closeerror');
            }

            this.reloadDocRequestForm += 1;

            this.loading = false;
        },

        methods: {
            closeDocRequestForm() {
                this.$emit('close');
            },
            async safeDocRequestForm(data) {
                this.loading = true;

                const requestConfig = {
                    headers: {
                        sessiontoken: localStorage.getItem("token"),
                    },
                };

                try {
                    const documentRequestsURL = `${appConfig.apiURL}/sozialstaffel/documentrequests/${this.documentRequestId}`;
                    const documentRequestsResponse = await axios.put(documentRequestsURL, data, requestConfig);

                    this.$emit('closesuccess', documentRequestsResponse.data);
                } catch(e) {
                    this.$emit('closeerror');
                }

            }
        }
    }
</script>