<template>
  <div style="background-color: white; min-height: 100vh">
    <v-snackbar
      v-model="snackbar"
      timeout="1500"
      :color="snackBarType == 'ERROR' ? 'red' : 'blue'"
      dark
      :absolute="true"
      :top="true"
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- Begin Confirm Dialog -->
    <v-dialog v-model="confirmDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5"> {{ confirmDialogHead }} </v-card-title>
        <v-card-text>{{ confirmDialogText }}</v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="confirmDialogAction()">
            Bestätigen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeConfirmDialog()">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Confirm Dialog -->

    <!-- Begin SafePageLeaveDialog Dialog -->
    <v-dialog v-model="pageLeaveConfirmDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5"> Seite verlassen? </v-card-title>
        <v-card-text>Sind Sie sich sicher, dass Sie die Seite verlassen wollen? <b>Nicht gespeicherte Änderungen gehen unwiderruflich verloren!</b></v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="confirmPageLeave()">
            Bestätigen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="pageLeaveConfirmDialog = false; isSafePageLeave = false">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End SafePageLeaveDialog Dialog -->

    <!-- Begin Family Bonus Error Dialog -->
    <v-dialog v-model="familyBonusErrorDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5"> Fehler beim Validieren des Familienzuschlags </v-card-title>
        <v-card-text>
          Es ist ein Fehler bei der Validierung der Daten des Familienzuschlags aufgetreten,
          bitte versichern Sie sich, dass alle Daten korrekt und vollständig angegeben sind.
          <b>Für jedes Haushaltsmitglied müssen mindestens die Daten Vorname, Nachname und Geburtsdatum hinterlegt sein,
            damit die Berechnung gespeichert werden kann.
          </b>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="familyBonusErrorDialog = false">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Family Bonus Error Dialog -->

    <!-- Begin Confirm Dialog -->
    <v-dialog v-model="timespanErrorDialog" persistent max-width="600px">
      <v-card>
        <v-card-text class="mt-3">
          <v-alert
            colored-border
            border="left"
            type="error"
            v-if="noCalcFormulaFound"
          >
          Für den angegeben Zeitraum gibt es kein Berechnungsschema
          </v-alert>
          <v-alert
            colored-border
            border="left"
            type="error"
            v-if="calcFormulaTimespanError.length > 0"
          >
            <span
              v-for="cf in calcFormulaTimespanError"
              :key="cf.name"
            >
              Berechnungsformel gültig seit 
              {{ cf.validTo? `${formatDate(cf.validFrom)} bis ${formatDate(cf.validTo)}`:  `${formatDate(cf.validFrom)}`}}
              <br />
            </span>
          </v-alert>
          <v-alert
            colored-border
            border="left"
            type="error"
            v-if="timespanError"
          >
          Es gibt eine Überschneidung {{ 
            timespanError.type == "regular"? 'bei mindestens einem Regelbedarfssatz':
            timespanError.type == "additional"? 'bei mindestens einem Mehrbedrafssatz': 'beim Regelsatz'
          }}: <br /><br />
          <b>{{ timespanError.name }}</b><br />
          <span
              v-for="nR in timespanError.values"
              :key="nR?.name"
            >
              {{ getCurrencyFormatted(nR.value, true) }}€ gültig seit 
              {{ nR.validTo? `${formatDate(nR.validFrom)} bis ${formatDate(nR.validTo)}`:  `${formatDate(nR.validFrom)}`}}
              <br />
            </span>
          </v-alert>
          <v-alert
            colored-border
            border="left"
            type="error"
            v-if="noNeedRateFound"
          >
            Es gibt keine Mehrbedarfs-und Regelbedarfsätze im angebebenen Zeitraum
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="closeTimespanErrorDialog()">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Confirm Dialog -->

    <!-- Begin Calculation Dialog -->
    <v-dialog v-model="computedDialog" persistent max-width="600px">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeComputedDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="mt-3">
          <v-alert type="info" border="left" colored-border>
            <b>Berechnete Werte werden automatisch übernommen</b> <br /><br />
            <span v-html="computedDialogSchema.description"></span>
          </v-alert>
          <v-row v-for="(prop, i) in Object.keys(computedDialogSchema.properties)" :key="`computedField${i}`">
            <v-col cols="12" md="6" style="font-size: 16px" class="pt-9">
              {{ computedDialogSchema.properties[prop].label}}
            </v-col>
            <v-col cols="12" xs="0" sm="0" md="2"></v-col>
            <v-col cols="12" md="4">
              <v-form ref="computedDialogForm">
              <!-- Property is enum -->
              <v-select
                v-if="computedDialogSchema.properties[prop].type == 'string' && computedDialogSchema.properties[prop].enum"
                :items="computedDialogSchema.properties[prop].enum"
                v-model="computedDialogInput[prop]"
                :rules="[rules.required]"
              ></v-select>

              <!-- Property is number or text -->
              <v-text-field
                v-else
                :type="computedDialogSchema.properties[prop].type == 'number'? 'number': 'text'"
                v-model="computedDialogInput[prop]"
                :rules="[rules.required]"
                @change="changedComputedDialogInput(prop)"
              >
                <span slot="append" v-if="computedDialogSchema.properties[prop].uom" color="primary">
                  {{computedDialogSchema.properties[prop].uom}}
                </span>
              </v-text-field>
              </v-form>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn color="primary" outlined style="width: 100%" @click="computedDialogCalcFunction()">
                <v-icon color="primary">mdi-calculator</v-icon>
                Berechnen
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-for="(computedResult, i) in computedDialogResultRender" :key="`computedResult${i}`">
            <span style="font-size: 14px" v-html="computedResult.html"></span>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Calculation Dialog -->

    <!-- Begin IntervalIncomeCalculator Dialog -->
    <v-dialog v-model="itemIntervalCalculator" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5"> Zu monatlichem Wert umrechnen </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                type="number"
                v-model="itemIntervalCalcValue"
                @change="changedItemIntervalCalcValue()"
                @focus="focusedItemIntervalCalcValue()"
              >
                <v-icon slot="append" color="primary">
                  mdi-currency-eur
                </v-icon>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-select
                label="Zeitraum"
                v-model="itemIntervalCalculatorInterval"
                :items="availableIncomeIntervals"
                item-text="text"
                item-value="value"
                @change="changedItemIntervalCalculatorInterval()"
            ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="2">
              <v-icon color="primary">
                mdi-equal
              </v-icon>
            </v-col>
            <v-col cols="12" sm="12" md="3" class="pt-4" style="text-align: right">
              <b>{{ parseFloat(this.itemIntervalCalcSum).toFixed(2).replace('.', ',') }}</b>
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <v-icon color="primary">
                mdi-currency-eur
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="closeItemIntervalCalculator()">
            Schließen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmItemIntervalCalculatorValue()">
            Übernehmen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End IntervalIncomeCalculator Dialog -->

    <<v-dialog v-model="loading" persistent max-width="400px">
      <v-card>
        <v-card-title>
          Speichere Berechnung ... <br /><span style="font-size: 10pt"
            >(Dies kann einige Sekunden dauern)</span
          ></v-card-title
        >
        <v-card-text>
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>>

    <v-dialog v-model="initialLoading" persistent max-width="400px">
      <v-card>
        <v-card-title>
          Lade Daten ... <span style="font-size: 10pt"
            >(Dies kann einige Sekunden dauern)</span
          ></v-card-title
        >
        <v-card-text>
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-card class="pa-8">
          <v-row>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon x-large v-bind="attrs" v-on="on" class="hover-pointer" @click="goback()"> mdi-arrow-left </v-icon>
                </template>
                <span>Zurück zum Fall</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-btn v-if="mode == 'CREATE'" :disabled="!timespanValid" color="secondary" @click="openConfirmDialog('SAVE_AS_DRAFT')" elevation="0">Als Entwurf speichern</v-btn>
              <v-btn v-if="mode == 'UPDATE'" :disabled="!timespanValid" color="secondary" @click="openConfirmDialog('UPDATE_DRAFT')" elevation="0">Entwurf aktualisieren</v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="px-6">
      <v-tabs v-model="activeTab">
        <v-tab class="text-left"> <v-icon v-if="activeTab == 0" class="mr-2">mdi-clipboard-text</v-icon> Grunddaten </v-tab>
        <v-tab class="text-left" :disabled="!timespanValid"> <v-icon v-if="activeTab == 1" class="mr-2">mdi-home</v-icon> Unterkunft </v-tab>
        <v-tab class="text-left" :disabled="!timespanValid"> <v-icon v-if="activeTab == 2" class="mr-2">mdi-human-male-child</v-icon> Kinder </v-tab>
        <v-tab v-if="additionalHouseholdMembers.length > 0" class="text-left" :disabled="!timespanValid"> <v-icon v-if="activeTab == 3" class="mr-2">mdi-account-group</v-icon> Haushaltsmitglieder </v-tab>
        <v-tab class="text-left" :disabled="!timespanValid"> <v-icon v-if="activeTab == (additionalHouseholdMembers.length > 0? 4: 3)" class="mr-2">mdi-abacus</v-icon> Hauptberechnung </v-tab>
        <v-tab class="text-left" :disabled="!timespanValid"> <v-icon v-if="activeTab == (additionalHouseholdMembers.length > 0? 5: 4)" class="mr-2">mdi-sigma</v-icon> Zusammenfassung </v-tab>
        <v-tab-item>
          <v-row>
          <v-col cols="12" md="6">
          <v-row class="pa-8">
            <v-col cols="12">
              <v-card elevation="3">
                <v-card-title>Berechnungszeitraum</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="timespanForm">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Beginn Berechnungszeitraum*"
                            v-model="calcStartDate"
                            type="date"
                            required
                            :rules="[rules.required]"
                            @change="changedTimespan()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Ende Berechnungszeitraum*"
                            v-model="calcEndDate"
                            type="date"
                            required
                            :rules="[rules.required]"
                            @change="changedTimespan()"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                  <small>* Pflichtfeld</small>
                  <br />
                  <v-btn color="primary" elevation="0" @click="checkTimespan()">
                    Zeitraum prüfen
                  </v-btn>
                  <v-alert
                    class="mt-3"
                    colored-border
                    border="left"
                    type="error"
                    v-if="!timespanValid"
                  >
                    Berechnungsblätter gesperrt - Bitte Zeitraum prüfen
                  </v-alert>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-card elevation="3" class="my-8">
                <v-card-title>
                  Berechnungssätze für
                  {{ formatDate(this.fetchedCalcStartDate) }}
                  bis
                  {{ formatDate(this.fetchedCalcEndDate) }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <!--<h3>Regelsatz: {{getCurrencyFormatted(this.baseNeedRate)}}€</h3>-->
                      <h3>Einzusetzendes Einkommen: {{this.calcFormula.incomeToBeUsedPercentage}}%</h3>
                      <!--<h4>Gültig: {{ baseNeedRateFull.value?.validTo? `${formatDate(baseNeedRateFull.value.validFrom)} bis ${formatDate(baseNeedRateFull.value.validTo)}`: `seit ${formatDate(baseNeedRateFull.value?.validFrom)}` }}</h4>-->
                      <br />
                      <div v-if="childNeedRates.base">
                        <h3>Berechnungssätze für Kinder</h3>
                        <h4>{{childNeedRates.base.name}} {{getCurrencyFormatted(childNeedRates.base.value)}}€</h4>
                        <h4>{{childNeedRates.familyBonusValue.name}} {{getCurrencyFormatted(childNeedRates.familyBonusValue.value)}}€</h4>
                      </div>
                      <br />
                      <div v-if="householdMemberNeedRates.base">
                        <h3>Berechnungssätze für weitere Haushaltsangehörige</h3>
                        <h4>{{householdMemberNeedRates.base.name}} {{getCurrencyFormatted(childNeedRates.base.value)}}€</h4>
                        <h4>{{householdMemberNeedRates.familyBonusValue.name}} {{getCurrencyFormatted(childNeedRates.familyBonusValue.value)}}€</h4>
                      </div>
                    </v-col>
                    <v-col cols="12">
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        
        <!-- Begin Accomodation Cost -->
        <v-tab-item>
          <v-row class="d-flex justify-center py-8">
            <v-col cols="12" md="6">
              <v-card elevation="3">
                <v-card-text>
                  <v-alert type="info" border="left" colored-border>
                    Alle Werte werden automatisch in die weitere Berechnung übertragen
                  </v-alert>
                  <v-row v-for="(field, index) in availableAccomodationComputedFields" :key="`calcField${index}`">
                    <v-col cols="12" sm="3" class="pt-7">
                      <h4 style="font-size: 16px">{{ field.name }}</h4>
                    </v-col>
                    <v-col cols="12" sm="4" lg="3">
                      <v-text-field type="number" v-model="computedFields[field.field]" @change="changedComputedField(`${field.field}`, true)" @focus="focusedComputedField(`${field.field}`)">
                        <template v-slot:append>
                          <span class="ml-2">€</span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-btn outlined @click="openComputedDialog({ computedPropertyName: `${field.field}` }, ()=>getComputedDialogResultAndWriteToDataAndCalc(`${field.field}`))">
                        <v-icon color="primary">mdi-calculator</v-icon>
                          Berechnen
                      </v-btn>
                    </v-col>    
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-divider></v-divider>
                      <v-divider class="mt-1"></v-divider>
                    </v-col>
                    <v-col cols="12" md="6">
                      <h3>Summe</h3>
                    </v-col>
                    <v-col cols="12" md="6">
                      <h3>{{ getCurrencyFormatted(sumOfAccomodationCosts) }}€</h3>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- End Accomodation Cost -->

        <!-- Begin Children Calculation -->
        <v-tab-item>
          <v-row class="py-8 pl-8">
            <v-col cols="12" md="4">
              <v-card elevation="3">
                <v-card-text>
                  <v-alert type="info" border="left" colored-border>
                    Alle Werte werden automatisch in die weitere Berechnung übertragen
                  </v-alert>
                  <v-alert type="info" border="left" colored-border>
                    Daten der Kinder können im Fall bearbeitet werden
                  </v-alert>
                </v-card-text>
                <v-card-title>
                  Personen im Haushalt (Anzahl: {{numberOfHouseholdMembers}})<br />
                  Kosten der Unterkunft: {{getCurrencyFormatted(sumOfAccomodationCosts)}}€<br />
                  <span class="mt-1" style="font-size: 16px"><v-icon>mdi-arrow-right-bold</v-icon> Kostenanteil pro Haushaltsmitglied: {{ getCurrencyFormatted(accomodationCostPerHouseholdMember) }}€</span>
                </v-card-title>
                <v-card-text>
                  <h2 class="mb-2">Antragsteller:</h2>
                  <h3>{{ `${caseData.primaryApplicant.formOfAddress} ${caseData.primaryApplicant.firstName} ${caseData.primaryApplicant.lastName}` }}</h3>
                  <h3 v-if="caseData.secondaryApplicant?.firstName">{{ `${caseData.secondaryApplicant.formOfAddress} ${caseData.secondaryApplicant.firstName} ${caseData.secondaryApplicant.lastName}` }}</h3>
                  <h2 class="my-2">Kinder:</h2>
                  <template v-for="(child, i) in caseData.children">
                    <h3 :key="i">{{i + 1}}. {{ child.firstName }} {{ child.lastName }}</h3>
                  </template>
                  <v-divider></v-divider>
                  <v-divider class="mt-1 mb-4"></v-divider>
                    <h3>Familienzuschlag für überwiegend unterhalten: {{ getCurrencyFormatted(familyBonusSum) }} €</h3>
                  </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="8" style="max-height:70vh; overflow-y: scroll">
              <v-form ref="familyBonusChildrenForm">
                <v-card v-for="(child, i) in children" :key="`child${i}`" class="mb-9">
                  <v-card-title>
                    <v-alert type="info" border="left" colored-border v-if="child.isInChildcare">
                      Vollständiger Einkommensübertrag - Kind ist in der KITA betreut
                    </v-alert> 
                    <v-alert type="error" border="left" colored-border v-else-if="getChildIsMostlySelfReliant(i)">
                      Ist nicht überwiegend unterhalten [ Einkommen ({{ getCurrencyFormatted(getChildIncome(i)) }})€ &gt; 50% Einkommengrenze ({{ getCurrencyFormatted(getChildNeedBorder(i)) }})€ ] <br /><br />
                      Kindergeld Rückfluss i.H.v. {{ getCurrencyFormatted(getChildIncomeToBeUsed(i)) }}€
                    </v-alert> 
                    <v-alert type="success" border="left" colored-border v-else>
                      Vollständiger Einkommensübertrag - Ist überwiegend unterhalten [ Einkommen ({{ getCurrencyFormatted(getChildIncome(i)) }})€ &lt; 50% Einkommengrenze ({{ getCurrencyFormatted(getChildNeedBorder(i)) }})€ ]
                    </v-alert>
                  </v-card-title>
                  <v-card-title>
                    <h4>Kind {{ i+1 }} - {{child.firstName}} {{child.lastName}}, geboren am {{formatDate(child.dateOfBirth)}}</h4>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6 ">
                        <h3><v-icon color="green" v-if="child.isInSchoolOrTraining">mdi-check</v-icon> <v-icon color="red" v-else>mdi-close</v-icon> 
                        Kind in der Schule oder Ausbildung</h3> <br />
                        <h3><v-icon color="green" v-if="child.isInChildcare">mdi-check</v-icon> <v-icon color="red" v-else>mdi-close</v-icon> 
                        Kind in der KITA</h3>
                      </v-col>
                    </v-row>
                  </v-card-text>
                    <v-card-title>
                      <h4>Einkommensgrenze ({{ getCurrencyFormatted(getChildNeedBorder(i)*2) }}€)</h4>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                      <v-col cols="12" md="8" lg="3" class="pt-6">
                        <v-select
                          v-model="child.needRateRegular"
                          item-text="name"
                          item-value="label"
                          :disabled="childNeedRates.regular.length == 1"
                          :items="childNeedRates.regular"
                          @changed="changedChildRegularNeedRate(i)"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4" lg="3" style="font-size: 16px; text-align: center" class="pt-9"> 
                        {{ getCurrencyFormatted(child.needRateRegularSum) }}€
                      </v-col>
                      <v-col cols="12" md="8" lg="3">
                        <v-select
                          v-model="child.needRateAdditionals"
                          item-text="name"
                          item-value="label"
                          multiple
                          chips
                          :disabled="childNeedRates.additional.length == 1"
                          :items="childNeedRates.additional"
                          @changed="changedChildAdditionalNeedRate(i)"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4" lg="3" style="font-size: 16px; text-align: center" class="pt-9"> 
                        {{ getCurrencyFormatted(child.needRateAdditionalsSum) }}€
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-title>
                    <h4>Einkommen ({{ getCurrencyFormatted(getChildIncome(i)) }}€)</h4>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" style="font-size: 20px">
                        Ausbildungsvergütung oder geringfügige Beschäftigung*
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label=""
                          v-model="child.incomeDuringSchoolOrTraining"
                          type="number"
                          required
                          :rules="[rules.required]"
                          @focus="focusedChildCurrencyField(i, 'incomeDuringSchoolOrTraining')"
                          @change="changedChildIncomeDuringSchoolOrTraining(i)"
                        >
                          <v-icon slot="append" color="primary">
                            mdi-currency-eur
                          </v-icon>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" style="font-size: 20px; text-align: right" class="pt-7">
                        (Freibetrag: {{ getCurrencyFormatted(employmentAllowance)}}€)
                      </v-col>
                      <v-col cols="12" md="4" style="font-size: 20px; text-align: right" class="pt-7">
                        {{getCurrencyFormatted(child.incomeDuringSchoolOrTrainingSum)}}€
                      </v-col>
                      <v-col cols="12" style="font-size: 20px">
                        Kindergeld*
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label=""
                          v-model="child.childAllowance"
                          type="number"
                          required
                          :rules="[rules.required]"
                          @focus="focusedChildCurrencyField(i, 'childAllowance')"
                          @change="changedChildAllowance(i)"
                        >
                          <v-icon slot="append" color="primary">
                            mdi-currency-eur
                          </v-icon>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" style="font-size: 20px; text-align: right" class="pt-7">
                        (Aktuell: {{ getCurrencyFormatted(250)}}€)
                      </v-col>
                      <v-col cols="12" md="4" style="font-size: 20px; text-align: right" class="pt-7">
                        {{getCurrencyFormatted(parseFloat(child.childAllowance))}}€
                      </v-col>
                      <v-col cols="12" md="6" style="font-size: 20px">
                        Weiteres Einkommen
                      </v-col>
                      <v-col cols="12" md="6" style="font-size: 20px; text-align: right">
                        monatliches Einkommen
                      </v-col>
                      <v-col>
                      <v-row v-for="(childIncome, incomeIndex) in children[i].income" :key="`child${i}income${incomeIndex}`">
                        <v-col cols="12" md="3">
                          <v-text-field
                            label="Einkommensart"
                            v-model="childrenIncome[`${i}/${incomeIndex}`].name"
                            @change="changedChildIncomeDataRow(i, incomeIndex)"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            label="Summe"
                            v-model="childrenIncome[`${i}/${incomeIndex}`].value"
                            @change="changedChildIncomeDataRow(i, incomeIndex)"
                            @focus="focusedChildIncome(i, incomeIndex)"
                            type="number"
                          >
                            <v-icon slot="append" color="primary">
                              mdi-currency-eur
                            </v-icon>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-select
                            label="Einkommenszeitraum"
                            v-model="childrenIncome[`${i}/${incomeIndex}`].interval"
                            :items="availableIncomeIntervals"
                            item-text="text"
                            item-value="value"
                            @change="changedChildIncomeDataRow(i, incomeIndex)"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="3" style="font-size: 20px; text-align: right" class="pt-7">
                          {{ getCurrencyFormatted(childrenIncome[`${i}/${incomeIndex}`].sum) }}€
                        </v-col>
                      </v-row>
                      </v-col>
                      <v-col cols="12" style="font-size: 20px">
                        Absetzungsbeträge
                      </v-col>
                      <v-col>
                      <v-row v-for="(childDeduction, deductionIndex) in children[i].deductions" :key="`child${i}deduction${deductionIndex}`">
                        <v-col cols="12" md="3">
                          <v-text-field
                            label="Einkommensart"
                            v-model="childrenDeductions[`${i}/${deductionIndex}`].name"
                            @change="changedChildDeductionDataRow(i, deductionIndex)"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            label="Summe"
                            v-model="childrenDeductions[`${i}/${deductionIndex}`].value"
                            @change="changedChildDeductionDataRow(i, deductionIndex)"
                            @focus="focusedChildDeduction(i, deductionIndex)"
                            type="number"
                          >
                            <v-icon slot="append" color="primary">
                              mdi-currency-eur
                            </v-icon>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                        </v-col>
                        <v-col cols="12" md="3" style="font-size: 20px; text-align: right" class="pt-7">
                          {{ getCurrencyFormatted(isNaN(parseFloat(childrenDeductions[`${i}/${deductionIndex}`].value))? 0: parseFloat(childrenDeductions[`${i}/${deductionIndex}`].value)) }}€
                        </v-col>
                      </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-form>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- End Children Calculation -->

        <!-- Begin Household Members -->
        <v-tab-item v-if="additionalHouseholdMembers.length > 0">
          <v-row class="py-8 pl-8">
            <v-col cols="12" md="4">
              <v-card elevation="3">
                <v-card-text>
                  <v-alert type="info" border="left" colored-border>
                    Alle Werte werden automatisch in die weitere Berechnung übertragen
                  </v-alert>
                  <v-alert type="info" border="left" colored-border>
                    Daten der Haushaltsmitglieder können im Fall bearbeitet werden
                  </v-alert>
                </v-card-text>
                <v-card-title>
                  Personen im Haushalt (Anzahl: {{numberOfHouseholdMembers}})<br />
                  Kosten der Unterkunft: {{getCurrencyFormatted(sumOfAccomodationCosts)}}€<br />
                  <span class="mt-1" style="font-size: 16px"><v-icon>mdi-arrow-right-bold</v-icon> Kostenanteil pro Haushaltsmitglied: {{ getCurrencyFormatted(accomodationCostPerHouseholdMember) }}€</span>
                </v-card-title>
                <v-card-text>
                  <h2 class="mb-2">Antragsteller:</h2>
                  <h3>{{ `${caseData.primaryApplicant.formOfAddress} ${caseData.primaryApplicant.firstName} ${caseData.primaryApplicant.lastName}` }}</h3>
                  <h3 v-if="caseData.secondaryApplicant?.firstName">{{ `${caseData.secondaryApplicant.formOfAddress} ${caseData.secondaryApplicant.firstName} ${caseData.secondaryApplicant.lastName}` }}</h3>
                  <h2 class="my-2">Weitere Haushaltsmitglieder:</h2>
                  <template v-for="(hh, i) in additionalHouseholdMembers">
                    <h3 :key="`hhmem${i}`">{{i + 1}}. {{ hh.firstName }} {{ hh.lastName }}</h3>
                  </template>
                  <v-divider></v-divider>
                  <v-divider class="mt-1 mb-4"></v-divider>
                    <h3>Familienzuschlag für überwiegend unterhalten: {{ getCurrencyFormatted(familyBonusSum) }} €</h3>
                  </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="8" style="max-height:70vh; overflow-y: scroll">
              <v-form ref="familyBonusAdditionalHouseholdMembersForm">
                <v-card v-for="(additionalHH, i) in additionalHouseholdMembers" :key="`child${i}`" class="mb-9">
                  <v-card-title>      
                    <v-alert type="error" border="left" colored-border v-if="getHHMemberIsMostlySelfReliant(i)">
                      Ist nicht überwiegend unterhalten [ Einkommen ({{ getCurrencyFormatted(getHHMemberIncome(i)) }})€ &gt; 50% Einkommengrenze ({{ getCurrencyFormatted(getHHMemberNeedBorder(i)) }})€ ]
                    </v-alert> 
                    <v-alert type="success" border="left" colored-border v-else>
                      Ist überwiegend unterhalten [ Einkommen ({{ getCurrencyFormatted(getHHMemberIncome(i)) }})€ &lt; 50% Einkommengrenze ({{ getCurrencyFormatted(getHHMemberNeedBorder(i)) }})€ ]
                    </v-alert>
                  </v-card-title>
                  <v-card-title>
                    <h4>Haushaltsmitglied {{ i+1 }} - {{additionalHH.firstName}} {{additionalHH.lastName}}, geboren am {{formatDate(additionalHH.dateOfBirth)}}</h4>
                  </v-card-title>
                  <v-card-title>
                      <h4>Einkommensgrenze ({{ getCurrencyFormatted(getHHMemberNeedBorder(i)*2) }}€)</h4>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                      <v-col cols="12" md="8" lg="3" class="pt-6">
                        <v-select
                          v-model="additionalHH.needRateRegular"
                          item-text="name"
                          item-value="label"
                          :disabled="householdMemberNeedRates.regular.length == 1"
                          :items="householdMemberNeedRates.regular"
                          @changed="changedHHMemberdRegularNeedRate(i)"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4" lg="3" style="font-size: 16px; text-align: center" class="pt-9"> 
                        {{ getCurrencyFormatted(additionalHH.needRateRegularSum) }}€
                      </v-col>
                      <v-col cols="12" md="8" lg="3">
                        <v-select
                          v-model="additionalHH.needRateAdditionals"
                          item-text="name"
                          item-value="label"
                          multiple
                          chips
                          :disabled="householdMemberNeedRates.additional.length == 1"
                          :items="householdMemberNeedRates.additional"
                          @changed="changedHHMemberAdditionalNeedRate(i)"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4" lg="3" style="font-size: 16px; text-align: center" class="pt-9"> 
                        {{ getCurrencyFormatted(additionalHH.needRateAdditionalsSum) }}€
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-title>
                    <h4>Einkommen ({{ getCurrencyFormatted(getHHMemberIncome(i)) }}€)</h4>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" style="font-size: 20px">
                        Ausbildungsvergütung oder geringfügige Beschäftigung*
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          label=""
                          v-model="additionalHH.incomeDuringSchoolOrTraining"
                          type="number"
                          required
                          :rules="[rules.required]"
                          @focus="focusedIncomeDuringSchoolOrTrainingHHMember(i)"
                          @change="changedHHMemberIncomeDuringSchoolOrTraining(i)"
                        >
                          <v-icon slot="append" color="primary">
                            mdi-currency-eur
                          </v-icon>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" style="font-size: 20px; text-align: right" class="pt-7">
                        (Freibetrag: {{ getCurrencyFormatted(employmentAllowance)}}€)
                      </v-col>
                      <v-col cols="12" md="4" style="font-size: 20px; text-align: right" class="pt-7">
                        {{getCurrencyFormatted(additionalHH.incomeDuringSchoolOrTrainingSum)}}€
                      </v-col>
                    </v-row>
                    <v-row>
                    <v-col cols="12" md="6" style="font-size: 20px;">
                      Weiteres Einkommen
                    </v-col>
                    <v-col cols="12" md="6" style="font-size: 20px; text-align: right">
                        monatliches Einkommen
                    </v-col>
                    <v-col>
                      <v-row v-for="(hhMemberIncome, incomeIndex) in additionalHouseholdMembers[i].income" :key="`hhmember${i}income${incomeIndex}`">
                        <v-col cols="12" md="3">
                          <v-text-field
                            label="Einkommensart"
                            v-model="additionalHHIncome[`${i}/${incomeIndex}`].name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            label="Summe"
                            v-model="additionalHHIncome[`${i}/${incomeIndex}`].value"
                            @change="changedHHMemberIncomeDataRow(i, incomeIndex)"
                            @focus="focusedHHMemberIncome(i, incomeIndex)"
                            type="number"
                          >
                            <v-icon slot="append" color="primary">
                              mdi-currency-eur
                            </v-icon>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-select
                            label="Einkommenszeitraum"
                            v-model="additionalHHIncome[`${i}/${incomeIndex}`].interval"
                            :items="availableIncomeIntervals"
                            item-text="text"
                            item-value="value"
                            @change="changedHHMemberIncomeDataRow(i, incomeIndex)"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="3" style="font-size: 20px; text-align: right" class="pt-7">
                          {{ getCurrencyFormatted(additionalHHIncome[`${i}/${incomeIndex}`].sum) }}€
                        </v-col>
                      </v-row>
                      </v-col>
                      <v-col cols="12" style="font-size: 20px">
                        Absetzungsbeträge
                      </v-col>
                      <v-col>
                      <v-row v-for="(childDeduction, deductionIndex) in additionalHouseholdMembers[i].deductions" :key="`child${i}deduction${deductionIndex}`">
                        <v-col cols="12" md="3">
                          <v-text-field
                            label="Einkommensart"
                            v-model="additionalHHDeductions[`${i}/${deductionIndex}`].name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            label="Summe"
                            v-model="additionalHHDeductions[`${i}/${deductionIndex}`].value"
                            @change="changedHHMemberDeductionDataRow(i, deductionIndex)"
                            @focus="focusedHHMemberDeduction(i, deductionIndex)"
                            type="number"
                          >
                            <v-icon slot="append" color="primary">
                              mdi-currency-eur
                            </v-icon>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                        </v-col>
                        <v-col cols="12" md="3" style="font-size: 20px; text-align: right" class="pt-7">
                          {{ getCurrencyFormatted(isNaN(parseFloat(additionalHHDeductions[`${i}/${deductionIndex}`].value))? 0: parseFloat(additionalHHDeductions[`${i}/${deductionIndex}`].value)) }}€
                        </v-col>
                      </v-row>
                    </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-form>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- End Houshold Members -->
        <!-- Begin Main Income Calculation -->
        <v-tab-item>
          <div class="pa-8">
            <v-row>
              <v-col cols="12" md="3">
              <v-tabs vertical v-model="activeCalcTab">
                <template
                  v-for="(firstSection, firstIndex) in calcFormula.calcSchema"
                  >
                    <v-tab
                      :key="'first' + firstIndex"
                      class="text-left"
                      style="font-size: 10pt"
                    >
                      <span style="width: 100%; text-align: left">{{
                        firstSection.name
                      }}</span>
                    </v-tab>
                  </template>
                </v-tabs>
              </v-col>
              <v-col cols="12" md="9">
                <v-tabs-items style="width: 100%; height: 100%; max-height: 70vh; overflow-y:scroll" v-model="activeCalcTab">
                <template
                  v-for="(firstSection, firstIndex) in calcFormula.calcSchema"
                >
                  <v-tab-item :key="'first2' + firstIndex" class="px-8">
                    <v-lazy>
                      <v-container>
                        <v-form v-on:submit.prevent>
                          <!-- Subsections on first Section -->
                          <template
                            v-for="(secondSection, secondIndex) in calcFormula
                              .calcSchema[firstIndex].sections"
                          >
                            <div
                              :key="'secondSection' + secondIndex"
                              class="pa-3"
                            >
                              <h3>
                                {{
                                  `${secondIndex + 1}. ${secondSection.name}`
                                }}
                              </h3>
                            </div>

                            <!-- Items on second Section -->
                            <div :key="'secLayout' + secondIndex" class="pl-8">
                              <template
                                v-for="(thirdItem, thirdIndex) in calcFormula
                                  .calcSchema[firstIndex].sections[secondIndex]
                                  .items"
                              >
                                <div :key="'thirdItem' + thirdIndex">
                                  <div v-if="thirdItem.type == 'TITLE'">
                                    <p>
                                      <b>{{
                                        `${secondIndex + 1}.${
                                          thirdIndex + 1
                                        }. ${thirdItem.name}`
                                      }}</b>
                                    </p>
                                  </div>
                                  <div v-else>
                                    <v-row>
                                      <v-col cols="12" md="6" v-if="thirdItem.type == 'ITEM_WILDCARD'">
                                        <v-text-field
                                          v-model="wildcardMapping[thirdItem.label]"
                                        >
                                          <span slot="prepend" class="pt-2">
                                            {{
                                              `${secondIndex + 1}.${
                                                thirdIndex + 1
                                              }. `
                                            }}
                                          </span>

                                          <span slot="append">
                                            {{`(ggf. eintragen)`}}
                                          </span>
                                        </v-text-field>
                                      </v-col>
                                      <v-col :cols="thirdItem.type == 'ITEM_WILDCARD'? '2': '8'" class="pt-7">
                                        <CalculationTitle
                                          :key="'thirdItem' + thirdIndex"
                                          :item="thirdItem"
                                          :enumeration="`${secondIndex + 1}.${
                                            thirdIndex + 1
                                          }.`"
                                          :section="`${firstSection.label}/${secondSection.label}/${thirdItem.label}`"
                                        />
                                      </v-col>
                                      <v-col cols="12" :md="thirdItem.showIncomeIntervalCalculator? 3: 4">
                                        <v-text-field
                                          type="number"
                                          v-model.lazy="
                                            calculation[
                                              `${firstSection.label}/${secondSection.label}/${thirdItem.label}`
                                            ]
                                          "
                                          @change="
                                            changedCalcValue(
                                              `${firstSection.label}/${secondSection.label}`,
                                              `${firstSection.label}/${secondSection.label}/${thirdItem.label}`
                                            )
                                          "
                                          @focus="focusedCalcField(
                                            `${firstSection.label}/${secondSection.label}/${thirdItem.label}`
                                          )"
                                          :disabled="thirdItem.type == 'ITEM_FLATRATE' || thirdItem.disabled"
                                        >
                                          <v-icon
                                            slot="prepend"
                                            color="primary"
                                            v-if="thirdItem.sign == '-'"
                                          >
                                            mdi-minus
                                          </v-icon>
                                          <v-icon
                                            slot="prepend"
                                            color="primary"
                                            v-else-if="thirdItem.sign == '+'"
                                          >
                                            mdi-plus
                                          </v-icon>
                                          <v-icon slot="append" color="primary">
                                            mdi-currency-eur
                                          </v-icon>
                                        </v-text-field>
                                      </v-col>
                                      <v-col md="1" class="pt-8" v-if="thirdItem.showIncomeIntervalCalculator">
                                        <v-icon color="primary" 
                                            @click="openItemIntervalCalculator(`${firstSection.label}/${secondSection.label}/${thirdItem.label}`)"
                                          >
                                            mdi-abacus
                                        </v-icon>
                                      </v-col>
                                    </v-row>
                                    <div v-if="thirdItem.type == 'ITEM_COMPUTED' && thirdItem.displayCalculator" class="d-flex justify-end">
                                        <v-btn outlined @click="openComputedDialog(thirdItem, getComputedResultAndWriteToCalc)">
                                          <v-icon
                                            color="primary"
                                          >
                                            mdi-calculator
                                          </v-icon>
                                          Berechnen
                                        </v-btn>
                                        <v-icon
                                          color="primary"
                                        >
                                          mdi-arrow-left-bottom
                                        </v-icon>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                            <!-- End Items on second Section -->
                            <v-row :key="'secDiv' + secondIndex" class="my-3">
                              <v-col cols="12">
                                <v-divider></v-divider>
                                <v-divider class="mt-1"></v-divider>
                              </v-col>
                            </v-row>
                            <v-row :key="'secSum' + secondIndex">
                              <v-col cols="12" md="6"></v-col>
                              <v-col cols="12" md="6">
                                <p style="text-align: right">
                                  <b>
                                    ({{
                                      sectionPathMapping[
                                        firstSection.label +
                                          "/" +
                                          secondSection.label
                                      ].sign
                                    }}) Zwischensumme:
                                    {{
                                      getCurrencyFormatted(
                                        sums[
                                          firstSection.label +
                                            "/" +
                                            secondSection.label
                                        ]
                                      )
                                    }}€
                                    <span
                                      v-if="
                                        sectionPathMapping[
                                          firstSection.label +
                                            '/' +
                                            secondSection.label
                                        ].type == 'SECTION_MIN'
                                      "
                                    >
                                      (Mindestens
                                      {{
                                        getCurrencyFormatted(
                                          sectionPathMapping[
                                            firstSection.label +
                                              "/" +
                                              secondSection.label
                                          ].min
                                        )
                                      }}€)
                                    </span>
                                  </b>
                                </p>
                              </v-col>
                            </v-row>
                            <!-- Insert suspicious code here if broken -->

                            <!-- End Items on second Section -->
                          </template>
                          <!-- End Subsections on first Section -->
                          <!-- Items on first Section -->
                          <template
                            v-for="(secondItem, secondIndex) in calcFormula
                              .calcSchema[firstIndex].items"
                          >
                            <div :key="'secondItemRow' + secondIndex">
                              <v-row>
                                <v-col cols="12" md="8">
                                  <v-row>
                                    <v-col :cols="secondItem.type == 'ITEM_WILDCARD'? `8`: 0">
                                      <span v-if="secondItem.type == 'ITEM_WILDCARD'">
                                        <v-text-field
                                          v-model="wildcardMapping[secondItem.label]"
                                        >
                                          <span slot="prepend">
                                            {{`${
                                              calcFormula.calcSchema[firstIndex]
                                                .sections.length +
                                              secondIndex +
                                              1
                                            }.`}}
                                          </span>

                                          <span slot="append">
                                            {{`(ggf. eintragen)`}}
                                          </span>
                                        </v-text-field>
                                      </span> 
                                    </v-col>
                                    <v-col 
                                      v-if="secondItem.type == 'TITLE'"
                                      :cols="secondItem.type == 'ITEM_WILDCARD'? `4`: `12`"
                                      :class="secondItem.type == 'ITEM_WILDCARD'? 'pt-7': ''"
                                    >
                                      <h3 >
                                        {{
                                          `${
                                            calcFormula.calcSchema[firstIndex]
                                              .sections.length +
                                            secondIndex +
                                            1
                                          }. ${secondItem.name}`
                                        }}
                                      </h3>
                                    </v-col>
                                    <v-col 
                                      v-else
                                      :cols="secondItem.type == 'ITEM_WILDCARD'? `4`: `12`"
                                      :class="secondItem.type == 'ITEM_WILDCARD'? 'pt-7': ''"
                                    >
                                    <CalculationTitle 
                                        :key="'secondItem' + secondIndex"
                                        :item="secondItem"
                                        :enumeration="`${
                                          calcFormula.calcSchema[firstIndex]
                                            .sections.length +
                                          secondIndex +
                                          1
                                        }.`"
                                        :section="`${firstSection.label}/${secondItem.label}`"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col cols="12" md="4" class="pl-8" v-if="secondItem.type != 'TITLE'">
                                  <v-text-field
                                    type="number"
                                    v-model="
                                      calculation[
                                        `${firstSection.label}/${secondItem.label}`
                                      ]
                                    "
                                    @change="
                                      changedCalcValue(
                                        firstSection.label,
                                        `${firstSection.label}/${secondItem.label}`
                                      )
                                    "
                                    @focus="focusedCalcField(
                                      `${firstSection.label}/${secondItem.label}`
                                    )"
                                    :disabled="secondItem.type == 'ITEM_FLATRATE' || secondItem.disabled"
                                  >
                                    <v-icon
                                      slot="prepend"
                                      color="primary"
                                      v-if="secondItem.sign == '-'"
                                    >
                                      mdi-minus
                                    </v-icon>
                                    <v-icon
                                      slot="prepend"
                                      color="primary"
                                      v-else-if="secondItem.sign == '+'"
                                    >
                                      mdi-plus
                                    </v-icon>
                                    <v-icon slot="append" color="primary">
                                      mdi-currency-eur
                                    </v-icon>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </div>
                          </template>
                          <!-- End Items on first Section -->
                          <v-row>
                            <v-col cols="12">
                              <v-divider></v-divider>
                              <v-divider class="mt-1"></v-divider>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="6"></v-col>
                            <v-col cols="12" md="6">
                              <h2 class="mt-6" style="text-align: right">
                                Gesamtsumme:
                                {{
                                  getCurrencyFormatted(sums[firstSection.label])
                                }}
                                €
                              </h2>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-lazy>
                  </v-tab-item>
                </template>
                </v-tabs-items>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
        <!-- End Main Income Calculation -->
        <v-tab-item class="pt-4">
          <SozialstaffelCalcResult
            :finalResult="finalResult"
            :sums="sums"
            :calcFormula="calcFormula"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>
<style lang="css" scoped>
.hover-pointer {
  cursor: pointer;
}
</style>
<script>
import axios from "axios";
import currency from "currency.js";
import utils from "../../utils";

import appConfig from "../../config";

import CalculationTitle from "../../components/sozialstaffel/incomeCalc/CalculationTitle.vue";
import SozialstaffelCalcResult from "../../components/sozialstaffel/incomeCalc/IncomeCalcResult.vue";

export default {
  name: "SozialstaffelIncomeCalculator",
  components: {
    CalculationTitle,
    SozialstaffelCalcResult,
  },
  data() {
    return {
      mode: "CREATE",

      /* Data set in created hook */
      caseData: {
        primaryApplicant: {},
        secondaryApplicant: {},
        children: []
      },
      calcFormula: {},
      needRates: [],
      calcEndDate: "",
      calcStartDate: "",
      fetchedCalcStartDate: new Date(),
      fetchedCalcStartDate: new Date(),
      wildcardMapping: {},
      calculationToUpdate: {},

      /* Computed */
      computedDialog: false,
      computedSchemas: {},
      computedResultLabelMapping: {},
      computedProperties: {},
      computedDialogSchema: {
        properties: {},
      },
      computedDialogItem: {},
      computedDialogInput: {},
      computedDialogCalcFunction: null,
      showUseComputedResult: false,
      computedFields: {},
      availableAccomodationComputedFields: [ { name: 'Kosten der Wohnunterkunft', field: 'costofhome' } ],

      /* Child Family Bonus */
      childNeedRates: {},
      employmentAllowance: 0,
      children: [],
      childrenIncome: {},
      childrenDeductions: {},
      
      /* General Data */
      sumOfAccomodationCosts: 0,
      availableIncomeIntervals: [
        { value:'monthly', text: 'monatlich' },
        { value:'yearly', text: 'jährlich' },
        { value:'weekly', text: 'wöchentlich' },
        { value:'daily', text: 'täglich' },
        { value:'quarterly', text: 'quartalsweise' }
      ],

      /* additional householdmembers family bonus */
      additionalHouseholdMembers: [],
      familyBonusTypePaths: {},
      familyBonusErrorDialog: false,
      householdMemberNeedRates: {},
      additionalHHIncome: {},
      additionalHHDeductions: {},

      /* snackbar */
      snackbarText: "",
      snackBarType: "SUCCESS",
      snackbar: false,

      /* loading */
      loading: false,

      /* itemIntervalCalc */
      itemIntervalCalculator: false,
      itemIntervalCalculatorPath: '',
      itemIntervalCalcValue: '0.00',
      itemIntervalCalcSum: '0,00',
      itemIntervalCalculatorInterval: 'monthly',

      /** Item_ref_math */
      itemMathWatch: {
        sections: [],
        items: []
      },

      timespanErrorDialog: false,
      timespanError: null,
      calcFormulaTimespanError: [],
      noCalcFormulaFound: false,
      timespanValid: false,
      noNeedRateFound: false,

      confirmDialogHead: "",
      confirmDialogText: "",
      confirmAction: "",
      confirmDialog: false,
      buildingCalcVars: true,
      sums: {},
      sectionPathMapping: {},
      calculation: {},
      itemPathMapping: {},
      initialLoading: false,
      activeTab: 0,
      errors: {},
      rules: {
        required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
      },
      activeCalcTab: 0,

      /* before leave hook */
      isSafePageLeave: false,
      pageLeaveConfirmDialog: false,
      pageLeavePath: ""
    };
  },
  computed: {
    finalResult: {
      get: function () {
        let sum = currency(0);

        for (let sec of Object.keys(this.sums)) {
          if ((sec.match(new RegExp("/", "g")) || []).length == 0) {
            sum = sum.add(
              currency(this.sums[sec]).multiply(
                this.sectionPathMapping[sec].sign == "-" ? -1 : 1
              )
            );
          }
        }

        return sum.value;
      },
    },
    numberOfHouseholdMembers: {
        get: function () {
          let sum = 0;

          if(this.caseData.primaryApplicant?.firstName) {
            sum += 1;
          }

          if(this.caseData.secondaryApplicant?.firstName) {
            sum += 1;
          }

          sum += this.caseData.children?.length;

          sum += this.additionalHouseholdMembers.length;

          return sum;
        },
    },

    accomodationCostPerHouseholdMember: {
      get: function() {
        if(!this.sumOfAccomodationCosts || !this.numberOfHouseholdMembers) {
          return 0;
        }

        return currency(this.sumOfAccomodationCosts).divide(this.numberOfHouseholdMembers).value;
      }
    },

    familyBonusSum: {
      get: function () {
        let childrenForFamilyBonus = this.children.length;

        //subtract self reliant children
        childrenForFamilyBonus -= this.childrenMostlySelfReliant;

        //subtract children in childcare
        childrenForFamilyBonus -= this.children.filter(child => child.isInChildcare).length;

        let hhMembersForFamilyBonus = this.additionalHouseholdMembers.length;

        hhMembersForFamilyBonus -= this.hhMembersMostlySelfReliant;

        const familyBonusValue = this.householdMemberNeedRates.familyBonusValue?.value? this.householdMemberNeedRates.familyBonusValue.value: 0;

        return currency(childrenForFamilyBonus).add(hhMembersForFamilyBonus).multiply(familyBonusValue).value;
      },
    },

    rentShareMostlySelfReliant: {
      get: function () {
        return currency(this.childrenMostlySelfReliant).add(this.hhMembersMostlySelfReliant).multiply(this.accomodationCostPerHouseholdMember).value;
      },
    },

    hhMembersMostlySelfReliant: {
      get: function () {
        return this.additionalHouseholdMembers.filter((hhMember, i) => this.getHHMemberIsMostlySelfReliant(i)).length;
      },
    },

    childrenMostlySelfReliant: {
      get: function() {
        let numOfSelfReliantChildren = 0;

        this.children.forEach((child, i) => {
          if(this.getChildIsMostlySelfReliant(i) && !child.isInChildcare) {
            numOfSelfReliantChildren += 1;
          }
        });

        return numOfSelfReliantChildren;
      }
    },

    computedDialogResultRender: {
        get: function() {
          return this.computedProperties[this.computedDialogItem.computedPropertyName]? 
                  this.computedProperties[this.computedDialogItem.computedPropertyName].results: [];
        }
    }
  },
  watch: {
    sumOfAccomodationCosts: function(val) {
      //recalc children
      this.children.forEach((child, i) => {
        this.changedChildIncome(i);
      });

      //recalc hhmembers
      this.additionalHouseholdMembers.forEach((hhmember, i) => {
        this.changedHHMemberIncome(i);
      });
    }
  },
  methods: {
    goback() {
      this.$router.push(
        `/sozialstaffel/cases/detail?id=${this.$router.currentRoute.query?.case}&activeTab=2`
      );
    },
    formatDate(d) {
      return utils.formatDate(d, false);
    },
    openConfirmDialog(action) {
      if (action == "SAVE_AS_DRAFT") {
        this.confirmDialogHead = "Als Entwurf speichern";
        this.confirmDialogText =
          "Die Einkommensberechnung wird als Entwurf gespeichert. Aus Einkommensberechnungen im Stadium Entwurf können nicht direkt Bescheide erstellt werden.";
      }

      if (action == "UPDATE_DRAFT") {
        this.confirmDialogHead = "Entwurf aktualisieren";
        this.confirmDialogText =
          "Die Einkommensberechnung wird als Entwurf gespeichert und die Änderungen übernommen. Aus Einkommensberechnungen im Stadium Entwurf können nicht direkt Bescheide erstellt werden.";
      }

      this.confirmAction = action;
      this.confirmDialog = true;
    },
    confirmDialogAction() {
      if(this.confirmAction == "SAVE_AS_DRAFT") {
        this.createCalculation();
      } else if(this.confirmAction == "UPDATE_DRAFT") {
        this.updateCalculation();
      }

      this.confirmDialog = false;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    focusedCalcField(itemLabel) {
      if(currency(this.calculation[itemLabel]).value == currency(0.00).value) {
        this.calculation[itemLabel] = "";
      }
    },
    changedCalcItemValue(itemLabel) {
      const splitLabel = itemLabel.split("/");
      splitLabel.splice(splitLabel.length - 1, 1);
      const section = splitLabel.join("/");
      this.changedCalcValue(section, itemLabel);
    },
    changedCalcValue(sectionLabel, itemLabel) {
      const sectionsToRecalc = [];
      const spltSection = sectionLabel.split("/");

      this.calculation[itemLabel] = (
        Math.round(this.calculation[itemLabel] * 100) / 100
      ).toFixed(2)

      if (
        this.itemPathMapping[itemLabel].type == "ITEM_MIN" &&
        parseFloat(this.calculation[itemLabel]) <
          this.itemPathMapping[itemLabel].min
      ) {
        this.calculation[itemLabel] = this.itemPathMapping[
          itemLabel
        ].min.toFixed(2);
      }

      if (
        this.itemPathMapping[itemLabel].type == "ITEM_MAX" &&
        parseFloat(this.calculation[itemLabel]) >
          this.itemPathMapping[itemLabel].max
      ) {
        this.calculation[itemLabel] = this.itemPathMapping[
          itemLabel
        ].max.toFixed(2);
      }

      for (let i = 0; i < spltSection.length; i++) {
        sectionsToRecalc.push(spltSection.slice(0, i + 1).join("/"));
      }

      for (let secToRecalc of sectionsToRecalc.reverse()) {
        this.sums[secToRecalc] = this.getSumForSection(secToRecalc);
      }
    },

    getCurrencyFormatted(num, supressWarning) {
      if(num == undefined) {
        if(!supressWarning){ console.log("tried to convert undefined via currency formatter"); }
        return;
      }
      return num.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
    getSumForSection(sectionPath) {
      const maxNumberOfSlashes =
        (sectionPath.match(new RegExp("/", "g")) || []).length + 1;

      let sum = currency(0);

      //add fields
      for (let key of Object.keys(this.calculation)) {
        const numberOfSlashesInKey = (key.match(new RegExp("/", "g")) || [])
          .length;

        if (
          key.startsWith(sectionPath) &&
          numberOfSlashesInKey == maxNumberOfSlashes
        ) {
          sum = sum.add(
            currency(this.calculation[key]).multiply(
              currency(this.itemPathMapping[key].sign == "-" ? -1 : 1)
            )
          );
        }
      }

      //add sections
      for (let key of Object.keys(this.sums)) {
        const numberOfSlashesInKey = (key.match(new RegExp("/", "g")) || [])
          .length;

        if (
          key.startsWith(sectionPath) &&
          key != sectionPath &&
          numberOfSlashesInKey == maxNumberOfSlashes
        ) {
          sum = sum.add(
            currency(this.sums[key]).multiply(
              currency(this.sectionPathMapping[key].sign == "-" ? -1 : 1)
            )
          );
        }
      }

      if (
        this.sectionPathMapping[sectionPath].type == "SECTION_MIN" &&
        sum.value < this.sectionPathMapping[sectionPath].min
      ) {
        return this.sectionPathMapping[sectionPath].min;
      }

      return sum.value;
    },

    createCalculation() {
      this.loading = true;
      const calc = this.buildCalculationForSubmission();

      const calculatedIncomeToBeUsed = currency(this.calcFormula.incomeToBeUsedPercentage).divide(100).multiply(this.finalResult).value;

      const body = {
        calc: calc,
        incomeSurplus: this.finalResult,
        incomeToBeUsed: calculatedIncomeToBeUsed < 0? 0: calculatedIncomeToBeUsed,
        calculationFormula: this.calcFormula._id,
        case: this.$router.currentRoute.query?.case,
        calculationStart: this.calcStartDate,
        calculationEnd: this.calcEndDate,
        computedProperties: this.computedProperties,
        familyBonusCalculation: this.buildFamilyBonusCalcForSubmission()
      };

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/calculations`;

      axios
        .post(url, body, requestConfig)
        .then((resp) => {
          this.loading = false;
          this.isSafePageLeave = true;
          this.$router.push(
            `/sozialstaffel/cases/documents/detail?id=${resp.data._id}&type=calculation`
          );
        })
        .catch((err) => {
          this.loading = false;
          this.showSnackbar("ERROR", appConfig.defaultErrorText);
          console.log(err);
        });
    },

    async updateCalculation() {
      this.loading = true;
      const calc = this.buildCalculationForSubmission();

      const calculatedIncomeToBeUsed = currency(this.calcFormula.incomeToBeUsedPercentage).divide(100).multiply(this.finalResult).value;

      const body = {
        calc: calc,
        incomeSurplus: this.finalResult,
        incomeToBeUsed: calculatedIncomeToBeUsed < 0? 0: calculatedIncomeToBeUsed,
        calculationFormula: this.calcFormula._id,
        case: this.$router.currentRoute.query?.case,
        calculationStart: this.calcStartDate,
        calculationEnd: this.calcEndDate,
        computedProperties: this.computedProperties,
        familyBonusCalculation: this.buildFamilyBonusCalcForSubmission()
      };

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/calculations/${this.$router.currentRoute.query?.calculation}`;
      
      try {
        const updateResp = await axios.put(url, body, requestConfig);
        
        this.isSafePageLeave = true;

        this.$router.push(
            `/sozialstaffel/cases/documents/detail?id=${updateResp.data._id}&type=calculation`
          );
      } catch(e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      } finally {
        this.loading = false;
      }
    },

    buildCalculationForSubmission() {
      //copy calc schema
      const filledCalcSchema = JSON.parse(
        JSON.stringify(this.calcFormula.calcSchema)
      );

      //firstSection
      for (let fs = 0; fs < filledCalcSchema.length; fs++) {
        filledCalcSchema[fs].value = new Number(
          this.sums[filledCalcSchema[fs].label]
        );

        //second item
        for (let si = 0; si < filledCalcSchema[fs].items.length; si++) {
          filledCalcSchema[fs].items[si].value = new Number(
            this.calculation[
              filledCalcSchema[fs].label +
                "/" +
                filledCalcSchema[fs].items[si].label
            ]
          );

          if(filledCalcSchema[fs].items[si].type == 'ITEM_WILDCARD') {
            filledCalcSchema[fs].items[si].name = this.wildcardMapping[filledCalcSchema[fs].items[si].label];
          }
        }

        //second section
        for (let ss = 0; ss < filledCalcSchema[fs].sections.length; ss++) {
          filledCalcSchema[fs].sections[ss].value = new Number(
            this.sums[
              filledCalcSchema[fs].label +
                "/" +
                filledCalcSchema[fs].sections[ss].label
            ]
          );

          //third item
          for (
            let ti = 0;
            ti < filledCalcSchema[fs].sections[ss].items.length;
            ti++
          ) {
            if (filledCalcSchema[fs].sections[ss].items[ti].type != "TITLE") {
              filledCalcSchema[fs].sections[ss].items[ti].value = new Number(
                this.calculation[
                  filledCalcSchema[fs].label +
                    "/" +
                    filledCalcSchema[fs].sections[ss].label +
                    "/" +
                    filledCalcSchema[fs].sections[ss].items[ti].label
                ]
              );

              if(filledCalcSchema[fs].sections[ss].items[ti].type == 'ITEM_WILDCARD') {
                filledCalcSchema[fs].sections[ss].items[ti].name = this.wildcardMapping[filledCalcSchema[fs].sections[ss].items[ti].label];
              }
            }
          }
        }
      }

      return filledCalcSchema;
    },

    showSnackbar(type, text) {
      if (type == "ERROR") {
        this.snackBarType = "ERROR";
        this.snackbarText = text;
      } else {
        this.snackBarType = "SUCCESS";
        this.snackbarText = text;
      }

      this.snackbar = true;
    },


    /* Child family Bonus Methods */
    addChild(childIndex, child, prefillChild) {
      this.children.push({
        incomeDuringSchoolOrTraining: '0.00',
        incomeDuringSchoolOrTrainingSum: 0,
        childAllowance: '250.00',
        needRateRegularSum: 0,
        needRateAdditionalsSum: 0,
        ...child,
        ...prefillChild
      });

      this.changedChildAllowance(childIndex);
      this.changedChildIncomeDuringSchoolOrTraining(childIndex);

      for(let j=0; j<child.income.length; j++) {
        this.$set(this.childrenIncome, `${childIndex}/${j}`, {
          sum: 0,
          value: '0.00',
          name: '',
          interval: this.availableIncomeIntervals[0].value,
          ...child.income[j],
          ...(prefillChild.income? prefillChild.income[j]: {})
        });

        this.changedChildIncomeDataRow(childIndex, j);
      }

      for(let j=0; j<child.deductions.length; j++) {
          this.$set(this.childrenDeductions, `${childIndex}/${j}`, {
          value: '0.00',
          name: '',
          ...child.deductions[j],
          ...(prefillChild.deductions? prefillChild.deductions[j]: {})
        });

        this.changedChildDeductionDataRow(childIndex, j);
      }
    },

    initializeChildren() {
      this.$set(this, 'children', []); //TODO add behavior of prefilling children on timespan change

      if(this.mode == "UPDATE") {
        this.calcFormula.familyBonusCalculation.children.forEach((child, i) => {
          const prefillChild = this.calculationToUpdate.familyBonusCalculation.children.filter(pChild => pChild._id == child._id);

          this.addChild(i, child, prefillChild.length > 0? prefillChild[0]: {});
        });
      } else {
        this.calcFormula.familyBonusCalculation.children.forEach((child, i) => {
          this.addChild(i, child, {});
        });
      }

      //set familybonus for children in childcare
      const familyBonusValue = this.childNeedRates.familyBonusValue?.value ? this.childNeedRates.familyBonusValue.value: 0;

      const familyBonusChildrenInChildcare = this.children.filter(child => child.isInChildcare).length * familyBonusValue;

      this.$set(this.calculation, this.familyBonusTypePaths["ITEM_CHILDREN_BONUS"], familyBonusChildrenInChildcare.toFixed(2));
      this.changedCalcItemValue(this.familyBonusTypePaths["ITEM_CHILDREN_BONUS"]);

      /* set regular needRate default */
      if(this.childNeedRates.regular.length == 1) {
        this.children.forEach(child => {
          child.needRateRegular = this.childNeedRates.regular[0].label;

          this.changedChildRegularNeedRate(this.children.indexOf(child));
        });
      }
      
      /* set additional needRate default */
      if(this.childNeedRates.additional.length == 1) {
        this.children.forEach(child => {
          child.needRateAdditionals = [this.childNeedRates.additional[0].label];

          this.changedChildAdditionalNeedRate(this.children.indexOf(child));
        });
      }

      /* calc income for all children */
      this.children.forEach((child, i) => {
        this.changedChildIncome(i);
      });
    },
    changedChildRegularNeedRate(i) {
      this.children[i].needRateRegularSum = this.childNeedRates.regular.filter(nR => nR.label == this.children[i].needRateRegular)[0].value;

      //recalc additional need rate if necessary
      if(this.children[i].needRateAdditionals?.length > 0) {
        this.changedChildAdditionalNeedRate(i);
      }
    },

    changedChildAdditionalNeedRate(i) {
      //TODO use currency for calculations
      if(this.children[i].needRateAdditionals.length == 0 || !this.children[i].needRateRegular) {
        this.children[i].needRateAdditionalsSum = 0;
        return;
      }

      this.children[i].needRateAdditionalsSum = this.children[i].needRateAdditionals.reduce((sum, nR) => {
        return sum + (this.children[i].needRateRegularSum * (this.childNeedRates.additional.filter(nR2 => nR2.label == nR)[0].value/100));
      }, 0);
    },

    getChildNeedBorder(i) {
      return currency(this.children[i].needRateRegularSum + this.children[i].needRateAdditionalsSum + this.accomodationCostPerHouseholdMember).divide(2).value;
    },
    
    getChildIncome(i) {
      const incomeKeysForSum = Object.keys(this.childrenIncome).filter(incomeKey => incomeKey.includes(`${i}/`))
      const deductionKeysForSum = Object.keys(this.childrenDeductions).filter(deductionKey => deductionKey.includes(`${i}/`))

      const incomeSum = currency(0).add(incomeKeysForSum.reduce((sum, incomeKey) => {
        return sum + parseFloat(this.childrenIncome[incomeKey].sum);
      }, 0))
        .add(parseFloat(this.children[i].incomeDuringSchoolOrTrainingSum))
        .add(parseFloat(this.children[i].childAllowance))
      .subtract(deductionKeysForSum.reduce((sum, deductionKey) => {
        return sum + parseFloat(this.childrenDeductions[deductionKey].value);
      }, 0)).value;

      return incomeSum < 0? 0: incomeSum;
    },

    focusedChildCurrencyField(index, field) {
      if(this.children[index][field] == '0.00') {
        this.children[index][field] = '';
      }
    },
    changedChildAllowance(index) {
      if(!this.children[index].childAllowance) {
        this.children[index].childAllowance = '0.00';
        return;
      }

      this.children[index].childAllowance = parseFloat(this.children[index].childAllowance).toFixed(2);

      this.changedChildIncome(index);
    },
    changedChildIncomeDuringSchoolOrTraining(index) {
      if(!this.children[index].incomeDuringSchoolOrTraining) {
        this.children[index].incomeDuringSchoolOrTraining = '0.00';
        return
      }

      if(parseFloat(this.children[index].incomeDuringSchoolOrTraining) <= this.employmentAllowance) {
        this.children[index].incomeDuringSchoolOrTrainingSum = 0;
      } else {
        this.children[index].incomeDuringSchoolOrTrainingSum = currency(parseFloat(this.children[index].incomeDuringSchoolOrTraining)).subtract(this.employmentAllowance).value;
      }

      this.children[index].incomeDuringSchoolOrTraining = parseFloat(this.children[index].incomeDuringSchoolOrTraining).toFixed(2);

      this.changedChildIncome(index);
    },

    changedChildIncomeDataRow(childIndex, incomeIndex) {
      if(!this.childrenIncome[`${childIndex}/${incomeIndex}`].value || parseFloat(this.childrenIncome[`${childIndex}/${incomeIndex}`].value) < 0) {
        this.$set(this.childrenIncome[`${childIndex}/${incomeIndex}`], 'value', '0.00');
        this.$set(this.childrenIncome[`${childIndex}/${incomeIndex}`], 'sum', 0);
        return;
      } else {
        this.$set(this.childrenIncome[`${childIndex}/${incomeIndex}`], 'value', parseFloat(this.childrenIncome[`${childIndex}/${incomeIndex}`].value).toFixed(2));
      }

      if(this.childrenIncome[`${childIndex}/${incomeIndex}`].interval) {
        this.$set(this.childrenIncome[`${childIndex}/${incomeIndex}`], 'sum', this.getIncomeSumAdjustedForInterval(
          parseFloat(this.childrenIncome[`${childIndex}/${incomeIndex}`].value), this.childrenIncome[`${childIndex}/${incomeIndex}`].interval
        ));
      } 

      this.changedChildIncome(childIndex);
    },

    focusedChildIncome(childIndex, incomeIndex) {
      if(this.childrenIncome[`${childIndex}/${incomeIndex}`].value == '0.00') {
        this.$set(this.childrenIncome[`${childIndex}/${incomeIndex}`], 'value', '');
      }
    },

    changedChildDeductionDataRow(childIndex, deductionIndex) {
      if(!this.childrenDeductions[`${childIndex}/${deductionIndex}`].value || parseFloat(this.childrenDeductions[`${childIndex}/${deductionIndex}`].value) < 0) {
        this.$set(this.childrenDeductions[`${childIndex}/${deductionIndex}`], 'value', '0.00');
        return;
      } else {
        this.$set(this.childrenDeductions[`${childIndex}/${deductionIndex}`], 'value', parseFloat(this.childrenDeductions[`${childIndex}/${deductionIndex}`].value).toFixed(2));
      }

      this.changedChildIncome(childIndex);
    },

    focusedChildDeduction(childIndex, deductionIndex) {
      if(this.childrenDeductions[`${childIndex}/${deductionIndex}`].value == '0.00') {
        this.$set(this.childrenDeductions[`${childIndex}/${deductionIndex}`], 'value', '');
      }
    },

    getChildIsMostlySelfReliant(childIndex) {
      return this.getChildNeedBorder(childIndex) < this.getChildIncome(childIndex)
    },

    changedChildIncome(childIndex) {
      if(this.children[childIndex].isInChildcare || !this.getChildIsMostlySelfReliant(childIndex)) {
        //set childAllowance to calc
        this.$set(this.calculation, this.children[childIndex].childAllowancePath, parseFloat(this.children[childIndex].childAllowance).toFixed(2));

        //set income school or training to calc
        this.$set(this.calculation, this.children[childIndex].incomeDuringSchoolOrTrainingPath, this.children[childIndex].incomeDuringSchoolOrTrainingSum.toFixed(2));

        //set wildcard income to calc
        const incomeKeys = Object.keys(this.childrenIncome).filter(incomeKey => incomeKey.includes(`${childIndex}/`));

        incomeKeys.forEach(incomeKey => {
          const wildcardMappingKey = this.childrenIncome[incomeKey].path.split("/").pop();

          this.$set(this.wildcardMapping, wildcardMappingKey, this.childrenIncome[incomeKey].name);
          this.$set(this.calculation, this.childrenIncome[incomeKey].path, this.childrenIncome[incomeKey].sum.toFixed(2));
        });


        //set wildcard deduction to calc
        const deductionKeys = Object.keys(this.childrenDeductions).filter(deductionKey => deductionKey.includes(`${childIndex}/`));

        deductionKeys.forEach(deductionKey => {
          const wildcardMappingKey = this.childrenDeductions[deductionKey].path.split("/").pop();

          this.$set(this.wildcardMapping, wildcardMappingKey, this.childrenDeductions[deductionKey].name);
          this.$set(this.calculation, this.childrenDeductions[deductionKey].path, parseFloat(this.childrenDeductions[deductionKey].value).toFixed(2));
        });

      } else {
        const incomeToBeUsed = this.getChildIncomeToBeUsed(childIndex);

        //set childAllowance to calc
        this.$set(this.calculation, this.children[childIndex].childAllowancePath, incomeToBeUsed);

        /* make sure other fields are set to 0 */

        //set income school or training to 0
        this.$set(this.calculation, this.children[childIndex].incomeDuringSchoolOrTrainingPath, '0.00');

        //set income to 0
        const incomeKeys = Object.keys(this.childrenIncome).filter(incomeKey => incomeKey.includes(`${childIndex}/`));
        incomeKeys.forEach(incomeKey => {
          const wildcardMappingKey = this.childrenIncome[incomeKey].path.split("/").pop();

          this.$set(this.wildcardMapping, wildcardMappingKey, '');
          this.$set(this.calculation, this.childrenIncome[incomeKey].path, '0.00');
        });

        //set deduction to 0
        const deductionKeys = Object.keys(this.childrenDeductions).filter(deductionKey => deductionKey.includes(`${childIndex}/`));
        deductionKeys.forEach(deductionKey => {
          const wildcardMappingKey = this.childrenDeductions[deductionKey].path.split("/").pop();

          this.$set(this.wildcardMapping, wildcardMappingKey, '');
          this.$set(this.calculation, this.childrenDeductions[deductionKey].path, '0.00');
        });

      }

      //realculate child in calculation
      this.changedCalcItemValue(this.children[childIndex].childAllowancePath);

      //reset family bonus
      this.setFamilyBonusAndRentSahreSelfReliant();
    },

    getChildIncomeToBeUsed(childIndex) {
      const income = this.getChildIncome(childIndex);
      const needBorder = this.getChildNeedBorder(childIndex) * 2;

      const incomeToBeUsed = currency(income).subtract(needBorder).value;

      //set childAllowance to calc
      if(incomeToBeUsed > 250) {
        return parseFloat(250).toFixed(2);
      } else {
        return incomeToBeUsed > 0? incomeToBeUsed.toFixed(2): 0;
      }
    },

    /*
    * Additional Household members methods
    */
    addAdditionalHouseholdMember(hhMemberIndex, hhMemeber, prefillhhMember) {
      let newHHMember = {
        incomeDuringSchoolOrTraining: '0.00',
        incomeDuringSchoolOrTrainingSum: 0,
        needRateRegularSum: 0,
        needRateAdditionalsSum: 0,
        income: new Array(5).fill({}),
        deductions: new Array(3).fill({}),
        ...hhMemeber,
        ...prefillhhMember
      }

      this.additionalHouseholdMembers.push(newHHMember);

      this.changedHHMemberIncomeDuringSchoolOrTraining(hhMemberIndex);

      for(let j=0; j<newHHMember.income.length; j++) {
        this.$set(this.additionalHHIncome, `${hhMemberIndex}/${j}`, {
          sum: 0,
          value: '0.00',
          name: '',
          interval: this.availableIncomeIntervals[0].value,
          ...newHHMember.income[j],
          ...(prefillhhMember.income? prefillhhMember.income[j]: {})
        });

        this.changedHHMemberIncomeDataRow(hhMemberIndex, j);
      }

      for(let j=0; j<newHHMember.deductions.length; j++) {
          this.$set(this.additionalHHDeductions, `${hhMemberIndex}/${j}`, {
          value: '0.00',
          name: '',
          ...newHHMember.deductions[j],
          ...(prefillhhMember.deductions? prefillhhMember.deductions[j]: {})
        });

        this.changedHHMemberDeductionDataRow(hhMemberIndex, j);
      }
    },

    initializeAdditionalHouseholdMembers() {
      this.$set(this, 'additionalHouseholdMembers', []); //TODO add behavior of prefilling addHH on timespan change

      if(this.mode == "UPDATE") {
        this.calcFormula.familyBonusCalculation.householdMembers?.forEach((hhMember, i) => {
          const prefillhhMember = this.calculationToUpdate.familyBonusCalculation.householdMembers.filter(pHHMember => pHHMember._id == hhMember._id);

          this.addAdditionalHouseholdMember(i, hhMember, prefillhhMember.length > 0? prefillhhMember[0]: {});
        });
      } else {
        this.calcFormula.familyBonusCalculation.householdMembers?.forEach((hhMember, i) => {
          this.addAdditionalHouseholdMember(i, hhMember, {});
        });
      }

      /* set regular needRate default */
      if(this.householdMemberNeedRates.regular.length == 1) {
        this.additionalHouseholdMembers.forEach(additionalHH => {
          additionalHH.needRateRegular = this.householdMemberNeedRates.regular[0].label;

          this.changedHHMemberdRegularNeedRate(this.additionalHouseholdMembers.indexOf(additionalHH));
        });
      }
      
      /* set additional needRate default */
      if(this.householdMemberNeedRates.additional.length == 1) {
        this.additionalHouseholdMembers.forEach(additionalHH => {
          additionalHH.needRateAdditionals = [this.householdMemberNeedRates.additional[0].label];

          this.changedHHMemberAdditionalNeedRate(this.additionalHouseholdMembers.indexOf(additionalHH));
        });
      }

      /* calc income for all hh members */
     this.additionalHouseholdMembers.forEach((hhmember, i) => {
        this.changedHHMemberIncome(i);
      });
    },

    getHHMemberIsMostlySelfReliant(i) {
      return this.getHHMemberNeedBorder(i) < this.getHHMemberIncome(i);
    },

    getHHMemberNeedBorder(i) {
      return currency(this.additionalHouseholdMembers[i].needRateRegularSum + this.additionalHouseholdMembers[i].needRateAdditionalsSum + this.accomodationCostPerHouseholdMember).divide(2).value;
    },

    changedHHMemberdRegularNeedRate(i) {
      this.additionalHouseholdMembers[i].needRateRegularSum = this.householdMemberNeedRates.regular.filter(nR => nR.label == this.children[i].needRateRegular)[0].value;

      //recalc additional need rate if necessary
      if(this.additionalHouseholdMembers[i].needRateAdditionals?.length > 0) {
        this.changedHHMemberAdditionalNeedRate(i);
      }
    },

    changedHHMemberAdditionalNeedRate(i) {
      //TODO use currency for calculations
      if(this.additionalHouseholdMembers[i].needRateAdditionals.length == 0 || !this.additionalHouseholdMembers[i].needRateRegular) {
        this.additionalHouseholdMembers[i].needRateAdditionalsSum = 0;
        return;
      }

      this.additionalHouseholdMembers[i].needRateAdditionalsSum = this.additionalHouseholdMembers[i].needRateAdditionals.reduce((sum, nR) => {
        return sum + (this.additionalHouseholdMembers[i].needRateRegularSum * (this.householdMemberNeedRates.additional.filter(nR2 => nR2.label == nR)[0].value/100));
      }, 0);
    },

    focusedIncomeDuringSchoolOrTrainingHHMember(i) {
      if(this.additionalHouseholdMembers[i].incomeDuringSchoolOrTraining == '0.00') {
        this.additionalHouseholdMembers[i].incomeDuringSchoolOrTraining = '';
      }
    },
    
    changedHHMemberIncomeDuringSchoolOrTraining(i) {
      if(!this.additionalHouseholdMembers[i].incomeDuringSchoolOrTraining) {
        this.additionalHouseholdMembers[i].incomeDuringSchoolOrTraining = '0.00';
        return
      }

      if(parseFloat(this.additionalHouseholdMembers[i].incomeDuringSchoolOrTraining) <= this.employmentAllowance) {
        this.additionalHouseholdMembers[i].incomeDuringSchoolOrTrainingSum = 0;
      } else {
        this.additionalHouseholdMembers[i].incomeDuringSchoolOrTrainingSum = currency(parseFloat(this.additionalHouseholdMembers[i].incomeDuringSchoolOrTraining)).subtract(this.employmentAllowance).value;
      }

      this.additionalHouseholdMembers[i].incomeDuringSchoolOrTraining = parseFloat(this.additionalHouseholdMembers[i].incomeDuringSchoolOrTraining).toFixed(2);

      this.changedHHMemberIncome(i);
    },

    getHHMemberIncome(i) {
      const incomeKeysForSum = Object.keys(this.additionalHHIncome).filter(incomeKey => incomeKey.includes(`${i}/`))
      const deductionKeysForSum = Object.keys(this.additionalHHDeductions).filter(deductionKey => deductionKey.includes(`${i}/`))

      const incomeSum = currency(0).add(incomeKeysForSum.reduce((sum, incomeKey) => {
        return sum + parseFloat(this.additionalHHIncome[incomeKey].sum);
      }, 0))
        .add(parseFloat(this.additionalHouseholdMembers[i].incomeDuringSchoolOrTrainingSum))
      .subtract(deductionKeysForSum.reduce((sum, deductionKey) => {
        return sum + parseFloat(this.additionalHHDeductions[deductionKey].value);
      }, 0)).value;

      return incomeSum < 0? 0: incomeSum;
    },

    focusedHHMemberIncome(i, incomeIndex) {
      if(this.additionalHHIncome[`${i}/${incomeIndex}`].value == '0.00') {
        this.$set(this.additionalHHIncome[`${i}/${incomeIndex}`], 'value', '');
      }
    },

    changedHHMemberIncomeDataRow(i, incomeIndex) {
      if(!this.additionalHHIncome[`${i}/${incomeIndex}`].value || parseFloat(this.additionalHHIncome[`${i}/${incomeIndex}`].value) < 0) {
        this.$set(this.additionalHHIncome[`${i}/${incomeIndex}`], 'value', '0.00');
        this.$set(this.additionalHHIncome[`${i}/${incomeIndex}`], 'sum', 0);
        return;
      } else {
        this.$set(this.additionalHHIncome[`${i}/${incomeIndex}`], 'value', parseFloat(this.additionalHHIncome[`${i}/${incomeIndex}`].value).toFixed(2));
      }

      if(this.additionalHHIncome[`${i}/${incomeIndex}`].interval) {
        this.$set(this.additionalHHIncome[`${i}/${incomeIndex}`], 'sum', this.getIncomeSumAdjustedForInterval(
          parseFloat(this.additionalHHIncome[`${i}/${incomeIndex}`].value), this.additionalHHIncome[`${i}/${incomeIndex}`].interval
        ));
      } 

      this.changedHHMemberIncome(i);
    },

    changedHHMemberDeductionDataRow(i, deductionIndex) {
      if(!this.additionalHHDeductions[`${i}/${deductionIndex}`].value || parseFloat(this.additionalHHDeductions[`${i}/${deductionIndex}`].value) < 0) {
        this.$set(this.additionalHHDeductions[`${i}/${deductionIndex}`], 'value', '0.00');
        return;
      } else {
        this.$set(this.additionalHHDeductions[`${i}/${deductionIndex}`], 'value', parseFloat(this.additionalHHDeductions[`${i}/${deductionIndex}`].value).toFixed(2));
      }

      this.changedHHMemberIncome(i);
    },

    focusedHHMemberDeduction(i, deductionIndex) {
      if(this.additionalHHDeductions[`${i}/${deductionIndex}`].value == '0.00') {
        this.$set(this.additionalHHDeductions[`${i}/${deductionIndex}`], 'value', '');
      }
    },

   changedHHMemberIncome(hhMemberIndex) {
      const hhMemeberIncomePath =  this.familyBonusTypePaths["ITEM_FAMILY_BONUS_HOUSEHOLD_MEMBERS_INCOME"];

      let sumOfIncome = 0;

      this.additionalHouseholdMembers.forEach((hhMember, i) =>{
        if(!this.getHHMemberIsMostlySelfReliant(i)) {
          sumOfIncome += this.getHHMemberIncome(i);
        }
      });

      this.$set(this.calculation, hhMemeberIncomePath, sumOfIncome.toFixed(2));
      this.changedCalcItemValue(hhMemeberIncomePath);

      this.setFamilyBonusAndRentSahreSelfReliant();
    },

    /* 
    * General FamilyBonus Methods 
    */

    setFamilyBonusAndRentSahreSelfReliant() {
      const familyBonusPath = this.familyBonusTypePaths["ITEM_FAMILY_BONUS_HOUSEHOLD_MEMBERS"];

      this.$set(this.calculation, familyBonusPath, this.familyBonusSum.toFixed(2));
      this.changedCalcItemValue(familyBonusPath);

      const rentSharePath =  this.familyBonusTypePaths["ITEM_FAMILY_BONUS_COST_OF_HOME_SHARE_HOUSEHOLD_MEMBERS_SELF_RELIANT"];
      this.$set(this.calculation, rentSharePath, this.rentShareMostlySelfReliant.toFixed(2));
      this.changedCalcItemValue(rentSharePath);
    },

    getIncomeSumAdjustedForInterval(value, interval) {
      switch(interval) {
        case 'monthly':
          return value;
        case 'yearly':
          return currency(value).divide(12).value;
        case 'weekly':
          return currency(value).multiply(52).divide(12).value;
        case 'daily':
          return currency(value).multiply(360).divide(12).value;
        case 'quarterly':
          return currency(value).divide(3).value;
      }
    },

    buildFamilyBonusCalcForSubmission() {
      let children = JSON.parse(JSON.stringify(this.children));

      this.children.forEach((child, i) => {
        const incomeKeys = Object.keys(this.childrenIncome).filter(incomeKey => incomeKey.includes(`${i}/`));

        children[i].income = incomeKeys.map(incomeKey => {
          return this.childrenIncome[incomeKey]
        });

        const deductionKeys = Object.keys(this.childrenDeductions).filter(dedKey => dedKey.includes(`${i}/`));

        children[i].deductions = deductionKeys.map(dedKey => {
          return this.childrenDeductions[dedKey]
        });

        children[i].costOfHomeShare = 0;
      });

      let householdMembers = JSON.parse(JSON.stringify(this.additionalHouseholdMembers));

      this.additionalHouseholdMembers.forEach((hhMember, i) => {
        const incomeKeys = Object.keys(this.additionalHHIncome).filter(incomeKey => incomeKey.includes(`${i}/`));

        householdMembers[i].income = incomeKeys.map(incomeKey => {
          return this.additionalHHIncome[incomeKey]
        });

        const deductionKeys = Object.keys(this.additionalHHDeductions).filter(dedKey => dedKey.includes(`${i}/`));

        householdMembers[i].deductions = deductionKeys.map(dedKey => {
          return this.additionalHHDeductions[dedKey]
        });
      });

      return { children, householdMembers };
    },

    async fetchCalcFormulaAndNeedRates(start, end, isInitial, prefillCalc) {
      if (!this.$router.currentRoute.query?.case) {
        this.showSnackbar("ERROR", "CASE Parameter ist nicht gesetzt");
        return;
      }

      //make prefill mapping -> keys = label of item - value = value of label in prefillCalc
      let itemPrefillMapping = {};
      
      if(prefillCalc) {
        const createPrefillMapping = (section) => {
          if(section.items) {
            section.items.forEach((item) => {
              itemPrefillMapping[item.label] = item.value;
              
              if(item.type == 'ITEM_WILDCARD') {
                this.wildcardMapping[item.label] = item.name;
              }
            });
          }

          if(section.sections) {
            section.sections.forEach(subsec => createPrefillMapping(subsec));
          }
        }

        prefillCalc.calc.forEach(section => createPrefillMapping(section));
      }

      const vm = this;

      //create paths for sum recursively
      const createSectionItemPaths = (basePath, section, first) => {
        const sectionLabel = section.label ? section.label : section._id;
        const newBasePath = first ? sectionLabel : basePath + "/" + sectionLabel;

        vm.$set(vm.sums, newBasePath, 0);
        vm.$set(vm.sectionPathMapping, newBasePath, section);

        if (section.items) {
          for (let item of section.items) {
            const itemLabel = item.label ? item.label : item._id;
            const itemPath = newBasePath + "/" + itemLabel;

            vm.$set(vm.calculation, itemPath, "0.00");

            if(item.default) {
              vm.$set(
                vm.calculation,
                itemPath,
                item.default.toFixed(2)
              );

              let sum = currency(vm.sums[newBasePath]);
              sum = sum
                .add(currency(item.default))
                .multiply(item.sign == "-" ? -1 : 1);

              vm.$set(vm.sums, newBasePath, sum.value);
            }

            if(item.type == "ITEM_COMPUTED") {
              vm.$set(vm.computedResultLabelMapping, item.computedResultLabel, itemPath);
            }

            if (item.type == "ITEM_MIN") {
              vm.$set(
                vm.calculation,
                itemPath,
                item.min.toFixed(2)
              );

              let sum = currency(vm.sums[newBasePath]);
              sum = sum
                .add(currency(item.min))
                .multiply(item.sign == "-" ? -1 : 1);

              vm.$set(vm.sums, newBasePath, sum.value);
            }

            //special items with prepopulation on init
            if(item.type.includes("ITEM_FAMILY_BONUS") || item.type.includes("ITEM_CHILDREN_BONUS")) {
              vm.$set(vm.familyBonusTypePaths, item.type, itemPath);
            } else if(item.type == 'ITEM_FLATRATE') {
              vm.$set(vm.calculation, itemPath, item.default.toFixed(2));
            } else if(itemPrefillMapping[item.label] && itemPrefillMapping[item.label] > 0) { //prefill items based on label equality with prefillcalc
              vm.$set(vm.calculation, itemPath, itemPrefillMapping[item.label].toFixed(2));
            }

            vm.$set(vm.itemPathMapping, itemPath, item);
          }
        }

        if (section.sections) {
          for (let sec of section.sections) {
            createSectionItemPaths(newBasePath, sec);
          }
        }
      };

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const caseURL = `${appConfig.apiURL}/sozialstaffel/cases/${this.$router.currentRoute.query?.case}`;

      try {
        const caseDataResponse = await  axios.get(caseURL, requestConfig);

        vm.caseData = caseDataResponse.data;
      } catch(e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }

      const startDate = new Date(start);
      const endDate = new Date(end);

      const calcContextURL = `${appConfig.apiURL}/sozialstaffel/calculationcontext/timespan/${this.$router.currentRoute.query?.case}?startDate=${utils.formatDateForDateInput(startDate)}&endDate=${utils.formatDateForDateInput(endDate)}`;

      try {
        const calcContextResp = await axios.get(calcContextURL, requestConfig);

        this.childNeedRates = calcContextResp.data.childNeedRates.value;
        this.employmentAllowance = calcContextResp.data.employmentAllowance.value.employmentAllowance;
        this.computedSchemas = calcContextResp.data.computedSchemas;
        this.householdMemberNeedRates = calcContextResp.data.householdMemberNeedRates.value;

        const calcFormula = calcContextResp.data.calculationFormula;

        /* Only rebuild sections if calcformula has changed */
        //if(calcFormula._id != this.calcFormula._id) {
          this.calculation = {};
          this.sectionPathMapping = {};
          this.itemPathMapping = {};
          this.sums = {};
          this.familyBonusTypePaths = {};

          /* Remove all sections that are second applicant only */
          let copiedFormula = {
            ...calcFormula,
            calcSchema: []
          }

          for (let section of calcFormula.calcSchema) {
              copiedFormula.calcSchema.push(section);

              createSectionItemPaths("", section, true);
            }

            this.calcFormula = copiedFormula; //use copied formula -> contains fields according to first/second applicant

            /* Initialize children after calcfomula -> needs familyBonusCalc */
            this.initializeChildren();
            /* Initialize additional household members after calcfomula -> needs familyBonusCalc */
            this.initializeAdditionalHouseholdMembers();
          //}

        //custom sort to recalc inner sections first (sections with more "/" )
        const innerSectionsFirst = (secA, secB) => {
          const numberOfSlashesInKey = (key) => (key.match(new RegExp("/", "g")) || []).length;

          return numberOfSlashesInKey(secB) - numberOfSlashesInKey(secA); 
        } 


        /* check if section is section min and correct values */
        Object.keys(vm.sectionPathMapping).sort(innerSectionsFirst).forEach(sectionPath => {
          vm.sums[sectionPath] = this.getSumForSection(sectionPath);

          if(vm.sectionPathMapping[sectionPath].type == "SECTION_MIN") {
            if(vm.sums[sectionPath] < vm.sectionPathMapping[sectionPath].min) {
              vm.sums[sectionPath] = vm.sectionPathMapping[sectionPath].min;
            }
          }
        });
        
        
        this.fetchedCalcStartDate = start;
        this.fetchedCalcEndDate = end;

        this.buildingCalcVars = false;
      } catch(e) {
        console.log(e)

        if(e.response?.status == 409) {

          if(e.response?.data?.payload.calculationFormula) {
            this.calcFormulaTimespanError = e.response.data.payload.calculationFormula.payload.conflictingDocuments;
          }

          if(e.response?.data?.payload.needRates) {
            this.timespanError = e.response?.data?.payload.needRates.payload.conflictingNeedRates;
          }

        } else if(e.response?.status == 406) {
          this.noCalcFormulaFound = true;
          this.noNeedRateFound = true;
        }

        this.timespanErrorDialog = true;
        return; //exit function to prevent from unlocking tables
      }

      if(!isInitial) {
        this.showSnackbar("SUCCESS", "Zeitspanne hat keine Überschneidung - Berechnungsblätter freigeschaltet");
        this.timespanValid = true;
      }
    },
    //check if form and timespan are valid
    async checkTimespan() {
      if (!this.$refs.timespanForm.validate()) {
        return;
      }

      const calcStart = new Date(this.calcStartDate);
      const calcEnd = new Date(this.calcEndDate)

      if(calcStart > calcEnd) {
        this.showSnackbar("ERROR", "Startdatum nach Enddatum - Bitte Zeitraum anpassen")
        return;
      }

      const prefill = {
        calc: this.buildCalculationForSubmission()
      }

      this.activeCalcTab = 0;

      await this.fetchCalcFormulaAndNeedRates(calcStart, calcEnd, false, prefill);
      this.initializeComputedFields();
    },
    //dialog control
    closeTimespanErrorDialog() {
      this.timespanErrorDialog = false;
      this.noCalcFormulaFound = false;
      this.noNeedRateFound = false;
      this.timespanError = null;
      this.calcFormulaTimespanError = [];
    },
    //Timespan was changed -> invalidate
    changedTimespan() {
      this.timespanValid = false;
    },

    confirmPageLeave() {
      this.isSafePageLeave = true;
      this.pageLeaveConfirmDialog = false;
      this.$router.push({ path: this.pageLeavePath });
    },

    openComputedDialog(item, resultFunction) {
      if(!this.computedProperties[item.computedPropertyName]) {
        
        this.$set(this.computedProperties, item.computedPropertyName, {
          inputs: {},
          results: []
        });

        Object.keys(this.computedSchemas[item.computedPropertyName].properties).forEach(prop => {
          this.computedProperties[item.computedPropertyName].inputs[prop] = 0;
        });
      }

      this.computedDialogSchema = this.computedSchemas[item.computedPropertyName];
      this.computedDialogInput = JSON.parse(JSON.stringify(this.computedProperties[item.computedPropertyName].inputs));
      this.computedDialogItem = item;
      this.computedDialogCalcFunction = resultFunction;

      this.computedDialog = true;
    },

    async getComputedDialogResult() {
      //save inputs
      this.computedProperties[this.computedDialogItem.computedPropertyName].inputs = this.computedDialogInput;

      //get result from server
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const startDate = new Date(this.calcStartDate);
      const endDate = new Date(this.calcEndDate);

      const computedResultURL = `${appConfig.apiURL}/sozialstaffel/computedfields/${this.computedDialogItem.computedPropertyName}/${this.$router.currentRoute.query.case}?startDate=${utils.formatDateForDateInput(startDate)}&endDate=${utils.formatDateForDateInput(endDate)}`;

      const body = this.computedProperties[this.computedDialogItem.computedPropertyName].inputs;

      try {
        const computedResultResponse = await  axios.post(computedResultURL, body, requestConfig);

        return computedResultResponse.data;
      } catch(e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },
    validateComputedDialogForm() {
      let valid = true;

      for(let ele of this.$refs["computedDialogForm"]) {
        if(ele.validate) {
          if(!ele.validate())  valid = false;
        }
      }

      return valid;
    },

    async getComputedResultAndWriteToCalc() {
      if(!this.validateComputedDialogForm()) return;

      try {
        const computedResult = await this.getComputedDialogResult();

        this.$set(this.computedProperties[this.computedDialogItem.computedPropertyName], "results", computedResult);

       //update calculation based on result
        computedResult.forEach(result => {

          //calculate section based on label
          const splitLabel = this.computedResultLabelMapping[result.computedResultLabel].split("/");
          splitLabel.splice(splitLabel.length - 1, 1);
          const section = splitLabel.join("/");

          //set value
          this.$set(this.calculation, this.computedResultLabelMapping[result.computedResultLabel], result.value);

          //update sum
          this.changedCalcValue(section, this.computedResultLabelMapping[result.computedResultLabel])
        });

        return computedResult;
      } catch(e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
        throw e;
      }
    },
    //get result and write to variable in data
    async getComputedDialogResultAndWriteToDataAndCalc(dataField) {
      try {
        if(!this.validateComputedDialogForm()) return;

        const computedResult = await this.getComputedResultAndWriteToCalc();

        this.$set(this.computedProperties[this.computedDialogItem.computedPropertyName], "results", computedResult);
        
        const valueOfResult = computedResult.filter(result => result.computedResultLabel == dataField)[0].value;

        if(valueOfResult != undefined) {
          this.$set(this.computedFields, dataField, valueOfResult.toFixed(2)); 
          this.changedComputedField(dataField);
        }
      } catch(e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
        throw e;
      }
    },
    changedComputedDialogInput(prop) {
      if(this.computedDialogSchema.properties[prop].type == 'number' && parseFloat(this.computedDialogInput[prop]) < 0) { 
        this.$set(this.computedDialogInput, prop, 0);
      }
    },
    closeComputedDialog() {
      this.computedDialog = false;
      this.computedDialogSchema = {
        properties: {}
      };
      this.computedDialogItem = {};
      this.computedDialogResult = {};
      this.computedDialogInput = {};
      this.computedDialogCalcFunction = null;
    },
    changedComputedField(field, isManualChange = false) {
      if(this.computedFields[field] == '') {
        this.computedFields[field] = '0.00';
        this.recalcSumOfAccomodationCosts();
        return;
      }

      this.computedFields[field] = parseFloat(this.computedFields[field]).toFixed(2);
      this.$set(this, field, parseFloat(this.computedFields[field]).toFixed(2));

      //calculate section based on label
      const splitLabel = this.computedResultLabelMapping[field].split("/");
      splitLabel.splice(splitLabel.length - 1, 1);
      const section = splitLabel.join("/");

      //set value
      this.$set(this.calculation, this.computedResultLabelMapping[field], parseFloat(this.computedFields[field]).toFixed(2));

      //update sum
      this.changedCalcValue(section, this.computedResultLabelMapping[field]);

      //on manual change set result to value
      if(isManualChange) {
        this.computedProperties[field].results = [{
          computedResultLabel: field,
          value: parseFloat(this.computedFields[field]).toFixed(2)
        }];
      }

      //recalculate sum of accomodation costs
      this.recalcSumOfAccomodationCosts();
    },
    focusedComputedField(field) {
      if(this.computedFields[field] == '0.00') {
        this.computedFields[field] = '';
      }
    },
    initializeComputedFields() {
      /* computed field initialization */
      if(this.mode == "UPDATE") {
        this.availableAccomodationComputedFields.forEach(computedField => {
          if(this.computedProperties[computedField.field].results.length == 0) {
            this.$set(this.computedFields, computedField.field, "0.00");
            this.$set(this, computedField.field, "0.00");
          } else {
            const valueOfResult = parseFloat(this.computedProperties[computedField.field].results.filter(result => result.computedResultLabel == computedField.field)[0].value);
            this.$set(this, computedField.field, valueOfResult.toFixed(2));
            this.$set(this.computedFields, computedField.field, valueOfResult.toFixed(2));  
          }
        });

      } else {
        //initialize available computed fields
        this.availableAccomodationComputedFields.forEach(computedField => {
          this.$set(this.computedFields, computedField.field, "0.00");
          this.$set(this, computedField.field, "0.00");
        });

        //initialize available computed fields
        Object.keys(this.computedSchemas).forEach(computedField => {
          this.$set(this.computedProperties, computedField, {
            inputs: {},
            results: []
          });

          Object.keys(this.computedSchemas[computedField].properties).forEach(prop => {
            
            if(this.computedSchemas[computedField].properties[prop].type == "number") {
              this.$set(this.computedProperties[computedField].inputs, prop, 0);
            } else {
              this.$set(this.computedProperties[computedField].inputs, prop, "");
            }
          });
        });
      }

      this.recalcSumOfAccomodationCosts();
    },
    recalcSumOfAccomodationCosts() {
        const sum = this.availableAccomodationComputedFields.reduce((acc, curr) => {
          if(this.computedProperties[curr.field]?.results?.length > 0) {
            acc = acc.add(this.computedProperties[curr.field].results.filter(result => result.computedResultLabel == curr.field)[0].value)
          }

          return acc;
        }, currency(0));

        this.sumOfAccomodationCosts = sum.value;
    },
    openItemIntervalCalculator(itemPath) {
      this.itemIntervalCalculatorPath = itemPath;
      this.itemIntervalCalculator = true;
      this.itemIntervalCalcValue = this.calculation[itemPath];
      this.recalcItemIntervalSum();
    },
    changedItemIntervalCalculatorInterval() {
      this.itemIntervalCalcValue = parseFloat(this.itemIntervalCalcValue).toFixed(2);
      this.recalcItemIntervalSum();
    },
    changedItemIntervalCalcValue() {
      if(!this.itemIntervalCalcValue) {
        this.itemIntervalCalcValue = '0.00';
      } else {
        this.itemIntervalCalcValue = parseFloat(this.itemIntervalCalcValue).toFixed(2);
      }

      this.recalcItemIntervalSum();
    },
    focusedItemIntervalCalcValue() {
      if(this.itemIntervalCalcValue == '0.00') {
        this.itemIntervalCalcValue = '';
      }
    },
    recalcItemIntervalSum() {
      this.itemIntervalCalcSum = this.getIncomeSumAdjustedForInterval(this.itemIntervalCalcValue, this.itemIntervalCalculatorInterval);
    },
    closeItemIntervalCalculator() {
      this.itemIntervalCalculator = false;
      if(!this.calculation[this.itemIntervalCalculatorPath]) {
        this.calculation[this.itemIntervalCalculatorPath] = '0.00';
      }

      this.itemIntervalCalculatorPath = '';
      this.itemIntervalCalcValue = '0.00';
      this.itemIntervalCalculatorInterval = 'monthly';
    },
    confirmItemIntervalCalculatorValue() {
      this.calculation[this.itemIntervalCalculatorPath] = this.itemIntervalCalcSum;
      this.changedCalcItemValue(this.itemIntervalCalculatorPath);
      this.closeItemIntervalCalculator();
    },
  },
  async mounted() {
    this.initialLoading = true;

    try {
      if(this.$router.currentRoute.query?.mode == "UPDATE") {
        this.mode = "UPDATE";

        const calculationURL = `${appConfig.apiURL}/sozialstaffel/calculations/${this.$router.currentRoute.query?.calculation}`;

        const requestConfig = {
          headers: {
            sessiontoken: localStorage.getItem("token"),
          },
        };

        const calculationResp = await axios.get(calculationURL, requestConfig);
        const calc = calculationResp.data;

        //set start and end Date
        this.calcStartDate = calc.calculationStart.split("T")[0];
        this.calcEndDate = calc.calculationEnd.split("T")[0];

        this.calculationToUpdate = calc;

        await this.fetchCalcFormulaAndNeedRates(new Date(this.calcStartDate), new Date(this.calcEndDate), false, calc);

        /* set computed properties to one from calc */
        this.computedProperties = calc.computedProperties;

        this.checkTimespan();
      } else {
        await this.fetchCalcFormulaAndNeedRates(new Date(), new Date(), true);
      }

    } finally {
      this.initialLoading = false;
    }
  },
  beforeRouteLeave(to, from , next) {
    if(!this.isSafePageLeave) {
      this.pageLeavePath = to.fullPath;
      this.pageLeaveConfirmDialog = true;
    } else {
      next();
    }
  }
};
</script>