<template>
  <v-main
    class="mt-3"
    style="background-color: #ffffff; width: 100vw; height: 95vh"
  >
    <v-app-bar app elevation="1" color="white">
      <v-btn color="primary" elevation="0" @click="back()" outlined>
        <v-icon>mdi-arrow-left-thick</v-icon>
        Zurück zum Launchpad
      </v-btn>
    </v-app-bar>
      <v-snackbar
        v-model="errorSnackbar"
        timeout="3000"
        color="red"
        dark
        :absolute="true"
        :top="true"
      >
        {{ errorSnackbarText }}
      </v-snackbar>
      <v-snackbar
        v-model="successSnackbar"
        timeout="3000"
        color="blue"
        dark
        :absolute="true"
        :top="true"
      >
        {{ successSnackbarText }}
      </v-snackbar>
    
    <h1 style="padding-left: 2cm">Hier wird mal DSGVO gemanaged</h1>

  </v-main>
</template>
<script>
import axios from "axios";

import appConfig from "../config";

export default {
  name: "Dsgvo",
  data() {
    return {
      //snackbars
      errorSnackbar: false,
      errorSnackbarText: "",
      successSnackbar: false,
      successSnackbarText: "",
    };
  },
  methods: {
    /*
     * common
     */
    back() {
      this.$router.push("/launchpad");
    },
    showSnackbar(type, text) {
      if (type == "ERROR") {
        this.errorSnackbar = true;
        this.errorSnackbarText = text;
      } else {
        this.successSnackbar = true;
        this.successSnackbarText = text;
      }
    },
  },
};
</script>