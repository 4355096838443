<template>
  <div class="pt-8">
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      :color="snackBarType == 'ERROR' ? 'red' : 'blue'"
      dark
      :absolute="true"
      :top="true"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-row class="justify-center">
      <v-col cols="12" sm="12" md="6">
        <v-card>
          <v-card-title class="text-h5">
            Sachbearbeiterkonto
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                  <p>Diese Daten erscheinen auf den Bescheiden.</p>
                </v-col>
            </v-row>
            <v-form ref="officialForm">
              <!--
              <v-row>
                <v-col cols="12">
                  <h3>Adresse (Standort ihrer Verwaltung)</h3>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    label="Stadt*"
                    v-model="formData.address.city"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Postleitzahl*"
                    v-model="formData.address.postalCode"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    label="Straße*"
                    v-model="formData.address.street"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Hausnummer*"
                    v-model="formData.address.number"
                  ></v-text-field>
                </v-col>
              </v-row>
              -->
              <v-row>
                <v-col cols="12">
                  <h3>Kontaktdaten</h3>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    label="Anrede*"
                    v-model="formData.formOfAddress"
                    :items="availableFormsOfAddress"
                    required
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    label="Arbeitsort*"
                    v-model="formData.location"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    label="Raumnummer*"
                    v-model="formData.room"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    label="Telefonnummer*"
                    v-model="formData.phone"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <small>* Pflichtfeld bitte ausfüllen</small>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary darken-1" outlined @click="save()">
              Änderungen Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" sm="12" md="6">
        <v-card>
          <v-card-title class="text-h5">
            Einstellungen für die Wiedervorlage
          </v-card-title>
          <v-card-text>
            <v-form ref="settingsForm">
              <v-row>
                <v-col cols="12">
                  <h3>Einstellungen</h3>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-switch
                    label="Erinnerungsemail für Wiedervorlage versenden*"
                    v-model="settingsFormData.sendMailForStaleCases"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="4">
                </v-col>
               <br/>
                <v-col cols="4">
                  <v-text-field
                    label="Erinnerungsemail Intervall (in Tagen)*"
                    v-model="settingsFormData.markCaseAsStaleAfterDays"
                    type="number"
                    @change="settingsFormData.markCaseAsStaleAfterDays = parseInt(settingsFormData.markCaseAsStaleAfterDays)"
                    :disabled="!settingsFormData.sendMailForStaleCases"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <small>* Pflichtfeld bitte ausfüllen</small>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary darken-1" outlined @click="saveSettings()">
              Änderungen Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import appConfig from "../../config";
import utils from "../../utils";
import axios from "axios";

export default {
  name: "SozialstaffelSettings",
  data() {
    return {
      snackBarType: "",
      snackbarText: "",
      snackbar: false,
      formData: {
        phone: "",
        room: "",
        location: "",
        formOfAddress: "",
        //address: {},
      },
      availableFormsOfAddress: [
          { text: "Herr", value: "Herr" },
          { text: "Frau", value: "Frau" },
          { text: "OHNE", value: " "}
      ],
      settingsFormData: {
        markCaseAsStaleAfterDays: 0,
        sendMailForStaleCases: false
      },
      rules: {
        required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
      },
    }
  },
  methods: {
    async save() {
      if(!this.$refs.officialForm.validate()) {
        return;
      }

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/officials/my`;

      const body = this.formData;

      try {
        const MyOfficialResponse = await axios.put(url, body, requestConfig);

        this.showSnackbar("SUCCESS", "Änderungen erfolgreich gespeichert.")
      } catch(e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      } finally {
        window.scrollTo({ top: 0 });
      }
    },
    async saveSettings() {
      if(!this.$refs.settingsForm.validate()) {
        return;
      }

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/settings/user/sozialstaffel`;

      const body = {
        "value": this.settingsFormData
      };

      try {
        const settingsResponse = await axios.put(url, body, requestConfig);

        this.showSnackbar("SUCCESS", "Änderungen erfolgreich gespeichert.")
      } catch(e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      } finally {
        window.scrollTo({ top: 0 });
      }
    },
    /* Utils */
    showSnackbar(type, text) {
      if (type == "ERROR") {
        this.snackBarType = "ERROR";
        this.snackbarText = text;
      } else {
        this.snackBarType = "SUCCESS";
        this.snackbarText = text;
      }

      this.snackbar = true;
    },
  },
  async mounted() {
    const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

    const url = `${appConfig.apiURL}/sozialstaffel/officials/my`;

    const settingsURL = `${appConfig.apiURL}/settings/user/sozialstaffel`;

    try {
      const myOfficialResponse = await axios.get(url, requestConfig);

      this.formData = myOfficialResponse.data;

      const settingsResponse = await axios.get(settingsURL, requestConfig);

      this.settingsFormData = settingsResponse.data.value;
    } catch(e) {
      this.showSnackbar("ERROR", appConfig.defaultErrorText);
    }
  }
};
</script>