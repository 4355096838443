<template>
  <div>
    <v-dialog v-model="confirmSupDialog" persistent max-width="600px">
      <ConfirmDialog title="Aktivierung Support Zugang"
        text="Durch die Aktivierung des Support Zugangs erlauben Sie einem Support-Mitarbeiter der Verwaltungscloud.SH GmbH auf Ihr System zuzugreifen, um eine Fehlerdiagnose durchzuführen. Aktivieren Sie den Support Zugang bitte nur, wenn Sie dazu aufgefordert werden. Nach Abschluss der Fehlerdiagnose den Zugang bitte wieder deaktivieren."
        action-label-continue="Bestätigen" action-label-abort="Schließen" ref="confirmSupConfirmDialog"
        v-on:continue="confirmDialog(true)" :inverse-buttons="true" v-on:close="closeDialog(false)" />
    </v-dialog>
    <v-dialog v-model="cancelSupDialog" persistent max-width="600px">
      <ConfirmDialog title="Deaktivierung Support Zugang"
        text="Durch die Deaktivierung des Support Zugangs entziehen Sie den Support-Mitarbeitern der Verwaltungscloud.SH GmbH den Zugriff auf Ihr System."
        action-label-continue="Bestätigen" action-label-abort="Schließen" ref="cancelSuptConfirmDialog"
        :inverse-buttons="true" v-on:continue="confirmDialog(false)" v-on:close="closeDialog(true)" />
    </v-dialog>

    <v-snackbar v-model="errorSnackbar" timeout="3000" color="red" dark :absolute="true" :top="true">
      {{ errorSnackbarText }}
    </v-snackbar>

    <v-app-bar color="primary" elevation="0" dark>
      <v-toolbar-title>Verwaltungscloud.SH</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-switch 
        v-model="supSwitch" 
        label="Support Zugang" 
        @change="switchSupport(supSwitch)" 
        hide-details
        class="ma-4" 
        v-if="currentUser.permissions.includes('config_supportaccess')" 
        :loading="supSwitchLoading"
        :disabled="supSwitchDisabled"/>
      <v-btn color="#B31342" @click="logout()">
        Ausloggen
        <v-icon dark> mdi-exit-to-app </v-icon>
      </v-btn>
    </v-app-bar>
    <v-main class="pl-12 pr-12">
      <v-row class="pt-12">
        <v-col>
          <h2>Applikationen</h2>
        </v-col>
      </v-row>
      <v-row class="d-flex align-stretch">
        <v-col xl="2" md="3" cols="12" sm="6" v-if="currentUser.permissions.includes('sozialstaffel_basic')">
          <v-hover v-slot="{ hover }" class="hover-pointer">
            <v-card outlined :elevation="hover ? 6 : 0" @click="clickedApp('/sozialstaffel/dashboard')">
              <v-responsive :aspect-ratio="1 / 1">
                <v-card-title>Sozialermäßigung Berechnung und <br/> Bescheidung</v-card-title>
                <v-card-text
                  >Auf die Kachel klicken um die Applikation zu
                  starten</v-card-text
                >
                <v-container>
                  <v-icon size="50"> mdi-human-male-female-child</v-icon>
                </v-container>
              </v-responsive>
            </v-card>
          </v-hover>
        </v-col>
        <v-col xl="2" md="3" cols="12" sm="6" v-if="currentUser.permissions.includes('sozialstaffel_ozg')">
          <v-hover v-slot="{ hover }" class="hover-pointer">
            <v-card outlined :elevation="hover ? 6 : 0" @click="clickedApp('/ozg/sozialstaffel')">
              <v-responsive :aspect-ratio="1 / 1">
                <v-card-title>Sozialermäßigung Online-Antrag</v-card-title>
                <v-card-text
                  >Auf die Kachel klicken um die Applikation zu
                  starten</v-card-text
                >
                <v-container>
                  <v-icon size="50">mdi-human-male-female-child</v-icon>
                  <v-icon size="50">mdi-mailbox</v-icon>
                  <v-badge color="secondary" :content="caseInboxCount" inline v-if="caseInboxCount > 0">
                  </v-badge>
                </v-container>
              </v-responsive>
            </v-card>
          </v-hover>
        </v-col>
        <v-col xl="2" md="3" cols="12" sm="6" v-if="currentUser.permissions.includes('sozialstaffel_analytics_global')">
          <v-hover v-slot="{ hover }" class="hover-pointer">
            <v-card outlined :elevation="hover ? 6 : 0" @click="clickedApp('/analytics/sozialstaffel')">
              <v-responsive :aspect-ratio="1 / 1">
                <v-card-title>Sozialermäßigung Statistik</v-card-title>
                <v-card-text>Auf die Kachel klicken um die Applikation zu
                  starten</v-card-text>
                <v-container>
                  <v-icon size="50">mdi-chart-arc</v-icon>
                </v-container>
              </v-responsive>
            </v-card>
          </v-hover>
        </v-col>
        <v-col xl="2" md="3" cols="12" sm="6" v-if="currentUser.permissions.includes('sozialstaffel_analytics_tenant')">
          <v-hover v-slot="{ hover }" class="hover-pointer">
            <v-card outlined :elevation="hover ? 6 : 0" @click="clickedApp('/analytics/sozialstaffel/tenant')">
              <v-responsive :aspect-ratio="1 / 1">
                <v-card-title>Sozialermäßigung Statistik (Lokal)</v-card-title>
                <v-card-text>Auf die Kachel klicken um die Applikation zu
                  starten</v-card-text>
                <v-container>
                  <v-icon size="50">mdi-chart-arc</v-icon>
                </v-container>
              </v-responsive>
            </v-card>
          </v-hover>
        </v-col>
        <v-col xl="2" md="3" cols="12" sm="6" v-if="currentUser.permissions.includes('config_global_sozialstaffel')">
          <v-hover v-slot="{ hover }" class="hover-pointer">
            <v-card outlined :elevation="hover ? 6 : 0" @click="clickedApp('/settings/global/sozialstaffel')">
              <v-responsive :aspect-ratio="1 / 1">
                <v-card-title>Sozialermäßigung Konfiguration (Fachaufsicht)</v-card-title>
                <v-card-text>Auf die Kachel klicken um die Applikation zu
                  starten</v-card-text>
                <v-container>
                  <v-icon size="50">mdi-folder-cog</v-icon>
                </v-container>
              </v-responsive>
            </v-card>
          </v-hover>
        </v-col>
        <v-col xl="2" md="3" cols="12" sm="6" v-if="currentUser.permissions.includes('kitabilling_basic')">
          <v-hover v-slot="{ hover }" class="hover-pointer">
            <v-card outlined :elevation="hover ? 6 : 0" @click="clickedApp('/kitabilling')">
              <v-responsive :aspect-ratio="1 / 1">
                <v-card-title>KITA Abrechnung</v-card-title>
                <v-card-text>Auf die Kachel klicken um die Applikation zu
                  starten</v-card-text>
                <v-container>
                  <v-icon size="50"> mdi-bank-transfer</v-icon>
                </v-container>
              </v-responsive>
            </v-card>
          </v-hover>
        </v-col>
        <v-col xl="2" md="3" cols="12" sm="6" v-if="currentUser.permissions.includes('config_security')">
          <v-hover v-slot="{ hover }" class="hover-pointer">
            <v-card outlined :elevation="hover ? 6 : 0" @click="clickedApp('/security_config')">
              <v-responsive :aspect-ratio="1 / 1">
                <v-card-title>Sicherheitseinstellungen</v-card-title>
                <v-card-text>Auf die Kachel klicken um die Applikation zu
                  starten</v-card-text>
                <v-container>
                  <v-icon size="50"> mdi-security </v-icon>
                </v-container>
              </v-responsive>
            </v-card>
          </v-hover>
        </v-col>
        <v-col xl="2" md="3" cols="12" sm="6" v-if="currentUser.permissions.includes('gdpr')">
          <v-hover v-slot="{ hover }" class="hover-pointer">
            <v-card outlined :elevation="hover ? 6 : 0" @click="clickedApp('/dsgvo')">
              <v-responsive :aspect-ratio="1 / 1">
                <v-card-title>DSGVO Management</v-card-title>
                <v-card-text>Auf die Kachel klicken um die Applikation zu
                  starten</v-card-text>
                <v-container>
                  <v-icon size="50"> mdi-cloud-lock</v-icon>
                </v-container>
              </v-responsive>
            </v-card>
          </v-hover>
        </v-col>
        <v-col xl="2" md="3" cols="12" sm="6" v-if="currentUser.permissions.includes('user_management')">
          <v-hover v-slot="{ hover }" class="hover-pointer">
            <v-card outlined :elevation="hover ? 6 : 0" @click="clickedApp('/users')">
              <v-responsive :aspect-ratio="1 / 1">
                <v-card-title>Benutzeradministration</v-card-title>
                <v-card-text>Auf die Kachel klicken um die Applikation zu
                  starten</v-card-text>
                <v-container>
                  <v-icon size="50"> mdi-account-cog</v-icon>
                </v-container>
              </v-responsive>
            </v-card>
          </v-hover>
        </v-col>
        <v-col xl="2" md="3" cols="12" sm="6" v-if="currentUser.permissions.includes('tenant_management')">
          <v-hover v-slot="{ hover }" class="hover-pointer">
            <v-card outlined :elevation="hover ? 6 : 0">
              <v-responsive :aspect-ratio="1 / 1">
                <v-card-title>Lizenzmanagement</v-card-title>
                <v-card-text>Auf die Kachel klicken um die Applikation zu
                  starten</v-card-text>
                <v-container>
                  <v-icon size="50"> mdi-credit-card-edit-outline</v-icon>
                </v-container>
              </v-responsive>
            </v-card>
          </v-hover>
        </v-col>
        <v-col xl="2" md="3" cols="12" sm="6" v-if="currentUser.isSupportUser">
          <v-hover v-slot="{ hover }" class="hover-pointer">
            <v-card outlined :elevation="hover ? 6 : 0" @click="clickedApp('/support/tenant')">
              <v-responsive :aspect-ratio="1 / 1">
                <v-card-title>Support Tenant Auswahl</v-card-title>
                <v-card-text>Auf die Kachel klicken um die Applikation zu
                  starten</v-card-text>
                <v-container>
                  <v-icon size="50"> mdi-face-agent</v-icon>
                </v-container>
              </v-responsive>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<style lang="css" scoped>
.hover-pointer {
  cursor: pointer;
}

.icon-pointer>>> :hover {
  cursor: pointer;
}
</style>
<script>
import axios from "axios";

import appConfig from "../config";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import { updateSupportAccess, getSupportAccess } from "../utils/apiHelper.js";

export default {
  name: "Launchpad",
  components: { ConfirmDialog },
  data: function () {
    return {
      username: "",
      password: "",
      currentUser: {
        permissions: []
      },
      caseInboxCount: 0,
      cancelSupDialog: false,
      confirmSupDialog: false,
      supSwitch: false,
      supSwitchLoading: false,
      supSwitchDisabled: false,
      errorSnackbar: false,
      errorSnackbarText: "",
    };
  },
  async mounted() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const requestConfig = {
      headers: {
        sessiontoken: localStorage.getItem("token"),
      },
    };

    if (this.currentUser.permissions.includes('sozialstaffel_ozg')) {
      const url = `${appConfig.apiURL}/sozialstaffel/ozgapplications/count?status=pending`;

      try {
        const resp = await axios.get(url, requestConfig);

        this.caseInboxCount = resp.data.count ? resp.data.count : 0;
      } catch (e) {
        console.log(e);
      }
    }

    if (this.currentUser.permissions.includes('config_supportaccess')) {
      try {
        const resp = await getSupportAccess(localStorage.getItem("token"));
        this.supSwitch = resp.data.value.active;
      } catch (e) {
        console.error(e);
      }
    }
  },
  props: {},
  methods: {
    setSupportAccess: async function (active) {
      try {
        await updateSupportAccess(localStorage.getItem("token"), active);
      } catch (e) {
        console.error(e)
        this.supSwitch = !active;
        this.showSnackbar("Fehler bei der Änderung des Support Zugangs");
      } finally {
        this.supSwitchDisabled = false
        this.supSwitchLoading = false
      }
    },

    clickedApp: function (app) {
      console.log(app);
      this.$router.push(app);
    },
    logout: function () {
      delete localStorage.token;

      this.$router.push('/login');
    },

    switchSupport: function (on) {
      if (on) {
        this.confirmSupDialog = true
      } else {
        this.cancelSupDialog = true
      }
    },
    confirmDialog: async function (on) {
      this.supSwitchDisabled = true
      this.supSwitchLoading = true
      await this.setSupportAccess(on)
      if (on) {
        this.confirmSupDialog = false
      } else {
        this.cancelSupDialog = false
      }
    },
    closeDialog: function (on) {
      console.log(on)
      this.supSwitch = on
      if (on) {
        this.cancelSupDialog = false
      } else {
        this.confirmSupDialog = false
      }
    },

    showSnackbar(text) {
      this.errorSnackbar = true;
      this.errorSnackbarText = text;
    },
  }
};
</script>