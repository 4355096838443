<template>
  <div class="mt-4">
    <v-row>
      <v-col cols="12" md="9">
        <template v-for="firstSection in calcFormula.calcSchema">
          <v-row :key="firstSection.label">
            <v-col cols="12" md="1"></v-col>
            <v-col cols="12" md="5"
              ><b>({{ firstSection.sign }}) {{ firstSection.name }}</b></v-col
            >
            <v-col cols="12" md="4"
              ><p style="text-align: right">
                <b>{{ getCurrencyFormatted(sums[firstSection.label]) }}€</b>
              </p></v-col
            >
          </v-row>
          <template v-for="SecondSection in firstSection.sections">
            <v-row :key="SecondSection.label">
              <v-col cols="12" md="2"></v-col>
              <v-col cols="12" md="5"
                >({{ SecondSection.sign }}) {{ SecondSection.name }}
              </v-col>
              <v-col cols="12" md="2"
                ><p style="text-align: right">
                  {{
                    getCurrencyFormatted(
                      sums[firstSection.label + "/" + SecondSection.label]
                    )
                  }}€
                </p></v-col
              >
            </v-row>
          </template>
        </template>
        <v-row>
          <v-col cols="12" md="1"></v-col>
          <v-col cols="12" md="10">
            <v-divider></v-divider>
            <v-divider class="mt-1"></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="5"></v-col>
          <v-col cols="12" md="5">
            <p style="text-align: right">
              <b
                >Verbleibendes Einkommen: {{ getCurrencyFormatted(finalResult) }}€</b
              >
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SozialstaffelCalcResult",
  props: ["sums", "calcFormula", "finalResult"],
  data() {
    return {}
  },
  methods: {
    getCurrencyFormatted(num) {
      return num.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  },
  created() {
  },
};
</script>