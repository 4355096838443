<template>
    <v-main>
        <v-dialog v-model="loadingSave" persistent max-width="400px">
            <v-card>
                <v-card-title>
                Speichere Dokument... <br /><span style="font-size: 10pt"
                    >(Dies kann einige Sekunden dauern)</span
                ></v-card-title
                >
                <v-card-text>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="2"></v-col>
            <v-col cols="7" class="pt-8">
                <v-row v-if="pdfError" justify="center">
                    <v-col cols="12" sm="6">
                        <v-alert type="error">
                            <h2>Ein Fehler ist aufgetreten</h2>
                            <p>Es ist ein Fehler beim Laden des PDFs aufgetreten. Bitte kontaktieren Sie den Support unter support@verwaltungscloud.sh.</p>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row v-else-if="pdfLoading" justify="center">
                    <v-col cols="12" sm="6">
                        <v-card>
                            <v-card-title>
                                    <h4>
                                        PDF wird generiert...<br />
                                        <span style="font-size: 10pt">(Dies kann einige Sekunden dauern)</span>
                                    </h4>
                                </v-card-title>
                                <v-card-text>
                                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                                </v-card-text>
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
                <iframe v-else id="pdfiframe" :src="`${blobUrl}#toolbar=0&navpanes=0&zoom=110`" style="width: 100%;z-index:2; height: 85vh; margin-top: 0;" frameborder="0">
                    Oops! an error has occurred.
                </iframe>  
            </v-col>
            <v-col cols="3" class="pt-9 pr-6">
                <v-card class="mb-3">
                    <v-card-actions>
                        <v-btn color="secondary" elevation="0" width="100%" @click="save()">Als Entwurf speichern</v-btn>
                    </v-card-actions>
                </v-card>   
                <v-card>
                    <v-alert type="warning" v-if="Object.values(calculatedTopLevelSections).some(val => val === false)">
                        Es wurden einige Abschnitte noch nicht abgeschlossen.<br /><br />
                        <v-btn color="primary" elevation="0" @click="showIncomplete">Anzeigen</v-btn>
                    </v-alert>
                    <v-card-title>
                        <h4>Abgeschlossene Sektionen</h4>
                    </v-card-title>
                    <v-card-text>
                        <v-row v-for="(sectionKey) in Object.keys(calculatedTopLevelSections)" :key="sectionKey">
                            <v-col cols="12" sm="1">
                                <v-icon color="green" dense class="mr-1" v-if="calculatedTopLevelSections[sectionKey]">
                                    mdi-check-circle
                                </v-icon>
                                <v-icon v-else color="red" dense class="mr-1">
                                    mdi-close-circle
                                </v-icon>
                            </v-col>
                            <v-col cols="12" sm="11">
                                <span style="text-overflow: ellipsis; color: black">{{sectionKey}}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-main>
</template>
<script>
import axios from "axios";
import appConfig from "../../../config";
import utils from "../../../utils";

import { createCalculation, updateCalculation } from "./apiHelper"

import { 
    resetState,
    calculationContextState, calculationMutations,
    computedPropertiesMutations, calculatedSectionsState,
    navigationMutations
} from "../../../store/sozialstaffel/CalculationState";

export default {
    name: "CalculationDocumentPreview",
    props: [ ],
    data() {
        return {
            blobUrl: "",
            pdfdata: "",
            pdfLoading: false,
            pdfError: false,
            loadingSave: false,
        }
    },
    methods: {
        getCalculationPayload() {
            const calculationForSubmission = calculationMutations.getCalculationForSubmission();
            const incomeSurplus = calculationMutations.getIncomeSurplus(calculationForSubmission);

            const calculationStart = this.calculationTimespanAndApplication.calculationStartDate;
            const calculationEnd = this.calculationTimespanAndApplication.calculationEndDate;
            const application = this.calculationTimespanAndApplication.application;
            const calculationFormula = this.calculationFormula._id;
            const computedProperties = computedPropertiesMutations.getCurrentComputedProperties();
            

            const payload = {
                calc: calculationForSubmission,
                case: this.$router.currentRoute.query?.case,
                ...{ 
                    calculationStart, calculationEnd, calculationFormula, 
                    computedProperties, incomeSurplus, application 
                }
            }

            return payload;
        },
        async getDocumentPDF() {
            this.pdfError = false;
            this.pdfLoading = true;

            const requestConfig = {
                headers: {
                    sessiontoken: localStorage.getItem("token"),
                },
            };

            let url = `${appConfig.apiURL}/sozialstaffel/calculations/prerender`;

            try {
                const payload = this.getCalculationPayload();

                const pdfResponse = await axios.post(url, payload, requestConfig);

                this.pdfdata = `data:application/pdf;base64, ${pdfResponse.data.base64}#toolbar=0&navpanes=0`;

                const blob = utils.b64toBlob(pdfResponse.data.base64, 'application/pdf');
                const blobUrl = URL.createObjectURL(blob);

                this.blobUrl = blobUrl;
            } catch(e) {
                console.log(e);
                this.pdfError = true;
            } finally {
                this.pdfLoading = false;
            }
        },
        async save() {
            this.loadingSave = true;
            const payload = this.getCalculationPayload();

            try {
                let document;

                if(this.$router.currentRoute.query?.mode === 'UPDATE') {
                    document = await updateCalculation(this.$router.currentRoute.query?.calculation, payload);
                } else {
                    document = await createCalculation(payload);
                }

                const documentDetailURL = `/sozialstaffel/cases/documents/detail?id=${document._id}&type=calculation`;

                resetState();
                this.$router.push(documentDetailURL);

            } catch(e) {
                console.log(e);
            } finally {
                this.loadingSave = false;
            }
        },
        showIncomplete() {
            for(let section of this.calculationContext?.calculationFormula?.calcSchema) {
                if(!section.sections.map((subsection) => subsection.label).every((subsection) => this.calculatedSections.includes(subsection))) {
                    navigationMutations.setSectionWithSubsection(section.label, null);
                    this.$emit("back");
                    return;
                }
            }
        }
    },
    computed: {
        calculationTimespanAndApplication() {
            return {
                calculationStartDate: calculationContextState.calculationStartDate,
                calculationEndDate: calculationContextState.calculationEndDate,
                application: calculationContextState.application,
                presetSet: calculationContextState.presetSet
            };
        },
        calculationFormula() {
            return calculationContextState.calculationContext.calculationFormula;
        },
        calculationContext: function() {
            return calculationContextState.calculationContext;
        },
        calculatedSections() {
            return calculatedSectionsState.calculatedSections;
        },
        calculatedTopLevelSections() {
            const calculated = {};

            const topLevelSections = this.calculationContext?.calculationFormula?.calcSchema?.forEach((section) => { 
                calculated[section.name] = section.sections.map((subsection) => subsection.label).every((subsection) => this.calculatedSections.includes(subsection));
            });
        
            return calculated;
        },
    },
    mounted() {
        this.getDocumentPDF();
    },
}
</script>