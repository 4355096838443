<template>
    <ApplicationForm 
        :caseData="caseData"
        formTitle="Neuen Antrag erstellen"
        v-on:close="closeApplicationForm()"
        v-on:safe="safe"
        ref="applicationFormChild"
    >
        <template v-slot:formExtend>
            <v-row align="center" justify="center">
                <v-col md="6">
                    <p style="font-size: 14pt">Antrag vollständig mit allen Nachweisen eingegangen?</p>
                </v-col>
                <v-col md="6">
                    <v-radio-group
                        row
                        class="pb-5"
                        v-model="applicationComplete"
                    >
                        <v-radio
                            label="JA"
                            :value="true"
                            class="pr-5"
                        ></v-radio>
                        <v-radio
                            label="NEIN"
                            :value="false"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-form ref="documentRequestForm">
                <div v-if="!applicationComplete">
                <v-row>
                    <v-col md="12">
                        <v-alert
                            border="left"
                            colored-border
                            type="info"
                            >
                                Aus diesen Angaben wird ein Dokument mit einer Nachforderung erstellt.
                        </v-alert>
                    </v-col>
                    <v-col md="12">
                        <DocRequestForm
                            ref="docRequestFormChild"
                            width="100%"
                        ></DocRequestForm>
                    </v-col>
                </v-row>
                </div>
            </v-form>
        </template>
    </ApplicationForm>
</template>
<script>
    import appConfig from "../../../config";
    import axios from "axios";
    import ApplicationForm from "./ApplicationForm.vue";
    import DocRequestForm from "../docRequestForm/DocRequestFormExtension.vue";

    export default {
        name: "ApplicationFormCreate",
        props: [ "caseData" ],
        components: {
            ApplicationForm,
            DocRequestForm
        },
        data() {
            return {
                applicationComplete: true
            }
        },
        async mounted() {

        },

        methods: {
            closeApplicationForm() {
                this.$emit('close');
                this.resetForm();
            },
            resetForm() {
                this.applicationComplete = true;
                this.$refs.docRequestFormChild.resetForm();
                if(this.$refs.applicationFormChild) {
                    this.$refs.applicationFormChild.resetForm();
                }
            },
            async safe(applicationFormData) {
                if (!this.$refs.documentRequestForm.validate()) {
                    return;
                }

                //only validate docRequestForm if application is not complete
                let docRequestFormChild = null;
                if(!this.applicationComplete) {
                    docRequestFormChild = this.$refs.docRequestFormChild.validateForm();

                    if(!docRequestFormChild) {
                        return;
                    }
                }

                //create result to send back to parent component
                let result;

                const requestConfig = {
                    headers: {
                        sessiontoken: localStorage.getItem("token"),
                    },
                };

                const url = `${appConfig.apiURL}/sozialstaffel/applications`;

                try {
                    const data = { ...applicationFormData, case: this.caseData._id };

                    if(this.applicationComplete) {
                        data.dateOfCompletion = applicationFormData.dateOfReceipt;
                    }

                    const applicationResp = await axios.post(url,  data, requestConfig);

                    result = { application: applicationResp.data }

                    if(!this.applicationComplete) {
                        const documentRequestData = {
                            application: applicationResp.data._id,
                            ...docRequestFormChild,
                            dateOfIssue: new Date(),
                            case: this.caseData._id,
                            deadline: new Date(new Date().setDate(new Date().getDate() + 14))
                        };

                        const documentRequestURL = `${appConfig.apiURL}/sozialstaffel/documentrequests`;

                        const docRequestResponse = await axios.post(documentRequestURL, documentRequestData, requestConfig);

                        result = { ...result, documentRequest: docRequestResponse.data };
                    }
                } catch (err) {
                    this.$emit('closeerror');
                    this.resetForm();
                    return;
                }

                this.$emit('closesuccess', result);
                this.resetForm();
            },
        }
    }
</script>