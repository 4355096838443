<template>
    <div>
        <v-row>
            <v-col cols="12">
                <p style="font-size: 14pt;">Kalkulationsformel Gültigkeit: {{
                    getFormattedDate(calculationContext?.calculationFormula?.validFrom) }} bis {{
                    getFormattedDate(calculationContext?.calculationFormula?.validTo) }}</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="12"><b>Geschwisterermäßigung (Gültig: {{ 
                        `${getFormattedDate(calculationContext?.calculationFormula?.validFrom)} bis ${getFormattedDate(calculationContext?.calculationFormula?.validTo)}`
                    }}):</b></v-col>
                    <v-col cols="12" md="6">Erstes Kind:</v-col>
                    <v-col cols="12" md="6" class="text-right">{{
                    calculationContext?.calculationFormula?.siblingDiscountPercentages?.first }}%</v-col>
                    <v-col cols="12" md="6">Zweites Kind: </v-col>
                    <v-col cols="12" md="6" class="text-right">{{
                    calculationContext?.calculationFormula?.siblingDiscountPercentages?.second }}%</v-col>
                    <v-col cols="12" md="6">Weitere Kinder: </v-col>
                    <v-col cols="12" md="6" class="text-right">{{
                    calculationContext?.calculationFormula?.siblingDiscountPercentages?.others }}%</v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="12"><b>Bedarfssätze (Gültig: {{ 
                        `${getFormattedDate(calculationContext?.householdMemberNeedRates?.validFrom)} bis ${getFormattedDate(calculationContext?.householdMemberNeedRates?.validTo)}`
                    }}):</b></v-col>
                    <v-col cols="12" md="6">{{ calculationContext?.householdMemberNeedRates?.value?.base?.name
                        }}</v-col>
                    <v-col cols="12" md="6" class="text-right">{{
                    formatCurrency(calculationContext?.householdMemberNeedRates?.value?.base?.value) }}€</v-col>
                    <v-col cols="12" md="6">{{
                    calculationContext?.householdMemberNeedRates?.value?.familyBonusValue?.name }}</v-col>
                    <v-col cols="12" md="6" class="text-right">{{
                    formatCurrency(calculationContext?.householdMemberNeedRates?.value?.familyBonusValue?.value)
                }}€</v-col>
                    <v-col cols="12" md="6">Freibetrag für geringfügig Beschäftigte: </v-col>
                    <v-col cols="12" md="6" class="text-right">{{
                        formatCurrency(calculationContext?.employmentAllowance?.value?.employmentAllowance) }}€</v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="12"><b>Kindergeld/Kindergrundsicherung (Gültig: {{ 
                        `${getFormattedDate(calculationContext?.childAllowance?.validFrom)} bis ${getFormattedDate(calculationContext?.childAllowance?.validTo)}`
                    }}):</b></v-col>
                    <v-col cols="12" md="6">Erstes Kind:</v-col>
                    <v-col cols="12" md="6" class="text-right">{{
                    formatCurrency(calculationContext?.childAllowance?.value?.first) }}€</v-col>
                    <v-col cols="12" md="6">Zweites Kind: </v-col>
                    <v-col cols="12" md="6" class="text-right">{{
                        formatCurrency(calculationContext?.childAllowance?.value?.second) }}€</v-col>
                    <v-col cols="12" md="6">Drittes Kinder: </v-col>
                    <v-col cols="12" md="6" class="text-right">{{
                        formatCurrency(calculationContext?.childAllowance?.value?.third) }}€</v-col>
                    <v-col cols="12" md="6">Weitere Kinder: </v-col>
                    <v-col cols="12" md="6" class="text-right">{{
                        formatCurrency(calculationContext?.childAllowance?.value?.others) }}€</v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import utils from "../../../utils";
import { calculationContextState } from "../../../store/sozialstaffel/CalculationState";

export default {
    name: "CalcContextDisplay",
    data() {
        return {
        }
    },
    methods: {
        getFormattedDate(date) {
            return utils.formatDate(date);
        },
        formatCurrency(num) {
            return utils.formatCurrency(num);
        }
    },
    computed: {
        calculationContext() {
            return calculationContextState.calculationContext;
        }
    },
}
</script>