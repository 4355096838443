<template>
    <v-main class="mt-6 mx-3">
        <v-dialog v-model="failedComputedPropsDialog" max-width="800">
            <v-card>
                <v-card-title>
                    <span class="headline">Fehler bei der automatischen Berechnung</span>
                </v-card-title>
                <v-card-text>
                    <v-alert type="error" color="orange" elevation="0">
                        Bei der Neuberechnung einiger Berechnungsfelder sind Fehler aufgetreten.
                    </v-alert>
                    <v-alert type="info" colored-border border="left" elevation="0">
                        Die Berechnung konnte für folgende Felder nicht automatisch durchgeführt werden:<br /><br />
                        <ul>
                            <template v-for="prop in failedComputedProps">
                                <li v-if="computedSchemas[prop]?.title">
                                    <i>{{ computedSchemas[prop].title }}</i>
                                </li>
                            </template>
                        </ul>
                        <br />
                        <b>Die Werte dieser Felder wurden nicht automatisch übernommen und müssen erneut manuell eingegeben werden.</b>
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="failedComputedPropsDialog = false">Schließen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="noRecalcInfoDialog" max-width="600">
            <v-card>
                <v-card-title>
                    <span class="headline">Werte aus Berechnungshilfe gelöscht</span>
                </v-card-title>
                <v-card-text>
                    <v-alert type="info" colored-border border="left" elevation="0">
                        Um eine korrekte Berechnung zu gewährleisten, wurden die Werte aus der Berechnungshilfe gelöscht. 
                        Bitte geben Sie die Werte an den entsprechenden Stellen erneut an.
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="noRecalcInfoDialog = false">Schließen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
            <v-row cols="12" justify="center">
                <v-col cols="12" xs="0" md="2"></v-col>
                <v-col v-if="initialLoading" cols="12" md="4">
                    <v-card>
                        <v-card-title>
                        Lade Daten... <br /><span style="font-size: 10pt"
                            >(Dies kann einige Sekunden dauern)</span
                        ></v-card-title
                        >
                        <v-card-text>
                            <v-progress-linear indeterminate color="primary"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col v-else cols="12" md="8" style="max-width: 1000px">
                    <h1 class="my-3 primary--text">Grunddaten</h1>
                    <v-expansion-panels accordion v-model="panel">
                        <v-expansion-panel :readonly="panel == 0">
                            <v-expansion-panel-header>
                                <span>
                                    <v-icon color="green" class="mr-2" style="margin-bottom: 4px;" v-if="panel > 0">
                                        mdi-check-circle
                                    </v-icon>
                                    <b style="font-size: 14pt">Antrag</b>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row justify="center">
                                    <v-col cols="12" md="8">
                                        <v-select
                                            label="Antrag auswählen"
                                            v-model="selectedApplication"
                                            :items="applications"
                                            required
                                        >
                                            <template v-slot:item="{ parent, item, on, attrs }">
                                                <v-list-item v-on="on" v-bind="attrs">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ item.text }}
                                                        <v-chip color="primary" v-if="applicationsData.find(app => app._id == item.value).isOnlineApplication">
                                                            Online
                                                        </v-chip>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" class="mt-6">
                                    <v-col cols="12" md="4">
                                        <v-btn elevation="0" color="primary" v-if="selectedApplication" @click="clickedNextPanel()" width="100%">
                                            Weiter
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel :readonly="panel == 1" :disabled="panel < 1">
                            <v-expansion-panel-header>
                                <span>
                                    <v-icon color="green" class="mr-2" style="margin-bottom: 4px;" v-if="panel > 1">
                                        mdi-check-circle
                                    </v-icon>
                                    <b style="font-size: 14pt">Zeitraum</b>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row v-if="timespanError" justify="center">
                                    <v-col cols="12" md="8">
                                        <v-alert type="error" colored-border border="left" elevation="0">
                                            <b>Der Beginn des Berechnungszeitraums muss vor dem Ende liegen.</b>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                                <v-form ref="timespanForm">
                                    <v-row justify="center">
                                        <v-col cols="12" md="4" class="pt-8">
                                            <p>Beginn Berechnungszeitraum:</p>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field
                                                label="Beginn*"
                                                type="date"
                                                v-model="calculationStartDate"
                                                :disabled="timespanPanelLoading"
                                                @change="changedTimespan()"
                                                :rules="[rules.required]"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" md="4" class="pt-8">
                                            <p>Ende Berechnungszeitraum:</p>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field
                                                label="Ende*"
                                                type="date"
                                                v-model="calculationEndDate"
                                                :disabled="timespanPanelLoading"
                                                @change="changedTimespan()"
                                                :rules="[rules.required]"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                <v-row justify="center" class="mt-6">
                                    <v-col cols="12" md="4" style="text-align: center">
                                        <v-progress-circular
                                            indeterminate
                                            color="primary"
                                            v-if="timespanPanelLoading"
                                        ></v-progress-circular>
                                        <v-btn elevation="0" v-if="!timespanPanelLoading" color="primary" @click="clickedNextPanelTimespan()" width="100%" class="mb-3">
                                            Weiter
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel :readonly="panel == 2" :disabled="panel < 2">
                            <v-expansion-panel-header>
                                <span>
                                    <b style="font-size: 14pt">Hintergrundwerte prüfen</b>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="mb-6">
                                    <v-divider></v-divider>
                                </v-row>
                                <CalcContextDisplay />
                                <v-row justify="center" class="mt-6">
                                    <v-col cols="12" md="4">
                                        <v-btn elevation="0" color="primary" @click="clickedNextSection()" width="100%" class="mb-3">
                                            Weiter
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
                <v-col cols="12" md="2">
                </v-col>
            </v-row>
    </v-main>
</template>
<script>
import axios from "axios";
import appConfig from "../../../config";
import utils from "../../../utils";

import { calculateComputedField } from "./apiHelper";

import { 
    calculationContextState,
    calculationContextMutations,
    calculationMutations,
    computedPropertiesMutations,
    computedPropertiesState
} from "../../../store/sozialstaffel/CalculationState";

import CalcContextDisplay from "./CalcContextDisplay.vue";

export default {
    name: "IncomeTimespanSelect",
    components: {
        CalcContextDisplay
    },
    data() {
        return {
            panel: 0,
            applications: [],
            selectedApplication: null,
            applicationsData: [],

            /* timespan panel data */
            calculationStartDate: null,
            calculationEndDate: null,
            timespanPanelLoading: false,
            timespanError: false,
            rules: {
                required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
            },

            /* check panel data */
            fetchedCalcContext: {},

            initialLoading: false,
            failedComputedPropsDialog: false,
            noRecalcInfoDialog: false,
            failedComputedProps: []
        }
    },
    methods: {
        clickedNextPanel() {
            this.panel += 1;
        },
        async clickedNextPanelTimespan() {
            if(this.calculationStartDate > this.calculationEndDate) {
                this.timespanError = true;
                return;
            }

            if(!this.$refs.timespanForm.validate()) {
                return;
            }

            this.timespanPanelLoading = true;
            calculationContextMutations.setLoading(true);

            try {
                const calcContext = await this.fetchCalculationContext();

                this.fetchedCalcContext = calcContext;

                //cache some fields for later processing
                const cachedCalc = calculationMutations.getCalculationForSubmission();
                const oldComputedFields = computedPropertiesMutations.getCurrentComputedProperties();
                const oldCalcFormulaId = this.calculationContext?.calculationFormula?._id;

                calculationContextMutations.setCalculationContext(calcContext);

                //after context set the preset if that has changed
                if(cachedCalc) {
                    calculationMutations.setPresetFromPreviousCalculation(cachedCalc);

                    if(oldCalcFormulaId && oldCalcFormulaId != this.calculationContext.calculationFormula._id) {
                        this.noRecalcInfoDialog = true;
                    } else {
                        //hacky solution to calculate all computed fields - TODO: refactor
                        this.failedComputedProps = await this.recalcComputedFieldsFromPreviousCalc(oldComputedFields);

                        if(this.failedComputedProps.length > 0) {
                            this.failedComputedPropsDialog = true;
                        }
                    }

                    //TODO: refactor also factor in that default values might have changed i.e. Kindergeld
                    /*await this.recalcComputedFieldsFromPreviousCalc(
                        
                    );*/
                }

                this.clickedNextPanel();
            } catch (e) {
                console.log(e);
            } finally {
                calculationContextMutations.setLoading(false);
                this.timespanPanelLoading = false;
            }
        },
        async fetchCalculationContext() {
            //fetch calculation context for the selected timespan
            const startDate = new Date(this.calculationStartDate);
            const endDate = new Date(this.calculationEndDate);

            const requestConfig = {
                headers: {
                    sessiontoken: localStorage.getItem("token"),
                },
            };

            const calcContextURL = `${appConfig.apiURL}/sozialstaffel/calculationcontext/timespan/${this.$router.currentRoute.query?.case}?startDate=${utils.formatDateForDateInput(startDate)}&endDate=${utils.formatDateForDateInput(endDate)}`;

            const calcContextResp = await axios.get(calcContextURL, requestConfig);

            //move to storage
            return calcContextResp.data;
        },
        changedTimespan() {
            this.timespanError = false;
        },
        clickedNextSection() {
            window.scrollTo({ top: 0 });
            
            //set state based on mutations
            calculationContextMutations.setCalculationTimespanAndApplication(
                this.calculationStartDate, this.calculationEndDate, this.selectedApplication
            );

            this.$emit('next');
        },
        async recalcComputedFieldsFromPreviousCalc(computedProps) {
            const failedComputedProps = [];

            for(let propertyName of Object.keys(computedProps)) {
                try {
                    const computedPropResponse = await calculateComputedField(
                        this.$router.currentRoute.query?.case,
                        propertyName, 
                        this.calculationStartDate, 
                        this.calculationEndDate,
                        computedProps[propertyName].inputs, 
                        computedPropertiesMutations.getCurrentComputedProperties()
                    );

                    computedPropertiesMutations.setComputedPropertyResult(propertyName, computedPropResponse);
                    computedPropertiesMutations.setComputedPropertyInput(propertyName, computedProps[propertyName].inputs);
                } catch(e) {
                    //add prop if request fails
                    failedComputedProps.push(propertyName);
                }
            }

            return failedComputedProps;
        }
    },
   async mounted() {
        const requestConfig = {
            headers: {
                sessiontoken: localStorage.getItem("token"),
            },
        };

        const caseId = this.$route.query.case;
        const applicationURL = `${appConfig.apiURL}/sozialstaffel/applications?$filter=case eq cast('${caseId}',ObjectId)&$orderby=created desc`;

        try {
            const applicationTypeMappingURL = `${appConfig.apiURL}/static/mappings/applicationtypemapping`;

            const [applicationResponse, applicationTypeMappingResponse] = await Promise.all([
                axios.get(applicationURL, requestConfig),
                axios.get(applicationTypeMappingURL, requestConfig)
            ]);
            
            //swap type mapping for easier handling
            const swap = obj => Object.fromEntries(Object.entries(obj).map(a => a.reverse()))
            const swappedTypeMapping = swap(applicationTypeMappingResponse.data)


            this.applications = applicationResponse.data.data.map(application => {
                return {
                    value: application._id,
                    text: `${swappedTypeMapping[application.type]} vom ${utils.formatDate(application.dateOfApplication)}`
                }
            });

            //put all applicaitons in applications data
            this.applicationsData = applicationResponse.data.data;

            //initialize with presets if present
            if(this.calculationTimespanAndApplication.presetSet) {
                if(this.calculationTimespanAndApplication.calculationStartDate) {
                    this.calculationStartDate = utils.formatDateForDateInput(this.calculationTimespanAndApplication.calculationStartDate);
                }

                if(this.calculationTimespanAndApplication.calculationEndDate) {
                    this.calculationEndDate = utils.formatDateForDateInput(this.calculationTimespanAndApplication.calculationEndDate);
                }

                if(this.calculationTimespanAndApplication.application) {
                    this.selectedApplication = this.calculationTimespanAndApplication.application;
                }
                
                this.panel = 2;

            //initialize with presets on update if there are no previous presets 
            } else if(this.$router.currentRoute.query?.mode === 'UPDATE') {
                this.initialLoading = true;

                const requestConfig = {
                    headers: {
                        sessiontoken: localStorage.getItem("token"),
                    },
                };

                //fetch calc context based on data from calc to update
                const calculationURL = `${appConfig.apiURL}/sozialstaffel/calculations/${this.$router.currentRoute.query?.calculation}`;

                const calculationResp = await axios.get(calculationURL, requestConfig);

                //set data based on previous application
                this.calculationStartDate = utils.formatDateForDateInput(calculationResp.data.calculationStart);
                this.calculationEndDate = utils.formatDateForDateInput(calculationResp.data.calculationEnd);
                this.selectedApplication = calculationResp.data.application;
                
                const calcContext = await this.fetchCalculationContext();

                this.fetchedCalcContext = calcContext;
                calculationContextMutations.setCalculationContext(calcContext);

                //after context set the preset
                calculationMutations.setPresetFromPreviousCalculation(calculationResp.data.calc);

                //hacky solution to calculate all computed fields - TODO: refactor
                this.failedComputedProps = await this.recalcComputedFieldsFromPreviousCalc(calculationResp.data.computedProperties);

                if(this.failedComputedProps.length > 0) {
                    this.failedComputedPropsDialog = true;
                }

                this.initialLoading = false;

                this.panel = 2;
            }

            
        } catch (e) {
            console.log(e);
        } 
    },
    computed: {
        calculationTimespanAndApplication() {
            return {
                calculationStartDate: calculationContextState.calculationStartDate,
                calculationEndDate: calculationContextState.calculationEndDate,
                application: calculationContextState.application,
                presetSet: calculationContextState.presetSet
            };
        },
        calculationContext() {
            return calculationContextState.calculationContext;
        },
        computedSchemas() {
            return computedPropertiesState.computedSchemas;
        }
    }
}
</script>