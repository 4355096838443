<template>
    <div>
        <v-dialog v-model="pageLeaveConfirmDialog" persistent max-width="600px">
            <ConfirmDialog
                title="Seite verlassen"
                text="Möchten Sie die Seite wirklich verlassen? <b>Nicht gespeicherte Änderungen gehen unwiderruflich verloren!</b>"
                @close="pageLeaveConfirmDialog = false"
                @continue="confirmPageLeave"
                action-label-continue="Bestätigen"
                action-label-abort="Schließen"
                inverse-buttons
            ></ConfirmDialog>
        </v-dialog>
        <IncomeCalculator2NavLayout
            :step="step"
            :key="reloadNavLayout"
            @back="backClicked()"
            @next="nextSectionClicked()"
        />
        <IncomeTimespanSelect
            v-if="step == 1"
            @next="nextSectionClicked()"
        />
        <CalculationSectionDisplay 
            v-if="step == 2"
            @next="nextSectionClicked()"
        />
        <CalculationDocumentPreview
            v-if="step == 3"
            @back="backClicked()"
        />
    </div>
</template>

<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import IncomeCalculator2NavLayout from "./../../components/sozialstaffel/incomeCalc2/NavLayout.vue";
import CalculationSectionDisplay from "../../components/sozialstaffel/incomeCalc2/CalculationSectionDisplay.vue";
import IncomeTimespanSelect from "../../components/sozialstaffel/incomeCalc2/IncomeTimespanSelect.vue";
import CalculationDocumentPreview from "../../components/sozialstaffel/incomeCalc2/CalculationDocumentPreview.vue";

import { resetState } from "../../store/sozialstaffel/CalculationState";

export default {
    name: "IncomeCalculator2",
    data() {
        return {
            /* before leave hook */
            isSafePageLeave: false,
            pageLeaveConfirmDialog: false,
            pageLeavePath: "",
            step: 1,
            reloadNavLayout: 0,
            calcContextDisplayDialog: false
        };
    },
    components: {
        ConfirmDialog,
        IncomeCalculator2NavLayout,
        CalculationSectionDisplay,
        IncomeTimespanSelect,
        CalculationDocumentPreview
    },
    methods: {
        confirmPageLeave() {
            this.isSafePageLeave = true;
            this.pageLeaveConfirmDialog = false;
            this.$router.push({ path: this.pageLeavePath });
        },
        nextSectionClicked() {
            this.step += 1;
        },
        backClicked() {
            this.step -= 1;
        }
    },
    async mounted() {
    },
    beforeRouteLeave(to, from , next) {
        if(!this.isSafePageLeave && to.path != "/sozialstaffel/cases/documents/detail") {
            this.pageLeavePath = to.fullPath;
            this.pageLeaveConfirmDialog = true;
        } else {
            resetState();
            next();
        }
    }
};
</script>

<style scoped>
</style>