<template>
    <div>
        <v-snackbar
            v-model="snackbar"
            timeout="3000"
            :color="snackBarType == 'ERROR' ? 'red' : 'blue'"
            dark
            :absolute="true"
            :top="true"
            >
            {{ snackbarText }}
            </v-snackbar>
        <v-row v-if="initialLoading">
            <v-col cols="12" align="center">
            <v-card class="mt-9" width="30%">
            <v-card-title style="text-align: left">
                Lade Anträge ...
                </v-card-title>
                <v-card-text>
                <span style="font-size: 12pt; text-align: left">Dies kann einige Sekunden dauern</span>
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
            </v-col>
        </v-row>
        <div  v-else>
        <v-row>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
                <v-text-field
                    v-model="search"
                    @change="fetchData"
                >
                <v-icon
                    slot="prepend"
                    color="primary"
                >
                    mdi-magnify
                </v-icon>
                </v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="data"
            :options.sync="options"
            :server-items-length="count"
            :loading="loading"
            loading-text="Lade Daten..."
            item-key="_id"
            class="elevation-0 row-pointer"
            @click:row="handleRowClick"
            :footer-props="{
                'items-per-page-options': [5, 10, 15, 20, 25],
                'items-per-page-text': 'Einträge pro Seite',
            }"
        ></v-data-table>
    </div>
    </div>
  </template>
  <style lang="css" scoped>
  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }
  .v-data-table.v-data-table.v-data-table >>> td {
    font-size: 12pt !important;
  }
  
  .v-data-table.v-data-table.v-data-table >>> th {
    font-size: 14pt !important;
  }
  </style>
  <script>
  import appConfig from "../../config";
  import utils from "../../utils";
  import axios from "axios";
  
  export default {
    name: "InboxTable",
    data() {
        return {
            options: {},
            headers: [
                {
                    text: "Antragstyp",
                    align: "start",
                    sortable: false,
                    value: "formattedApplicationType",
                },
                { text: "Erster Antragsteller", value: "formattedPrimaryApplicant", sortable: false  },
                { text: "Erstellt am", value: "formattedCreatedAt", sortable: false  },
                { text: "", value: "actions", sortable: false },
            ],
            snackbarText: "",
            snackBarType: "SUCCESS",
            snackbar: false,
            data: [],
            count: 0,
            loading: true,
            search: "",
            applicationTypes: {},
            initialLoading: true
        }
    },
    async mounted() {
        this.initialLoading = true;

        const requestConfig = {
            headers: {
                sessiontoken: localStorage.getItem("token"),
            },
        };

        const applicationTypeMappingURL = `${appConfig.apiURL}/static/mappings/applicationtypemapping`;
        const response = await axios.get(applicationTypeMappingURL, requestConfig);
        this.applicationTypes = response.data;

        await this.fetchData();

        this.initialLoading = false;
    },
  
    watch: {
        options: {
            handler() {
                this.fetchData();
            },
            deep: true,
        },
    },

    methods: {
        showSnackbar(type, text) {
            if (type == "ERROR") {
                this.snackBarType = "ERROR";
                this.snackbarText = text;
            } else {
                this.snackBarType = "SUCCESS";
                this.snackbarText = text;
            }

            this.snackbar = true;
        },
        async fetchData() {
            this.loading = true;

            const requestConfig = {
                headers: {
                    sessiontoken: localStorage.getItem("token"),
                },
            };

            //build url
            let url = `${appConfig.apiURL}/sozialstaffel/ozgapplications?status=pending`;

            if(this.search) {
                url += `&$search=${this.search}`;
            }

            if(this.options.itemsPerPage && this.options.page) {
                url += `&$skip=${(this.options.page - 1)*this.options.itemsPerPage}&$top=${this.options.itemsPerPage}`;
            }

            const response = await axios.get(url, requestConfig);

            this.data = response.data.data.map((application) => {
                return {
                    ...application,
                    formattedApplicationType: this.getFormattedApplicationType(application.applicationData.type),
                    formattedCreatedAt: this.getFormattedDate(application.createdAt, true),
                    formattedPrimaryApplicant: `${application.caseData.primaryApplicant.formOfAddress} ${application.caseData.primaryApplicant.firstName} ${application.caseData.primaryApplicant.lastName}`,
                };
            });

            this.count = response.data.count;

            this.loading = false;
        },
        handleRowClick(row) {
            this.$router.push(`/ozg/sozialstaffel/detail?id=${row._id}`);
        },
        getFormattedApplicationType(applicationType) {
            return Object.keys(this.applicationTypes).filter(
                (key) => this.applicationTypes[key] == applicationType
            )[0];
        },
        getFormattedDate(d, minutes) {
            return utils.formatDate(d, minutes);
        },
    },
  
  };
  </script>
  