<template>
    <v-card>
        <v-card-title class="text-h5"> 
            Zu monatlichem Wert umrechnen
            <v-icon slot="append" class="ml-3">
                mdi-abacus
            </v-icon>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="12" md="6">
                    <v-text-field
                        type="number"
                        v-model="value"
                        @change="changedItemIntervalCalcValue()"
                        @focus="focusedItemIntervalCalcValue()"
                    >
                        <v-icon slot="append" color="primary">
                            mdi-currency-eur
                        </v-icon>
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                <v-select
                    label="Zeitraum"
                    v-model="itemIntervalCalculatorInterval"
                    :items="availableIncomeIntervals"
                    item-text="text"
                    item-value="value"
                    @change="changedItemIntervalCalculatorInterval()"
                ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="2">
                <v-icon color="primary">
                    mdi-equal
                </v-icon>
                </v-col>
                <v-col cols="12" sm="12" md="3" class="pt-4" style="text-align: right">
                    <b>{{ formatCurrency(this.result) }}</b>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                    <v-icon color="primary">
                        mdi-currency-eur
                    </v-icon>
                    <span class="ml-5" style="color: black">
                        pro Monat
                    </span>
                </v-col>
            </v-row>
            </v-card-text>
        <v-card-actions>
            <v-btn color="red darken-1" text @click="closeItemIntervalCalculator()">
                Schließen
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="confirmItemIntervalCalculatorValue()">
                Übernehmen
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import utils from "../../../utils";
    import currency from "currency.js";

    export default {
        name: "ItemIntervalDialog",
        props: [ "itemLabel", "initialValue" ],
        data() {
            return {
                value: "0.00",
                result: 0,
                itemIntervalCalculatorInterval: 'monthly',
                availableIncomeIntervals: [
                    { value:'monthly', text: 'monatlich' },
                    { value:'yearly', text: 'jährlich' },
                    { value:'weekly', text: 'wöchentlich' },
                    { value:'daily', text: 'täglich' },
                    { value:'quarterly', text: 'quartalsweise' }
                ],
            }
        },
        methods: {
            closeItemIntervalCalculator() {
                this.$emit("close");
            },
            confirmItemIntervalCalculatorValue() {
                this.$emit("close", this.result);
            },
            changedItemIntervalCalcValue() {
                if(this.value == "") {
                    this.value = "0.00";
                } else {
                    this.value = parseFloat(this.value).toFixed(2);
                }

                this.recalculateItemInterval();
            },
            focusedItemIntervalCalcValue() {
                if(this.value == "0.00") {
                    this.value = "";
                }
            },
            changedItemIntervalCalculatorInterval() {
                this.recalculateItemInterval();
            },
            recalculateItemInterval() {
                this.result = this.getIncomeSumAdjustedForInterval(
                    parseFloat(this.value),
                    this.itemIntervalCalculatorInterval
                );
            },
            getIncomeSumAdjustedForInterval(value, interval) {
                switch(interval) {
                    case 'monthly':
                        return value;
                    case 'yearly':
                        return currency(value).divide(12).value;
                    case 'weekly':
                        return currency(value).multiply(52).divide(12).value;
                    case 'daily':
                        return currency(value).multiply(360).divide(12).value;
                    case 'quarterly':
                        return currency(value).divide(3).value;
                }
            },
            formatCurrency(num) {
                return utils.formatCurrency(num);
            }
        },
        mounted() {
            if(this.initialValue) {
                this.value = this.initialValue;
                this.recalculateItemInterval();
            }
        },

    }
</script>