<template>
  <div class="mt-3">
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      :color="snackBarType == 'ERROR' ? 'red' : 'blue'"
      dark
      :absolute="true"
      :top="true"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-row>
      <v-col cols="12" lg="3" md="4" sm="6" xs="12">
         <v-text-field
          v-model="caseSearch"
          label="Fall suchen"
          @change="enteredSearch()"
         >
          <v-icon
            slot="prepend"
            color="primary"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="data"
      :options.sync="options"
      :server-items-length="count"
      :loading="loading"
      @click:row="handleRowClick"
      item-key="_id"
      class="elevation-0 row-pointer"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20, 25],
        'items-per-page-text': 'Einträge pro Seite',
      }"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="item.isOnResubmission">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-2"
              @click.stop="removeFromResubmission(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-paperclip-off
            </v-icon>
          </template>
          <span>Von Wiedervorlage nehmen</span>
        </v-tooltip>
        <v-tooltip top v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-2"
              @click.stop="putOnResubmission(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-paperclip-plus
            </v-icon>
          </template>
          <span>Auf Wiedervorlage legen</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        Keine Fälle vorhanden
      </template>
    </v-data-table>
  </div>
</template>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 12pt !important;
}

.v-data-table.v-data-table.v-data-table >>> th {
  font-size: 14pt !important;
}
</style>
<script>
import axios from "axios";
import appConfig from "../../../config";
import utils from "../../../utils";

export default {
  name: "SozialstaffelFavCasesTable",
  data() {
    return {
      count: 0,
      data: [],
      loading: true,
      options: {},
      caseSearch: "",
      headers: [
        {
          text: "Aktenzeichen",
          align: "start",
          sortable: false,
          value: "reference",
        },
        {
          text: "Antragsteller",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Anzahl Kinder", value: "numberOfChildren" },
        { text: "Erstellt am", value: "formattedCreatedAt" },
        { text: "", value: "actions", sortable: false },
      ],
      snackbarText: "",
      snackBarType: "SUCCESS",
      snackbar: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    showSnackbar(type, text) {
      if (type == "ERROR") {
        this.snackBarType = "ERROR";
        this.snackbarText = text;
      } else {
        this.snackBarType = "SUCCESS";
        this.snackbarText = text;
      }

      this.snackbar = true;
    },
    async putOnResubmission(item) {
      const url = `${appConfig.apiURL}/sozialstaffel/cases/resubmission/${item._id}`;

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const body = {
        user: localStorage.currentUser._id,
      };

      try {
        const resp = await axios.post(url, body, requestConfig);
        this.data[item.index].isOnResubmission = true;
      } catch (e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },
    async removeFromResubmission(item) {
      const url = `${appConfig.apiURL}/sozialstaffel/cases/resubmission/${item._id}`;

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      try {
        const resp = await axios.delete(url, requestConfig);
        this.data[item.index].isOnResubmission = false;
      } catch (e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },
    handleRowClick(row) {
      this.$router.push(`/sozialstaffel/cases/detail?id=${row._id}`);
    },
    getDataFromApi() {
      this.loading = true;
      this.fetchData().then((data) => {
        this.data = data.data;
        this.count = data.count;
        this.loading = false;
      });
    },
    /**
     * In a real application this would be a call to fetch() or axios.get()
     */
    fetchData() {
      return new Promise(async (resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        const skip = (page - 1) * itemsPerPage;
        const top = itemsPerPage;
        const search = this.caseSearch != ""? this.caseSearch: undefined;

        const resp = await this.getCases(skip, top, search);

        let index = 0;

        const data = resp.data.map((d) => {
          let res = d;
          

          res.key = d._id;
          res.index = index;
          res.name = `${d.primaryApplicant.formOfAddress} ${d.primaryApplicant.firstName} ${d.primaryApplicant.lastName}`;
          res.numberOfChildren = d.children.length;
          res.formattedCreatedAt = utils.formatDate(d.createdAt, true);
          res.isOnResubmission = d.resubmissionUsers.includes(
            JSON.parse(localStorage.currentUser)._id
          );

          index += 1;

          return res;
        });

        const count = resp.count;

        resolve({ data, count });
      });
    },
    async getCases(skip, top, search) {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      let dataUrl = `${appConfig.apiURL}/sozialstaffel/cases?$skip=${skip}&$top=${top}&$filter=owner eq cast('${JSON.parse(localStorage.currentUser)._id}',ObjectId)`;

      if(search) {
        dataUrl += `&$search=${search}`;
      }

      try {
        const dataResp = await axios.get(dataUrl, requestConfig);

        return {
          data: dataResp.data.data,
          count: dataResp.data.count,
        };
      } catch (e) {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log(e);
        }

        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },
    enteredSearch() {
      this.getDataFromApi();
    }
  },
};
</script>