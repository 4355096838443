<template>
  <div style="background-color: white; height: 100vh">
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      :color="snackBarType == 'ERROR' ? 'red' : 'blue'"
      dark
      :absolute="true"
      :top="true"
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- Begin incomplete Application Dialog -->
    <v-dialog v-model="incompleteApplicationDialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <v-icon color="orange">mdi-alert</v-icon>&nbsp;&nbsp;&nbsp;Unvollständiger Antrag
        </v-card-title>
        <v-card-text>
          <v-alert
            border="left"
            colored-border
            type="warning"
            >
              Der Fall enthält einen Antrag der noch nicht als vollständig markiert wurde. 
              Bitte überprüfen Sie den Antrag und markieren Sie ihn als vollständig oder erstellen Sie eine Nachforderung.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="incompleteApplicationDialog = false"> Schließen </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="showIcompleteApplication()" color="blue darken-1"> Anzeigen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End incomplete Application Dialog -->

    <!-- Begin Update Case Dialog -->
    <v-dialog v-model="casesDialog" persistent max-width="800px">
      <CasesForm
        v-on:close="closeCasesForm()"
        v-on:closesuccess="closeCasesFormSuccess()"
        mode="UPDATE"
        :caseData="caseData"
        :key="reloadCaseForm"
      />
    </v-dialog>
    <!-- End Update Case Dialog -->

    <!-- Begin Letter Create Dialog -->
    <v-dialog v-model="letterDialog" persistent max-width="800px">
      <LetterForm
        v-on:close="closeLetterForm()"
        v-on:closesuccess="closeLetterFormSuccess"
        mode="CREATE"
        :caseData="caseData"
        :key="reloadLetterForm"
      />
    </v-dialog>
    <!-- End Letter Create Dialog -->

    <!-- Begin Letter Update Dialog -->
    <v-dialog v-model="letterDraftUpdateDialog" persistent max-width="800px">
      <LetterForm
        v-on:close="closeUpdateLetterForm()"
        v-on:closesuccess="closeUpdateLetterFormSuccess()"
        mode="UPDATE"
        :caseData="caseData"
        :letterData="letterToUpdate"
        :key="reloadLetterUpdateForm"
      />
    </v-dialog>
    <!-- End Letter Update Dialog -->

    <!-- Begin Application Dialog -->
    <v-dialog v-model="applicationCreateForm" persistent max-width="800px">
      <ApplicationFormCreate 
        v-on:close="closeApplicationForm()"
        v-on:closesuccess="closeApplicationFormSuccess"
        v-on:closeerror="closeApplicationFormError()"
        :caseData="caseData"
      />
    </v-dialog>
    <!-- End Application Dialog -->

    <!-- Begin Application Update Dialog -->
    <v-dialog v-model="applicationUpdateForm" persistent max-width="800px">
      <ApplicationFormUpdate 
        v-on:close="closeApplicationUpdateForm()"
        v-on:closesuccess="closeApplicationFormUpdateSuccess"
        v-on:closeerror="closeApplicationUpdateFormError()"
        :key="reloadApplicationUpdateForm"
        :caseData="caseData"
        :applicationId="applicationToUpdate"
      />
    </v-dialog>
    <!-- End Application Update Dialog -->


    <!-- Begin Application Display Dialog -->
    <v-dialog v-model="applicationDisplayDialog" persistent max-width="800px">
      <ApplicationDisplay
        v-on:close="closeApplicationDisplayDialog"
        v-on:closeopendocrequest="closeApplicationOpenDocRequestCreate"
        v-on:closeopenapplicationcomplete="closeApplicationOpenApplicationComplete"
        v-on:closeopenupdateapplication="closeApplicationOpenUpdateApplication"
        :applicationId="applicationToDisplay"
        :caseData="caseData"
        :key="reloadApplicationDisplay"
      />
    </v-dialog>
    <!-- End Application Display Dialog -->

    <!-- Begin Application Display Dialog -->
    <v-dialog v-model="docRequestCreateDialog" persistent max-width="800px">
      <DocRequestCreateForm
        v-on:close="closeDocRequestCreateDialog"
        v-on:closesuccess="closeDocRequestCreateDialogSuccess"
        v-on:closeerror="closeDocRequestCreateDialogError"
        :caseData="caseData"
        :applicationId="docRequestApplicationPrefill"
        :key="reloadDocRequestCreate"
      />
    </v-dialog>
    <!-- End Application Display Dialog -->

    <!-- Begin Application as complete Dialog -->
    <v-dialog v-model="applicationAsCompleteDialog" persistent max-width="800px">
      <ApplicationAsCompleteForm
        v-on:close="closeApplicationAsCompleteDialog"
        v-on:closesuccess="closeApplicationAsCompleteDialogSuccess"
        v-on:closeerror="closeApplicationAsCompleteDialogError"
        :applicationId="applicationToComplete"
        :key="reloadApplicationAsComplete"
      />
    </v-dialog>
    <!-- End Application as complete Dialog -->

    <!-- Begin docRequest update form -->
    <v-dialog v-model="documentRequestDraftUpdateDialog" persistent max-width="800px">
      <DocumentRequestUpateForm
        v-on:close="closeUpdateDocumentRequestForm()"
        v-on:closesuccess="closeUpdateDocumentRequestFormSuccess()"
        v-on:closeerror="closeUpdateDocumentRequestFormError()"
        :document-request-id="docReqestToUpdate"
        :key="reloadDocumentRequestUpdateForm" />
    </v-dialog>
    <!-- End docRequest update form -->

    <!-- Begin Document Loading Dialog -->
    <v-dialog v-model="loadDocDialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          Lade Dokument ... <br /><span style="font-size: 10pt"
            >(Dies kann einige Sekunden dauern)</span
          ></v-card-title
        >
        <v-card-text>
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Document Loading Dialog -->


    <!-- Begin Comment Dialog --> 
    <v-dialog v-model="commentEditorDialog" persistent max-width="800px">
      <v-card>
        <v-card-title>Anmerkung Bearbeiten</v-card-title>
        <v-card-text class="pb-9 mb-9">
          <quill-editor
            :content="commentEditorContent"
            :options="editorOptions"
            @change="onCommentEditorChange($event)"
            style="height: 200px"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="safeCommentEditorDialog">Speichern</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="closeCommentEditorDialog">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Comment Dialog -->

    <!-- Begin Archive Dialog -->
    <v-dialog v-model="archiveDialog" persistent max-width="800px">
      <v-card>
        <v-card-title>Änderungen zur Vorgängerversion</v-card-title>
        <v-card-text v-if="archiveLoading">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
        <v-card-text v-else>
          <h3 v-if="archiveChangesToDisplay.length == 0">
            Es gab keine relevanten Veränderungen zur Vorgängerversion
          </h3>
          <template 
            v-else
            v-for="(caseArchive, i) in archiveChangesToDisplay"
          >
            <p :key="'caseArchive' + i" style="font-size: 18px">
              {{ caseArchive.displayName }}: {{ caseArchive.oldVal }} <v-icon>mdi mdi-arrow-right-thin</v-icon> <b>{{ caseArchive.newVal }}</b>
            </p>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="closeArchiveDialog">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Document Loading Dialog -->

    <!-- Begin Supervision Request Dialog -->
    <v-dialog v-model="supervisionRequestDialog" persistent max-width="600px">
        <ConfirmDialog
          title="Fall an die Fachaufsicht freigeben?"
          text="Soll der Fall an die Fachaufsicht freigegeben werden? Der Fall wird für 72 Stunden der Fachaufsicht zur Prüfung freigegeben. Die Fachaufsicht kann in diesem Zeitraum auf alle mit dem Fall assoziierten Daten zugreifen."
          action-label-continue="Freigeben"
          action-label-abort="Abbrechen"
          ref="supervisionConfirmDialog"
          v-on:continue="createSuperVisionRequest()"
          v-on:close="supervisionRequestDialog = false"
        />
    </v-dialog>
    <!-- End Supervision Request Dialog -->

     <!-- Begin Open Letter create Dialog -->
     <v-dialog v-model="continueToLetterDialog" persistent max-width="600px">
        <ConfirmDialog
          title="Direkt Bescheid erstellen?"
          text="Da Sie sich in einem Fall mit Sozialermäßigung befinden und einen Antrag abgeschlossen haben kann nun ein Bescheid erstellen werden. Hierfür wird das Formular zum erstellen eines Bescheids geöffnet."
          action-label-continue="Erstellen öffnen"
          action-label-abort="Schließen"
          v-on:continue="constinueToLetterDialogConfirm()"
          v-on:close="continueToLetterDialog = false"
        />
    </v-dialog>
    <!-- End Open Letter create Dialog -->

    <div v-if="initialLoading">
      <v-row>
        <v-col cols="12" align="center">
          <v-card class="mt-9" width="30%">
           <v-card-title style="text-align: left">
              Lade Fallansicht ...
            </v-card-title>
            <v-card-text>
              <span style="font-size: 12pt; text-align: left">Dies kann einige Sekunden dauern</span>
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else> 
      <v-row>
        <v-col cols="12">
          <v-card class="pa-7">
            <v-row>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    x-large
                    v-bind="attrs"
                    v-on="on"
                    class="hover-pointer"
                    @click="goback()"
                  >
                    mdi-arrow-left
                  </v-icon>
                </template>
                <span>Zurück zur Übersicht</span>
              </v-tooltip>

              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="icon-pointer mx-8"
                    @click="applicationCreateForm = true"
                  >
                    <v-icon dark> mdi-list-box </v-icon>
                  </v-avatar>
                </template>
                <span>Antrag anlegen</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="icon-pointer mx-8"
                    @click="openDocRequestCreate()"
                  >
                    <v-icon dark> mdi-file-document-alert </v-icon>
                  </v-avatar>
                </template>
                <span>Nachforderung</span>
              </v-tooltip>

              <!-- Begin Calculation Tooltip conditional -->
              <v-tooltip top v-if="incomeCalcRequired">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="icon-pointer mx-8"
                    @click="openIncomeCalc()"
                  >
                    <v-icon dark> mdi-calculator </v-icon>
                  </v-avatar>
                </template>
                <span>Einkommensberechnung erstellen</span>
              </v-tooltip>
              <v-tooltip top v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    color="grey"
                    v-bind="attrs"
                    v-on="on"
                    class="icon-pointer mx-8"
                  >
                    <v-icon dark> mdi-calculator </v-icon>
                  </v-avatar>
                </template>
                <span
                  >Keine Einkommensberechnung erstellbar, da Antragsteller
                  Pauschalbefreit</span
                >
              </v-tooltip>
              <!-- End Calculation Tooltip conditional -->
              
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="icon-pointer mx-8"
                    @click="openLetterForm()"
                  >
                    <v-icon dark> mdi-file-document </v-icon>
                  </v-avatar>
                </template>
                <span>Bescheid erstellen</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="icon-pointer mx-8"
                    @click="openUpdateCaseForm()"
                  >
                    <v-icon dark> mdi-pencil </v-icon>
                  </v-avatar>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>

              <!-- Begin Wiedervorlage Tooltip conditional -->
              <v-tooltip top v-if="!onResubmission">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="icon-pointer mx-8"
                    @click="updateResubmission()"
                  >
                    <v-icon dark> mdi-paperclip-plus </v-icon>
                  </v-avatar>
                </template>
                <span>Auf Wiedervorlage legen</span>
              </v-tooltip>
              <v-tooltip top v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="icon-pointer mx-8"
                    @click="updateResubmission()"
                  >
                    <v-icon dark> mdi-paperclip-minus </v-icon>
                  </v-avatar>
                </template>
                <span>Auf Wiedervorlage legen</span>
              </v-tooltip>
              <!-- End Wiedervorlage Tooltip conditional -->

              <!-- Supervision Tooltip -->
              <!--<v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="icon-pointer mx-8"
                    @click="openSupervisionRequestConfirm()"
                  >
                    <v-icon dark> mdi-eye </v-icon>
                  </v-avatar>
                </template>
                <span>An Fachaufsicht freigeben</span>
              </v-tooltip>-->
              <!-- End Supervision Tooltip -->

            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-tabs v-model="globalTab">
        <v-tab @click="globalTabClicked(0)">
          Grunddaten
        </v-tab>
        <v-tab @click="globalTabClicked(1)">
          <v-badge
              v-if="unfinishedApplications.length > 0"
              color="secondary"
              :content="unfinishedApplications.length"
              inline
            ></v-badge>
          Anträge
        </v-tab>
        <v-tab @click="globalTabClicked(2)">
          <v-badge
              v-if="draftLetters.length > 0 || draftCalcs.length > 0 || draftDocRequests.length > 0"
              color="secondary"
              :content="draftLetters.length + draftCalcs.length + draftDocRequests.length"
              inline
            ></v-badge>
            Entwürfe
        </v-tab>
        <v-tab @click="globalTabClicked(3)">Dokumente</v-tab>
        <v-tab @click="globalTabClicked(4)">Revisionen</v-tab>
      </v-tabs>
        <div v-if="globalTab == 0">
          <v-row class="px-2">
            <v-col lg="6" cols="12">
              <v-col lg="8">
                <v-alert type="info" 
                  v-if="draftLetters.length > 0 || draftCalcs.length > 0 || draftDocRequests.length > 0"
                >
                Die Mappe enthält aktuell Entwürfe
              </v-alert>
              </v-col>
              <v-row>
              <!-- Begin Case Data -->
                <v-col lg="6" cols="12">
                  <v-card class="mb-3">
                    <v-card-text style="font-size: 14pt">
                      <b>Sachbearbeiter:</b>
                      {{ `${caseData.owner?.firstName} ${caseData.owner?.lastName}` }}
                      <br /><br />
                      <b>Aktenzeichen:</b>
                      {{ `${caseData.reference}` }} <br />
                    </v-card-text>
                  </v-card>
                  <v-card
                    class="mb-3"
                    v-if="
                      caseData.primaryApplicant?.firstName &&
                      caseData.primaryApplicant?.lastName
                    "
                  >
                    <v-card-text style="font-size: 14pt">
                      <b>Erster Antragsteller:</b>
                      {{
                        `${caseData.primaryApplicant.formOfAddress} ${caseData.primaryApplicant.firstName} ${caseData.primaryApplicant.lastName}`
                      }}
                      <span
                        v-if="
                          caseData.secondaryApplicant?.firstName &&
                          caseData.secondaryApplicant?.lastName
                        "
                      ><br/>
                        <b>Zweiter Antragsteller:</b>
                        {{
                          `${caseData.secondaryApplicant.formOfAddress} ${caseData.secondaryApplicant.firstName} ${caseData.secondaryApplicant.lastName}`
                        }}
                      </span>
                      <br />
                      <br />
                      <v-divider></v-divider> <br />
                      <b>Anschrift:</b> <br />
                      {{ `${caseData.address.street} ${caseData.address.number}` }}
                      <br />
                      {{
                        `${caseData.address.postalCode} ${caseData.address.city}`
                      }}
                      <br />
                      <br />
                      <v-divider></v-divider> <br />
                      <b>Sozialleistungen:</b>
                      {{ formattedSocialbenefits }}
                    </v-card-text>
                    <v-card-actions> </v-card-actions>
                  </v-card>
                </v-col>
                <!-- End Case Data -->
                <v-col lg="6" cols="12">
                  <v-card class="mb-3" v-if="caseData.contactInformation">
                    <v-card-title style="color: grey">
                      Kontaktinformationen:
                    </v-card-title>
                    <v-card-text style="font-size: 14pt">
                      <b>Email:</b>
                      {{ caseData.contactInformation.email }}
                      <br />
                      <br />
                      <b>Telefon:</b>
                      {{ caseData.contactInformation.phone }}
                    </v-card-text>
                  </v-card>
                  <v-expansion-panels class="mb-3">
                    <v-expansion-panel>
                      <v-expansion-panel-header style="font-size: 14pt">
                        <b style="color: grey">Anzahl Kinder:</b>
                        {{ caseData.children.length }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template
                          v-for="(child, i) in caseData.children"
                        >
                          <p :key="'child' + i">
                            <b>Name: </b> {{ child.firstName }} {{ child.lastName
                            }}<br /><b>Geburtsdatum: </b>
                            {{ getFormattedDate(child.dateOfBirth, false) }}
                          </p>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-expansion-panels class="mb-3">
                    <v-expansion-panel>
                      <v-expansion-panel-header style="font-size: 14pt">
                        <b style="color: grey">Weitere Haushaltsmitglieder:</b>
                        {{ caseData.householdMembers.length }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template
                          v-for="(hh, i) in caseData.householdMembers"
                        >
                          <p :key="'hh' + i">
                            <b>Name: </b> {{ hh.firstName }} {{ hh.lastName
                            }}<br /><b>Geburtsdatum: </b>
                            {{ getFormattedDate(hh.dateOfBirth, false) }}
                          </p>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-card v-if="this.caseData.comment">
                    <v-card-title style="color: grey">
                      Anmerkung:
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon 
                            v-bind="attrs"
                            v-on="on" @click="openCommentEditorDialog()"> 
                              mdi-pencil
                            </v-icon>
                        </template>
                        <span>Bearbeiten</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text style="font-size: 14pt">
                      <span v-html="this.caseData.comment"></span>
                    </v-card-text>
                  </v-card>
                  <v-layout justify-center align-center v-else>
                    <v-btn @click="openCommentEditorDialog()" elevation="0">Anmerkung hinzufügen</v-btn>
                  </v-layout>
                </v-col>
              </v-row>
            </v-col>
            <!-- Begin Timeline -->
            <v-col lg="5" cols="12">
              <h2>Letzte Aktivitäten</h2>
              <v-timeline dense clipped>
                <v-slide-x-transition group>
                  <v-timeline-item
                    v-for="event in timeline"
                    :key="event.id"
                    class="mb-4"
                    color="pink"
                    small
                  >
                    <v-row justify="space-between">
                      <v-col cols="7">{{event.text}}</v-col>
                      <v-col
                        class="text-right"
                        cols="5"
                      >{{event.time}}</v-col>
                    </v-row>
                  </v-timeline-item>
                </v-slide-x-transition>
                <template v-for="(activity, index) in sortedActivities">
                  <v-timeline-item color="primary lighten-1" right :key="index" v-if="index < activityShowCount">
                    <v-card v-if="activity.type == 'CREATED'">
                      <v-card-title class="grey lighten-1">
                        <p class="white--text" style="font-size: 12pt">
                          Fall erstellt -
                          <span style="font-size: 10pt">{{
                            getFormattedDate(activity.createdAt, true)
                          }}</span>
                        </p>
                      </v-card-title>
                    </v-card>
                    <v-card v-else-if="activity.type == 'letter_created'">
                      <v-card-title class="grey lighten-1">
                        <p class="white--text" style="font-size: 12pt">
                          <span
                            :style="
                              activity.letter.status == 'canceled'
                                ? 'text-decoration: line-through;'
                                : ''
                            "
                            >{{ getLetterLabel(activity.letter) }}
                            {{
                              activity.letter.verificationCode
                            }}
                            erstellt</span
                          >
                          -
                          <span style="font-size: 10pt">{{
                            getFormattedDate(activity.createdAt, true)
                          }}<br />
                          von {{ `${activity.user.firstName} ${activity.user.lastName}` }}</span>
                          <span v-if="activity.letter.status == 'canceled'"
                            ><br />(storniert)</span
                          >
                        </p>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-btn
                              color="primary"
                              @click="showLetterActivity(activity.letter)"
                              elevation="0"
                              outlined
                              >Dokument anzeigen</v-btn
                            >
                          </v-col>
                          <v-col>
                            <p>
                              <b>Zeitraum:</b>
                              {{
                                getFormattedDate(
                                  activity.letter.letterStart,
                                  false
                                )
                              }}
                              -
                              {{
                                getFormattedDate(
                                  activity.letter.letterEnd,
                                  false
                                )
                              }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                    <v-card v-else-if="activity.type == 'calculation_created'">
                      <v-card-title class="grey lighten-1">
                        <p class="white--text" style="font-size: 12pt">
                          <span 
                          :style="
                              activity.calculation.status == 'canceled'
                                ? 'text-decoration: line-through;'
                                : ''
                            "
                          >
                          Kalkulationsanlage erstellt -
                          <span style="font-size: 10pt">{{
                            getFormattedDate(activity.createdAt, true)
                          }}</span><br />
                          von {{ `${activity.user.firstName} ${activity.user.lastName}` }}</span>
                        </p>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-btn
                              color="primary"
                              @click="
                                showCalculationActivity(activity.calculation)
                              "
                              elevation="0"
                              outlined
                              >Dokument anzeigen</v-btn
                            >
                          </v-col>
                          <v-col>
                            <p>
                              <b>Einkommensüberhang:</b>
                              {{
                                getCurrencyFormatted(
                                  activity.calculation.incomeSurplus
                                )
                              }}€
                            </p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                    <v-card v-else-if="activity.type == 'calculation_canceled'">
                      <v-card-title class="grey lighten-1">
                        <p class="white--text" style="font-size: 12pt">
                          Kalkulationsanlage storniert -
                          <span style="font-size: 10pt">{{
                            getFormattedDate(activity.createdAt, true)
                          }}</span><br />
                          von {{ `${activity.user.firstName} ${activity.user.lastName}` }}</span>
                        </p>
                      </v-card-title>
                    </v-card>
                    <v-card v-else-if="activity.type == 'letter_canceled'">
                      <v-card-title class="grey lighten-1">
                        <p class="white--text" style="font-size: 12pt">
                          <span
                            >{{ getLetterLabel(activity.letter) }}
                            {{
                              activity.letter.verificationCode
                            }}
                            storniert</span
                          >
                          -
                          <span style="font-size: 10pt">{{
                            getFormattedDate(activity.createdAt, true)
                          }}<br />
                          von {{ `${activity.user.firstName} ${activity.user.lastName}` }}</span>
                        </p>
                      </v-card-title>
                    </v-card>
                    <v-card v-else-if="activity.type == 'case_edited'">
                      <v-card-title class="grey lighten-1">
                        <p class="white--text" style="font-size: 12pt">
                          <span>Fall bearbeitet</span>
                          -
                          <span style="font-size: 10pt">{{
                            getFormattedDate(activity.createdAt, true)
                          }}<br />
                          von {{ `${activity.user.firstName} ${activity.user.lastName}` }}</span>
                        </p>
                      </v-card-title>
                    </v-card>
                    <v-card v-else-if="activity.type == 'case_created'">
                      <v-card-title class="grey lighten-1">
                        <p class="white--text" style="font-size: 12pt">
                          <span>Fall erstellt</span>
                          -
                          <span style="font-size: 10pt">{{
                            getFormattedDate(activity.createdAt, true)
                          }}<br />
                          von {{ `${activity.user.firstName} ${activity.user.lastName}` }}
                          </span> 
                          
                        </p>
                      </v-card-title>
                    </v-card>
                    <v-card v-else-if="activity.type == 'application_created'">
                      <v-card-title class="grey lighten-1">
                        <p class="white--text" style="font-size: 12pt">
                          <span v-if="activity.application.isOnlineApplication">
                            <v-badge color="primary" content="Online"></v-badge>
                            <br />
                          </span>
                          <span>
                            {{ `${getFormattedApplicationType(activity.application.type)}` }} erstellt
                          </span>
                          -
                          <span style="font-size: 10pt">{{
                            getFormattedDate(activity.createdAt, true)
                          }}<br />
                          von {{ `${activity.user.firstName} ${activity.user.lastName}` }}
                          </span> 
                        </p>
                      </v-card-title>
                      <v-container >
                        <v-row>
                          <v-col>
                              <b>Eingegangen am:</b>
                              {{
                                getFormattedDate(
                                  activity.application.dateOfReceipt
                                )
                              }}
                            <v-btn v-if="activity.application.isOnlineApplication" style="float: right" color="primary" outlined @click="openOnlineApplication(activity.application.ozgApplication)">
                              Antrag anzeigen
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                    <v-card v-else-if="activity.type == 'application_edited'">
                      <v-card-title class="grey lighten-1">
                        <p class="white--text" style="font-size: 12pt">
                          <span>Antrag aktualisiert</span>
                          -
                          <span style="font-size: 10pt">{{
                            getFormattedDate(activity.createdAt, true)
                          }}<br />
                          von {{ `${activity.user.firstName} ${activity.user.lastName}` }}
                          </span> 
                          
                        </p>
                      </v-card-title>
                    </v-card>
                    <v-card v-else-if="activity.type == 'document_request_created'">
                      <v-card-title class="grey lighten-1">
                        <p class="white--text" style="font-size: 12pt">
                          <span>Dokumentennachforderung erstellt</span>
                          -
                          <span style="font-size: 10pt">{{
                            getFormattedDate(activity.createdAt, true)
                          }}<br />
                          von {{ `${activity.user.firstName} ${activity.user.lastName}` }}
                          </span> 
                          
                        </p>
                      </v-card-title>
                    </v-card>
                  </v-timeline-item>
                </template>
              </v-timeline>
              <div class="text-center pb-6" v-if="activityShowCount < sortedActivities.length">
                <v-btn
                  color="primary"
                  @click="activityShowCount += ((sortedActivities.length - activityShowCount) > 5? 5: (sortedActivities.length - activityShowCount))"
                  elevation="0"
                  outlined
                >Mehr laden {{ `(${activityShowCount > sortedActivities.length? sortedActivities.length: activityShowCount }/${sortedActivities.length})` }}</v-btn>
              </div>
            </v-col>
            <!-- End Timeline -->
          </v-row>
        </div>
        <div class="px-8" v-if="globalTab == 1">
          <v-card-title style="font-size: 16pt">Antragsverlauf</v-card-title>
          <v-card-text v-if="applicationsCount == 0" style="font-size: 14pt">
            Keine Anträge vorhanden
          </v-card-text>
          <v-card-text v-else>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="font-size: 14pt">
                      Art des Antrags
                    </th>
                    <th style="font-size: 14pt">Antrag vom</th>
                    <th style="font-size: 14pt">Eingegangen am</th>
                    <th style="font-size: 14pt">Vollständig</th>
                  </tr>
                  </thead>
                    <tbody>
                      <template v-for="(application, index) in applications">
                        <tr
                          :key="'application' + index"
                          @click="applicationRowClicked(index)"
                          class="hover-pointer"
                        >
                          <td style="font-size: 14pt">
                            {{ getFormattedApplicationType(application.type) }}
                            <v-chip
                              class="ma-2"
                              color="primary"
                              v-if="application.isOnlineApplication"
                              >Online</v-chip>
                          </td>
                          <td style="font-size: 14pt">
                            {{ getFormattedDate(application.dateOfApplication, false) }}
                          </td>
                          <td style="font-size: 14pt">
                            {{ getFormattedDate(application.dateOfReceipt, false) }}
                          </td>
                          <td style="font-size: 14pt">
                            <v-chip
                              class="ma-2"
                              color="primary"
                              v-if="application.dateOfCompletion"
                            >
                              JA
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              color="secondary"
                              v-else>
                              Nein
                            </v-chip>
                          </td>
                       </tr>
                    </template>
                  </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </div>
        <div v-if="globalTab == 2">
          <v-row>
            <v-col xl="2" lg="3" cols="12">
              <v-tabs v-model="draftTab" vertical>
                <v-tab>
                  <span class="text-left" style="width: 100%">
                    <v-badge
                      :color="draftLetters.length > 0? 'secondary': 'primary'"
                      :content="`${draftLetters.length}`"
                      inline
                    ></v-badge>
                    Bescheide
                  </span>
                </v-tab>
                <v-tab class="text-left">
                  <span class="text-left" style="width: 100%">
                    <v-badge
                      :color="draftCalcs.length > 0? 'secondary': 'primary'"
                      :content="`${draftCalcs.length}`"
                      inline
                    ></v-badge>
                    Berechnungen
                  </span>
                </v-tab>
                <v-tab class="text-left">
                  <span class="text-left" style="width: 100%">
                    <v-badge
                      :color="draftDocRequests.length > 0? 'secondary': 'primary'"
                      :content="`${draftDocRequests.length}`"
                      inline
                    ></v-badge>
                    Nachforderungen
                  </span>
                </v-tab>
              </v-tabs>
            </v-col>
            <v-col lg="8" xl="9" cols="12">
              <v-tabs-items v-model="draftTab">
                <v-tab-item>
                  <v-card-title style="font-size: 16pt">Bescheide</v-card-title>
                  <v-card-text style="font-size: 14pt" v-if="draftLetters.length == 0">Keine Bescheide vorhanden</v-card-text>
                  <v-card-text style="font-size: 14pt" v-else>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" style="font-size: 14pt">Bescheid</th>
                            <th class="text-left" style="font-size: 14pt">Zeitraum</th>
                            <th class="text-left" style="font-size: 14pt"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(letter, index) in draftLetters">
                            <tr
                              :key="'letter' + index"
                              @click="draftLetterRowClicked(index)"
                              class="hover-pointer"
                            >
                              <td style="font-size: 14pt">
                                {{
                                  `${getLetterLabel(letter)} ` +
                                  letter.verificationCode +
                                  " (" +
                                  getFormattedDate(letter.dateOfIssue, false) +
                                  ")"
                                }}
                              </td>
                              <td style="font-size: 14pt">
                                {{ `${getFormattedDate(letter.letterStart)} - ${getFormattedDate(letter.letterEnd)}` }}
                              </td>
                              <td style="text-align: right">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      class="hover-pointer px-1"
                                      v-bind="attrs"
                                      v-on="on"
                                      size="30"
                                      @click.stop="openLetterDraftUpdate(index)"
                                    >
                                      mdi-pencil
                                    </v-icon>
                                  </template>
                                  <span>Bescheid bearbeiten</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item>
                  <v-card-title style="font-size: 16pt">Berechnungen</v-card-title>
                  <v-card-text style="font-size: 14pt" v-if="draftCalcs.length == 0">Keine Berechnungen vorhanden</v-card-text>
                  <v-card-text style="font-size: 14pt" v-else>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" style="font-size: 14pt">
                              Einkommensberechnung
                            </th>
                            <th style="font-size: 14pt">Zeitraum</th>
                            <th style="font-size: 14pt">Überhang</th>
                            <th class="text-left" style="font-size: 14pt"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(calc, index) in draftCalcs">
                            <tr
                              :key="'calc' + index"
                              @click="draftCalcRowClicked(index)"
                              class="hover-pointer"
                            >
                              <td style="font-size: 14pt">
                                Berechnung vom {{ getFormattedDate(calc.createdAt) }}
                              </td>
                              <td style="font-size: 14pt">
                                {{ `${getFormattedDate(calc.calculationStart)} - ${getFormattedDate(calc.calculationEnd)}` }}
                              </td>
                              <td style="font-size: 14pt">
                                {{ getCurrencyFormatted(calc.incomeSurplus) }}€
                              </td>
                              <td style="text-align: right">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      class="hover-pointer px-1"
                                      v-bind="attrs"
                                      v-on="on"
                                      size="30"
                                      @click.stop="openDraftCalcUpdate(index)"
                                    >
                                      mdi-pencil
                                    </v-icon>
                                  </template>
                                  <span>Berechnung bearbeiten</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item>
                  <v-card-title style="font-size: 16pt">Nachforderungen</v-card-title>
                  <v-card-text style="font-size: 14pt" v-if="draftDocRequests.length == 0">Keine Nachforderungen vorhanden</v-card-text>
                  <v-card-text style="font-size: 14pt" v-else>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" style="font-size: 14pt">
                              Antrag
                            </th>
                            <th style="font-size: 14pt">Erstellt am</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(docRequest, index) in draftDocRequests">
                            <tr
                              :key="'docRequest' + index"
                              @click="draftDocRequestRowClicked(index)"
                              class="hover-pointer"
                            >
                              <td style="font-size: 14pt">
                                Zum {{ getFormattedApplicationType(docRequest.application.type) }} vom {{ getFormattedDate(docRequest.application.dateOfApplication) }}
                              </td>
                              <td style="font-size: 14pt">
                                {{ `${getFormattedDate(docRequest.createdAt)}` }}
                              </td>
                              <td style="text-align: right">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      class="hover-pointer px-1"
                                      v-bind="attrs"
                                      v-on="on"
                                      size="30"
                                      @click.stop="openDarftDocRequestUpdate(index)"
                                    >
                                      mdi-pencil
                                    </v-icon>
                                  </template>
                                  <span>Nachforderung bearbeiten</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </div>
        <div v-if="globalTab == 3">
          <!-- Begin Draft Col -->
          <v-row>
          <v-col xl="2" lg="3" cols="12">
            <v-tabs v-model="letterTab" vertical>
              <v-tab>
                <span class="text-left" style="width: 100%">
                  <v-badge
                    color="primary"
                    :content="`${finishedLetters.length}`"
                    inline
                  ></v-badge>
                  Bescheide
                </span>
              </v-tab>
              <v-tab class="text-left">
                <span class="text-left" style="width: 100%">
                  <v-badge
                    color="primary"
                    :content="`${finishedCalcs.length}`"
                    inline
                  ></v-badge>
                  Berechnungen
                </span>
              </v-tab>
              <v-tab class="text-left">
                <span class="text-left" style="width: 100%">
                  <v-badge
                    color="primary"
                    :content="`${finishedDocRequests.length}`"
                    inline
                  ></v-badge>
                  Nachforderungen
                </span>
              </v-tab>
            </v-tabs>
          </v-col>
              <v-col lg="8" xl="9" cols="12">
                <v-tabs-items v-model="letterTab">
                  <v-tab-item>
                    <v-card-title style="font-size: 16pt">Bescheide</v-card-title>
                    <v-card-text v-if="finishedLetters.length == 0 && canceledLetters.length == 0" style="font-size: 14pt">
                      Keine Bescheide vorhanden
                    </v-card-text>
                    <v-card-text v-else>
                      <v-simple-table v-if="finishedLetters.length > 0">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left" style="font-size: 14pt">
                                Bescheid
                              </th>
                              <th style="font-size: 14pt">Zeitraum</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(letter, index) in finishedLetters">
                              <tr
                                :key="'finishedLetter' + index"
                                @click="finishedLetterRowClicked(index)"
                                class="hover-pointer"
                              >
                                <td style="font-size: 14pt">
                                  {{
                                    `${getLetterLabel(letter)} ` +
                                    letter.verificationCode +
                                    " vom " +
                                    getFormattedDate(letter.dateOfIssue, false) +
                                    ""
                                  }}
                                </td>
                                <td style="font-size: 14pt">
                                  {{ `${getFormattedDate(letter.letterStart)} - ${getFormattedDate(letter.letterEnd)}` }}
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-expansion-panels
                        v-if="canceledLetters.length > 0"
                        class="mt-9"
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header style="font-size: 12pt">
                            Stornierte Bescheide ({{ canceledLetters.length }})
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-simple-table>
                              <template v-slot:default>
                                <tbody>
                                  <template
                                    v-for="(letter, index) in canceledLetters"
                                  >
                                    <tr
                                      :key="'canceledLetter' + index"
                                      @click="canceledLetterRowClicked(index)"
                                      class="hover-pointer"
                                    >
                                      <td style="font-size: 14pt">
                                        {{
                                          `Storniert - ${getLetterLabel(letter)} ` +
                                          letter.verificationCode +
                                          " vom " +
                                          getFormattedDate(
                                            letter.dateOfIssue,
                                            false
                                          ) +
                                          ""
                                        }}
                                      </td>
                                      <td style="font-size: 14pt">
                                        {{ `${getFormattedDate(letter.letterStart)} - ${getFormattedDate(letter.letterEnd)}` }}
                                      </td>
                                      <td style="font-size: 14pt">
                                        {{ getFormattedLetterResultType(letter) }}
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card-text>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card-title style="font-size: 16pt"
                      >Berechnungen</v-card-title
                    >
                    <v-card-text v-if="finishedCalcs.length == 0" style="font-size: 14pt">
                      Keine Berechnungen vorhanden
                    </v-card-text>
                    <v-card-text v-else>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left" style="font-size: 14pt">
                                Berechnung
                              </th>
                              <th style="font-size: 14pt">Zeitraum</th>
                              <th style="font-size: 14pt">Überhang</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(calc, index) in finishedCalcs">
                              <tr
                                :key="'finishedCalc' + index"
                                @click="finishedCalcRowClicked(index)"
                                class="hover-pointer"
                              >
                                <td style="font-size: 14pt">
                                Berechnung vom {{ getFormattedDate(calc.createdAt) }}
                                </td>
                                <td style="font-size: 14pt">
                                  {{ `${getFormattedDate(calc.calculationStart)} - ${getFormattedDate(calc.calculationEnd)}` }}
                                </td>
                                <td style="font-size: 14pt">
                                  {{ getCurrencyFormatted(calc.incomeSurplus) }}€
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-expansion-panels
                        v-if="canceledCalcs.length > 0"
                        class="mt-9"
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header style="font-size: 12pt">
                            Stornierte Berechnungen ({{ canceledCalcs.length }})
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-simple-table>
                              <template v-slot:default>
                                <tbody>
                                  <template
                                    v-for="(calc, index) in canceledCalcs"
                                  >
                                    <tr
                                      :key="'canceledCalc' + index"
                                      @click="canceledCalcRowClicked(index)"
                                      class="hover-pointer"
                                    >
                                      <td style="font-size: 14pt">
                                        Berechnung vom {{ getFormattedDate(calc.createdAt) }}
                                      </td>
                                      <td style="font-size: 14pt">
                                        {{ `${getFormattedDate(calc.calculationStart)} - ${getFormattedDate(calc.calculationEnd)}` }}
                                      </td>
                                      <td style="font-size: 14pt">
                                        {{ getCurrencyFormatted(calc.incomeSurplus) }}€
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card-text>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card-title style="font-size: 16pt">Nachforderungen</v-card-title>
                    <v-card-text v-if="finishedDocRequests.length == 0 && canceledLetters.length == 0" style="font-size: 14pt">
                      Keine Nachforderungen vorhanden
                    </v-card-text>
                    <v-card-text v-else>
                      <v-simple-table v-if="finishedDocRequests.length > 0">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left" style="font-size: 14pt">
                                Antrag
                              </th>
                              <th style="font-size: 14pt">Datum</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(docRequest, index) in finishedDocRequests">
                              <tr
                                :key="'finishedDocRequest' + index"
                                @click="finishedDocRequestRow(index)"
                                class="hover-pointer"
                              >
                                <td style="font-size: 14pt">
                                  {{
                                    `${getFormattedApplicationType(docRequest.application.type)} vom ${getFormattedDate(docRequest.application.dateOfApplication)}`
                                  }}
                                </td>
                                <td style="font-size: 14pt">
                                  {{ `${getFormattedDate(docRequest.dateOfIssue)}` }}
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-expansion-panels
                        v-if="canceledDocRequests.length > 0"
                        class="mt-9"
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header style="font-size: 12pt">
                            Stornierte Nachforderungen ({{ canceledDocRequests.length }})
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-simple-table>
                              <template v-slot:default>
                                <tbody>
                                  <template
                                    v-for="(docRequest, index) in canceledDocRequests"
                                  >
                                    <tr
                                      :key="'canceledDocRequests' + index"
                                      @click="canceledDocRequestRowClicked(index)"
                                      class="hover-pointer"
                                    >
                                      <td style="font-size: 14pt">
                                        {{ `Storniert - Nachforderung vom ${getFormattedDate(docRequest.dateOfIssue)}` }}
                                      </td>
                                      <td style="font-size: 14pt">
                                        {{
                                          `${getFormattedApplicationType(docRequest.application.type)} vom ${getFormattedDate(docRequest.application.dateOfApplication)}`
                                        }}
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card-text>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          <!-- End Draft Col -->
        </div>
        <div class="px-8" v-if="globalTab == 4">
          <v-card-title style="font-size: 16pt">Änderungsverlauf</v-card-title>
          <v-card-text v-if="caseArchive.length == 0" style="font-size: 14pt">
            Keine Berechnungen vorhanden
          </v-card-text>
          <v-card-text v-else>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="font-size: 14pt">
                      Änderung vom
                    </th>
                    <th style="font-size: 14pt">Versionsnummer</th>
                    <th style="font-size: 14pt">Bearbeiter</th>
                  </tr>
                  </thead>
                    <tbody>
                      <template v-for="(archive, index) in caseArchive">
                        <tr v-if="archive.version == 0" :key="'archive' + index">
                          <td style="font-size: 14pt">
                            {{ getFormattedDate(archive.createdAt, true) }}
                          </td>
                          <td style="font-size: 14pt">
                            Initiale Erstellung
                          </td>
                        </tr>
                        <tr
                          :key="'archive' + index"
                          @click="archiveRowClicked(index)"
                          class="hover-pointer"
                          v-else
                        >
                          <td style="font-size: 14pt">
                            {{ getFormattedDate(archive.createdAt, true) }}
                          </td>
                          <td style="font-size: 14pt">
                            {{ archive.version }}
                          </td>
                          <td style="font-size: 14pt">{{ `${archive.activity?.user?.firstName} ${archive.activity?.user?.lastName}` }}</td>
                       </tr>
                    </template>
                  </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.icon-pointer >>> :hover {
  cursor: pointer;
}
.hover-pointer {
  cursor: pointer;
}

</style>
<script>
import appConfig from "../../config";
import utils from "../../utils";
import axios from "axios";

import CasesForm from "../../components/sozialstaffel/CaseForm.vue";
import LetterForm from "../../components/sozialstaffel/LetterForm.vue";
import ApplicationFormCreate from "../../components/sozialstaffel/application/ApplicationFormCreate.vue";
import ApplicationFormUpdate from "../../components/sozialstaffel/application/ApplicationFormUpdate.vue";
import ApplicationDisplay from "../../components/sozialstaffel/application/ApplicationDisplay.vue";
import DocRequestCreateForm from "../../components/sozialstaffel/docRequestForm/DocRequestFormCreate.vue";
import ApplicationAsCompleteForm from "../../components/sozialstaffel/application/ApplicationAsCompleteForm.vue";
import DocumentRequestUpateForm from "../../components/sozialstaffel/docRequestForm/DocRequestFormUpdate.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";

//import quill editor
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor'

export default {
  name: "SozialstaffelCaseDetail",
  components: {
    CasesForm,
    LetterForm,
    ApplicationFormCreate,
    quillEditor,
    ApplicationDisplay,
    DocRequestCreateForm,
    ApplicationAsCompleteForm,
    ApplicationFormUpdate,
    DocumentRequestUpateForm,
    ConfirmDialog
  },
  data() {
    return {
      /* snackbar */
      snackbarText: "",
      snackBarType: "SUCCESS",
      snackbar: false,

      /* render data */
      activities: [],
      activityShowCount: 5,
      letters: [],
      calcs: [],
      docRequests: [],

      caseArchive: [],
      caseArchiveCount: 0,

      applications: [],
      applicationsCount: 0,

      userData: {},
      caseData: {
        primaryApplicant: {},
        secondaryApplicant: {},
        address: {},
        children: [],
        householdMembers: [],
      },
      onResubmission: false,
      initialLoading: true,
      statics: {},

      /* cases dialog */
      casesDialog: false,
      reloadCaseForm: 0,

      /* letter create dialog */
      letterDialog: false,
      reloadLetterForm: 0,

      /* load doc dialog */
      loadDocDialog: false,

      /* letter draft update dialog */
      letterDraftUpdateDialog: false,
      letterToUpdateIndex: 0,
      reloadLetterUpdateForm: 0,
      letterToUpdate: {},

      /* archive dialog */
      archiveDialog: false,
      archiveLoading: false,
      archiveChangesToDisplay: [],

      /* for Editor */
      editorOptions: {
        modules: {
          toolbar: ['bold', 'italic', 'underline', 'strike']
        },
        placeholder: 'Kurzer Anmerkungstext'
      },
      commentEditorContent: "",
      commentEditorDialog: false,

      /* applicationCreateForm */
      applicationCreateForm: false,

      /* applicationUpdateForm */
      applicationUpdateForm: false,
      reloadApplicationUpdateForm: 0,
      applicationToUpdate: "",
      
      /* application dialog */
      applicationDisplayDialog: false,
      applicationToDisplay: "",
      reloadApplicationDisplay: 0,

      /* docRequest create dialog */
      docRequestCreateDialog: false,
      reloadDocRequestCreate: 0,
      docRequestApplicationPrefill: "",

      /* docRequest update */
      documentRequestDraftUpdateDialog: false,
      documentRequestToUpdate: "",
      reloadDocumentRequestUpdateForm: 0,
      docReqestToUpdate: "",

      /* application as complete dialog */
      applicationAsCompleteDialog: false,
      reloadApplicationAsComplete: 0,
      applicationToComplete: "",

      /* incomplete application dialog */
      incompleteApplicationDialog: false,

      /* supervision request dialog */
      supervisionRequestDialog: false,

      /* continue to letter dialog */
      continueToLetterDialog: false,

      /* utils */
      letterTab: 0,
      draftTab: 0,
      globalTab: 0
    };
  },
  async created() {
    this.statics = await this.fetchStatics();

    await this.fetchAllDetailData();

    this.initialLoading = false;

    this.globalTab = parseInt(this.$router.currentRoute.query.activeTab);

    if(this.unfinishedApplications.length > 0) {
      this.incompleteApplicationDialog = true;
    }
    
  },
  computed: {
    formattedSocialbenefits: {
      get: function () {
        return this.getSocialBenefitsLabel(
          this.caseData.receivingSocialBenefits
        );
      },
    },
    incomeCalcRequired: {
      get: function () {
        return this.caseData.receivingSocialBenefits == "0";
      },
    },
    sortedActivities: {
      get: function () {
        return this.activities.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      },
    },
    draftLetters: {
      get: function () {
        return this.letters.filter((letter) => letter.status == "draft");
      },
    },
    canceledLetters: {
      get: function () {
        return this.letters.filter((letter) => letter.status == "canceled");
      },
    },
    finishedLetters: {
      get: function () {
        return this.letters.filter((letter) => letter.status == "finished");
      },
    },
    draftCalcs: {
      get: function () {
        return this.calcs.filter((calc) => calc.status == "draft");
      },
    },
    canceledCalcs: {
      get: function () {
        return this.calcs.filter((calc) => calc.status == "canceled");
      },
    },
    finishedCalcs: {
      get: function () {
        return this.calcs.filter((calc) => calc.status == "finished");
      },
    },
    draftDocRequests: {
      get: function () {
        return this.docRequests.filter((docReq) => docReq.status == "draft");
      },
    },
    finishedDocRequests: {
      get: function () {
        return this.docRequests.filter((docReq) => docReq.status == "finished");
      },
    },
    canceledDocRequests: {
      get: function () {
        return this.docRequests.filter((docReq) => docReq.status == "canceled");
      },
    },
    unfinishedApplications: {
      get: function () {
        return this.applications.filter(app => !app.dateOfCompletion)
      }
    }
  },
  methods: {
    globalTabClicked(tab) {
      this.$router.replace({ query: { ...this.$route.query, activeTab: tab } })
    },
    getFormattedLetterResultType(letter) {
        console.log(letter)
    },

    getFormattedApplicationType(applicationType) {
      return Object.keys(this.statics.applicationTypeMapping).filter(
        (key) => this.statics.applicationTypeMapping[key] == applicationType
      )[0];
    },

    getLetterLabel(letter) {
      const type = Object.keys(this.statics.letterTypeMapping).filter(
        (key) =>  this.statics.letterTypeMapping[key] == letter.letterType
      )[0];

      const resultType = Object.keys(this.statics.letterResultTypeMapping).filter(
        (key) =>  this.statics.letterResultTypeMapping[key] == letter.resultType
      )[0];

      return `${type} - (${resultType})`;
    },

    openIncomeCalc: function () {
      this.$router.push(
        `/sozialstaffel/incomecalc2?case=${this.caseData._id}`
      );
    },

    updateResubmission: async function () {
      const url = `${appConfig.apiURL}/sozialstaffel/cases/resubmission/${this.caseData._id}`;

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      try {
        if (this.onResubmission) {
          const resp = await axios.delete(url, requestConfig);
          this.onResubmission = false;
          this.showSnackbar("SUCCESS", "Fall von Wiedervorlage genommen");
        } else {
          const body = {
            user: localStorage.currentUser._id,
          };

          const resp = await axios.post(url, body, requestConfig);
          this.onResubmission = true;
          this.showSnackbar("SUCCESS", "Fall auf Wiedervorlage gelegt");
        }
      } catch (e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },

    goback: function () {
      this.$router.push("/sozialstaffel/cases/fav");
    },

    /* Edit Cases Form */
    closeCasesForm: function () {
      this.casesDialog = false;
    },
    openUpdateCaseForm: function () {
      this.reloadCaseForm += 1;
      this.casesDialog = true;
    },
    closeCasesFormSuccess: async function () {
      this.casesDialog = false;
      await this.fetchAllDetailData();
      this.showSnackbar("SUCCESS", "Fall erfolgreich aktualisiert");
    },

    /* Create Letter */
    closeLetterForm: function () {
      this.letterDialog = false;
    },
    closeLetterFormSuccess: function (letterData) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${letterData._id}&type=letter`
      );
    },
    openLetterForm: function () {
      this.reloadLetterForm += 1;
      this.letterDialog = true;
    },

    /* Update Letter Dialog */
    openLetterDraftUpdate(index) {
      this.letterToUpdate = this.draftLetters[index];
      console.log(this.letterToUpdate);
      this.reloadLetterUpdateForm += 1;
      this.letterDraftUpdateDialog = true;
    },
    closeUpdateLetterForm() {
      this.letterDraftUpdateDialog = false;
    },
    closeUpdateLetterFormSuccess: function() {
      this.letterDraftUpdateDialog = false;
      this.showSnackbar("SUCCESS", "Bescheidentwurf erfolgreich aktualisiert");
    },

    /* Calc finish */
    async draftCalcRowClicked(index) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${this.draftCalcs[index]._id}&type=calculation`
      );
    },

    openDraftCalcUpdate(index) {
      this.$router.push(
        `/sozialstaffel/incomecalc2?case=${this.caseData._id}&calculation=${this.draftCalcs[index]._id}&mode=UPDATE`
      );
    },

    /* Utils */
    showSnackbar(type, text) {
      if (type == "ERROR") {
        this.snackBarType = "ERROR";
        this.snackbarText = text;
      } else {
        this.snackBarType = "SUCCESS";
        this.snackbarText = text;
      }

      this.snackbar = true;
    },

    /* On created methods */
    fetchActivities: async function () {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/caseactivities?$expand=letter,calculation,application&$filter=case eq cast('${this.$router.currentRoute.query.id}',ObjectId)`;

      try {
        const resp = await axios.get(url, requestConfig);

        return resp.data.data;
      } catch (e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },
    fetchCaseData: async function () {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/cases?$filter=_id eq cast('${this.$router.currentRoute.query.id}',ObjectId)`;

      try {
        const resp = await axios.get(url, requestConfig);

        return resp.data.data[0];
      } catch (e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },

    fetchLetters: async function () {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/letters?$filter=case eq cast('${this.$router.currentRoute.query.id}',ObjectId)`;

      try {
        const resp = await axios.get(url, requestConfig);

        return resp.data.data;
      } catch (e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },

    fetchCalculations: async function () {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/calculations?$filter=case eq cast('${this.$router.currentRoute.query.id}',ObjectId)`;

      try {
        const resp = await axios.get(url, requestConfig);

        return resp.data.data;
      } catch (e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },

    fetchCaseArchive: async function () {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/cases/archive?$filter=case eq cast('${this.$router.currentRoute.query.id}',ObjectId)`;

      try {
        const resp = await axios.get(url, requestConfig);

        return resp.data
      } catch (e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },

    fetchApplications: async function() {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/applications?$filter=case eq cast('${this.$router.currentRoute.query.id}',ObjectId)&$sort=dateOfApplication asc`;

      try {
        const resp = await axios.get(url, requestConfig);

        return resp.data
      } catch (e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },

    async fetchDocRequests() {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/documentrequests?$filter=case eq cast('${this.$router.currentRoute.query.id}',ObjectId)&$sort=createdAt asc&$expand=application`;

      try {
        const resp = await axios.get(url, requestConfig);

        console.log(resp.data);

        return resp.data.data;
      } catch (e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },

    fetchStatics: async function() {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const letterTypeMappingURL = `${appConfig.apiURL}/static/mappings/lettertypemapping`;
      const letterResultTypeMappingURL = `${appConfig.apiURL}/static/mappings/letterresulttypemapping`;
      const applicationTypeMappingURL = `${appConfig.apiURL}/static/mappings/applicationtypemapping`;

      const [letterTypeMappingResponse, letterResultTypeMappingResponse, applicationTypeMappingResponse] = await Promise.all([
        axios.get(letterTypeMappingURL, requestConfig),
        axios.get(letterResultTypeMappingURL, requestConfig),
        axios.get(applicationTypeMappingURL, requestConfig)
      ]);

      return {
        letterTypeMapping: letterTypeMappingResponse.data,
        letterResultTypeMapping: letterResultTypeMappingResponse.data,
        applicationTypeMapping: applicationTypeMappingResponse.data
      };
    },

    fetchAllDetailData: async function () {
      if (!this.$router.currentRoute.query?.id) {
        this.showSnackbar("ERROR", "ID Parameter ist nicht gesetzt");
        return;
      }

      this.activities = [];

      //declare temp variables
      let fetchedActivities, caseArchiveResponse, applicationsResponse;

      this.userData = JSON.parse(localStorage.currentUser);
      [
        this.caseData,
        this.letters,
        this.calcs,
        fetchedActivities,
        caseArchiveResponse,
        applicationsResponse,
        this.docRequests
      ] = await Promise.all([
        this.fetchCaseData(),
        this.fetchLetters(),
        this.fetchCalculations(),
        this.fetchActivities(),
        this.fetchCaseArchive(),
        this.fetchApplications(),
        this.fetchDocRequests()
      ]);

      this.activities.push(...fetchedActivities);

      this.caseArchive = caseArchiveResponse.data.sort((a, b) => b.version - a.version).map(archive => {
        const activityToJoin = fetchedActivities.filter(activity => activity.archivedCase == archive._id)[0];
        return {
          ...archive,
          activity: activityToJoin
        }
      });
      this.caseArchiveCount = caseArchiveResponse.count;

      this.applications = applicationsResponse.data;

      this.applicationsCount = applicationsResponse.count;

      this.onResubmission = this.caseData.resubmissionUsers.includes(
        JSON.parse(localStorage.currentUser)._id
      );
    },

    /* Utility Functions */
    getSocialBenefitsLabel(num) {
      for (let k of Object.keys(appConfig.socialBenefitsMapping)) {
        if (appConfig.socialBenefitsMapping[k] == num) {
          return k;
        }
      }
    },
    getFormattedDate(d, minutes) {
      return utils.formatDate(d, minutes);
    },

    getCurrencyFormatted(num) {
      return parseFloat(num).toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    draftLetterRowClicked(index) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${this.draftLetters[index]._id}&type=letter`
      );
    },
    canceledLetterRowClicked(index) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${this.canceledLetters[index]._id}&type=letter`
      );
    },
    finishedLetterRowClicked(index) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${this.finishedLetters[index]._id}&type=letter`
      );
    },
    finishedCalcRowClicked(index) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${this.finishedCalcs[index]._id}&type=calculation`
      );
    },
    draftDocRequestRowClicked(index) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${this.draftDocRequests[index]._id}&type=documentrequest`
      );
    },
    finishedDocRequestRow(index) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${this.finishedDocRequests[index]._id}&type=documentrequest`
      );
    },
    canceledDocRequestRowClicked(index) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${this.canceledDocRequests[index]._id}&type=documentrequest`
      );
    },

    canceledCalcRowClicked(index) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${this.canceledCalcs[index]._id}&type=calculation`
      );
    },

    openOnlineApplication(applicationId) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${applicationId}&type=application`
      );
    },

    applicationRowClicked(index) {
      this.reloadApplicationDisplay += 1;
      this.applicationToDisplay = this.applications[index]._id;
      this.applicationDisplayDialog = true;
    },

    showIcompleteApplication() {
      this.globalTabClicked(1);
      this.globalTab = 1;
      this.incompleteApplicationDialog = false;
      this.applicationRowClicked(this.applications.findIndex(app => !app.dateOfCompletion));
    },

    async closeApplicationDisplayDialog() {
      this.applicationDisplayDialog = false;
      const applications = await this.fetchApplications();

      this.applications = applications.data;
      this.applicationsCount = applications.count;
    },

    closeApplicationOpenDocRequestCreate(applicationId) {
      this.closeApplicationDisplayDialog();
      this.docRequestApplicationPrefill = applicationId;
      this.reloadDocRequestCreate += 1;
      this.docRequestCreateDialog = true;
    },

    closeApplicationOpenApplicationComplete(applicationId) {
      this.closeApplicationDisplayDialog();
      this.applicationToComplete = applicationId;
      this.reloadApplicationAsComplete += 1;
      this.applicationAsCompleteDialog = true;
    },

    async closeApplicationOpenUpdateApplication(applicationId) {
      this.closeApplicationDisplayDialog();
      this.applicationToUpdate = applicationId;
      this.reloadApplicationUpdateForm += 1;
      this.applicationUpdateForm = true;
    },

    async closeApplicationAsCompleteDialog() {
      this.applicationAsCompleteDialog = false;
      this.applicationToComplete = "";
      this.reloadApplicationAsComplete += 1;

      const applications = await this.fetchApplications();

      this.applications = applications.data;
      this.applicationsCount = applications.count;
    },

    closeApplicationAsCompleteDialogSuccess() {
      this.closeApplicationAsCompleteDialog();
      this.showSnackbar("SUCCESS", "Antrag erfolgreich als abgeschlossen markiert");

      if(this.caseData.receivingSocialBenefits != "0") {
        this.continueToLetterDialog = true;
      }
    },

    closeApplicationAsCompleteDialogError() {
      this.closeApplicationAsCompleteDialog();
      this.showSnackbar("ERROR", "Fehler beim Markieren des Antrags als abgeschlossen");
    },

    openDocRequestCreate() {
      this.reloadDocRequestCreate += 1;
      this.docRequestCreateDialog = true;
    },

    closeDocRequestCreateDialogSuccess(data) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${data._id}&type=documentrequest`
      );
    },

    closeDocRequestCreateDialogError() {
      this.closeDocRequestCreateDialog();
      this.showSnackbar("ERROR", "Fehler beim Erstellen der Nachforderung");
    },

    closeDocRequestCreateDialog() {
      this.docRequestCreateDialog = false;
      this.reloadDocRequestCreate += 1;
    },

    archiveRowClicked: async function (index) {
      this.archiveLoading = true;
      this.archiveDialog = true;

      const isField = (val) => val && val['newVal'] && val['oldVal'] && val['__type__']

      let displayChanges = []

      const buildDisplayChanges = (basePath, obj) => {
        Object.keys(obj).forEach(key => {
          const fullPath = basePath? `${basePath}.${key}`: key;

          if(isField(obj[key])) {
            if(obj[key]?.__type__ == "updated") {
              displayChanges.push({
                path: fullPath,
                ...obj[key]
              });
            }
          } else {
            if(obj[key]?.__type__) {
              buildDisplayChanges(fullPath, obj[key]);
            }
          }
        });
      }

      const createDisplayName = (path) => {
        const split_path = path.split(".");

        const excludeLabels = [ "comment", "updatedAt", "id", "_id", "createdAt", "deleted", "resubmissionUsers", "owner", "isFollowUp", "version" ]

        if(excludeLabels.includes(split_path[0]) || excludeLabels.includes(split_path[1]) || excludeLabels.includes(split_path[2])) {
          return;
        }

        const oneLabels = {
          "reference": "Aktenzeichen",
          "firstName": "Vorname",
          "lastName": "Nachname",
          "dateOfBirth": "Geburtsdatum",
          "formOfAddress": "Anrede",
          "maritalStatus": "Ehestand",
          "street": "Straße",
          "city": "Stadt",
          "number": "Hausnummer",
          "postalCode": "Postleitzahl",
          "receivingSocialBenefits": "Sozialleistungen",
          "email": "E-Mail",
          "phone": "Telefonnummer",
        }

        const twoLabels = {
          "address": "Adresse",
          "contactInformation": "Kontaktinformationen",
          "primaryApplicant": "Erster Antragsteller",
          "secondaryApplicant": "Zweiter Antragsteller"
        } 

        const threeLabels = {
          "children": "$n$. Kind in der KITA",
          "householdMembers": "$n$. weiteres Haushaltsmitglied"
        }

        if(split_path.length == 1) {
          return oneLabels[split_path[0]];
        } else if(split_path.length == 2) {
          return `${twoLabels[split_path[0]]} ${oneLabels[split_path[1]]}`;
        } else if(split_path.length == 3) {
          return `${oneLabels[split_path[2]]} ${threeLabels[split_path[0]].replace("$n$", parseInt(split_path[1]) + 1)}`;
        }
      }

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/cases/archive/${this.caseArchive[index]._id}/diff`;

      try {
        const resp = await axios.get(url, requestConfig);

        
        try {
          buildDisplayChanges("", resp.data);
        } catch(e) {
          console.log(e);
        }
        
        this.archiveChangesToDisplay = displayChanges.map(dC => {
          const displayName = createDisplayName(dC.path);

          if(!displayName) {
            return undefined
          }

          //map old and new values if required
          let additional = {};

          if(dC.path == "receivingSocialBenefits") {
            additional = {
              oldVal: this.getSocialBenefitsLabel(dC.oldVal),
              newVal: this.getSocialBenefitsLabel(dC.newVal),
            }
          }

          return {
            ...dC,
            displayName: createDisplayName(dC.path),
            ...additional
          }
        }).filter(val => val !== undefined);

        this.archiveLoading = false;
      } catch (e) {
        console.log(e)

        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },

    closeArchiveDialog() {
      this.archiveDialog = false;
    },

    /* Activity Actions */
    showLetterActivity: async function (letter) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${letter._id}&type=letter`
      );
    },

    showCalculationActivity: async function (calc) {
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${calc._id}&type=calculation`
      );
    },

    onCommentEditorChange({ quill, html, text }) {
      this.commentEditorContent = html;
    },

    closeCommentEditorDialog() {
      this.commentEditorDialog = false;
      this.commentEditorContent = "";
    },

    async safeCommentEditorDialog() {
      this.caseData.comment = this.commentEditorContent;

      this.closeCommentEditorDialog();

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/cases/${
        this.caseData._id
      }`;

      try {
        const resp = await axios.put(url, { comment: this.caseData.comment } , requestConfig);
      } catch (err) {
        console.log(err);
        this.showSnackbar("ERROR", "Fehler beim Aktualisieren des Falls");
      }
    },
    openCommentEditorDialog() {
      this.commentEditorContent = this.caseData.comment? this.caseData.comment: "";
      this.commentEditorDialog = true;
    },

    /* Application Form Actions */
    closeApplicationForm() {
      this.applicationCreateForm = false;
    },
    closeApplicationFormSuccess(data) {
      if(data.documentRequest) {
        this.$router.push(
          `/sozialstaffel/cases/documents/detail?id=${data.documentRequest._id}&type=documentrequest`
        );
      }
      
      this.closeApplicationForm();
      this.showSnackbar("SUCCESS", "Antrag erfolgreich erstellt");
      this.fetchAllDetailData();
    },
    closeApplicationFormError() {
      this.closeApplicationForm();
      this.showSnackbar("ERROR", appConfig.defaultErrorText);
    },

    /* Application Update Form Actions */
    closeApplicationUpdateForm() {
      this.applicationUpdateForm = false;
      this.reloadApplicationUpdateForm += 1;
    },

    closeApplicationFormUpdateSuccess() {
      this.closeApplicationUpdateForm();
      this.showSnackbar("SUCCESS", "Antrag erfolgreich aktualisiert");
      this.initialLoading = true;
      this.fetchAllDetailData();
      this.initialLoading = false;
    },

    closeApplicationFormUpdateError() {
      this.closeApplicationUpdateForm();
      this.showSnackbar("ERROR", appConfig.defaultErrorText);
    },

    openDarftDocRequestUpdate(index) {
      this.docReqestToUpdate = this.draftDocRequests[index]._id;
      this.reloadDocumentRequestUpdateForm += 1;
      this.documentRequestDraftUpdateDialog = true;
    },
    closeUpdateDocumentRequestForm() {
      this.documentRequestDraftUpdateDialog = false;
      this.reloadDocumentRequestUpdateForm += 1;
    },
    closeUpdateDocumentRequestFormSuccess() {
      this.closeUpdateDocumentRequestForm();
      this.$router.push(
        `/sozialstaffel/cases/documents/detail?id=${this.docReqestToUpdate}&type=documentrequest`
      );
    },
    closeUpdateDocumentRequestFormError() {
      this.closeUpdateDocumentRequestForm();
      this.showSnackbar("ERROR", appConfig.defaultErrorText);
    },

    openSupervisionRequestConfirm() {
      this.supervisionRequestDialog = true;
    },

    async createSuperVisionRequest() {
      this.$refs.supervisionConfirmDialog.setLoadingState(true);

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const url = `${appConfig.apiURL}/sozialstaffel/supervisionrequests/${
        this.caseData._id
      }`;

      try {
        const resp = await axios.post(url, {}, requestConfig);

        console.log(resp.data)

        this.showSnackbar("SUCCESS", "Fall erfolgreich freigegeben");
      } catch (e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      } finally  {
        this.$refs.supervisionConfirmDialog.setLoadingState(false);
        this.supervisionRequestDialog = false;
      }
    },

    constinueToLetterDialogConfirm() {
      this.continueToLetterDialog = false;
      this.openLetterForm();
    },
  }
};
</script>