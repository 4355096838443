<template>
  <p>
    <span v-if="item.type != 'ITEM_WILDCARD'">
      {{
        `${this.enumeration} ${this.item.name}`
      }}
      <!--{{
        ` ${JSON.stringify(this.item)}`
      }}-->
    </span>

    <span v-if="this.item.type == 'ITEM_MAX'"
      >(Maximal {{ getCurrencyFormatted(this.item.max) }}€)</span
    >
    <span v-if="this.item.type == 'ITEM_MIN'"
      >(Mindestens {{ getCurrencyFormatted(this.item.min) }}€)</span
    >

    <v-tooltip top v-if="this.item.comment">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" color="primary">
          mdi-information
        </v-icon>
      </template>
      <span v-html="this.item.comment"></span>
    </v-tooltip>
  </p>
</template>

<script>
export default {
  name: "SozialstaffelCalculationTitle",
  props: ["item", "enumeration", "section"],
  data() {
    return {
      count: 0,
    };
  },
  created() {},
  methods: {
    getCurrencyFormatted(num) {
      return (num).toFixed(2).toString().replace(".", ",");
    },
  },
};
</script>