<template>
  <div class="mx-9 mt-8">
    <v-snackbar
      v-model="errorSnackbar"
      timeout="3000"
      color="red"
      dark
      :absolute="true"
      :top="true"
    >
      {{ errorSnackbarText }}
    </v-snackbar>
    <v-snackbar
      v-model="successSnackbar"
      timeout="3000"
      color="blue"
      dark
      :absolute="true"
      :top="true"
    >
      {{ successSnackbarText }}
    </v-snackbar>
    <v-dialog
      v-model="billingRunDetailDialog"
      width="1200"
    >
      <v-card>
        <v-card-title>
          {{ billingRunDetail.name }}<v-spacer></v-spacer>
          <v-btn icon @click="closeBillingRunDetailDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
            <v-row>
              <v-col cols="12">
                <h3>Abrechnung bis: {{ formatDate(billingRunDetail.deadline) }}</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3>KITAs im Abrechnungslauf: {{ billingRunDetail.includedDaycares?.length }}</h3>
                <v-simple-table
                  fixed-header
                  height="300px"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Status
                        </th>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Email
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="daycare in billingRunDetail.includedDaycares"
                        :key="daycare._id"
                      >
                        <td>
                          <v-chip :color="getFormattedDaycareStatus(daycare.status)[1]" dark>
                            {{ getFormattedDaycareStatus(daycare.status)[0] }}
                          </v-chip>
                          <v-tooltip top v-if="daycare.status == 'awaiting_approval'">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="ml-2"
                                color="green darken-2"
                                @click.stop="approveKitaBill(daycare)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-check-decagram
                              </v-icon>
                            </template>
                            <span>Bestätigen</span>
                          </v-tooltip>
                        </td>
                        <td>{{ daycare.name }}</td>
                        <td>{{ daycare.email }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions v-if="showBillingRunConfirmButton">
          <v-spacer></v-spacer>
          <v-btn color="secondary" elevation="0" @click="confirmBillingRun()">
            Abschließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="billingRunDialog"
      width="800"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline" v-if="billingRunDialogMode == 'CREATE'">Abrechnungslauf anlegen</span>
          <span class="headline" v-else>Abrechnungslauf bearbeiten</span>
        </v-card-title>
        <v-card-text>
            <v-form ref="billingrunform">
              <v-row>
                <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                  <v-text-field
                    v-model="dialogBillingRun.name"
                    label="Name"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                  <v-text-field
                    v-model="dialogBillingRun.deadline"
                    label="Abrechnung bis"
                    type="date"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="12">
                <h3>{{ numberOfSelectedDaycares }} von {{ numberOfDaycares }} ausgewählt</h3>
                <v-simple-table
                  fixed-header
                  height="300px"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th></th>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Email
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="daycare in availableDaycares"
                        :key="daycare._id"
                      >
                        <td>
                          <v-checkbox
                            v-model="daycare.selected"
                          ></v-checkbox>
                        </td>
                        <td>{{ daycare.name }}</td>
                        <td>{{ daycare.email }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="closeBillingRunDialog()">
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveBillingRun">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" lg="3" md="4" sm="6" xs="12">
         <v-text-field
          v-model="billingRunSearch"
          @change="enteredSearch()"
         >
          <v-icon
            slot="prepend"
            color="primary"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" lg="3" md="3" sm="3" xs="12" style="text-align: right;">
        <v-btn color="primary" elevation="0" @click="openBillingRunCreateDialog()">
            Abrechnungslauf anlegen
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="data"
      :options.sync="options"
      :server-items-length="count"
      :loading="loading"
      @click:row="handleRowClick"
      loading-text="Lade Daten..."
      item-key="_id"
      class="elevation-0 row-pointer"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20, 25],
        'items-per-page-text': 'Einträge pro Seite',
      }"
    >
      <template v-slot:no-data>
        Keine Abrechnungsläufe vorhanden
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="getFormattedStatus(item)[1]" dark>
          {{ getFormattedStatus(item)[0] }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-2"
              @click.stop="editBillingRun(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
        <v-tooltip top v-if="item.status == 'finished'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-2"
              @click.stop="downloadExcel(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-microsoft-excel
            </v-icon>
          </template>
          <span>Excel Download</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 12pt !important;
}

.v-data-table.v-data-table.v-data-table >>> th {
  font-size: 14pt !important;
}
</style>

<script>
  import axios from "axios";
  import appConfig from "../../config";
  import utils from "../../utils";

export default {
  name: 'BillingRunTable',
  data() {
    return {
      errorSnackbar: false,
      errorSnackbarText: "",
      successSnackbar: false,
      successSnackbarText: "",
      count: 0,
      data: [],
      loading: true,
      options: {},
      billingRunSearch: "",
      dialogBillingRun: {},
      billingRunDialog: false,
      billingRunDialogMode: "CREATE",
      billingRunDetail: {},
      billingRunDetailInvoices: [],
      billingRunDetailDialog: false,
      availableDaycares: [],
      rules: {
        required: (value) => !!value || "Pflichtfeld, bitte ausfüllen"
      },
      headers: [
      {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Abrechnung bis",
          align: "start",
          sortable: false,
          value: "formattedDeadline",
        },
        {
          text: "Anzahl KITAs",
          align: "start",
          sortable: false,
          value: "numOfDaycares",
        },
        { text: "", value: "actions", sortable: false },
      ],
      daycareHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
      ],
      selectedDaycares: [],
    }
  },
  computed: {
    numberOfSelectedDaycares: function() {
      return this.availableDaycares.filter(daycare => daycare.selected).length;
    },
    numberOfDaycares: function() {
      return this.availableDaycares.length;
    },
    showBillingRunConfirmButton: function() {
      return this.billingRunDetail.includedDaycares?.every(daycare => daycare.status == 'confirmed') && this.billingRunDetail.status == 'in_progress';
    }
  },  
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    showSnackbar(type, text) {
        if (type == "ERROR") {
          this.errorSnackbar = true;
          this.errorSnackbarText = text;
        } else {
          this.successSnackbar = true;
          this.successSnackbarText = text;
        }
    },
    formatDate(d, m) {
      return utils.formatDate(d, m);
    },
    getDataFromApi() {
      this.loading = true;
      this.fetchData().then((data) => {
        this.data = data.data;
        this.count = data.count;
        this.loading = false;
      });
    },
    getFormattedStatus(billingRun) {
      if(billingRun.status == "finished") {
        return ["Abgeschlossen", "primary"];
      } else if(billingRun.status == "canceled") {
        return ["Abgebrochen", "primary"];
      } else if(billingRun.status == "in_progress" && new Date(billingRun.deadline) < new Date()) {
        return ["Überfällig", "orange"];
      } else {
        return ["Aktiv", "secondary"];
      }
    },
    fetchData() {
      return new Promise(async (resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        const skip = (page - 1) * itemsPerPage;
        const top = itemsPerPage;
        const search = this.billingRunSearch != ""? this.billingRunSearch: undefined;

        const resp = await this.getBillingRuns(skip, top, search);

        let index = 0;

        const data = resp.data.map((d) => {
          let res = d;
          

          res.key = d._id;
          res.index = index;
          res.formattedCreatedAt = utils.formatDate(d.createdAt, true);
          res.formattedDeadline = utils.formatDate(d.deadline);
          res.numOfDaycares = d.includedDaycares.length;

          index += 1;

          return res;
        });

        const count = resp.count;

        resolve({ data, count });
      });
    },
    async getBillingRuns(skip, top, search) {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      let dataUrl = `${appConfig.apiURL}/kitabilling/billingruns?$skip=${skip}&$top=${top}&$orderby=deadline asc`;

      if(search) {
        dataUrl += `&$search=${search}`;
      }

      try {
        const dataResp = await axios.get(dataUrl, requestConfig);

        return {
          data: dataResp.data.data,
          count: dataResp.data.count,
        };
      } catch (e) {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log(e);
        }

        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },
    enteredSearch() {
      this.getDataFromApi();
    },
    editBillingRun(billingRun) {
        
    },
    async openBillingRunCreateDialog() {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      let dataUrl = `${appConfig.apiURL}/kitabilling/daycares`;

      try {
        const dataResp = await axios.get(dataUrl, requestConfig);

        this.availableDaycares = dataResp.data.data.map(daycare => {
          return {
            ...daycare,
            selected: true
          }
        });
        console.log(this.availableDaycares)
      } catch (e) {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }

      this.billingRunDialogMode = "CREATE";
      this.dialogBillingRun = {};
      this.billingRunDialog = true;
    },
    closeBillingRunDetailDialog() {
      this.billingRunDetailDialog = false;
      this.billingRunDetail = {};
      this.billingRunDetailInvoices = [];
    },
    getFormattedDaycareStatus(status) {
      if(status == "waiting") {
        return ["Ausstehend", "primary"];
      } else if(status == "awaiting_approval") {
        return ["Wartet auf Bestätigung", "secondary"];
      } else {
        return ["Bestätigt", "green darken-2"];
      }
    },
    async handleRowClick(row) {
      this.billingRunDetail = { ...row };

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      this.billingRunDetailInvoices = (await axios.get(
        `${appConfig.apiURL}/kitabilling/kitainvoices?$filter=billingRun eq cast('${row._id}', ObjectId)`, requestConfig
      )).data.data;

      const getInvoiceStatus = function(invoice) {
        console.log(invoice)
        if(invoice.length == 0) {
          return "waiting";
        } else if(invoice[0].confirmed) {
          return "confirmed";
        } else {
          return "awaiting_approval";
        }
      }

      this.billingRunDetail.includedDaycares = (await Promise.all(
        this.billingRunDetail.includedDaycares.map(daycareId => {
          const url = `${appConfig.apiURL}/kitabilling/daycares/${daycareId}`;

          return axios.get(url, requestConfig)
        })
      )).map(resp => {
        const invoice = this.billingRunDetailInvoices.filter(invoice =>invoice.daycare == resp.data._id);

        return {
          status: getInvoiceStatus(invoice),
          invoice: invoice.length > 0? invoice[0]: null,
          ...resp.data
        }
      });

      this.billingRunDetailDialog = true;
    },
    closeBillingRunDialog() {
      this.billingRunDialog = false;
      this.dialogBillingRun = {};
      this.$refs.billingrunform.resetValidation();
    },
    saveBillingRun() {
      if(!this.$refs.billingrunform.validate()) {
        return;
      }

      if(this.billingRunDialogMode == "CREATE") {
        this.createBillingRun();
      } else {
        this.updateBillingRun();
      }
    },
    createBillingRun() {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const data = {
        name: this.dialogBillingRun.name,
        deadline: this.dialogBillingRun.deadline,
        includedDaycares: this.availableDaycares.filter(daycare => daycare.selected).map(daycare => daycare._id)
      };

      const url = `${appConfig.apiURL}/kitabilling/billingruns`;

      axios.post(url, data, requestConfig).then(resp => {
        this.closeBillingRunDialog();
        this.showSnackbar("SUCCESS", "Abrechnungslauf erfolgreich angelegt");
        this.getDataFromApi();
      }).catch(e => {
        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      });
    },
    approveKitaBill(item) {
      this.$router.push(`/kitabilling/invoiceaccept?daycare=${item._id}&invoice=${item.invoice._id}&billingrun=${item.invoice.billingRun}`);
    },
    async confirmBillingRun() {
      const requestConfig = {
          headers: {
            sessiontoken: localStorage.token,
          },
        };

        try {
          const url = `${appConfig.apiURL}/kitabilling/billingruns/${this.billingRunDetail._id}/finish`;

          const resp = await axios.put(url, {}, requestConfig);
          this.closeBillingRunDetailDialog();
          this.getDataFromApi();
        } catch(e) {
          this.showSnackbar("ERROR", "Es ist ein unerwarteter Fehler aufgetreten");
        }
    },
    async downloadExcel(item) {
      const requestConfig = {
          headers: {
            sessiontoken: localStorage.token,
          },
        };

        try {
          const url = `${appConfig.apiURL}/kitabilling/billingruns/${item._id}/summary`;

          const resp = await axios.get(url, requestConfig);
          console.log(resp.data);
          let fileName = `Abrechnung ${item.name}.txt`;
          const downloadLink = document.createElement("a");
          downloadLink.href = resp.data.base64;
          downloadLink.download = fileName;
          downloadLink.click();
        } catch(e) {
          this.showSnackbar("ERROR", "Es ist ein unerwarteter Fehler aufgetreten");
        }
    }
  }
}
</script>
