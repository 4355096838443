<template>
  <div>
    <InboxTable />
  </div>
</template>

<script>
import appConfig from "../../config";
import utils from "../../utils";
import axios from "axios";

import InboxTable from "./InboxTable.vue";

export default {
  name: "InitialCaseInboxTable",
  components: {
    InboxTable,
  },
  data() {
    return {
      
    };
  },

  async mounted() {

  },

  methods: {
  },

};
</script>
