import { render, staticRenderFns } from "./SozialstaffelOZG.vue?vue&type=template&id=31fac4d8&scoped=true&"
import script from "./SozialstaffelOZG.vue?vue&type=script&lang=js&"
export * from "./SozialstaffelOZG.vue?vue&type=script&lang=js&"
import style0 from "./SozialstaffelOZG.vue?vue&type=style&index=0&id=31fac4d8&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31fac4d8",
  null
  
)

export default component.exports