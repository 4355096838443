<template>
  <v-main>
    <v-dialog v-model="pwResetDialog" persistent max-width="800px">
      <v-card>
        <v-card-title>Bitte geben Sie Ihre E-Mail-Adresse ein, um das Passwort zurückzusetzen.</v-card-title>
        <v-card-text>
          <v-form ref="resetForm">
            <v-text-field label="E-Mail-Adresse" outlined v-model="pwResetEmail" placeholder="max@mustermann.de"
              :rules="emailRules"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="!pwResetLoading">
          <v-btn color="red darken-1" text @click="closeResetPwForm()">
            Schließen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetPw()">
            Zurücksetzen
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid fill-height>
      <v-snackbar v-model="serverErrorSnackbar" timeout="3000" color="red" dark :absolute="true" :top="true">
        {{ errorSnackbarText }}
      </v-snackbar>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 v-if="loginStep == 'default'">
          <v-card>
            <v-toolbar dark color="primary" elevation="0">
              <v-toolbar-title>Verwaltungscloud.SH Login</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6" order-lg="1" md="12" order-md="3" order-sm="3" order-xs="3">
                  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="login()">
                    <v-text-field v-model="email" name="email" label="E-mail" type="text" placeholder="max@mustermann.de"
                      :rules="emailRules" required></v-text-field>

                    <v-text-field v-model="password" name="password" label="Passwort"
                      :append-icon="showLoginPW ? 'mdi-eye' : 'mdi-eye-off'" :type="showLoginPW ? 'text' : 'password'"
                      @click:append="showLoginPW = !showLoginPW" placeholder="Passwort" :rules="passwordRules"
                      required></v-text-field>
                    <v-btn type="submit" class="mt-4 px-6" color="secondary" value="log in" :disabled="loading">
                      <span v-if="!loading">Login</span>
                      <v-progress-circular :size="30" color="primary" indeterminate v-else></v-progress-circular>
                    </v-btn>
                    <a style="float: right; text-decoration: underline" class="mt-8"
                      @click="pwResetDialog = true">Passwort vergessen?</a>
                  </v-form>
                </v-col>
                <v-col lg="1" class="hidden-md-and-down" order="2">
                  <v-divider vertical></v-divider>
                </v-col>
                <v-col cols="12" lg="5" md="12" order-lg="3" order-xs="1">
                  <v-container class="fill-height" style="width: 100%">
                    <img src="./../assets/LogoHL.png" style="width: 95%; margin: auto;">
                  </v-container>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm8 md6 v-if="loginStep == 'twoFactor'" style="max-width: 750px">
          <v-card>
            <v-toolbar dark color="primary" elevation="0">
              <v-toolbar-title>Verwaltungscloud.SH Login</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-layout justify-center align-center>
                <img src="./../assets/LogoHL.png" style="width: 80%; max-width: 250px">
              </v-layout>
              <h3 class="text-center my-4">
                Wir haben eine Email an das Emailkonto verschickt, das mit Ihrem Account verknüpft ist. In dieser Email
                finden Sie einen 6-stelligen
                Code. Bitte geben Sie diesen Code in das untenstehende Eingabefeld ein.
              </h3>
              <v-row>
                <v-col cols="12" sm="2"></v-col>
                <v-col cols="12" sm="8">
                  <v-otp-input length="6" @finish="twoFactorEntered" type="number" :disabled="twoFactorLoading"
                    v-model="twoFactorNonce"></v-otp-input>
                </v-col>
                <v-col cols="12" sm="2"></v-col>
              </v-row>
              <v-layout justify-center align-center class="mt-9">
                <v-btn style="width: 50%" color="primary" :disabled="!twoFactorComplete" @click="twoFactorSubmit">
                  <span v-if="!twoFactorLoading">Fortfahren</span>
                  <v-progress-circular :size="30" color="white" indeterminate v-else></v-progress-circular>
                </v-btn>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm8 md6 v-if="loginStep == 'pwForceReset'" style="max-width: 750px">
          <v-card>
            <v-toolbar dark color="primary" elevation="0">
              <v-toolbar-title>Verwaltungscloud.SH Login</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-layout justify-center align-center>
                <img src="./../assets/LogoHL.png" style="width: 80%; max-width: 250px">
              </v-layout>
              <h3 class="text-center my-4">
                Gemäß den Richtlinien Ihrer Organisation ist Ihr Passwort abgelaufen. Aus Sicherheitsgründen müssen Sie
                deshalb ein neues Passwort festlegen.
              </h3>
              <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="8">
                  <v-alert v-if="!passwordForceResetValid" type="error">Die angegebenen Passwörter müssen
                    übereinstimmen</v-alert>
                </v-col>
                <v-col cols="2"></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="2"></v-col>
                <v-col cols="12" sm="8">
                  <v-text-field v-model="newPasswordForceReset" name="newPasswordForceReset" label="Neues Passwort"
                    :type="pwForceResetShow ? 'text' : 'password'" placeholder="Neues Passwort"
                    :append-icon="pwForceResetShow ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="pwForceResetShow = !pwForceResetShow" :rules="[...passwordRules, pwRules]"
                    required></v-text-field>
                  <v-text-field v-model="newPasswordForceResetRepeat" name="newPasswordForceResetRepeat"
                    label="Neues Passwort wiederholen" :type="pwForceResetShow ? 'text' : 'password'"
                    placeholder="Neues Passwort wiederholen" :append-icon="pwForceResetShow ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="pwForceResetShow = !pwForceResetShow" :rules="[...passwordRules, pwRules]"
                    required></v-text-field>
                </v-col>
                <v-col cols="12" sm="2"></v-col>
              </v-row>
              <v-layout justify-center align-center class="mt-9">
                <v-btn style="width: 50%" color="primary"
                  :disabled="!passwordForceResetValid || passwordForceResetLoading" @click="submitNewPWForceReset">
                  <span v-if="!passwordForceResetLoading">Fortfahren</span>
                  <v-progress-circular :size="30" color="white" indeterminate v-else></v-progress-circular>
                </v-btn>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import config from "../config";
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      /* Data */
      valid: false,
      email: "",
      password: "",
      serverErrorSnackbar: false,
      errorSnackbarText: "",
      loading: false,
      pwResetDialog: false,
      pwResetLoading: false,
      showPw: false,
      pwResetEmail: "",

      loginStep: "default",

      twoFactorComplete: false,
      twoFactorNonceId: "",
      twoFactorNonce: "",
      twoFactorLoading: false,

      newPasswordForceReset: "",
      newPasswordForceResetRepeat: "",
      passwordForceResetLoading: false,
      passwordForceResetToken: "",
      pwForceResetShow: false,

      /* Form Validations */
      emailRules: [
        (v) => !!v || "Bitte geben Sie eine E-mail Adresse an.",
        (v) =>
          /.+@.+\..+/.test(v) || "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
      ],
      passwordRules: [
        (v) => !!v || "Bitte geben Sie ein gültiges Passwort ein.",
      ],

      passwordPolicy: {},
      pwRules: (v) => {
        if (!v || v.length < this.passwordPolicy.minLength) {
          return `Das Passwort muss mindestens ${this.passwordPolicy.minLength} Zeichen lang sein.`;
        } else if (this.passwordPolicy.minSpecialChars > 0 && !v.match(new RegExp(`[^a-zA-Z0-9]{${this.passwordPolicy.minSpecialChars}}`))) {
          return `Das Passwort muss mindestens ${this.passwordPolicy.minSpecialChars} Sonderzeichen enthalten.`;
        } else if (this.passwordPolicy.minNumbers > 0 && !v.match(new RegExp(`[0-9]{${this.passwordPolicy.minNumbers}}`))) {
          return `Das Passwort muss mindestens ${this.passwordPolicy.minNumbers} Zahlen enthalten.`;
        }

        return true;
      },

      showLoginPW: false
    };
  },
  computed: {
    passwordForceResetValid: function () {
      return this.newPasswordForceReset == this.newPasswordForceResetRepeat;
    }
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const { email, password } = this;

        const url = `${config.apiURL}/auth/login`;

        const body = {
          email: email,
          password: password,
        };

        //send request to server
        axios
          .post(url, body)
          .then((response) => {
            if (response.data.nonceId) { //response is 2fa
              this.twoFactorNonceId = response.data.nonceId;
              this.loginStep = "twoFactor";
            } else { //response is without 2fa
              //success
              localStorage.token = response.data.sessionToken;
              this.$router.push("/launchpad");
            }

            this.loading = false;

          })
          .catch(async (err) => {
            //error
            if (err.response?.status == 401) {
              this.errorSnackbarText =
                "Bitte überprüfen Sie die eingegebene E-Mail und das Passwort.";
              this.serverErrorSnackbar = true;
            } else if (err?.response?.status == 428) {
              console.log(err.response.data);

              this.passwordForceResetToken = err.response?.data?.sessionToken;

              const pwPolicyURL = `${config.apiURL}/auth/passwordpolicy`;
              const pwPolicyResponse = await axios.get(pwPolicyURL, {
                headers: {
                  sessiontoken: this.passwordForceResetToken
                }
              });

              this.passwordPolicy = pwPolicyResponse.data.passwordPolicy;

              this.twoFactorNonce = "";
              this.loginStep = "pwForceReset";
            } else {
              this.errorSnackbarText =
                "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support.";
              this.serverErrorSnackbar = true;
            }

            this.loading = false;
          });
      }
    },
    twoFactorEntered() {
      this.twoFactorComplete = true;
      this.twoFactorSubmit();
    },
    twoFactorSubmit() {
      this.twoFactorLoading = true;

      const url = `${config.apiURL}/auth/twofactorauthenticate/${this.twoFactorNonceId}`;

      const body = {
        nonce: this.twoFactorNonce
      };

      axios
        .post(url, body)
        .then((response) => {
          localStorage.token = response.data.sessionToken;
          this.$router.push("/launchpad");

          this.twoFactorLoading = false;
        })
        .catch((err) => {
          if (err.response?.status == 428) {
            if (err.response.data.userNeedsToAssignTenant) {
              localStorage.token = err.response.data.sessionToken;
              this.$router.push("/support/tenant");
            } else {
              this.passwordForceResetToken = err.response?.data?.sessionToken;
              this.loginStep = "pwForceReset";
              this.twoFactorNonce = "";
            }
          } else if (err.response?.status == 401) {
            this.errorSnackbarText =
              "Der eingegebene 2-Faktor Code ist nicht korrekt";
            this.serverErrorSnackbar = true;
          } else {
            this.errorSnackbarText =
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support";
            this.serverErrorSnackbar = true;
          }

          this.twoFactorLoading = false;
        });
    },
    async submitNewPWForceReset() {
      try {
        this.passwordForceResetLoading = true;

        const url = `${config.apiURL}/auth/changepassword/my`;

        const body = {
          oldPassword: this.password,
          newPassword: this.newPasswordForceReset
        };

        const requestHeader = {
          headers: {
            sessiontoken: this.passwordForceResetToken
          }
        };

        const pwResetReq = await axios.post(url, body, requestHeader);

        this.password = ""
        this.loginStep = "default";
      } catch (err) {
        if (err.response?.status == 409) {
          this.errorSnackbarText = "Das neue Passwort darf nicht mit dem Alten nicht übereinstimmen";
          this.serverErrorSnackbar = true;
        } else {
          this.errorSnackbarText = "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktieren Sie den Support";
          this.serverErrorSnackbar = true;
        }
      } finally {
        this.passwordForceResetLoading = false;
      }
    },
    resetPw() {
      if (!this.$refs.resetForm.validate()) {
        return;
      }

      this.pwResetLoading = true;

      const url = `${config.apiURL}/auth/passwordreset/request`;
      const body = {
        email: this.pwResetEmail
      };

      axios
        .post(url, body)
        .then((response) => {
          //success
          this.pwResetDialog = false;
          this.pwResetLoading = false;
          this.pwResetEmail = "";
          this.$refs.resetForm.resetValidation();
        })
        .catch((err) => {
          //error
          if (err.response?.status == 401) {
            this.errorSnackbarText =
              "Bitte überprüfen Sie die eingegebene E-Mail und das Passwort.";
            this.serverErrorSnackbar = true;
          } else {
            this.errorSnackbarText =
              "Es ist ein unerwarteter Fehler aufgetreten bitte kontaktiere den Support";
            this.serverErrorSnackbar = true;
          }

          this.pwResetLoading = false;
        });
    },
    closeResetPwForm() {
      this.pwResetDialog = false;
      this.pwResetEmail = "";
      this.$refs.resetForm.resetValidation();
    }
  },
};
</script>