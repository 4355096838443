<template>
  <v-main
    class="mt-3"
    style="background-color: #ffffff; width: 100vw; height: 95vh"
  >
    <v-app-bar app elevation="1" color="white">
      <v-btn color="primary" elevation="0" @click="back()" outlined>
        <v-icon>mdi-arrow-left-thick</v-icon>
        Zurück zur Startseite
      </v-btn>
    </v-app-bar>
    <div class="ma-9">
      <v-snackbar
        v-model="errorSnackbar"
        timeout="3000"
        color="red"
        dark
        :absolute="true"
        :top="true"
      >
        {{ errorSnackbarText }}
      </v-snackbar>
      <v-snackbar
        v-model="successSnackbar"
        timeout="3000"
        color="blue"
        dark
        :absolute="true"
        :top="true"
      >
        {{ successSnackbarText }}
      </v-snackbar>
      <v-row>
        <v-text-field
            v-model="search"
            @change="getDataFromApi"
            label="Suche"
            style="max-width: 400px"
        >
          <v-icon
            slot="prepend"
            color="primary"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" elevation="0" @click="openUserCreateDialog()">
          Benutzer anlegen
        </v-btn>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="data"
        :options.sync="options"
        :server-items-length="count"
        :loading="loading"
        class="elevation-0 mt-6"
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 20, 25],
          'items-per-page-text': 'Einträge pro Seite',
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-on:click.stop="openUserUpdateDialog(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Bearbeiten</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-2"
                v-on:click.stop="openUserDeleteDialog(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Löschen</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template>
      </v-data-table>
      <!-- Begin User Dialog -->
      <v-dialog v-model="userDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5" v-if="userForm.mode == 'CREATE'"
              >Benutzer anlegen</span
            >
            <span class="text-h5" v-else>Benutzer aktualisieren</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="userForm.valid" lazy-validation>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Vorname*"
                      v-model="userForm.firstName"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Nachname*"
                      v-model="userForm.lastName"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="E-Mail*"
                      v-model="userForm.email"
                      required
                      :rules="[rules.required, rules.isEmail]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="11">
                    <v-text-field
                      v-if="userForm.mode == 'CREATE'"
                      label="Initiales Passwort*"
                      :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPW? 'text': 'password'"
                      @click:append="showPW = !showPW"
                      v-model="userForm.password"
                      :rules="[rules.required, rules.pwRules]"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1" class="pt-8" v-if="userForm.mode == 'CREATE'">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="primary"> mdi-information </v-icon>
                      </template>
                      <span>
                        Das initial festgelegte Passwort ist nur für den Start im System. <br /> Der Benutzer wird bei erstmaliger Anmeldung aufgefordert, dieses <br />Passwort zu ändern und ein eigenes sicherheitskonformes Passwort festzulegen.
                      </span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="userForm.permissions"
                      :items="availablePermissions"
                      label="Rechte-und Rollen"
                      multiple
                      chips
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>* Pflichtfeld</small>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="closeUserDialog()">
              Schließen
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="createUser()"
              v-if="userForm.mode == 'CREATE'"
            >
              Erstellen
            </v-btn>
            <v-btn color="blue darken-1" text @click="updateUser()" v-else>
              Aktualisieren
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- End User create Dialog -->

      <!-- Begin Delete Confirm Dialog -->
      <v-dialog v-model="userDeleteDialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="text-h5"> Benutzer löschen? </v-card-title>
          <v-card-text
            >Soll der Benutzer wirklich gelöscht werden? Der Benutzer wird
            unwiederruflich gelöscht und hat keinen Zugriff mehr auf die
            Verwaltungscloud.</v-card-text
          >
          <v-card-actions>
            <v-btn color="red darken-1" text @click="deleteUser()">
              Löschen Bestätigen
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeUserDeleteDialog()">
              Schließen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- End Delete Confirm Dialog -->
    </div>
  </v-main>
</template>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 12pt !important;
}

.v-data-table.v-data-table.v-data-table >>> th {
  font-size: 14pt !important;
}
</style>
<script>
import axios from "axios";

import appConfig from "../config";
import utils from "../utils";

export default {
  name: "UsersTable",
  data() {
    return {
      //table realted data
      search: "",
      count: 0,
      data: [],
      loading: true,
      options: {},
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "E-mail", value: "email" },
        { text: "Erstellt am", value: "formattedCreatedAt" },
        { text: "", value: "actions", sortable: false },
      ],

      //form related data
      userDialog: false,
      userDeleteDialog: false,
      userToDelete: {},
      availablePermissions: [],
      permissionMapping: {},
      userForm: {},
      passwordPolicy: {},
      showPW: false,

      rules: {
        required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
        isEmail: (v) => /.+@.+\..+/.test(v) || "Bitte geben Sie eine valide E-mail Adresse an",
        pwRules: (v) => {
          if (!v || v.length < this.passwordPolicy.minLength) {
            return `Das Passwort muss mindestens ${this.passwordPolicy.minLength} Zeichen lang sein`;
          }  else if (this.passwordPolicy.minSpecialChars > 0 && !v.match(new RegExp(`[^a-zA-Z0-9]{${this.passwordPolicy.minSpecialChars}}`))) {
            return `Das Passwort muss mindestens ${this.passwordPolicy.minSpecialChars} Sonderzeichen enthalten`;
          } else if (this.passwordPolicy.minNumbers > 0 && !v.match(new RegExp(`[0-9]{${this.passwordPolicy.minNumbers}}`))) {
            return `Das Passwort muss mindestens ${this.passwordPolicy.minNumbers} Zahlen enthalten`;
          } 

          return true;
        }
      },

      //snackbars
      errorSnackbar: false,
      errorSnackbarText: "",
      successSnackbar: false,
      successSnackbarText: "",
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    /*
     * common
     */
    back() {
      this.$router.push("/launchpad");
    },
    showSnackbar(type, text) {
      if (type == "ERROR") {
        this.errorSnackbar = true;
        this.errorSnackbarText = text;
      } else {
        this.successSnackbar = true;
        this.successSnackbarText = text;
      }
    },

    /*
     * table
     */
    getDataFromApi() {
      this.loading = true;
      this.apiCall().then((data) => {
        this.data = data.data;
        this.count = data.count;
        this.loading = false;
      });
    },
    apiCall() {
      return new Promise(async (resolve, reject) => {
        const token = localStorage.token;

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let sort;

        if (sortBy.length > 0) {
          sort = sortBy[0] == 'name'? 'firstName': sortBy[0] == 'formattedCreatedAt'? 'createdAt': sortBy[0];

          if (sortDesc) {
            sort += " desc";
          } else if (sort) {
            sort += " asc";
          }
        }

        const skip = (page - 1) * itemsPerPage;
        const top = itemsPerPage;

        const resp = await this.getUsers(skip, top, this.search, sort);

        const data = resp.data.map((d) => {
          return {
            ...d,
            ...{
              name: `${d.firstName} ${d.lastName}`,
              formattedCreatedAt: utils.formatDate(d.createdAt, true),
            },
          };
        });
        const count = resp.count;

        resolve({ data, count });
      });
    },
    getUsers: async function (skip, top, search, sortBy) {
      const token = localStorage.getItem("token");

      let url = `${appConfig.apiURL}/users?$skip=${skip}&$top=${top}`;

      if (sortBy) {
        url += `&$orderby=${sortBy}`;
      }

      if(search) {
        url += `&$search=${search}`;
      }

      const countURL = `${appConfig.apiURL}/users/count`;

      const requestConfig = {
        headers: {
          sessiontoken: token,
        },
      };

      try {
        const response = await axios.get(url, requestConfig);
        const countResponse = await axios.get(countURL, requestConfig);

        return {
            data: response.data.data,
            count: countResponse.data.count
        };
      } catch (e) {
        this.showSnackbar(
          "ERROR",
          "Es ist ein Fehler aufgetreten bitte kontaktieren Sie den support"
        );
      }
    },

    /*
     * User Dialog Methods
     */
    getDataFromUserForm: function () {
      return {
        firstName: this.userForm.firstName,
        lastName: this.userForm.lastName,
        password: this.userForm.password,
        email: this.userForm.email,
        //map permissions back to backend idetifiers
        permissions: this.userForm.permissions.map((permission) => {
          return this.permissionMapping[permission];
        }).filter(permission => permission != null),
      };
    },

    openUserCreateDialog: function () {
      this.userForm.mode = "CREATE";
      this.userDialog = true;
    },
    openUserUpdateDialog: function (user) {
      this.userForm.mode = "UPDATE";
      this.userDialog = true;

      //set form data
      this.userForm.firstName = user.firstName;
      this.userForm.lastName = user.lastName;
      this.userForm.email = user.email;
      this.userForm.permissions = Object.keys(this.permissionMapping).map(
        (p) => {
          if (user.permissions.includes(this.permissionMapping[p])) {
            return p;
          }
        }
      );

      this.userForm.userToUpdate = user._id;
    },
    resetUserForm: function () {
      this.userForm = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        permissions: [],
        mode: "",
        userToUpdate: "",
        valid: true,
      };

      this.showPW = false;

      this.$refs.form.resetValidation();
    },
    createUser: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      const body = this.getDataFromUserForm();

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.token,
        },
      };

      const url = `${appConfig.apiURL}/users`;
      let err = false;

      try {
        const response = await axios.post(url, body, requestConfig);
        this.getDataFromApi();
      } catch (e) {
        console.log(e.response);
        err = true;
      }

      this.resetUserForm();
      this.userDialog = false;
      if (!err) {
        this.showSnackbar("SUCCESS", "Benutzer erfolgreich angelegt");
      } else {
        this.showSnackbar(
          "ERROR",
          "Es ist ein Fehler aufgetreten bitte kontaktieren Sie den support"
        );
      }
    },

    updateUser: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      const body = this.getDataFromUserForm();

      delete body.password;

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.token,
        },
      };

      const url = `${appConfig.apiURL}/users/${this.userForm.userToUpdate}`;
      let err = false;

      try {
        const response = await axios.put(url, body, requestConfig);
        this.getDataFromApi();
      } catch (e) {
        console.log(e.response);
        err = true;
      }

      this.resetUserForm();
      this.userDialog = false;
      if (!err) {
        this.showSnackbar("SUCCESS", "Benutzer erfolgreich aktualisiert");
      } else {
        this.showSnackbar(
          "ERROR",
          "Es ist ein Fehler aufgetreten bitte kontaktieren Sie den support"
        );
      }
    },

    closeUserDialog: function () {
      this.userDialog = false;
      this.resetUserForm();
    },

    /*
     * User Delete Dialog Method
     */
    openUserDeleteDialog: function (user) {
      this.userToDelete = user;
      this.userDeleteDialog = true;
    },
    closeUserDeleteDialog: function () {
      this.userToDelete = {};
      this.userDeleteDialog = false;
    },
    deleteUser: async function () {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.token,
        },
      };

      const url = `${appConfig.apiURL}/users/${this.userToDelete._id}`;

      let err = false;

      try {
        const response = await axios.delete(url, requestConfig);
      } catch (e) {
        err = true;
      }

      this.getDataFromApi();
      this.closeUserDeleteDialog();

      if (!err) {
        this.showSnackbar("SUCCESS", "Benutzer erfolgreich gelöscht");
      } else {
        this.showSnackbar(
          "ERROR",
          "Es ist ein Fehler aufgetreten bitte kontaktieren Sie den support"
        );
      }
    },
  },
  mounted() {
    const requestConfig = {
        headers: {
          sessiontoken: localStorage.token,
        },
      };

    const url = `${appConfig.apiURL}/static/mappings/permissionsmapping`;

    axios
    .get(url, requestConfig)
    .then(async (response) => {
      try {
        const availablePermissionsURL = `${appConfig.apiURL}/auth/permissions`;

        const availablePermissionsResponse = await axios.get(availablePermissionsURL, requestConfig);

        this.permissionMapping = response.data;
        this.availablePermissions = Object.keys(this.permissionMapping).filter(roleName => availablePermissionsResponse.data.roles.includes(this.permissionMapping[roleName]));

        const securityURL = `${appConfig.apiURL}/settings/tenant/security`;
        const securityResponse = await axios.get(securityURL, requestConfig);

        this.passwordPolicy = securityResponse.data.value.passwordPolicy;
      } catch(e) {
        throw Error(e.message);
      }
    })
    .catch(error => {
      this.showSnackbar(
        "ERROR",
        "Es ist ein Fehler aufgetreten bitte kontaktieren Sie den support"
      );
    })
  }
};
</script>