<template>
  <v-main>
      <v-container fluid fill-height>
      <v-snackbar
        v-model="snackbar"
        timeout="5000"
        :color="snackBarType == 'ERROR' ? 'red' : 'blue'"
        dark
        :absolute="true"
        :top="true"
        >
        {{ snackbarText }}
        </v-snackbar>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card>
            <v-toolbar dark color="primary" elevation="0">
              <v-toolbar-title>Verwaltungscloud.sh Passwort zurücksetzen</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="resetPW()"
                  >
                    <v-text-field
                      v-model="password"
                      :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPW ? 'text' : 'password'"
                      @click:append="showPW = !showPW"
                      name="email"
                      label="Neues Passwort"
                      placeholder="Passwort"
                      :rules="passwordRules"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="passwordRepeat"
                      name="password"
                      label="Passwort Wiederholen"
                      type="password"
                      placeholder="Passwort"
                      :rules="passwordRules"
                      required
                    ></v-text-field>
                      <v-btn type="submit" class="mt-4 px-6" color="secondary" value="log in" :disabled="pwResetLoading">
                        <span v-if="!pwResetLoading">Zurücksetzen</span>
                        <v-progress-circular :size="30" color="primary" indeterminate v-else></v-progress-circular>
                      </v-btn>
                  </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import config from "../config";
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      /* snackbar */
      snackbarText: "",
      snackBarType: "SUCCESS",
      snackbar: false,

      password: "",
      passwordRepeat: "",
      showPW: false,
      pwResetLoading: false,
      passwordRules: [
         (v) => !!v || "Bitte geben Sie ein Passwort an",
         (v) => {
            if (!v || v.length < this.passwordPolicy.minLength) {
              return `Das Passwort muss mindestens ${this.passwordPolicy.minLength} Zeichen lang sein`;
            }  else if (this.passwordPolicy.minSpecialChars > 0 && !v.match(new RegExp(`[^a-zA-Z0-9]{${this.passwordPolicy.minSpecialChars}}`))) {
              return `Das Passwort muss mindestens ${this.passwordPolicy.minSpecialChars} Sonderzeichen enthalten`;
            } else if (this.passwordPolicy.minNumbers > 0 && !v.match(new RegExp(`[0-9]{${this.passwordPolicy.minNumbers}}`))) {
              return `Das Passwort muss mindestens ${this.passwordPolicy.minNumbers} Zahlen enthalten`;
            } 

            return true;
        },
      ],

      passwordPolicy: {},
    }
  },
  methods: {
    showSnackbar(type, text) {
      if (type == "ERROR") {
        this.snackBarType = "ERROR";
        this.snackbarText = text;
      } else {
        this.snackBarType = "SUCCESS";
        this.snackbarText = text;
      }

      this.snackbar = true;
    },
    async resetPW() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if(this.password != this.passwordRepeat) {
          this.showSnackbar("ERROR", "Die angegebenen Passwörter stimmen nicht überein");
          return;
      }

      this.pwResetLoading = true;

      const nonce = this.$router.currentRoute.query?.nonce;

      const url = `${config.apiURL}/auth/passwordreset/execute`;
      const body = {
        nonce: nonce,
        password: this.password
      };

      try {
        const response = await axios.put(url, body);

        this.$router.push("/login");
      } catch(err) {
        if(err.response?.status == 400) {
            this.showSnackbar("ERROR", "Die Anfrage ist abgelaufen, bitte versuchen Sie eine neue Anfrage zum Zurücksetzen zu stellen")
        } else if(err.response?.status == 409) {
            this.showSnackbar("ERROR", "Das alte Passwort kann nicht wiederverwendet werden, bitte wählen Sie ein neues Passwort")
        } else {
            this.showSnackbar("ERROR", "Es ist ein unerwarteter Fehler aufgetreten")
        }

        this.$refs.form.resetValidation();
      } finally {
        this.pwResetLoading = false;
      }
    }
  },
  async mounted() {
    if (!this.$router.currentRoute.query?.nonce) {
        this.$router.push("/login");
    }

    const pwPolicyURL = `${config.apiURL}/auth/passwordpolicy`;
    const pwPolicyResponse = await axios.get(pwPolicyURL, {
      headers: {
        nonce: this.$router.currentRoute.query?.nonce
      }
    });

    this.passwordPolicy = pwPolicyResponse.data.passwordPolicy;
  }
};
</script>