<template>
    <v-card>
        <v-card-title>
            Antrag als abgeschlossen markieren
        </v-card-title>
        <v-card-text>
            <v-form ref="completeForm">
                <v-row>
                    <v-col sm="12" md="4">
                        <v-text-field
                            label="Datum des vollständigen Eingangs*"
                            v-model="dateOfCompletion"
                            type="date"
                            required
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <slot name="formExtend"></slot>
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="close()"> Schließen </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="safe()">
            Speichern
          </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import appConfig from "../../../config";
    import axios from "axios";

    export default {
        name: "ApplicationFormAsCompleteForm",
        props: [ "applicationId" ],
        data() {
            return {
                dateOfCompletion: new Date().toISOString().substr(0, 10),
                rules: {
                    required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
                },
            }
        },
        async mounted() {

        },
        methods: {
            close() {
                this.dateOfCompletion = new Date().toISOString().substr(0, 10);
                this.$refs.completeForm.resetValidation()
                this.$emit('close');
            },
            async safe() {
                if(!this.$refs.completeForm.validate()) {
                    return;
                }
                try {
                    this.loading = true;

                    const requestConfig = {
                        headers: {
                            sessiontoken: localStorage.getItem("token"),
                        },
                    };

                    const applicationURL = `${appConfig.apiURL}/sozialstaffel/applications/${this.applicationId}`;

                    await axios.put(applicationURL, { dateOfCompletion: this.dateOfCompletion }, requestConfig);

                    this.$emit('closesuccess');
                } catch(e) {
                    this.$emit('closeerror');
                }
            }
        }
    }
</script>