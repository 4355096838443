<template>
    <v-main>
        <!-- computedFields Dialog -->
        <v-dialog v-model="computedFieldDialog" width="1000" persistent>
            <ComputedFieldDialog
                :key="reloadComputedFieldDialog"
                :fieldNames="computedFieldNames"
                :optional="optionalComputedField"
                @close="closeComputedFieldDialog"
                @back="backComputedFieldDialog"
            />
        </v-dialog>
        <!-- Begin ComputedField info dialog -->
        <v-dialog v-model="computedFieldCloseInfoDialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    Pflichtberechnungsfeld Dialog geschlossen
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col sm="12">
                            <v-alert type="info" border="left" colored-border>
                                Die Berechnungshilfe für das Pflichtberechnungsfeld wurde geschlossen. 
                                Es wird dringend empfohlen, diese Berechnungen auszuführen, da Sie i.d.R. essenziell für die Antragsbearbeitung sind.
                                Um wieder in den Dialog zu gelangen, klicken Sie auf den entsprechenden Abschnitt in der Berechnungsansicht.
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="computedFieldCloseInfoDialog = false">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End Begin ComputedField info dialog -->

        <!-- Begin IntervalIncomeCalculator Dialog -->
        <v-dialog v-model="itemIntervalCalculatorDialog" persistent max-width="600px">
            <ItemIntervalDialog
                :key="reloadItemIntervalCalculator"
                :initialValue="itemIntervalCalculatorInitialValue"
                @close="closeItemIntervalCalculator"
            />
        </v-dialog>
        <!-- End IntervalIncomeCalculator Dialog -->
        <v-container v-if="currentSection == null">
            <h1 class="my-3">Bitte auswählen</h1>
        </v-container>
        <v-container v-else>
            <h1 class="my-3 primary--text">{{ currentSectionToDisplay.name }}</h1>
            <v-row justify="center" class="mt-3">
                <v-expansion-panels accordion v-model="currentSubsectionToDisplay" elevation="0">
                    <v-expansion-panel v-for="(section, i) in currentSectionToDisplay.sections" :key="`panel${i}`" @click="clickedExpansionPanel(section)">
                        <v-expansion-panel-header>
                            <span>
                                <v-icon color="green" class="mr-2" style="margin-bottom: 4px;" v-if="calculatedSections.includes(section.label)">
                                    mdi-check-circle
                                </v-icon>
                                <b style="font-size: 14pt"> {{ `${i + 1}. ${section.name}` }} </b>
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row v-if="section.computedPropertyNames?.length > 0" class="my-3">
                                <v-col sm="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col sm="12">
                                    <v-row justify="center">
                                        <v-col sm="6">
                                            <v-alert type="info" border="left" colored-border>
                                                Dieser Sektion sind ein oder mehrere Berechnungsfelder zugeordnet.
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col sm="12">
                                    <v-row>
                                        <v-col class="text-center" sm="12" v-for="(propertyName, j) in section.computedPropertyNames" :key='`compproperty${i}`'>
                                            <v-btn color="primary" elevation="0" outlined @click="openComputedFieldDialog([propertyName])">
                                                <v-icon class="pr-3"> mdi-calculator </v-icon>
                                                {{ computedSchemas[propertyName]?.title }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col sm="12">
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                            <template v-for="(item, j) in section.items">
                                <v-row v-if="item.type == 'TITLE'" class="pt-9">
                                    <v-col cols="12" class="pl-9">
                                        <h4>{{ `${i + 1}.${j + 1}. ${item.name}` }}</h4>
                                    </v-col>
                                </v-row>
                                <CalculationRow 
                                    v-else
                                    :prefix="`${i + 1}.${j + 1}.`" 
                                    :key="`calculationRow${item.label}`" 
                                    :ref="`calculationRow${item.label}`"
                                    :item="item" 
                                    @openItemIntervalCalc="openItemIntervalCalculator"
                                    @compute="openComputedFieldDialog"
                                /> 
                            </template>
                            <v-row>
                                <v-col cols="12">
                                    <v-divider class="mb-1"></v-divider>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" class="text-right pr-9">
                                    <h4>
                                        ({{ section.sign }}) Zwischensumme: {{ formatCurrency(calculationResultMap[section.label]) }}€
                                        <span v-if="section.type == 'SECTION_MIN'">
                                            (Mindestens: {{ formatCurrency(section.min) }}€)
                                        </span>
                                        <span v-if="section.type == 'SECTION_MAX'">
                                            (Höchstens: {{ formatCurrency(section.max) }}€)
                                        </span>
                                        <span v-if="section.type == 'SECTION_EXPR'">
                                            (Mindestens: {{ formatCurrency(getMinMaxFromExpr(section.expr, calculationResultMap).min) }}€; Höchstens: {{ formatCurrency(getMinMaxFromExpr(section.expr, calculationResultMap).max) }}€)
                                        </span>
                                    </h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col sm="8"></v-col>
                                <v-col sm="4" class="text-right pr-9">
                                    <v-btn elevation="0" color="primary" style="width: 80%" @click="clickedNextButton()">
                                        Weiter
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels accordion class="mt-3" v-model="summaryPanel">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <b style="font-size: 14pt">Zusammenfassung</b>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" class="pr-9" v-for="(subsectionLabel, i) in calculationMetaDataMap.section[currentSection].subsections" :key="`result${i}`">
                                    <v-row>
                                        <v-col class="text-right" cols="10">
                                            <h4 style="display: inline;">
                                                {{ calculationMetaDataMap.subsection[subsectionLabel].schema.name }}: 
                                            </h4>
                                            ({{ calculationMetaDataMap.subsection[subsectionLabel].schema.sign }}) 
                                        </v-col>
                                        <v-col class="text-right">
                                            {{ formatCurrency(calculationResultMap[subsectionLabel]) }}€
                                        </v-col>
                                    </v-row>
                                    
                                </v-col>
                                <v-col cols="12">
                                    <v-divider class="mb-1"></v-divider>
                                    <v-divider></v-divider>
                                </v-col>
                                <!-- Overall Result of section -->
                                <v-col cols="12" class="text-right pr-9">
                                    <h4>
                                        <span v-if="calculationMetaDataMap.section[currentSection].schema.type == 'SECTION_MIN'">
                                            (Mindestens: {{ formatCurrency(calculationMetaDataMap.section[currentSection].schema.min ) }}€)
                                        </span>
                                        <span v-if="calculationMetaDataMap.section[currentSection].schema.type == 'SECTION_MAX'">
                                            (Höchstens: {{ formatCurrency(calculationMetaDataMap.section[currentSection].schema.max) }}€)
                                        </span>
                                        Summe: {{ formatCurrency(calculationResultMap[currentSection]) }}€
                                    </h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col sm="8"></v-col>
                                <v-col sm="4" class="text-center">
                                    <v-btn dark elevation="0" color="#B31342" style="width: 80%" @click="clickedNextSectionButton()">
                                        Weiter
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
import {
    navigationState,
    navigationMutations, 
    calculationContextState,
    calculatedSectionsState,
    calculatedSectionsMutations,
    calculationState,
    computedPropertiesState
} from "../../../store/sozialstaffel/CalculationState";

import { getMinMaxFromExpr } from "../../../store/sozialstaffel/sectionExpr";

import CalculationRow from "./CalculationRow.vue";
import ComputedFieldDialog from "./ComputedFieldDialog.vue";
import ItemIntervalDialog from "./ItemIntervalDialog.vue";

import { formatCurrency } from "../../../utils";

export default {
    name: "CalculationSectionDisplay",
    components: {
        CalculationRow,
        ComputedFieldDialog,
        ItemIntervalDialog
    },
    data() {
        return {
            panel: 1,
            summaryPanel: null,
            computedFieldDialog: false,
            computedFieldNames: [],
            reloadComputedFieldDialog: 0,
            reloadItemIntervalCalculator: 0,
            itemIntervalCalculatorDialog: false,
            itemIntervalCalculatorInitialValue: undefined,
            itemIntervalCalculatorItem: {},
            setValueFunction: null,
            optionalComputedField: false,
            computedFieldCloseInfoDialog: false
        };
    },
    methods: {
        clickedNextButton() {
            //add subsection to calculated sections
            calculatedSectionsMutations.addCalculatedSection(
                this.currentSubsection
            );

            if(this.currentSubsectionToDisplay < this.currentSectionToDisplay.sections.length - 1) {
                this.currentSubsectionToDisplay += 1;
                window.scrollTo({ top: 80 });
            } else {
                navigationMutations.setSectionWithSubsection(this.currentSection, null);
                this.summaryPanel = 0;
            }
        },
        clickedNextSectionButton() {
            //add top level sections to calculated sections
            const currentSectionIndex = this.calculationContext?.calculationFormula?.calcSchema?.findIndex((section) => section.label === this.currentSection);

            if(currentSectionIndex === this.calculationContext?.calculationFormula?.calcSchema.length - 1) {
                this.$emit("next");
                return;
            }

            const nextSection = this.calculationContext?.calculationFormula?.calcSchema[currentSectionIndex + 1];

            navigationMutations.setSectionWithSubsection(nextSection.label, nextSection.sections[0].label);

            this.summaryPanel = null;
        },
        clickedExpansionPanel(section) {
            //console.log(section);
        },
        closeComputedFieldDialog() {
            this.computedFieldDialog = false;
        },
        backComputedFieldDialog() {
            navigationMutations.setSectionWithSubsection(this.currentSection, null);
            this.closeComputedFieldDialog();
            this.computedFieldCloseInfoDialog = true;
        },
        openItemIntervalCalculator(item, value, setValue) {
            this.itemIntervalCalculatorInitialValue = value;
            this.itemIntervalCalculatorItem = item;
            this.setValueFunction = setValue;

            this.reloadItemIntervalCalculator += 1;
            this.itemIntervalCalculatorDialog = true;
        },
        closeItemIntervalCalculator(result)  {
            if(result) {
                this.setValueFunction(result);
            }

            this.itemIntervalCalculatorDialog = false;
            this.reloadItemIntervalCalculator += 1;
        },
        formatCurrency(num) {
            return formatCurrency(num);
        },
        getMinMaxFromExpr(expr, resultMap) {
            return getMinMaxFromExpr(expr, resultMap);
        },
        openComputedFieldDialog(computedProps, optional = false) {
            this.optionalComputedField = optional;
            this.computedFieldNames = computedProps.filter(prop => prop != 'simplehouseholdmembers');
            this.reloadComputedFieldDialog += 1;
            this.computedFieldDialog = true;
        }
    },
    mounted() {
        const computedProps = this.currentSectionToDisplay.sections[this.currentSubsectionToDisplay].computedPropertyNames;

        if(computedProps.length > 0 && !computedProps.every(prop => Object.keys(this.computedPropertiesResults).includes(prop))) {
            this.optionalComputedField = false;
            this.computedFieldNames = computedProps.filter(prop => prop != 'simplehouseholdmembers');
            this.reloadComputedFieldDialog += 1;
            this.computedFieldDialog = true;
        }
    },
    watch: {
        currentSection(newValue) {
            this.summaryPanel = null;
        },
        currentSubsection(newValue) {
            //dont procceed if null
            if(newValue === null) {
                return;
            }

            const computedProps = this.currentSectionToDisplay.sections[this.currentSubsectionToDisplay].computedPropertyNames;

            if(computedProps.length > 0 && !computedProps.every(prop => Object.keys(this.computedPropertiesResults).includes(prop))) {
                this.optionalComputedField = false;
                this.computedFieldNames = computedProps.filter(prop => prop != 'simplehouseholdmembers');
                this.reloadComputedFieldDialog += 1;
                this.computedFieldDialog = true;
            }
        },
    },
    computed: {
        currentSection() {
            return navigationState.currentSection;
        },
        currentSubsection() {
            return navigationState.currentSubsection;
        },
        calculationContext: function() {
            return calculationContextState.calculationContext;
        },
        calculationResultMap() {
            return calculationState.resultMap;
        },
        calculationMetaDataMap() {
            return calculationState.metaDataMap;
        },
        currentSectionToDisplay() {
            const sectionToDisplay = this.calculationContext?.calculationFormula?.calcSchema?.find((section) => section.label === this.currentSection);

            return sectionToDisplay? sectionToDisplay: null;
        },
        calculatedSections() {
            return calculatedSectionsState.calculatedSections;
        },
        computedPropertiesResults() {
            return computedPropertiesState.computedPropertyResults;
        },
        computedSchemas: function() {
            return computedPropertiesState.computedSchemas;
        },
        currentSubsectionToDisplay: {
            get: function() {
                if(this.currentSectionToDisplay) {
                    return this.currentSectionToDisplay.sections.findIndex((section) => section.label === this.currentSubsection);
                } else {
                    return null;
                }
            },
            set: function(val) {
                if(val === undefined) {
                    return;
                }

                if(this.currentSectionToDisplay) {
                    navigationMutations.setSectionWithSubsection(this.currentSection, this.currentSectionToDisplay.sections[val].label);
                }

                this.summaryPanel = null;
            }
        },
    }
}
</script>