import axios from "axios";

import appConfig from "../../../config";
import * as utils from "../../../utils";

const getRequestConfig = () => {
    return {
        headers: {
            sessiontoken: localStorage.getItem("token"),
        },
    };
}

export async function calculateComputedField(caseId, computedFieldName, startDate, endDate, inputs, currentComputedProperties){
    const requestConfig = getRequestConfig();

    const url = `${appConfig.apiURL}/sozialstaffel/computedfields/${computedFieldName}/${caseId}?startDate=${utils.formatDateForDateInput(startDate)}&endDate=${utils.formatDateForDateInput(endDate)}`;

    const body = {
        inputs: inputs,
        currentComputedProperties: currentComputedProperties
    }

    const computedPropResponse = await axios.post(url, body, requestConfig);

    return computedPropResponse.data;
}

export async function createCalculation(calculation) {
    const requestConfig = getRequestConfig();

    const url = `${appConfig.apiURL}/sozialstaffel/calculations`;

    const response = await axios.post(url, calculation, requestConfig);

    return response.data;
}

export async function updateCalculation(calculationId, calculation) {
    const requestConfig = getRequestConfig();

    const url = `${appConfig.apiURL}/sozialstaffel/calculations/${calculationId}`;

    const response = await axios.put(url, calculation, requestConfig);

    return response.data;
}