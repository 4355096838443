<template>
  <v-card class="py-4 d-flex">
    <v-avatar
      color="primary"
      v-bind="attrs"
      v-on="on"
      class=" mx-6"
      size="60"
    >
      <v-icon dark x-large>{{ icon }} </v-icon>
    </v-avatar>
    <div>
      {{ header }}
      <h1 class="font-weight-bold">{{ count }}</h1>
    </div>
  </v-card>
</template>

<script>
import appConfig from "../../config";
import axios from "axios";

export default {
  name: "SozialstaffelDashboardCard",
  props: ["icon", "header", "countURL", "route"],
  data() {
    return {
      count: 0,
    };
  },
  created() {
    const url = `${appConfig.apiURL}${this.countURL}`;

    const requestConfig = {
      headers: {
        sessiontoken: localStorage.getItem("token"),
      },
    };

    axios
      .get(url, requestConfig)
      .then((resp) => {
        this.count = resp.data.count;
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
  }
};
</script>