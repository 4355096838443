function stringifyDigit(val) {
    if(val < 10) {
        return `0${val}`;
    }

    return val;
}

function formatDate(val, minutes) {
    const date = new Date(val);

    let stringDate = `${stringifyDigit(date.getDate())}.${stringifyDigit(date.getMonth() + 1)}.${date.getFullYear()}`;

    if(minutes) {
        stringDate += ` ${stringifyDigit(date.getHours())}:${stringifyDigit(date.getMinutes())} Uhr`
    }

    return stringDate;
}

function formatDateForDateInput(val) {
    const date = new Date(val);

    return `${date.getFullYear()}-${stringifyDigit(date.getMonth() + 1)}-${stringifyDigit(date.getDate())}`;
}

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  const formatCurrency = (num, supressWarning=true) => {
    if(num == undefined) {
      if(!supressWarning){ console.log("tried to convert undefined via currency formatter"); }
      return;
    }

    const number = parseFloat(num);

    return number.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

module.exports = { formatDate, formatDateForDateInput, b64toBlob, formatCurrency }