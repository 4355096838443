<template>
    <div width="100%" class="mt-8">
        <v-dialog v-model="ackDialog" persistent max-width="600px">
            <ConfirmDialog title="Anfrage abgeschickt!"
                text="Die Anfrage wurde abgeschickt. Sie erhalten eine Bestätigung per E-Mail." action-label-continue="OK"
                :display-no-abort-button="true" v-on:continue="ackDialog = false" v-on:close="ackDialog = false" />
        </v-dialog>

        <v-dialog v-model="confirmSupDialog" persistent max-width="600px">
            <ConfirmDialog title="Aktivierung Support Zugang"
                text="Durch die Aktivierung des Support Zugangs erlauben Sie einem Support-Mitarbeiter der Verwaltungscloud.SH GmbH auf Ihr System zuzugreifen, um eine Fehlerdiagnose durchzuführen. Aktivieren Sie den Support Zugang bitte nur, wenn Sie dazu aufgefordert werden. Nach Abschluss der Fehlerdiagnose den Zugang bitte wieder deaktivieren."
                action-label-continue="Bestätigen" action-label-abort="Schließen" ref="confirmSupConfirmDialog"
                v-on:continue="confirmDialog(true)" :inverse-buttons="true" v-on:close="closeDialog(false)" />
        </v-dialog>
        <v-dialog v-model="cancelSupDialog" persistent max-width="600px">
            <ConfirmDialog title="Support Zugang verweigern?"
                text="Soll die Erteilung des Support Zugangs entzogen werden? Damit kann ein Support User keine Einblicke mehr ins System bekommen, um eine genaue Fehlerdiagnose erstellen zu können."
                action-label-continue="Bestätigen" action-label-abort="Schließen" ref="cancelSuptConfirmDialog"
                :inverse-buttons="true" v-on:continue="confirmDialog(false)" v-on:close="closeDialog(true)" />
        </v-dialog>

        <v-snackbar v-model="errorSnackbar" timeout="3000" color="red" dark :absolute="true" :top="true">
            {{ errorSnackbarText }}
        </v-snackbar>

        <div style="width: 100%; max-width: 800px; margin: auto">
            <v-card class="pb-8">
                <v-card-title>
                    <h2>Software Supportanfrage</h2>
                </v-card-title>
                <v-card-text class="pt-6">
                    Um die Supportanfrage bestmöglich zu bearbeiten, bitten wir Sie dazu den Zugang zu ihrem System zu ermöglichen.
                    <v-switch v-model="supSwitch" label="Support Zugang" @change="switchSupport(supSwitch)" hide-details
                        class="mb-4" v-if="currentUser.permissions.includes('config_supportaccess')"
                        :loading="supSwitchLoading" :disabled="supSwitchDisabled" />
                    <v-form ref="supportForm">
                        <v-row>
                            <v-col cols="12">
                                <v-alert type="info">
                                    Über dieses Formular können Sie eine Supportanfrage an uns stellen. Aus den Angaben wird
                                    automatisch ein Ticket erstellt, Sie erhalten eine Bestätigung per E-Mail.
                                    Alternativ können Sie uns auch eine E-Mail an <a
                                        href="mailto:support@verwaltungscloud.sh">support@verwaltungscloud.sh</a> schreiben.
                                </v-alert>
                            </v-col>
                            <v-col cols="12" style="margin-bottom: 0;">
                                <v-text-field label="Titel/Kurzbeschreibung" v-model="title" :rules="[rules.required]"
                                    outlined></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <quill-editor :content="commentEditorContent" :options="editorOptions"
                                    @change="onCommentEditorChange($event)" style="height: 300px; width: 100%" />
                            </v-col>
                            <v-col class="pt-9">
                                <v-btn color="primary" @click="submitRequest">Anfrage absenden</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

            </v-card>
        </div>
    </div>
</template>

<script>

import appConfig from "../../config";
import axios from "axios";

//import quill editor
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import { updateSupportAccess, getSupportAccess } from '../../utils/apiHelper.js';

import ConfirmDialog from "../../components/ConfirmDialog.vue";

export default {
    name: "SozialstaffelSupport",
    components: {
        quillEditor,
        ConfirmDialog
    },
    data() {
        return {
            editorOptions: {
                modules: {
                    toolbar: ['bold', 'italic', 'underline', 'strike']
                },
                placeholder: 'Ausführlicher Beschreibungstext...'
            },
            commentEditorContent: "",
            title: "",
            userData: {},
            ackDialog: false,
            rules: {
                required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
            },
            cancelSupDialog: false,
            confirmSupDialog: false,
            supSwitch: false,
            supSwitchLoading: true,
            supSwitchDisabled: true,
            errorSnackbar: false,
            errorSnackbarText: "",
            currentUser: {
                permissions: []
            },
        };
    },
    async mounted() {
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if (this.currentUser.permissions.includes('config_supportaccess')) {
            try {
                const resp = await getSupportAccess(localStorage.getItem("token"));
                this.supSwitch = resp.data.value.active;
                this.supSwitchLoading = false;
                this.supSwitchDisabled = false;
            } catch (e) {
                console.error(e);
            }
        }
    },
    methods: {
        onCommentEditorChange({ quill, html, text }) {
            this.commentEditorContent = html;
        },
        async submitRequest() {
            if (!this.$refs.supportForm.validate()) {
                return;
            }

            this.ackDialog = true;

            const url = `${appConfig.apiURL}/users/my`;

            const requestConfig = {
                headers: {
                    sessiontoken: localStorage.getItem("token"),
                },
            };

            try {
                const resp = await axios.get(url, requestConfig);

                const userData = resp.data;

                const supportURL = "https://n8n.tools.verwaltungscloud.dev/webhook/93fe6f98-8194-4e9b-bbf2-9b545cec945a";

                const supportData = {
                    title: this.title,
                    description: this.commentEditorContent,
                    from: userData.email
                };

                const supportConfig = {
                    headers: {
                        Authorization: "Mm*7*iv`%0`6r3VPi2A7qp6E5&-G*u",
                    },
                };

                const supportResp = await axios.post(supportURL, supportData, supportConfig);

                this.commentEditorContent = "";
                this.title = "";

                console.log(this.$refs);

                this.$refs.supportForm.resetValidation();
            } catch (e) {
                console.log(e);
            }
        },
        setSupportAccess: async function (active) {
            try {
                await updateSupportAccess(localStorage.getItem("token"), active);
            } catch (e) {
                console.error(e)
                this.supSwitch = !active;
                this.showSnackbar("Fehler bei der Änderung des Support Zugangs");
            } finally {
                this.supSwitchDisabled = false
                this.supSwitchLoading = false
            }
        },
        switchSupport: function (on) {
            if (on) {
                this.confirmSupDialog = true
            } else {
                this.cancelSupDialog = true
            }
        },
        confirmDialog: async function (on) {
            this.supSwitchDisabled = true
            this.supSwitchLoading = true
            await this.setSupportAccess(on)
            if (on) {
                this.confirmSupDialog = false
            } else {
                this.cancelSupDialog = false
            }
        },
        closeDialog: function (on) {
            console.log(on)
            this.supSwitch = on
            if (on) {
                this.cancelSupDialog = false
            } else {
                this.confirmSupDialog = false
            }
        },

        showSnackbar(text) {
            this.errorSnackbar = true;
            this.errorSnackbarText = text;
        },
    }
};
</script>

<style lang="css" scoped>
.ql-container {
    font-size: 20px;
}
</style>
  