<template>
    <v-main
      class="mt-3"
      style="background-color: #ffffff; width: 100vw; height: 95vh"
    >
      <v-app-bar app elevation="1" color="white">
        <v-btn color="primary" elevation="0" @click="back()" outlined>
          <v-icon>mdi-arrow-left-thick</v-icon>
          Zurück zum Launchpad
        </v-btn>
      </v-app-bar>
        <v-snackbar
          v-model="errorSnackbar"
          timeout="3000"
          color="red"
          dark
          :absolute="true"
          :top="true"
        >
          {{ errorSnackbarText }}
        </v-snackbar>
        <v-snackbar
          v-model="successSnackbar"
          timeout="3000"
          color="blue"
          dark
          :absolute="true"
          :top="true"
        >
          {{ successSnackbarText }}
        </v-snackbar>
        <div>
          <v-tabs v-model="activeTab">
            <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact>
                <v-icon>{{ tab.icon }}</v-icon>
                &nbsp;{{ tab.name }}
            </v-tab>
          </v-tabs>
        <router-view></router-view>
        </div>
    </v-main>
  </template>
  <script>
  import axios from "axios";
  
  import appConfig from "../../config";
  
  export default {
    name: "KITABilling",
    data() {
      return {
        //snackbars
        errorSnackbar: false,
        errorSnackbarText: "",
        successSnackbar: false,
        successSnackbarText: "",
        rules: {
          required: (value) => !!value || "Pflichtfeld, bitte ausfüllen"
        },
        tabs: [ 
            { name: "Dashboard", route: "/kitabilling/dashboard", icon: "mdi-view-dashboard" },
            { name: "KITAS", route: "/kitabilling/kita", icon: "mdi-home" },
            { name: "Abrechnungen", route: "/kitabilling/billingruns", icon: "mdi-abacus" },
        ]
      }
    },
    methods: {
      /*
       * common
       */
      back() {
        this.$router.push("/launchpad");
      },
      showSnackbar(type, text) {
        if (type == "ERROR") {
          this.errorSnackbar = true;
          this.errorSnackbarText = text;
        } else {
          this.successSnackbar = true;
          this.successSnackbarText = text;
        }
      },
      
    }
  };
  </script>