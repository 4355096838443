import axios from 'axios';
import _ from 'lodash';
import appConfig from '../config.js';

axios.defaults.baseURL = appConfig.apiURL

export default axios;

export const updateSupportAccess = async (sessionToken, active) => {
    const data = {
        value: {
            active: active
        }
    };

    const response = await axios.put('/settings/tenant/supportaccess', data, requestConfig(sessionToken));
    return response;

};

export const getSupportAccess = async (sessionToken) => {
    const response = await axios.get('/settings/tenant/supportaccess', requestConfig(sessionToken));
    return response;
}

export const requestConfig = (sessionToken) => {
    const config = {
        headers: {
            sessiontoken: sessionToken,
        },
    };
    return config
}