<template>
  <v-row>
    <v-col sm="8" class="ml-5 mt-6">
      <v-text-field
        v-if="item.type == 'ITEM_WILDCARD'"
        :disabled="item.disabled"
        :label="item.name"
        style="float: left; width: 400px; margin-top: 0; padding-top: 0"
        @change="changedWildcardFieldName"
        v-model="wildcardFieldName"
        class="mr-3"
      >
        <span slot="append"> (ggf. eingeben) </span>
      </v-text-field>
      <span v-else class="pt-8">{{ `${prefix} ${item.name}` }}</span>
      <span v-if="item.min"> (Minimal: {{ formatCurrency(item.min) }}€) </span>
      <span v-if="item.max"> (Maximal: {{ formatCurrency(item.max) }}€) </span>
      <v-tooltip top v-if="item.computedPropertyName">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="primary">
            mdi-calculator-variant
          </v-icon>
        </template>
        <span>Dieses Feld wird automatisch berechnet und ist deshalb gesperrt</span>
      </v-tooltip>
      <v-tooltip top v-if="item.derivedFromCaseData">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="primary"> mdi-folder-account </v-icon>
        </template>
        <span
          >Dieses Feld wurde automatisch auf Basis der Angaben im Fall eingesetzt</span
        >
      </v-tooltip>
      <v-tooltip top v-if="item.comment">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="primary"> mdi-information </v-icon>
        </template>
        <span v-html="item.comment"></span>
      </v-tooltip>
    </v-col>
    <v-col class="mr-5 mb-5">
      <v-text-field
        type="number"
        v-model="inputValue"
        @change="changedField"
        @focus="focusedField"
        :disabled="item.disabled"
      >
        <v-icon slot="prepend" color="primary">
          {{ item.sign == "-" ? "mdi-minus" : "mdi-plus" }}
        </v-icon>
        <v-icon slot="append" color="primary"> mdi-currency-eur </v-icon>
      </v-text-field>
      <div width="100%" class="text-center" v-if="item.displayCalculator">
        <v-btn color="primary" elevation="0" outlined @click="openComputedFieldDialog()">
          <v-icon class="pr-3"> mdi-calculator </v-icon> Berechnen
        </v-btn>
      </div>
    </v-col>
    <v-col sm="1" class="pt-8" v-if="item.showIncomeIntervalCalculator">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="openItemIntervalCalculator()"
          >
            mdi-abacus
          </v-icon>
        </template>
        <span>Monatliche Summe berechnen</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>
<script>
import {
  calculationState,
  calculationMutations,
  calculatedSectionsMutations,
} from "../../../store/sozialstaffel/CalculationState";
import { formatCurrency } from "../../../utils";

export default {
  name: "CalculationRow",
  props: ["prefix", "item"],
  data() {
    return {
      inputValue: "0.00",
      wildcardFieldName: "",
    };
  },
  mounted() {
    this.inputValue = parseFloat(this.resultMap[this.item.label]).toFixed(2);

    if (this.item.type == "ITEM_WILDCARD") {
      this.wildcardFieldName = this.wildcardMap[this.item.label];
    }
  },
  methods: {
    focusedField() {
      if (this.inputValue == "0.00") {
        this.inputValue = "";
      }
    },
    changedField() {
      if (this.inputValue == "") {
        calculationMutations.setItemValue(this.item.label, 0);
      } else {
        //mutate store
        calculationMutations.setItemValue(this.item.label, parseFloat(this.inputValue));

        //make this section finished
        calculatedSectionsMutations.addCalculatedSectionBasedOnItem(this.item.label);
      }

      this.inputValue = parseFloat(calculationState.resultMap[this.item.label]).toFixed(
        2
      );
    },
    setValue(val) {
      this.inputValue = val;
      this.changedField();
    },
    openItemIntervalCalculator() {
      this.$emit("openItemIntervalCalc", this.item, this.inputValue, this.setValue);
    },
    formatCurrency(num) {
      return formatCurrency(num);
    },
    openComputedFieldDialog() {
      this.$emit("compute", [this.item.computedPropertyName], true);
    },
    changedWildcardFieldName() {
      calculationMutations.setWildcardMapItem(this.item.label, this.wildcardFieldName);
    },
  },
  computed: {
    resultMap() {
      return calculationState.resultMap;
    },
    wildcardMap() {
      return calculationState.wildcardMap;
    },
  },
  watch: {
    resultMap: {
      handler: function (val) {
        this.inputValue = parseFloat(val[this.item.label]).toFixed(2);
      },
      deep: true,
    },
    wildcardMap: {
      handler: function (val) {
        this.wildcardFieldName = val[this.item.label];
      },
      deep: true,
    },
  },
};
</script>
