<template>
    <div>
      <v-snackbar
        v-model="snackbar"
        timeout="3000"
        :color="snackBarType == 'ERROR' ? 'red' : 'blue'"
        dark
        :absolute="true"
        :top="true"
      >
        {{ snackbarText }}
      </v-snackbar>

      <v-dialog v-model="applicationImportForm" persistent max-width="800px">
        <ApplicationImportForm
            :application-data="application"
            :key="reloadImportForm"
            v-on:close="closeImportForm"
            v-on:import="openImportCaseNewDialog"
            v-on:importexist="openImportCaseExistDialog"
        />
      </v-dialog>

      <!-- Begin Confirm downloadAttachments Dialog -->
    <v-dialog v-model="downloadAttachmentsDialog" persistent max-width="600px">
        <ConfirmDialog
          title="Anhänge herunterladen"
          text="Die Anhänge (Nachweise und angehängte Dokumente) werden als ZIP-Datei heruntergeladen. Möchten Sie fortfahren?"
          action-label-continue="Herunterladen"
          action-label-abort="Schließen"
          ref="downloadAttachmentsDocumentConfirmDialog"
          v-on:continue="downloadAttachments(true)"
          v-on:close="downloadAttachmentsDialog = false"
        />
    </v-dialog>
    <!-- End Confirm downloadAttachments Dialog -->

    <v-dialog v-model="importDialogExist" persistent max-width="600px">
        <v-card>
            <v-card-title style="text-align: left">
                Antrag in bestehenden Fall importieren
            </v-card-title>
            <v-card-text v-if="importExistLoading">
                <span style="font-size: 12pt; text-align: left">Bitte warten ...</span>
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <v-form ref="caseExistForm">
                    <v-row>
                        <v-col cols="8">
                            <v-select
                                :items="duplicateCasesToSelect"
                                v-model="selectedCase"
                                label="Bestehenden Fall auswählen*"
                                required
                                :rules="[rules.required]"
                            ></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-tooltip top v-if="selectedCase">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        large
                                        v-bind="attrs"
                                        v-on="on"
                                        class="hover-pointer"
                                        @click="openCaseInNewTab()"
                                    >
                                        mdi-open-in-new
                                    </v-icon>
                                </template>
                                <span>In neuem Tab öffnen</span>
                            </v-tooltip>  
                        </v-col>
                    </v-row>                  
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="closeImportCaseExistDialog" text color="red">Abbrechen</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="importCaseExist" text>Importieren</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="importDialogNew" persistent max-width="600px">
        <v-card>
            <v-card-title style="text-align: left">
                Antrag als neuen Fall importieren
            </v-card-title>
            <v-card-text v-if="importNewLoading">
                <span style="font-size: 12pt; text-align: left">Bitte warten ...</span>
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <v-form ref="referenceForm">
                    <v-text-field
                        label="Aktenzeichen*"
                        v-model="importNewReference"
                        required
                        :rules="[rules.required]"
                    >
                    <v-icon
                        slot="prepend"
                        >
                            mdi-file-account-outline
                        </v-icon>
                    </v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="closeImportCaseNewDialog" text color="red">Abbrechen</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="importCaseNew" text>Importieren</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

      <!-- End Confirm finish Dialog -->
        <v-row style="margin-bottom: 0; padding-bottom: 0;">
            <v-col cols="12" style="margin-bottom: 0; padding-bottom: 0;">
              <v-card class="pa-7" style="margin-bottom: 0;">
              <v-row>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon x-large v-bind="attrs" v-on="on" class="hover-pointer" @click="goback()"> mdi-arrow-left </v-icon>
                  </template>
                  <span>Zurück</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <div v-if="!pdfloading">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            class="icon-pointer mx-8"
                            @click="openImportForm()"
                        >
                            <v-icon dark> mdi-import </v-icon>
                        </v-avatar>
                        </template>
                        <span>Antrag importieren</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            class="icon-pointer mx-8"
                            @click=""
                        >
                            <v-icon dark> mdi-file-document-remove </v-icon>
                        </v-avatar>
                        </template>
                        <span>Antrag Unwirksam</span>
                    </v-tooltip>
                    <v-tooltip top v-if="application.numberOfAttachments != 0">
                        <template v-slot:activator="{ on, attrs }">
                          
                          <v-avatar
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              class="icon-pointer mx-8"
                              @click="downloadAttachmentsDialog = true"
                          >
                            <v-icon dark> mdi-attachment </v-icon>
                          </v-avatar>
                        </template>
                        <span>Anhänge herunterladen</span>
                    </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        class="icon-pointer mx-8"
                        @click="printLetter()"
                      >
                        <v-icon dark> mdi-printer </v-icon>
                      </v-avatar>
                    </template>
                    <span>Antrag drucken</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        class="icon-pointer mx-8"
                        @click="downloadLetter()"
                      >
                        <v-icon dark> mdi-download </v-icon>
                      </v-avatar>
                    </template>
                    <span>Antrag herunterladen</span>
                  </v-tooltip>
                </div>
              </v-row>
            </v-card>
            </v-col>
        </v-row>
        <v-row style="margin-top: 0; padding-top: 0;" v-if="pdfloading">
            <v-col cols="12" align="center">
                <v-card class="mt-9" width="30%">
                  <v-card-title style="text-align: left">
                      Lade Dokument ...
                  </v-card-title>
                  <v-card-text>
                      <span style="font-size: 12pt; text-align: left">Dies kann einige Sekunden dauern</span>
                      <v-progress-linear
                          indeterminate
                          color="primary"
                      ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-col>
          </v-row>
          <v-row style="margin-top: 0; padding-top: 0;" v-else>
            <v-col cols="9" md="9" sm="12" align="center" style="margin-top: 0; padding-top: 0;">
              <!--<v-alert type="info" style="margin-bottom: 0" class="py-6" v-if="application.splitGroupUUID">
                Für diesen Antrag wurde aufgrund der Angaben des Antragstellers zwei Anträge erstellt
              </v-alert>-->
              <iframe id="pdfiframe" :src="`${blobUrl}#toolbar=0&navpanes=0&zoom=110`" style="width: 100%;z-index:2; height: 85vh; margin-top: 0;" frameborder="0">
                  Oops! an error has occurred.
              </iframe>    
            </v-col>
            <v-col cols="3" md="3" sm="12" style="margin-top: 0; padding-top: 0;" class="pr-5">
                <v-card class="mr-2 mt-2">
                  <v-alert type="error" color="orange" v-if="application.numberOfAttachments == 0">
                    Dem Onlineantrag sind keinerlei Nachweise beigefügt
                  </v-alert>
                </v-card>
                <v-card class="mr-2 mt-2">
                    <v-card-title>Onlineantrag</v-card-title>
                    <v-card-text v-if="this.application.applicationData">
                        <h3>Antragstyp: {{ getFormattedApplicationType(this.application.applicationData.type) }}</h3>
                    </v-card-text>
                    <v-card-text v-if="this.application.caseData">
                        <h3>Erster Antragsteller: {{ `${this.application.caseData.primaryApplicant.formOfAddress} ${this.application.caseData.primaryApplicant.firstName} ${this.application.caseData.primaryApplicant.lastName}` }}</h3>
                        <h3>Anzahl Kinder: {{ this.application.caseData.children.length }}</h3>
                        <h3>Sozialleistungen: {{ getSocialBenefitsLabel(this.application.caseData.receivingSocialBenefits) }}</h3>
                        <br />
                        <h3>Antrag erstellt: {{ formatDate(this.application.createdAt, true) }}</h3>
                    </v-card-text>
                </v-card>
                <!--
                <v-card v-if="application.splitGroupUUID" class="mt-3">
                  <v-card-actions>
                    <v-btn color="primary" elevation="0" width="100%">Zusammenhängenden Antrag anzeigen</v-btn>
                  </v-card-actions>
                </v-card>-->
            </v-col>
        </v-row>
    </div>
  </template>
  
  <style lang="css" scoped>
  .icon-pointer >>> :hover {
    cursor: pointer;
  }
  .hover-pointer {
    cursor: pointer;
  }
  </style>
  <script>
  import appConfig from "../config";
  import utils from "../utils";
  import axios from "axios";
  import ConfirmDialog from "../components/ConfirmDialog.vue";
  import ApplicationImportForm from "../components/sozialstaffelOZG/CaseImportDialog.vue";
  
  import pdf from 'pdfvuer';
  
  export default {
    name: "SozialstaffelDocumentDetail",
    components: {
        pdf,
        ConfirmDialog,
        ApplicationImportForm
    },
    data() {
      return {
        documentID: '',
        pdfdata: undefined,
        pdfloading: true,
        application: {},
        blobUrl: "",
        docType: "letter",
        downloadAttachmentsDialog: false,
        applicationImportForm: false,
        statics: {},
        reloadImportForm: 0,
        importDialogExist: false,
        importDialogNew: false,

        importNewReference: "",
        importNewLoading: false,

        importExistLoading: false,
        duplicateCasesToSelect: [],
        selectedCase: null,
  
        rules: {
            required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
        },

        /* snackbar */
        snackbarText: "",
        snackBarType: "SUCCESS",
        snackbar: false
      };
    },
    async mounted() {
        if (!this.$router.currentRoute.query?.id) {
          this.showSnackbar("ERROR", "ID Parameter ist nicht gesetzt");
          return;
        }

        this.statics = await this.fetchStatics();
  
        this.documentID = this.$router.currentRoute.query?.id;
  
        this.getDocumentPDF();
        this.getApplicationData();
    },
    methods: {
      goback: function() {
        this.$router.back();
      },
      formatDate(d, withTime) {
        return utils.formatDate(d, withTime);
      },
      formatCurrency(v) {
        return parseFloat(v).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      },

      getFormattedApplicationType(applicationType) {
        return Object.keys(this.statics.applicationTypes).filter(
            (key) => this.statics.applicationTypes[key] == applicationType
        )[0];
        },
  
        /* Utility Functions */
        getSocialBenefitsLabel(num) {
        for (let k of Object.keys(appConfig.socialBenefitsMapping)) {
            if (appConfig.socialBenefitsMapping[k] == num) {
                return k;
            }
        }
        },

      /* Utils */
      showSnackbar(type, text) {
        if (type == "ERROR") {
          this.snackBarType = "ERROR";
          this.snackbarText = text;
        } else {
          this.snackBarType = "SUCCESS";
          this.snackbarText = text;
        }
  
        this.snackbar = true;
      },
  
      fetchStatics: async function() {
        const requestConfig = {
          headers: {
            sessiontoken: localStorage.getItem("token"),
          },
        };
  
        const letterTypeMappingURL = `${appConfig.apiURL}/static/mappings/lettertypemapping`;
        const letterResultTypeMappingURL = `${appConfig.apiURL}/static/mappings/letterresulttypemapping`;
        const applicationTypeMappingURL = `${appConfig.apiURL}/static/mappings/applicationtypemapping`;
  
        const [
                letterTypeMappingResponse, letterResultTypeMappingResponse,
                applicationTypeMappingResponse
            ] = await Promise.all([
          axios.get(letterTypeMappingURL, requestConfig),
          axios.get(letterResultTypeMappingURL, requestConfig),
          axios.get(applicationTypeMappingURL, requestConfig)
        ]);
  
        return {
          letterTypeMapping: letterTypeMappingResponse.data,
          letterResultTypeMapping: letterResultTypeMappingResponse.data,
          applicationTypes: applicationTypeMappingResponse.data
        };
      },

      openImportForm() {
        this.reloadImportForm += 1;
        this.applicationImportForm = true;
      },

      closeImportForm() {
        this.applicationImportForm = false;
        this.reloadImportForm += 1;
      },
  
      printLetter() {
          const iframeWindow = document.getElementById("pdfiframe").contentWindow;
          
          iframeWindow.print();
      },
  
      downloadLetter() {
          let fileName = `Antrag.pdf`;
  
          const downloadLink = document.createElement("a");
          
          downloadLink.href = this.pdfdata;
          downloadLink.download = fileName;
          downloadLink.click();
      },

      async downloadAttachments(setLoading) {
        if(this.application.numberOfAttachments == 0) {
          return;
        }

        if(setLoading) {
            this.$refs.downloadAttachmentsDocumentConfirmDialog.setLoadingState(true);
        }
        
  
          const requestConfig = {
              headers: {
                  sessiontoken: localStorage.getItem("token"),
              },
          };
  
          let url = `${appConfig.apiURL}/sozialstaffel/ozgapplications/${this.$router.currentRoute.query.id}/attachments`;
  
          try {
              const attachmentResponse = await axios.get(url, requestConfig);
  
              this.attachmentdata = `data:application/x-zip-compressed;base64, ${attachmentResponse.data.base64}`;
  
              const blob = utils.b64toBlob(attachmentResponse.data.base64, 'application/x-zip-compressed');
              const blobUrl = URL.createObjectURL(blob);
  
              this.blobUrl = blobUrl;
  
          } catch(e) {
              this.showSnackbar("ERROR", "Beim Laden des Dokuments ist ein Fehler aufgetreten")
          }

        if(setLoading) {
            this.$refs.downloadAttachmentsDocumentConfirmDialog.setLoadingState(false);
        }
        
        this.downloadAttachmentsDialog = false;
      },
  
      async getDocumentPDF() {
          this.pdfloading = true;
  
          const requestConfig = {
              headers: {
                  sessiontoken: localStorage.getItem("token"),
              },
          };
  
          let url = `${appConfig.apiURL}/sozialstaffel/ozgapplications/${this.$router.currentRoute.query.id}/userinputs`;
  
          try {
              const pdfResponse = await axios.get(url, requestConfig);
  
              this.pdfdata = `data:application/pdf;base64, ${pdfResponse.data.base64}#toolbar=0&navpanes=0`;
  
              const blob = utils.b64toBlob(pdfResponse.data.base64, 'application/pdf');
              const blobUrl = URL.createObjectURL(blob);
  
              this.blobUrl = blobUrl;
  
              this.pdfloading = false;
          } catch(e) {
              this.showSnackbar("ERROR", "Beim Laden des Dokuments ist ein Fehler aufgetreten")
          }
      },
      async getApplicationData() {
        const requestConfig = {
              headers: {
                  sessiontoken: localStorage.getItem("token"),
              },
          };
  
          let url = `${appConfig.apiURL}/sozialstaffel/ozgapplications/${this.$router.currentRoute.query.id}`;
  
          try {
              const response = await axios.get(url, requestConfig);

              this.application = response.data;

              console.log(this.application)
          } catch(e) {
            this.showSnackbar("ERROR", "Beim Laden des Dokuments ist ein Fehler aufgetreten")
          }
      },
      openImportCaseExistDialog(casesToSelect) {
        this.closeImportForm();
        this.duplicateCasesToSelect = casesToSelect.map((c) => {
          return {
            text: `${c.primaryApplicant.formOfAddress} ${c.primaryApplicant.firstName} ${c.primaryApplicant.lastName}`,
            value: c._id
          }
        });
        this.importDialogExist = true;
      },

      openCaseInNewTab() {
        let route = this.$router.resolve(`/sozialstaffel/cases/detail?id=${this.selectedCase}`);
        window.open(route.href, '_blank');
      },

      closeImportCaseExistDialog() {
        this.$refs.caseExistForm?.resetValidation();
        this.importDialogExist = false;
        this.selectedCase = null;
        this.importExistLoading = false;
      },

      async importCaseExist() {
        if(!this.$refs.caseExistForm.validate()) {
            return;
        }

        this.importExistLoading = true;

        const requestConfig = {
            headers: {
              sessiontoken: localStorage.getItem("token"),
            },
        };
  
        let url = `${appConfig.apiURL}/sozialstaffel/ozgapplications/${this.$router.currentRoute.query.id}/import`;
  
        try {
            const data = {
                caseId: this.selectedCase
            }

            const response = await axios.post(url, data, requestConfig);

            await this.downloadAttachments(false);

            this.$router.push(`/sozialstaffel/cases/detail?id=${response.data.case._id}`);
        } catch(e) {
            this.closeImportCaseExistDialog()
            this.showSnackbar("ERROR", "Beim Laden des Dokuments ist ein Fehler aufgetreten")
        }
      },

      openImportCaseNewDialog() {
        this.closeImportForm();
        this.importDialogNew = true;
      },

      closeImportCaseNewDialog() {
        this.$refs.referenceForm?.resetValidation();
        this.importNewReference = "";
        this.importDialogNew = false;
        this.importNewLoading = false;
      },

      async importCaseNew() {
        if(!this.$refs.referenceForm.validate()) {
            return;
        }

        this.importNewLoading = true;

        const requestConfig = {
            headers: {
              sessiontoken: localStorage.getItem("token"),
            },
        };
  
        let url = `${appConfig.apiURL}/sozialstaffel/ozgapplications/${this.$router.currentRoute.query.id}/import`;
  
        try {
            const data = {
                reference: this.importNewReference
            }

            const response = await axios.post(url, data, requestConfig);

            await this.downloadAttachments(false);

            this.$router.push(`/sozialstaffel/cases/detail?id=${response.data.case._id}`);
        } catch(e) {
            this.closeImportCaseNewDialog()
            this.showSnackbar("ERROR", "Beim Laden des Dokuments ist ein Fehler aufgetreten")
        }
      }
    },
  };
  </script>