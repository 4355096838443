<template>
  <div class="mx-9 mt-8">
    <v-snackbar
          v-model="errorSnackbar"
          timeout="3000"
          color="red"
          dark
          :absolute="true"
          :top="true"
        >
          {{ errorSnackbarText }}
        </v-snackbar>
        <v-snackbar
          v-model="successSnackbar"
          timeout="3000"
          color="blue"
          dark
          :absolute="true"
          :top="true"
        >
          {{ successSnackbarText }}
        </v-snackbar>
    <!-- Begin Edit Dialog -->
    <v-dialog
      v-model="daycareDialog"
      width="800"
      persistent
    >
        <v-card>
            <v-card-title style="font-size: 16pt;">
                <span v-if="daycareDialogMode == 'CREATE'">KITA anlegen</span>
                <span v-else>KITA aktualisieren</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="daycareDialogForm">
                    <v-row>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                v-model="daycareDialogData.name"
                                label="Name"
                                prepend-inner-icon="mdi-home"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field
                                v-model="daycareDialogData.email"
                                label="Email"
                                prepend-inner-icon="mdi-at"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                v-model="daycareDialogData.iban"
                                label="IBAN"
                                prepend-inner-icon="mdi-credit-card"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="red" text @click="closeDaycareDialog()">Abbrechen</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="saveDaycare()">Speichern</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- End Edit Dialog -->
    <!-- Begin Detail Dialog -->
    <v-dialog
      v-model="daycareDetailDialog"
      width="800"
    >
        <v-card>
            <v-card-title style="font-size: 16pt;">
                {{ daycareDetail.name }} <v-spacer></v-spacer> 
                <v-btn icon @click="daycareDetailDialogEdit()"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn icon @click="daycareDetailDialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12" md="6">
                        <v-card elevation="0" style="font-size: 14pt;" class="pa-4">
                            <v-avatar
                                color="primary"
                                size="40"
                            >
                                <v-icon dark>mdi-at</v-icon>
                            </v-avatar>
                            &nbsp;&nbsp;
                            <b>Email:</b> <br/><br/>
                            <a :href="`mailto:${daycareDetail.email}`">{{ daycareDetail.email }}</a>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-card elevation="0" style="font-size: 14pt;" class="pa-4">
                            <v-avatar
                                color="primary"
                                size="40"
                            >
                                <v-icon dark>mdi-folder-plus</v-icon>
                            </v-avatar>
                            &nbsp;&nbsp;
                            <b>Erstellt am:</b> <br/><br/>
                            {{ formatDate(daycareDetail.createdAt) }}
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-card elevation="0" style="font-size: 14pt;" class="pa-4">
                            <v-avatar
                                color="primary"
                                size="40"
                            >
                                <v-icon dark>mdi-credit-card</v-icon>
                            </v-avatar>
                            &nbsp;&nbsp; <b>IBAN:</b> <br/><br/>
                            {{ daycareDetail.iban }}
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- End Detail Dialog -->
    <v-row>
      <v-col cols="12" lg="3" md="4" sm="6" xs="12">
         <v-text-field
          v-model="daycareSearch"
          @change="enteredSearch()"
         >
          <v-icon
            slot="prepend"
            color="primary"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" lg="3" md="3" sm="3" xs="12" style="text-align: right;">
        <v-btn color="primary" elevation="0" @click="openDaycareDialogCreate()">
            KITA anlegen
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="data"
      :options.sync="options"
      :server-items-length="count"
      :loading="loading"
      @click:row="handleRowClick"
      loading-text="Lade Daten..."
      item-key="_id"
      class="elevation-0 row-pointer"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20, 25],
        'items-per-page-text': 'Einträge pro Seite',
      }"
    >
      <template v-slot:no-data>
        Keine KITAs vorhanden
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-2"
              @click.stop="editDaycare(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 12pt !important;
}

.v-data-table.v-data-table.v-data-table >>> th {
  font-size: 14pt !important;
}
</style>

<script>
  import axios from "axios";
  import appConfig from "../../config";
  import utils from "../../utils";

export default {
  name: 'Kita',
  data() {
    return {
      //snackbars
      errorSnackbar: false,
      errorSnackbarText: "",
      successSnackbar: false,
      successSnackbarText: "",
      count: 0,
      data: [],
      loading: true,
      options: {},
      daycareSearch: "",
      daycareDetailDialog: false,
      daycareDialog: false,
      daycareDetail: {},
      daycareDialogData: {},
      daycareDialogMode: "CREATE",
      headers: [
        {
          text: "KITA",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
        { text: "", value: "actions", sortable: false },
      ],
      rules: {
          required: (value) => !!value || "Pflichtfeld, bitte ausfüllen"
        },
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    showSnackbar(type, text) {
        if (type == "ERROR") {
          this.errorSnackbar = true;
          this.errorSnackbarText = text;
        } else {
          this.successSnackbar = true;
          this.successSnackbarText = text;
        }
    },
    getDataFromApi() {
      this.loading = true;
      this.fetchData().then((data) => {
        this.data = data.data;
        this.count = data.count;
        this.loading = false;
      });
    },
    fetchData() {
      return new Promise(async (resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        const skip = (page - 1) * itemsPerPage;
        const top = itemsPerPage;
        const search = this.daycareSearch != ""? this.daycareSearch: undefined;

        const resp = await this.getDaycares(skip, top, search);

        let index = 0;

        const data = resp.data.map((d) => {
          let res = d;
          

          res.key = d._id;
          res.index = index;
          res.formattedCreatedAt = utils.formatDate(d.createdAt, true);

          index += 1;

          return res;
        });

        const count = resp.count;

        resolve({ data, count });
      });
    },
    formatDate(d) {
        return utils.formatDate(d, false);
    },
    async getDaycares(skip, top, search) {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      let dataUrl = `${appConfig.apiURL}/kitabilling/daycares?$skip=${skip}&$top=${top}`;

      if(search) {
        dataUrl += `&$search=${search}`;
      }

      try {
        const dataResp = await axios.get(dataUrl, requestConfig);

        return {
          data: dataResp.data.data,
          count: dataResp.data.count,
        };
      } catch (e) {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log(e);
        }

        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },
    enteredSearch() {
      this.getDataFromApi();
    },
    openDaycareDialogCreate() {
        this.daycareDialogMode = "CREATE";
        this.daycareDialogData = {};
        this.daycareDialog = true;
    },
    editDaycare(daycare) {
        this.daycareDialogMode = "EDIT";
        this.daycareDialogData = daycare;
        this.daycareDialog = true;
    },
    daycareDetailDialogEdit() {
        this.daycareDetailDialog = false;
        this.editDaycare({ ...this.daycareDetail });
    },
    handleRowClick(row) {
        this.daycareDetail = { ...row };
        this.daycareDetailDialog = true;
    },
    closeDaycareDialog() {
        this.daycareDialog = false;
        this.daycareDialogData = {};
    },
    async saveDaycare() {
        if(!this.$refs.daycareDialogForm.validate()) {
            return;
        }

        if(this.daycareDialogMode == "CREATE") {
            await this.createDaycare();
        } else {
            await this.updateDaycare();
        }

        this.closeDaycareDialog();
    },
    async createDaycare() {
        const requestConfig = {
            headers: {
                sessiontoken: localStorage.getItem("token"),
            },
        };

      let dataUrl = `${appConfig.apiURL}/kitabilling/daycares`;

      try {
        const data = {
            name: this.daycareDialogData.name,
            email: this.daycareDialogData.email,
            iban: this.daycareDialogData.iban
        };

        const dataResp = await axios.post(dataUrl, data, requestConfig);

        this.showSnackbar("SUCCESS", "KITA erfolgreich erstellt");
        await this.getDataFromApi();
      } catch (e) {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log(e);
        }

        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    },
    async updateDaycare() {
        const requestConfig = {
            headers: {
                sessiontoken: localStorage.getItem("token"),
            },
        };

      let dataUrl = `${appConfig.apiURL}/kitabilling/daycares/${this.daycareDialogData._id}`;

      try {
        const data = {
            name: this.daycareDialogData.name,
            email: this.daycareDialogData.email,
            iban: this.daycareDialogData.iban
        };

        const dataResp = await axios.put(dataUrl, data, requestConfig);

        this.showSnackbar("SUCCESS", "KITA erfolgreich aktualisiert");
        this.getDataFromApi();
      } catch (e) {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log(e);
        }

        this.showSnackbar("ERROR", appConfig.defaultErrorText);
      }
    }
  }
}
</script>
