<template>
    <v-card>
        <v-card-title>
            {{ formTitle }}
        </v-card-title>
        <v-card-text>
            <v-form ref="docrequestForm">
                <v-row>
                    <v-col md="6">
                        <v-select
                            label="Antrag auswählen*"
                            v-model="formData.application"
                            :items="availableApplications"
                            :rules="[rules.required]"
                        >
                        </v-select>
                    </v-col>
                    <v-col md="6">
                        <v-text-field
                            label="Datum des Dokuments*"
                            v-model="formData.dateOfIssue"
                            type="date"
                            required
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col md="6">
                        <v-text-field
                            label="Frist zur Einreichung*"
                            v-model="formData.deadline"
                            type="date"
                            required
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col md="6" cols="12">
                        <v-select
                            label="Zuständiger Sachbearbeiter*"
                            :items="availableOfficials"
                            v-model="formData.owner"
                            required
                            :rules="[rules.required]"
                        ></v-select>
                    </v-col>
                </v-row>
                <DocRequestFormExtension 
                    ref="docRequestFormExtension"
                    :prefill="prefill"
                    :key="reloadExtension"
                />
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="close()"> Schließen </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="safe()">
            Speichern
          </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import appConfig from "../../../config";
    import axios from "axios";
    import utils from "../../../utils";

    import DocRequestFormExtension from "./DocRequestFormExtension.vue";

    export default {
        name: "DocRequestForm",
        props: [ "formTitle", "caseData", "prefill" ],
        components: {
            DocRequestFormExtension
        },

        data() {
            return {
                formData: {
                    dateOfIssue: null,
                    application: null,
                    deadline: null,
                    owner: null
                },
                availableApplications: [],
                availableOfficials: [],
                applicationTypes: {},
                reloadExtension: 0,
                prefill2: {},
                rules: {
                    required: (value) => !!value || "Pflichtfeld, bitte ausfüllen"
                },
            }
        },

        async mounted() {
            const requestConfig = {
                headers: {
                    sessiontoken: localStorage.getItem("token"),
                },
            };

            const applicationURL = `${appConfig.apiURL}/sozialstaffel/applications?$filter=case eq cast('${this.caseData._id}', ObjectId)`;
            const applicationResponse = await axios.get(applicationURL, requestConfig);

            const applicationTypeMappingURL = `${appConfig.apiURL}/static/mappings/applicationtypemapping`;
            const applicationTypeMapping = await axios.get(applicationTypeMappingURL, requestConfig);

            this.applicationTypes = applicationTypeMapping.data;

            this.availableApplications = applicationResponse.data.data.map(app => {
                return {
                    text: this.getFormattedApplicationType(app.type) + " vom " + this.getFormattedDate(app.dateOfApplication),
                    value: app._id
                }
            });


            const officialNameUrl = `${appConfig.apiURL}/sozialstaffel/officials/names`;

            const officialNameResp = await axios.get(officialNameUrl, requestConfig);

            officialNameResp.data.forEach(official => {
                this.availableOfficials.push({
                    value: official.userId,
                    text: `${official.firstName} ${official.lastName}`
                });
            });

            if(this.prefill.owner) {
                this.formData.owner = this.prefill.owner;
            }

            if(this.prefill.application) {
                this.formData.application = this.prefill.application;
            }

            if(this.prefill.dateOfIssue) {
                this.formData.dateOfIssue = new Date(this.prefill.dateOfIssue).toISOString().substr(0, 10);
            } else {
                this.formData.dateOfIssue = new Date().toISOString().substr(0, 10)
            }

            if(this.prefill.deadline) {
                this.formData.deadline = new Date(this.prefill.deadline).toISOString().substr(0, 10);
            } else {
                const d = new Date();
                d.setDate(d.getDate() + 14);

                this.formData.deadline = d.toISOString().substr(0, 10)
            }

            this.reloadExtension += 1;
        },

        methods: {
            getFormattedApplicationType(applicationType) {
                return Object.keys(this.applicationTypes).filter(
                    (key) => this.applicationTypes[key] == applicationType
                )[0];
            },

            getFormattedDate(d, minutes) {
                return utils.formatDate(d, minutes);
            },

            close() {
                this.$emit('close');
            },

            safe() {
                console.log(this.$refs)

                const docRequestValidate = this.$refs.docrequestForm.validate()
                const docRequestFormExtensionValidate = this.$refs.docRequestFormExtension.validateForm();

                if(!docRequestValidate || !docRequestFormExtensionValidate) {
                    return;
                }

                const data = {
                    ...this.formData,
                    ...docRequestFormExtensionValidate,
                    case: this.caseData._id
                };

                this.$emit('safe', data);
            }
        }
    }
</script>