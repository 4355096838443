import Vue from 'vue';
import currency from 'currency.js';

import { getMinMaxFromExpr, getLabelListFromExpr } from "./sectionExpr";


/* resetStore */
export const resetState = () => {
    navigationMutations.reset();
    computedPropertiesMutations.reset();
    calculationMutations.reset();
    calculationContextMutations.reset();
    calculatedComputedFieldsMutations.reset();
    calculatedSectionsMutations.reset();
}

export const navigationState = Vue.observable({
    currentSection: null,
    currentSubsection: null,
});

export const navigationMutations = {
    reset() {
        navigationState.currentSection = null;
        navigationState.currentSubsection = null;
    },
    setCurrentSection(section) {
        navigationState.currentSection = section;
    },
    setSectionWithSubsection(section, subsection) {
        navigationState.currentSection = section;
        navigationState.currentSubsection = subsection;
    }
}

export const computedPropertiesState = Vue.observable({
    computedSchemas: {},
    computedPropertyResults: {},
    computedPropertyInputs: {},
    computedLabelMap: {}
});

export const computedPropertiesMutations = {
    reset() {
        computedPropertiesState.computedSchemas = {};
        computedPropertiesState.computedPropertyResults = {};
        computedPropertiesState.computedPropertyInputs = {};
        computedPropertiesState.computedLabelMap = {};
    },
    setComputedSchemas(schemas) {
        computedPropertiesState.computedSchemas = schemas;
        computedPropertiesState.computedPropertyResults = {};
        computedPropertiesState.computedPropertyInputs = {};
    },  

    setComputedPropertyInput(property, value) {
        const assignSectionObject = {};
        assignSectionObject[property] = value;
        computedPropertiesState.computedPropertyInputs = Object.assign({}, computedPropertiesState.computedPropertyInputs, assignSectionObject);
    },
    setComputedPropertyResult(property, value) {
        const assignSectionObject = {};
        assignSectionObject[property] = value;
        computedPropertiesState.computedPropertyResults = Object.assign({}, computedPropertiesState.computedPropertyResults, assignSectionObject);

        //set results to resultmap
        value.results.forEach((result) => {
            if(!result.computedResultLabel.startsWith("#")) {
                calculationMutations.setItemValue(result.computedResultLabel, result.value);
            }

            if(Object.keys(calculationState.wildcardMap).includes(result.computedResultLabel)) {
                calculationMutations.setWildcardMapItem(result.computedResultLabel, result.name);
            }
        });

        if(value.otherFields) {
            Object.keys(value.otherFields).forEach((key) => {
                computedPropertiesMutations.setComputedPropertyResult(key, value.otherFields[key]);
            });
        }
    },
    setComputedLabelMapLabel(computedResultLabel, label) {
        const assignSectionObject = {};
        assignSectionObject[computedResultLabel] = label;
        computedPropertiesState.computedLabelMap = Object.assign({}, computedPropertiesState.computedLabelMap, assignSectionObject);
    },
    getCurrentComputedProperties() {
        const currentComputedProperties = {};

        Object.keys(computedPropertiesState.computedSchemas).forEach((key) => {
            const results = computedPropertiesState.computedPropertyResults[key];
           
            if(results) {
                currentComputedProperties[key] = {
                    inputs: computedPropertiesState.computedPropertyInputs[key],
                    ...results
                };
            }
            
        });

        return currentComputedProperties;
    }
}

export const calculationState = Vue.observable({
    calculation: [],
    metaDataMap: {},
    resultMap: {},
    wildcardMap: {},
    sectionExprWatchMap: {} //map to say which which sections (key: string) are watched by which sectionsExpr (value: Array)
});

export const calculationMutations = {
    reset() {
        calculationState.calculation = [];
        calculationState.metaDataMap = {};
        calculationState.resultMap = {};
        calculationState.wildcardMap = {};
        calculationState.sectionExprWatchMap = {};
    },
    buildMetaDataMap() {
        calculationState.metaDataMap = {
            section: {},
            subsection: {},
            item: {}
        };

        calculationState.calculation.forEach((section) => {
            /* metadatamap */
            //add section to map
            calculationState.metaDataMap.section[section.label] = {};
            calculationState.metaDataMap.section[section.label].schema = section;
            calculationState.metaDataMap.section[section.label].subsections = section.sections.map((subsection)=> subsection.label);

            /* resultmap */
            const assignSectionObject = {};
            assignSectionObject[section.label] = 0;
            calculationState.resultMap = Object.assign({}, calculationState.resultMap, assignSectionObject);

            section.sections.forEach((subsection) => {
                /* metadatamap */
                //include schema of subsection, label of topsection and list of items
                calculationState.metaDataMap.subsection[subsection.label] = {};
                calculationState.metaDataMap.subsection[subsection.label].schema = subsection;
                calculationState.metaDataMap.subsection[subsection.label].section = section.label;
                calculationState.metaDataMap.subsection[subsection.label].items = subsection.items.map((item) => item.label);

                /* resultmap */
                const assignSubSectionObject = {};
                assignSubSectionObject[subsection.label] = 0;
                calculationState.resultMap = Object.assign({}, calculationState.resultMap, assignSubSectionObject);

                if(subsection.type == 'SECTION_EXPR') {
                    const labelListFromExpr = getLabelListFromExpr(subsection.expr);
                    
                    const copiedWatchMap = {...calculationState.sectionExprWatchMap};

                    labelListFromExpr.forEach((label) => {
                        if(!copiedWatchMap[label]) {
                            copiedWatchMap[label] = [];
                        }

                        copiedWatchMap[label].push(subsection.label);
                    });

                    Vue.set(calculationState, "sectionExprWatchMap", copiedWatchMap);
                }

                subsection.items.forEach((item) => {
                    /* metadatamap */
                    //include schema of item, label of topsection and label of subsection
                    calculationState.metaDataMap.item[item.label] = {};
                    calculationState.metaDataMap.item[item.label].schema = item;
                    calculationState.metaDataMap.item[item.label].section = section.label;
                    calculationState.metaDataMap.item[item.label].subsection = subsection.label;
                    
                    /* resultmap */
                    const assignItemObject = {};
                    assignItemObject[item.label] = 0;
                    
                    if(item.default) {
                        assignItemObject[item.label] = parseFloat(item.default);
                    }

                    calculationState.resultMap = Object.assign({}, calculationState.resultMap, assignItemObject);

                    if(item.type == 'ITEM_WILDCARD') {
                        calculationMutations.setWildcardMapItem(item.label, "");
                    }
                });

                this.recalcSubSection(subsection.label);
            });

            this.recalcSection(section.label);
        });
    },

    setCalculation(calculation) {
        calculationState.calculation = calculation;

        calculationMutations.buildMetaDataMap(); //metadatamap is built
    },

    recalcSubSection(label) {
        const subsectionMetaData = calculationState.metaDataMap.subsection[label];

        //calculate sum for subsection
        let subsectionSum =
            subsectionMetaData.items.reduce((accumulator, itemLabel) => {
                const currencyAccumulator = currency(accumulator);

                if(calculationState.metaDataMap.item[itemLabel].schema.sign == "+") {
                    return currencyAccumulator.add(calculationState.resultMap[itemLabel]).value;
                } else {
                    return currencyAccumulator.subtract(calculationState.resultMap[itemLabel]).value;
                }
            }, 0);

        let minExpr, maxExpr;

        if(subsectionMetaData.schema.type == 'SECTION_EXPR') {
            const minMaxExpr = getMinMaxFromExpr(subsectionMetaData.schema.expr, calculationState.resultMap);
            minExpr = minMaxExpr.min;
            maxExpr = minMaxExpr.max;
        }

        if(maxExpr < subsectionSum) {
            subsectionSum = maxExpr;
        }

        if(minExpr > subsectionSum) {
            subsectionSum = minExpr;
        }

        if(subsectionMetaData.schema.max < subsectionSum) {
            subsectionSum = subsectionMetaData.schema.max;
        }
        
        if (subsectionMetaData.schema.min > subsectionSum) {
            subsectionSum = subsectionMetaData.schema.min;
        }

        Vue.set(calculationState.resultMap, label, subsectionSum);
    },

    recalcSection(label) {
        const sectionMetaData = calculationState.metaDataMap.section[label];

        //calculate sum for section
        let sectionSum = sectionMetaData.subsections.reduce((accumulator, subsectionLabel) => {
            const currencyAccumulator = currency(accumulator);

            if(calculationState.metaDataMap.subsection[subsectionLabel].schema.sign == "+") {
                return currencyAccumulator.add(calculationState.resultMap[subsectionLabel]).value;
            } else {
                return currencyAccumulator.subtract(calculationState.resultMap[subsectionLabel]).value;
            }
        }, 0);

        if(sectionMetaData.schema.max < sectionSum) {
            sectionSum = sectionMetaData.schema.max;
        } 
        
        if (sectionMetaData.schema.min > sectionSum) {
            sectionSum = sectionMetaData.schema.min;
        }

        Vue.set(calculationState.resultMap, label, sectionSum);
    },

    setItemValue(label, value) {
        //have all the logic for input processeing here
        const itemMetaData = calculationState.metaDataMap.item[label];

        if(value > itemMetaData.schema.max) {
            value = itemMetaData.schema.max;
        }
        
        if(value < itemMetaData.schema.min) {
            value = itemMetaData.schema.min;
        }

        Vue.set(calculationState.resultMap, label, value);
        
        //update all sections that watch this item
        this.updateWatchers(label);

        //calculate sum for subsection
        const subsectionLabel = calculationState.metaDataMap.item[label].subsection;
        this.recalcSubSection(subsectionLabel);

        //update all sections that watch this subsection
        this.updateWatchers(subsectionLabel);

        //update section and subsection
        const sectionLabel = calculationState.metaDataMap.item[label].section;
        this.recalcSection(sectionLabel);

        //update all sections that watch this section
        this.updateWatchers(sectionLabel);
    },

    updateWatchers(label) {
        //if label is not watched by any sectionExpr return
        if(!Object.keys(calculationState.sectionExprWatchMap).includes(label)) 
            return;

        const sectionsToRecalc = calculationState.sectionExprWatchMap[label];

        sectionsToRecalc.forEach((watchingSectionLabel) => {
            this.recalcSubSection(watchingSectionLabel);
            this.recalcSection(calculationState.metaDataMap.subsection[watchingSectionLabel].section);
        });
    },

    getCalculationForSubmission() {
        if(calculationState.calculation.length == 0) {
            return null;
        }

        const buildCalculationWithResult = (calculationItem) => {
            const calcItem = {...calculationItem};

            calcItem.value = calculationState.resultMap[calcItem.label];

            if(calcItem.sections) {
                calcItem.sections = calcItem.sections.map((section) => buildCalculationWithResult(section));
            }

            if(calcItem.items) {
                calcItem.items = calcItem.items.map((item) => buildCalculationWithResult(item));
            }

            if(Object.keys(calculationState.wildcardMap).includes(calcItem.label)) {
                calcItem.name = calculationState.wildcardMap[calcItem.label];
            }

            return calcItem;
        }

        return calculationState.calculation.map((section)=>buildCalculationWithResult(section));
    },

    getIncomeSurplus(calculation) {
        calculation.forEach((section) => calculationMutations.recalcSection(section.label));

        return calculation.reduce((accumulator, section) => {
            const currencyAccumulator = currency(accumulator);

            if(section.sign == "+") {
                return currencyAccumulator.add(section.value).value;
            } else {
                return currencyAccumulator.subtract(section.value).value;
            }
            
        }, 0);
    },

    setWildcardMapItem(label, value) {
        Vue.set(calculationState.wildcardMap, label, value);
    },

    setPresetFromPreviousCalculation(previousCalculation) {
        previousCalculation.forEach((section) => {
            section.sections.forEach((subsection) => {
                subsection.items.forEach((item) => {
                    //field from old calc is not in new calc discard
                    if(!Object.keys(calculationState.metaDataMap.item).includes(item.label)) {
                        return;
                    }

                    //dont overwrite field derived from case data
                    if(calculationState.metaDataMap.item[item.label].schema.derivedFromCaseData) {
                        return;
                    }

                    if(calculationState.metaDataMap.item[item.label].schema.type == 'ITEM_COMPUTED') {
                        calculationMutations.setItemValue(item.label, 0);
                        return;
                    }

                    if(Object.keys(calculationState.wildcardMap).includes(item.label) && item.name != calculationState.metaDataMap.item[item.label].schema.name) {
                        calculationMutations.setWildcardMapItem(item.label, item.name);
                    }

                    calculationMutations.setItemValue(item.label, item.value);
                });
            });
        });
    }
}

/*
* Store for the calculation context
*/
export const calculationContextState = Vue.observable({
    calculationContext: {},
    presetSet: false,
    calculationStartDate: null,
    calculationEndDate: null,
    application: null,
    loading: true
});

export const calculationContextMutations = {
    reset() {
        calculationContextState.calculationContext = {};
        calculationContextState.presetSet = false;
        calculationContextState.calculationStartDate = new Date();
        calculationContextState.calculationEndDate = new Date();
        calculationContextState.application = "65dc54f00c08abd4172175e8";
        calculationContextState.loading = true;
    },
    setCalculationContext(context) {
        calculationContextState.calculationContext = context;

        //on calcstate set also set current section to first section
        navigationMutations.setSectionWithSubsection(
            context.calculationFormula.calcSchema[0].label, 
            context.calculationFormula.calcSchema[0].sections[0].label
        );

        //set calculation state
        calculationMutations.setCalculation(context.calculationFormula.calcSchema);

        //set computed properties state
        computedPropertiesMutations.setComputedSchemas(context.computedSchemas);
    },
    setLoading(loading) {
        calculationContextState.loading = loading;
    },
    setCalculationTimespanAndApplication(start, end, application) {
        calculationContextState.calculationStartDate = start;
        calculationContextState.calculationEndDate = end;
        calculationContextState.application = application;
        calculationContextState.presetSet = true;
    },
};

/**
 * Store what has been calculated already
 */
export const calculatedComputedFieldsState = Vue.observable({
    calculatedComputedFields: [],
});

export const calculatedComputedFieldsMutations = {
    reset() {
        calculatedComputedFieldsState.calculatedComputedFields = [];
    },
    setCalculatedComputedFields(fields) {
        calculatedComputedFieldsState.calculatedComputedFields = fields;
    },
    addCalculatedComputedField(field) {
        calculatedComputedFieldsState.calculatedComputedFields.push(field);
    }
};

/* State to display checks in nav and section display */
export const calculatedSectionsState = Vue.observable({
    calculatedSections: [],
});

export const calculatedSectionsMutations = {
    reset() {
        calculatedSectionsState.calculatedSections = [];
    },
    setCalculatedSections(sections) {
        calculatedSectionsState.calculatedSections = sections;
    },
    addCalculatedSection(section) {
        const copySections = new Set(calculatedSectionsState.calculatedSections)
        copySections.add(section);
        calculatedSectionsState.calculatedSections = Array.from(copySections);
    },
    addCalculatedSectionBasedOnItem(item) {
        const subsection = calculationState.metaDataMap.item[item].subsection;
        calculatedSectionsMutations.addCalculatedSection(subsection);
    }
};

