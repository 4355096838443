import VueRouter from 'vue-router';
import appconfig from './config';
import axios from 'axios';

import Login from './pages/Login.vue';
import PWReset from './pages/PWReset.vue';
import Launchpad from './pages/Launchpad.vue';

import Users from './pages/Users.vue';

import SozialstaffelApp from './pages/sozialstaffel/App.vue';
import SozialstaffelDashboard from './pages/sozialstaffel/Dashboard.vue';

import SozialStaffelCases from './pages/sozialstaffel/Cases.vue';
import SozialStaffelMyCasesTable from './components/sozialstaffel/caseTables/MyCasesTable.vue';
import SozialStaffelFavCasesTable from './components/sozialstaffel/caseTables/FavCasesTable.vue';
import SozialStaffelAllCasesTable from './components/sozialstaffel/caseTables/AllCasesTable.vue';

import SozialStaffelCaseDetail from './pages/sozialstaffel/CaseDetail.vue';
import SozialStaffelDocumentDetail from './pages/sozialstaffel/DocumentDetail.vue';
import SozialStaffelIncomeCalculator from './pages/sozialstaffel/IncomeCalculator.vue';
import SozialStaffelIncomeCalculator2 from './pages/sozialstaffel/IncomeCalculator2.vue';
import SozialStaffelSettings from './pages/sozialstaffel/Settings.vue';
import SozialStaffelSupport from './pages/sozialstaffel/Support.vue';

import SozialstaffelOZGApp from './pages/SozialstaffelOZG.vue';
import SozialstaffelOZGIntialCaseInbox from './components/sozialstaffelOZG/initialCaseInboxTable.vue';
import SozialStaffelOZGDetail from './pages/SozialstaffelOZGDetail.vue';

import SozialstaffelGlobalSettings from './pages/sozialstaffel/GlobalSettings.vue';

import KITABilling from './pages/kitabilling/App.vue';
import KITABillingDashboard from './pages/kitabilling/Dashboard.vue';
import KITABillingKITA from './pages/kitabilling/Kita.vue';
import KITABillingBillingRuns from './pages/kitabilling/Billingrun.vue';
import KITABillingInvoiceAccept from './pages/kitabilling/InvoiceAccept.vue';

import Analytics from './pages/Analytics.vue';
import TenantAnalytics from './pages/TenantAnalytics.vue';
import TenantSelection from './pages/TenantSelection.vue';
import DSGVO from './pages/Dsgvo.vue';
import SecurityConfig from './pages/SecurityConfig.vue';

const router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/launchpad'
        },
        {
            path: '/login',
            component: Login
        },
        {
            path: '/pwReset',
            component: PWReset
        },
        {
            path: '/launchpad',
            component: Launchpad,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/users',
            component: Users,
            meta: {
                requiresAuth: true
            }  
        },
        {
            path: '/kitabilling/invoiceaccept',
            component: KITABillingInvoiceAccept,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/kitabilling',
            component: KITABilling,
            meta: {
                requiresAuth: true
            },
            redirect: '/kitabilling/dashboard',
            children: [
                {
                    path: 'dashboard',
                    component: KITABillingDashboard,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'kita',
                    component: KITABillingKITA,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'billingruns',
                    component: KITABillingBillingRuns,
                    meta: {
                        requiresAuth: true
                    }
                }
            ]
        },
        {
            path: '/analytics/sozialstaffel',
            component: Analytics,
            meta: {
                requiresAuth: true
            }  
        },
        {
            path: '/analytics/sozialstaffel/tenant',
            component: TenantAnalytics,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/dsgvo',
            component: DSGVO,
            meta: {
                requiresAuth: true
            }  
        },
        {
            path: '/security_config',
            component: SecurityConfig,
            meta: {
                requiresAuth: true
            }  
        },
        {
            path: '/ozg/sozialstaffel/detail',
            component: SozialStaffelOZGDetail,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/ozg/sozialstaffel',
            component: SozialstaffelOZGApp,
            redirect: '/ozg/sozialstaffel/all',
            children: [
                {
                    path: '/ozg/sozialstaffel/all',
                    component: SozialstaffelOZGIntialCaseInbox,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/ozg/sozialstaffel/initial',
                    component: SozialstaffelOZGIntialCaseInbox,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/ozg/sozialstaffel/followup',
                    component: SozialstaffelOZGIntialCaseInbox,
                    meta: {
                        requiresAuth: true
                    }
                },
            ]
        },
        {
            path: '/settings/global/sozialstaffel',
            component: SozialstaffelGlobalSettings,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/sozialstaffel/incomecalc2',
            component: SozialStaffelIncomeCalculator2,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/sozialstaffel',
            component: SozialstaffelApp,
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: 'dashboard',
                    component: SozialstaffelDashboard,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'cases',
                    component: SozialStaffelCases,
                    redirect: '/sozialstaffel/cases/fav',
                    children: [
                        {
                            path: 'fav',
                            component: SozialStaffelFavCasesTable,
                            meta: {
                                requiresAuth: true
                            }
                        },
                        {
                            path: 'my',
                            component: SozialStaffelMyCasesTable,
                            meta: {
                                requiresAuth: true
                            }
                        },
                        {
                            path: 'all',
                            component: SozialStaffelAllCasesTable,
                            meta: {
                                requiresAuth: true
                            }
                        }
                    ]
                },
                {
                    path: 'cases/detail',
                    component: SozialStaffelCaseDetail,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'cases/incomecalc',
                    component: SozialStaffelIncomeCalculator,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'cases/documents/detail',
                    component: SozialStaffelDocumentDetail,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'settings',
                    component: SozialStaffelSettings,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: 'support',
                    component: SozialStaffelSupport,
                    meta: {
                        requiresAuth: true
                    }
                }
            ]
        },
        {
            path: '/support/tenant',
            component: TenantSelection,
        },
        //catch all
        {
            path: '*',
            redirect: '/launchpad'
          }
    ]
});

router.beforeEach(async (to, from, next)=>{
    if(to.meta.requiresAuth) {
        const token = localStorage.token;

        if(!token) {
            next({ path: '/login' });
        }

        //check if user is authenticated
        const url = `${appconfig.apiURL}/users/my`
        const config = {
            headers: {
                sessiontoken: token
            }
        }

        try {
            const request = await axios.get(url, config);

            localStorage.currentUser = JSON.stringify(request.data);
        } catch(e) {
            //error user is most probably not loged in - remove token and redirect
            localStorage.removeItem('token');

            next({ path: '/login' });
        }
    }

    next();
});

export default router;