<template>
  <v-main
    class="mt-3"
    style="background-color: #ffffff; width: 100vw; height: 95vh"
  >
    <v-app-bar app elevation="1" color="white">
      <v-btn color="primary" elevation="0" @click="back()" outlined>
        <v-icon>mdi-arrow-left-thick</v-icon>
        Zurück zur Startseite
      </v-btn>
    </v-app-bar>
    <div class="ma-9">
        <v-tabs v-model="activeTab" class="mt-1">
          <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact>
            {{ tab.name }}
            <v-badge v-if="tab.route == '/ozg/sozialstaffel/all' && allCaseInboxCount > 0" color="secondary" :content="allCaseInboxCount" inline></v-badge>
          </v-tab>
          <v-spacer></v-spacer>
        </v-tabs>
        <router-view></router-view>
    </div>
  </v-main>
</template>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 12pt !important;
}

.v-data-table.v-data-table.v-data-table >>> th {
  font-size: 14pt !important;
}
</style>
<script>
import axios from "axios";
import appConfig from "../config";

export default {
  name: "CaseInboxApp",
  data() {
    return {
      activeTab: "/ozg/sozialstaffel/all",
      tabs: [
        { id: 1, name: "Alle Anträge", route: "/ozg/sozialstaffel/all" },
        //{ id: 2, name: "Erstanträge", route: "/ozg/sozialstaffel/initial" },
        //{ id: 3, name: "Folgeanträge", route: "/ozg/sozialstaffel/followup" },
      ],
      casesDialog: false,
      reloadCaseForm: 0,
      allCaseInboxCount: 0
    };
  },
  methods: {
    back() {
      this.$router.push("/launchpad");
    },
  },
  async mounted() {
    const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

    const url = `${appConfig.apiURL}/sozialstaffel/casesInInbox/count`;

    try {
      const resp = await axios.get(url, requestConfig);

      this.allCaseInboxCount = resp.data.count;
    } catch(e) {
      console.log(e);
    }
    
  }
};
</script>