import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts';
import VueQuillEditor from 'vue-quill-editor';

//quill editor styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import router from './router.js';

Vue.config.silent = true;
Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)


Vue.use(VueQuillEditor)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');