<template>
  <v-card v-if="loading">
    <v-card-title class="justify-center">
        <span>Lade Daten...</span><br /><br />
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </v-card-title>
  </v-card>
  <v-card v-else>
    <v-card-title>
        <v-chip
            color="secondary"
            class="mr-3"
            v-if="!applicationData.dateOfCompletion"
        >Unvollständig</v-chip>
      {{ getFormattedApplicationType(applicationData.type) }} vom {{ getFormattedDate(applicationData.dateOfApplication, false) }}
    </v-card-title>
    <v-card-text class="mt-3">
        <h2 class="text-h5">Ereignisverlauf</h2>
        <v-timeline dense class="mt-2">
            <v-timeline-item small dot-color="primary">
                <v-card class="elevation-2">
                    <v-card-title class="text-h6">
                        {{ getFormattedDate(applicationData.dateOfReceipt) }} - Antrag eingegangen 
                        <v-chip color="primary" class="ml-3" v-if="applicationData.isOnlineApplication">Online</v-chip>
                        <v-spacer></v-spacer>
                        <v-tooltip top v-if="applicationData.isOnlineApplication">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              large
                              v-bind="attrs"
                              v-on="on"
                              class="hover-pointer mr-3"
                              @click="openDocument('application', applicationData.ozgApplication)"
                            >
                              mdi-file-document
                            </v-icon>
                          </template>
                          <span>Anzeigen</span>
                        </v-tooltip>
                        <v-tooltip top v-if="applicationData.isOnlineApplication">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              large
                              v-bind="attrs"
                              v-on="on"
                              class="hover-pointer"
                              @click="openDocumentInTab('application', applicationData.ozgApplication)"
                            >
                              mdi-open-in-new
                            </v-icon>
                          </template>
                          <span>In neuem Tab öffnen</span>
                        </v-tooltip>
                    </v-card-title>
                </v-card>
            </v-timeline-item>
            <template v-for="(docRequest, i) in docRequests">
            <v-timeline-item small dot-color="primary" v-if="docRequest.status != 'canceled'" :key="`${i}docReq`">
              <v-card class="elevation-2">
                <v-card-title class="text-h6">
                  <v-chip
                    color="primary"
                    class="mr-3"
                    v-if="docRequest.status === 'draft'"
                  >Entwurf</v-chip>
                  {{ getFormattedDate(docRequest.createdAt) }} - Nachforderung erstellt
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        large
                        v-bind="attrs"
                        v-on="on"
                        class="hover-pointer mr-3"
                        @click="openDocument('documentrequest', docRequest._id)"
                      >
                        mdi-file-document
                      </v-icon>
                    </template>
                    <span>Anzeigen</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        large
                        v-bind="attrs"
                        v-on="on"
                        class="hover-pointer"
                        @click="openDocumentInTab('documentrequest', docRequest._id)"
                      >
                        mdi-open-in-new
                      </v-icon>
                    </template>
                    <span>In neuem Tab öffnen</span>
                  </v-tooltip>
                </v-card-title>
              </v-card>
            </v-timeline-item>
          </template>
            <v-timeline-item small dot-color="primary" v-if="applicationData.dateOfCompletion">
                <v-card class="elevation-2">
                    <v-card-title class="text-h6">
                        {{ getFormattedDate(applicationData.dateOfCompletion) }} - Antrag vollständig eingegangen
                    </v-card-title>
                </v-card>
            </v-timeline-item>
            <v-timeline-item small dot-color="primary" v-for="(calculation, i) in calculations" :key="`${i}calc`">
                <v-card class="elevation-2">
                    <v-card-title class="text-h6">
                        {{ getFormattedDate(calculation.createdAt) }} - Berechnung erstellt
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              large
                              v-bind="attrs"
                              v-on="on"
                              class="hover-pointer mr-3"
                              @click="openDocument('calculation', calculation._id)"
                            >
                              mdi-file-document
                            </v-icon>
                          </template>
                          <span>Anzeigen</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              large
                              v-bind="attrs"
                              v-on="on"
                              class="hover-pointer"
                              @click="openDocumentInTab('calculation', calculation._id)"
                            >
                              mdi-open-in-new
                            </v-icon>
                          </template>
                          <span>In neuem Tab öffnen</span>
                        </v-tooltip>
                    </v-card-title>
                </v-card>
            </v-timeline-item>
            <v-timeline-item small dot-color="primary" v-for="(letter, i) in letters" :key="`${i}letter`">
                <v-card class="elevation-2">
                    <v-card-title class="text-h6">
                        {{ getFormattedDate(letter.dateOfIssue) }} - Bescheid erstellt
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              large
                              v-bind="attrs"
                              v-on="on"
                              class="hover-pointer mr-3"
                              @click="openDocument('letter', letter._id)"
                            >
                              mdi-file-document
                            </v-icon>
                          </template>
                          <span>Anzeigen</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              large
                              v-bind="attrs"
                              v-on="on"
                              class="hover-pointer"
                              @click="openDocumentInTab('letter', letter._id)"
                            >
                              mdi-open-in-new
                            </v-icon>
                          </template>
                          <span>In neuem Tab öffnen</span>
                        </v-tooltip>
                    </v-card-title>
                </v-card>
            </v-timeline-item>
        </v-timeline>
        <v-row class="pl-9 mt-3">
          <v-tooltip top v-if="!applicationData.dateOfCompletion">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="icon-pointer mr-6"
                    @click="openApplicationComplete()"
                >
                  <v-icon dark> mdi-file-document-check </v-icon>
              </v-avatar>
            </template>
            <span>Antrag vollständig eingegangen</span>
          </v-tooltip>
          <v-tooltip top v-if="!applicationData.dateOfCompletion">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="icon-pointer mr-6"
                @click="openCreateDocRequest()"
              >
                <v-icon dark> mdi-file-document-alert </v-icon>
              </v-avatar>
            </template>
            <span>Nachforderung erstellen</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="icon-pointer"
                @click="openUpdateApplication()"
              >
                <v-icon dark> mdi-pencil </v-icon>
              </v-avatar>
            </template>
            <span>Antrag bearbeiten</span>
          </v-tooltip>
        </v-row>
        <v-row v-if="showDateOfCompletionInput">
          <v-col sm="8" md="3">
            <v-text-field
              label="Datum vollständiger Eingang"
              v-model="dateOfCompletionInput"
              type="date"
              required
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col sm="4" md="3" class="pt-6">
            <v-icon large color="green" class="hover-pointer" @click="updateApplicationDateOfCompletion">mdi-check-all</v-icon>
          </v-col>
        </v-row>
    </v-card-text>
    <v-card-actions> 
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close()"> Schließen </v-btn>
    </v-card-actions> 
  </v-card>
</template>
<style lang="css" scoped>
  .icon-pointer >>> :hover {
    cursor: pointer;
  }
  .hover-pointer {
    cursor: pointer;
  }

</style>
<script>
import appConfig from "../../../config";
import axios from "axios";
import utils from "../../../utils";

export default {
  name: "ApplicationDisplay",
  props: [ "applicationId", "caseData" ],
  data() {
    return {
        applicationData: {},
        applicationTypes: [],
        docRequests: [],
        calculations: [],
        letters: [],
        loading: true,
        showDateOfCompletionInput: false,
        dateOfCompletionInput: new Date().toISOString().substr(0, 10),
        rules: {
          required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
        }
    }
  },
  // Your component logic here
  mounted() {
    this.fetchData();
  },
  
  methods: {
    async fetchData() {
      this.loading = true;

      const requestConfig = {
          headers: {
              sessiontoken: localStorage.getItem("token"),
          },
      };

      const applicationTypeMappingURL = `${appConfig.apiURL}/static/mappings/applicationtypemapping`;
      const applicationURL = `${appConfig.apiURL}/sozialstaffel/applications/${this.applicationId}`;
      const docRequestURL = `${appConfig.apiURL}/sozialstaffel/documentrequests?$filter=application eq cast('${this.applicationId}', ObjectId)`;
      const calculationURL = `${appConfig.apiURL}/sozialstaffel/calculations?$filter=application eq cast('${this.applicationId}', ObjectId)`;
      const letterURL = `${appConfig.apiURL}/sozialstaffel/letters?$filter=application eq cast('${this.applicationId}', ObjectId)`;

      const [ 
          applicationTypeResponse, applicationDataResponse, 
          docRequestResponse, calculationResponse, letterResponse 
        ] = await Promise.all([
        axios.get(applicationTypeMappingURL, requestConfig),
        axios.get(applicationURL, requestConfig),
        axios.get(docRequestURL, requestConfig),
        axios.get(calculationURL, requestConfig),
        axios.get(letterURL, requestConfig)
      ]);

      this.applicationTypes = applicationTypeResponse.data;
      this.applicationData = applicationDataResponse.data;
      this.docRequests = docRequestResponse.data.data;
      this.calculations = calculationResponse.data.data; 
      this.letters = letterResponse.data.data;

      this.loading = false;
    },  

    getFormattedApplicationType(applicationType) {
      return Object.keys(this.applicationTypes).filter(
        (key) => this.applicationTypes[key] == applicationType
      )[0];
    },
    getFormattedDate(d, minutes) {
      return utils.formatDate(d, minutes);
    },
    close() {
        this.$emit('close');
    },
    openDocument(type, id) {
      this.$router.push(`/sozialstaffel/cases/documents/detail?id=${id}&type=${type}`);
    },
    openDocumentInTab(type, id) {
      let route = this.$router.resolve(`/sozialstaffel/cases/documents/detail?id=${id}&type=${type}`);
      window.open(route.href, '_blank');
    },
    openApplicationComplete() {
      this.$emit('closeopenapplicationcomplete', this.applicationData._id);
    },
    async updateApplicationDateOfCompletion() {
      this.loading = true;
      this.showDateOfCompletionInput = false;

      const requestConfig = {
          headers: {
              sessiontoken: localStorage.getItem("token"),
          },
      };

      const applicationURL = `${appConfig.apiURL}/sozialstaffel/applications/${this.applicationId}`;

      await axios.put(applicationURL, { dateOfCompletion: this.dateOfCompletionInput }, requestConfig);

      await this.fetchData();
    },
    openCreateDocRequest() {
      this.$emit("closeopendocrequest", this.applicationData._id);
    },
    openUpdateApplication() {
      this.$emit("closeopenupdateapplication", this.applicationData._id);
    }
  }
}
</script>


