<template>
    <span>
        <v-dialog v-model="calcContextDisplayDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <h3>Hintergrundwerte der Kalkulationsformel</h3>
                </v-card-title>
                <v-card-text>
                    <v-row class="my-3">
                        <v-divider></v-divider>
                    </v-row>
                    <CalcContextDisplay />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-2" text @click="calcContextDisplayDialog = false">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-navigation-drawer
            v-model="drawer"
            temporary
            style="width: 100vw; max-width: 800px;"
            app
        >
        <v-row class="py-4">
            <h1 class="my-3 ml-8">
                Einkommensberechnung
            </h1>
            <v-spacer></v-spacer>
            <v-avatar color="primary" class="mt-1 mr-4 text-right icon-pointer" @click="drawer = false">
                <v-icon dark>
                    mdi-chevron-left
                </v-icon>
            </v-avatar>
        </v-row>
        <v-divider></v-divider>
        <v-row justify="center" v-if="step == 1">
            <v-col cols="12" sm="8" class="mt-9">
                <v-alert type="info">
                    In den folgenden Schritten kann über diese Ansicht navigiert werden.
                </v-alert>
            </v-col>
        </v-row>
        <v-treeview
            v-model="tree"
            :items="navigationTree"
            activatable
            item-key="name"
            open-on-click
            v-if="step == 2 || step == 3"
        >  
            <template v-slot:label="{ item, open }">
                <div @click="clickedNavElement(item)" class="py-3">
                    <v-icon color="green" dense class="mr-1" v-if="calculatedSections.includes(item.label) || calculatedTopLevelSections[item.label]">
                        mdi-check-circle
                    </v-icon>
                    <b v-if="item.bold" class="pt-9">
                        {{ item.name }}
                    </b>
                    <span v-else class="pt-9">
                        {{ item.name }}
                    </span>
                </div>
            </template>
        </v-treeview>
        <template v-slot:append >
            <v-row v-if="step == 2">
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="6" class="pl-9 pb-6">
                    <v-btn color="primary" outlined width="100%" @click="backToLastStep()">
                        Zurück zu den Grunddaten
                    </v-btn>
                </v-col>
                <v-col cols="6" class="pr-9 pb-6">
                    <v-btn color="primary" outlined width="100%" @click="clickedNextToCheck()">
                        Weiter zum Prüfen
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-else-if="step == 3">
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="6" class="pl-9 pb-6">
                    <v-btn color="primary" outlined width="100%" @click="backToLastStep()">
                        Zurück zur Berechnung
                    </v-btn>
                </v-col>
            </v-row>
        </template>
        </v-navigation-drawer>
        <v-app-bar app elevation="1" color="white" style="min-height: 75px">
            <v-avatar color="primary" class="icon-pointer mt-3" @click="drawer = true">
                <v-icon dark>
                    mdi-menu
                </v-icon>
            </v-avatar>
            <v-stepper elevation="0" style="height: 100%" class="ml-6" v-model="step">
                <v-stepper-header>
                    <v-stepper-step
                        step="1"
                        class="mr-6"
                        :complete="step > 1"
                    >
                        Grunddaten
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        step="2"
                        class="mr-6"
                        :complete="step > 2"
                    >
                        Berechnung
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        step="3"
                        class="mr-6"
                    >
                        Prüfen
                    </v-stepper-step>
                </v-stepper-header>
            </v-stepper>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined class="mt-2" @click="abortCalculation()" v-if="step == 1">
                Berechnung Abbrechen
            </v-btn>
            <v-tooltip bottom v-if="step > 1">
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        class="icon-pointer mx-8 mt-3"
                        @click="openCalcContextDisplayDialog()"
                    >
                        <v-icon dark> mdi-database-eye </v-icon>
                    </v-avatar>
                </template>
                <span>Hintergrundwerte anzeigen</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                <v-avatar
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="icon-pointer mx-8 mt-3"
                    @click="openCaseInNewTab()"
                >
                    <v-icon dark> mdi-account-eye </v-icon>
                </v-avatar>
                </template>
                <span>Fall in neuem Tab öffnen</span>
            </v-tooltip>
        </v-app-bar>
    </span>
</template>

<script>
import {
    calculationContextState, 
    navigationMutations,
    calculatedSectionsState
} from "../../../store/sozialstaffel/CalculationState";

import CalcContextDisplay from "./CalcContextDisplay.vue";

export default {
    name: "IncomeCalculator2NavLayout",
    props: [ "step" ],
    components: {
        CalcContextDisplay
    },
    data() {
        return {
            drawer: false,
            mini: false,
            items: [
                { title: "Grunddaten", icon: "mdi-account" },
                { title: "Berechnung", icon: "mdi-calculator" },
                { title: "Prüfen", icon: "mdi-check" }
            ],
            tree: [],
            calcContextDisplayDialog: false
        };
    },
    methods: {
        //recursive function to build the navigation tree from the calculation schema
        buildNavigationTreeFromCalcSchema(schema, parent=null, first = true, prefix = null, num = 0) {
            return schema.map((section) => {
                num += 1;

                return {
                    name: `${prefix? prefix + ".": ""}${num}. ${section.name}`,
                    bold: first,
                    parent: parent,
                    label: section.label,
                    children: section.sections?.length > 0 ? this.buildNavigationTreeFromCalcSchema(section.sections, section.label, false, num) : []
                }
            });
        },
        clickedNavElement(item) {
            if(this.step == 3) {
                this.$emit("back");
            }

            //check if section or subsection
            if(!item.parent) {
                navigationMutations.setCurrentSection(item.label);
            } else {
                navigationMutations.setSectionWithSubsection(item.parent, item.label);
            }

            //close drawer
            this.drawer = false;
        },
        setNavigationStep(step) {
            this.stepper = step;
        },
        openCalcContextDisplayDialog() {
            this.calcContextDisplayDialog = true;
        },
        abortCalculation() {
            this.$router.push(`/sozialstaffel/cases/detail?id=${this.$route.query.case}`)
        },
        openCaseInNewTab() {
            window.open(`/#/sozialstaffel/cases/detail?id=${this.$route.query.case}`, "_blank");
        },
        backToLastStep() {
            this.$emit("back");
            this.drawer = false;
        },
        clickedNextToCheck() {
            this.$emit("next");
            this.drawer = false;
        },
    },
    computed: {
        calculationContext: function() {
            return calculationContextState.calculationContext;
        },
        navigationTree: function() {
            if(!this.calculationContext?.calculationFormula?.calcSchema) {
                return [];
            }

            return this.buildNavigationTreeFromCalcSchema(
                this.calculationContext.calculationFormula.calcSchema
            );
        },
        calculatedSections() {
            return calculatedSectionsState.calculatedSections;
        },
        calculatedTopLevelSections() {
            const calculated = {};

            const topLevelSections = this.calculationContext?.calculationFormula?.calcSchema?.forEach((section) => { 
                calculated[section.label] = section.sections.map((subsection) => subsection.label).every((subsection) => this.calculatedSections.includes(subsection));
            });
        
            return calculated;
        }
    }
}
</script>

<style lang="css" scoped>
.icon-pointer >>> :hover {
  cursor: pointer;
}
</style>