import * as mathjs from "mathjs";

/* Helper Functions */
//helper to find element from expr
const findElement = (expr, elementName) => {
    const split_expr = expr.split(";");
    const element = split_expr.find((element) => element.startsWith(elementName));
    return element;
}

//helper to find element value from expr
const getElementValue = (expr, elementName) => {
    const element = findElement(expr, elementName);
    return element.split("=")[1];
}

export const getLabelListFromExpr = (expr) => getElementValue(expr, "LABELS").split(",");

export const getMinMaxFromExpr = (expr, resultMap) => {
    const labels = getLabelListFromExpr(expr);

    //get min and max expr and replace labels with values from resultMap
    let minExprValue = getElementValue(expr, "MIN");
    let maxExprValue = getElementValue(expr, "MAX");

    labels.forEach((label) => {
        let replaceVal = resultMap[label];

        if(replaceVal === undefined) {
            replaceVal = 0;
        }

        minExprValue = minExprValue.replace(`\$\{${label}\}`, replaceVal);
        maxExprValue = maxExprValue.replace(`\$\{${label}\}`, replaceVal);
    });

    //eval math expressions
    const min = mathjs.evaluate(minExprValue);
    const max = mathjs.evaluate(maxExprValue);

    return { min, max };
};