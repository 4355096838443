<template>
    <v-form ref="docRequestForm">
        <v-row>
            <v-col cols="12">
                <v-combobox
                    v-model="missingDocuments"
                    :filter="filter"
                    :hide-no-data="!search"
                    :items="items"
                    :search-input.sync="search"
                    hide-selected
                    label="Fehlende Dokumente angeben"
                    multiple
                    small-chips
                    solo
                    flat
                    outlined
                    :rules="[rules.minOneDoc]"
                >
                    <template v-slot:no-data>
                        <v-list-item>
                            <span class="subheading">
                                Eingabe durch Eingabetaste bestätigen &nbsp;
                            </span>
                            <v-chip
                                :color="`grey lighten-3`"
                                label
                                small
                            >
                                {{ search }}
                            </v-chip>
                        </v-list-item>
                    </template>
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                            v-if="item === Object(item)"
                            v-bind="attrs"
                            :color="`grey lighten-2`"
                            :input-value="selected"
                            label
                            small
                        >
                            <span class="pr-2" style="font-size: 16px;">
                                {{ item.text }}
                            </span>
                            <v-icon
                                small
                                @click="parent.selectItem(item)"
                            >
                                $delete
                            </v-icon>
                        </v-chip>
                    </template>
                </v-combobox>
            </v-col>
        </v-row>
        <v-row>
            <v-col align="center" justify="center" md="12">
                <v-textarea
                    outlined
                    v-model="additionalComment"
                    label="Zusätzliche Anmerkung (erscheint auf Nachforderung)"
                ></v-textarea>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
    import appConfig from "../../../config";
    import axios from "axios";

    export default {
        name: "DocRequestForm",
        props: [ "prefill" ],
        data() {
            return {
                activator: null,
                attach: null,
                items: [
                    { header: 'Fehlendes Dokument auswählen oder eingeben und durch Eingabetaste bestätigen' }
                ],
                nonce: 1,
                menu: false,
                missingDocuments: [
                ],
                search: null,
                rules: {
                    required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
                    minOneDoc: (value) => value.length > 0 || "Mindestens ein Dokument angeben",
                },
                additionalComment: "",
            }
        },

        watch: {
            missingDocuments (val, prev) {
                if (val.length === prev.length) return

                this.missingDocuments = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                    text: v,
                    }

                    this.items.push(v)

                    this.nonce++
                }

                return v
                })
            },
        },

        methods: {
            resetForm() {
                this.missingDocuments = [];
                this.additionalComment = "";
            },
            validateForm() {
                if (!this.$refs.docRequestForm.validate()) {
                    return null;
                }
                return { 
                    requestedDocuments: this.missingDocuments.map(doc => doc.text), 
                    additionalComment: this.additionalComment 
                };
            }
        },

        async mounted() {
            const requestConfig = {
                headers: {
                    sessiontoken: localStorage.getItem("token"),
                },
            };

            const commonDocumentList = `${appConfig.apiURL}/static/lists/commondocumentslist`;

            const commonDocumentResponse = await axios.get(commonDocumentList, requestConfig);

            this.items = [...this.items, ...commonDocumentResponse.data];

            console.log("prefill", this.prefill)

            if(this.prefill?.requestedDocuments) {
                this.missingDocuments = this.prefill.requestedDocuments.map(doc => {
                    return {
                        text: doc
                    }
                });
            }

            if(this.prefill?.additionalComment) {
                this.additionalComment = this.prefill.additionalComment;
            }
        }
    }
</script>