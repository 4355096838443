<template>
  <span>
    <v-navigation-drawer
      app
      v-model="drawer"
      :mini-variant="mini"
      :permanent="isDesktop"
      mini-variant-width="75"
    >
      <v-list-item class="mb-4 pa-1" justify-center align-center>
        <img src="./../../assets/LogoHL.png" style="max-height: 60px; margin: auto;" class="pointer" @click="backToLaunchpad()" v-if="!mini">
        <img src="./../../assets/LogoMiniCut.png" style="max-height: 60px; margin: auto;" class="pointer" @click="backToLaunchpad()" v-else>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
        <v-list-item-group
          v-model="selectedItem"
          active-class="border"
          mandatory
          color="primary"
        >
          <div v-for="item in items" :key="item.title">
            <v-list-item class="tile" link>
              <v-tooltip right v-if="mini">
                <template v-slot:activator="{ on }">
                  <v-list-item-icon v-on="on">
                    <v-icon medium>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <span>{{ item.title }}</span>
              </v-tooltip>

              <v-list-item-icon v-else>
                <v-icon medium>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
          <v-layout justify-center align-center class="pa-2">
            <v-btn color="primary" elevation="0" @click="$router.push('/launchpad')" outlined>
              <span v-if="!mini">
                <v-icon>mdi-arrow-left-thick</v-icon>
                Zur Startseite
              </span>
              <span v-else>
                <v-icon>mdi-arrow-left-thick</v-icon>
              </span>
            </v-btn>
          </v-layout>
        </template>
    </v-navigation-drawer>
    <v-app-bar app elevation="1" color="white">
      <v-app-bar-nav-icon
        @click="drawerHelper = !drawerHelper"
        class="hidden-lg-and-up"
      ></v-app-bar-nav-icon>
      <v-app-bar-nav-icon
        @click="miniHelper = !miniHelper"
        class="hidden-md-and-down"
      ></v-app-bar-nav-icon>
    </v-app-bar>
  </span>
</template>
<style scoped>
.border {
  border-left: 6px solid #1E345E;
}

.pointer:hover {
  cursor: pointer;
}
</style>
<script>
export default {
  name: "SozialstaffelNavLayout",
  data() {
    return {
      miniHelper: false,
      selectedItemHelper: 0,
      drawerHelper: true,
      showBackPaths: ["/sozialstaffel/cases/detail"],
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", link: "dashboard" },
        { title: "Fälle", icon: "mdi-folder-multiple-outline", link: "cases" },
        { title: "Einstellungen", icon: "mdi-cog", link: "settings" },
        { title: "Support", icon: "mdi-lifebuoy", link: "support"}
      ],
    };
  },
  mounted() {},
  computed: {
    selectedItem: {
      get: function () {
        for (let i = 0; i < this.items.length; i++) {
          if (
            this.$router.currentRoute.fullPath.includes(
              `/sozialstaffel/${this.items[i].link}`
            )
          ) {
            return i;
          }
        }
      },
      set: function (val) {
        this.$router.push(`/sozialstaffel/${this.items[val].link}`);
      },
    },
    drawer: {
      get: function () {
        return this.drawerHelper;
      },
      set: function (value) {
        this.drawerHelper = value;
      },
    },
    mini: {
      get: function () {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
          case "sm":
          case "md":
            return false;
            break;
          case "lg":
          case "xl":
            return this.miniHelper;
        }
      },
    },
    isDesktop: {
      get: function () {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
          case "sm":
          case "md":
            return false;
            break;
          case "lg":
          case "xl":
            return true;
        }
      },
    },
  },
  methods: {
    backToLaunchpad: function() {
      this.$router.push("/launchpad")
    }
  }
};
</script>