
module.exports = {
    apiURL: 'https://api.demo.verwaltungscloud.sh/v1',
    defaultErrorText: "Es ist ein Fehler aufgetreten, bitte kontaktieren Sie den Support",
    roleMapping: {
        "Benutzerverwaltung": "user_management",
        "Lizenzverwaltung": "tenant_management",
        "Sozialstaffel Sachbearbeiter": "sozialstaffel_basic",
        "Sozialstaffel Auswertung": "sozialstaffel_analytics_global",
        "Sozialstaffel Auswertung (Amt)": "sozialstaffel_analytics_tenant",
        "Datenschutz": "gdpr",
        "Sozialstaffel OZG": "sozialstaffel_ozg",
        "KITA Abrechnung": "kitabilling_basic"
    },
    socialBenefitsMapping: {
        "Keine": 0,
        "Leistungen nach dem dritten Kapitel SGB XII": 1,
        "Leistungen nach dem vierten Kapitel SGB XII": 2,
        "Leistungen nach dem §§ 2/3 Asylbewerberleistungsgesetz": 3,
        "Wohngeld nach dem Wohngeldgesetz": 4,
        "Kinderzuschlag nach dem Kindergeldgesetz": 5,
        "Pflegschaft (SGB VIII)": 6,
        "Bürgergeld (SGB II)": 7
    },
    letterTypeMapping: {
        "Erstbescheid": "initial",
        "Folgebescheid": "follow-up",
        "Änderungsbescheid": "amendment"
    },
    maritalStatusMapping: {
        "Ledig": 0,
        "Verheiratet": 1,
        "Verwitwet": 2,
        "Geschieden": 3,
        "Eingetragene Lebenspartnerschaft": 4,
        "Eingetragene Lebenspartnerschaft (Lebenspartner verstorben)": 5,
        "Eingetragene Lebenspartnerschaft (Partnerschafts aufgehoben)": 6,
    }
}