<template>
    <v-main
      class="mt-3"
      style="background-color: #ffffff; width: 100vw; height: 95vh"
    >
      <v-app-bar app elevation="1" color="white">
        <v-btn color="primary" elevation="0" @click="back()" outlined>
          <v-icon>mdi-arrow-left-thick</v-icon>
          Zurück zum Launchpad
        </v-btn>
      </v-app-bar>
        <v-snackbar
          v-model="errorSnackbar"
          timeout="3000"
          color="red"
          dark
          :absolute="true"
          :top="true"
        >
          {{ errorSnackbarText }}
        </v-snackbar>
        <v-snackbar
          v-model="successSnackbar"
          timeout="3000"
          color="blue"
          dark
          :absolute="true"
          :top="true"
        >
          {{ successSnackbarText }}
        </v-snackbar>
      
      <v-row class="justify-center">
        <v-col cols="12" sm="12" md="6">
          <v-card>
            <v-card-title class="text-h5">
              Berechnungseinstellungen
            </v-card-title>
            <v-card-text>
              <v-form ref="settingsForm">
                <v-row>
                  <v-col cols="12">
                    <h3>Einstellung für Berechnungszeitraum</h3>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" class="pt-7" style="font-size: 20px">
                      Nur Startdatum berücksichtigen
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-switch
                      v-model="calculationContextOnlyDependsOnStartDate"
                      label="Achtung: Bitte setzen Sie sich vor der Aktivierung mit uns in Verbindung"
                    ></v-switch>
                  </v-col>
                 <br/>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3>Einstellung für Bescheidvorlagen</h3>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" class="pt-7" style="font-size: 20px">
                      Bescheid nur mit Unterschrift gültig
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-switch
                      v-model="requiresSignature"
                      label=""
                    ></v-switch>
                  </v-col>
                 <br/>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <small>* Pflichtfeld bitte ausfüllen</small>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary darken-1" @click="updateSetting()">
                Änderungen Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </template>
  <script>
  import axios from "axios";
  
  import appConfig from "../../config";
  
  export default {
    name: "GlobalSettings",
    data() {
      return {
        //snackbars
        errorSnackbar: false,
        errorSnackbarText: "",
        successSnackbar: false,
        successSnackbarText: "",
  
        calculationContextOnlyDependsOnStartDate: false,
        requiresSignature: false,

        rules: {
          required: (value) => !!value || "Pflichtfeld, bitte ausfüllen"
        },
      }
    },
    methods: {
      /*
       * common
       */
      back() {
        this.$router.push("/launchpad");
      },
      showSnackbar(type, text) {
        if (type == "ERROR") {
          this.errorSnackbar = true;
          this.errorSnackbarText = text;
        } else {
          this.successSnackbar = true;
          this.successSnackbarText = text;
        }
      },
      async updateSetting() {
        const requestConfig = {
          headers: {
            sessiontoken: localStorage.token,
          },
        };
  

  
        try {
          const calcURL = `${appConfig.apiURL}/settings/global/sozialstaffel_calculations`;
          const calculationContextOnlyDependsOnStartDate = this.calculationContextOnlyDependsOnStartDate;
          const calcBody = { value: { calculationContextOnlyDependsOnStartDate } };

          const letterURL = `${appConfig.apiURL}/settings/global/sozialstaffel_letters`;
          const requiresSignature = this.requiresSignature;
          const letterBody = { value: { requiresSignature } };
  
          const response = await Promise.all([axios.put(calcURL, calcBody, requestConfig), axios.put(letterURL, letterBody, requestConfig)]);
  
          this.showSnackbar("SUCESS", "Änderungen erfolgreich gespeichert");
        } catch(e) {
          this.showSnackbar("ERROR", "Es ist ein unerwarteter Fehler aufgetreten");
        }
      }
    },
    async mounted() {
      const requestConfig = {
          headers: {
            sessiontoken: localStorage.token,
          },
      };
  
      const calcURL = `${appConfig.apiURL}/settings/global/sozialstaffel_calculations`;
      const letterURL = `${appConfig.apiURL}/settings/global/sozialstaffel_letters`;
  
      try {
          const responses = await Promise.all([axios.get(calcURL, requestConfig), axios.get(letterURL, requestConfig)]);
  
          this.calculationContextOnlyDependsOnStartDate = responses[0].data.value.calculationContextOnlyDependsOnStartDate;
          this.requiresSignature = responses[1].data.value.requiresSignature;
      } catch(e) {
        console.log(e);

        this.showSnackbar("ERROR", "Es ist ein unerwarteter Fehler aufgetreten");
      }
    }
  };
  </script>