import { render, staticRenderFns } from "./CaseForm.vue?vue&type=template&id=05a5e023&scoped=true&"
import script from "./CaseForm.vue?vue&type=script&lang=js&"
export * from "./CaseForm.vue?vue&type=script&lang=js&"
import style0 from "./CaseForm.vue?vue&type=style&index=0&id=05a5e023&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05a5e023",
  null
  
)

export default component.exports