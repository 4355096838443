<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">
                Antrag importieren
            </span>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close()"> Schließen </v-btn>
        </v-card-title>
        <v-card-text v-if="importLoading">
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-card-text>
        <v-card-text v-else>
            <br />
            <v-row justify="center" v-for="check in checks" :key="check.name">
                <v-col md="6">
                    <h3>{{ check.label }}</h3>
                </v-col>
                <v-col md="6">
                    <span v-if="checkState[check.name] == 'loading'">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            ></v-progress-circular>
                            &nbsp;&nbsp;Suche nach Duplikaten ...
                    </span>
                    <span v-else-if="checkState[check.name] == 'notfound'">
                        <v-icon color="green">mdi-check-all</v-icon>
                        &nbsp;&nbsp;<span class="font-color: green">Keine Duplikate gefunden</span>
                    </span>
                    <span v-else-if="checkState[check.name] == 'error'">
                        <v-icon color="red">mdi-alert-circle</v-icon>
                        &nbsp;&nbsp;<span class="font-color: red">Fehler bei der Prüfung</span>
                    </span>
                    <span v-else-if="Array.isArray(checkState[check.name])">
                        <v-icon color="orange">mdi-alert</v-icon>
                        &nbsp;&nbsp;<span class="font-color: orange">Potenzielle Duplikate gefunden</span>
                    </span>
                </v-col>
                <v-col v-if="Array.isArray(checkState[check.name])" width="100%">
                    <v-expansion-panels>
                        <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{ checkState[check.name].length }} potenzielle Duplikat(e) gefunden
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-simple-table>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Name
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="caseData in checkState[check.name]"
                                        :key="caseData.primaryApplicant.firstName"
                                    >
                                    <td>{{ `${caseData.primaryApplicant.formOfAddress} ${caseData.primaryApplicant.firstName} ${caseData.primaryApplicant.lastName}` }}</td>
                                    <td>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                large
                                                v-bind="attrs"
                                                v-on="on"
                                                class="hover-pointer"
                                                @click="openCaseInNewTab(caseData)"
                                                >
                                                mdi-open-in-new
                                                </v-icon>
                                            </template>
                                            <span>In neuem Tab öffnen</span>
                                        </v-tooltip>
                                    </td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <br />
            <div v-if="importResult != 'loading'">
                <v-row>
                <v-col>
                    <v-divider></v-divider>
                </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="importResult == 'success'">
                        <h3><v-icon x-large color="green">mdi-check-all</v-icon> Ergebnis: Keine Duplikate</h3>
                        <br />
                        <v-btn color="primary" width="30%" elevation="0" @click="importCaseAsNew">Importieren</v-btn>
                    </v-col>
                    <v-col v-else-if="importResult == 'duplicate'">
                        <h3><v-icon x-large color="orange">mdi-alert</v-icon> Ergebnis: Potenzielle Duplikate gefunden</h3>
                        <br />
                        <v-btn color="primary" width="30%" elevation="0" outlined @click="importCaseAsNew" class="mr-3">neu importieren</v-btn>
                        <v-btn color="primary" width="30%" elevation="0" @click="importCaseExisting">bestehender Fall</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
    </v-card>
</template>
<style lang="css" scoped>
    .icon-pointer >>> :hover {
        cursor: pointer;
    }
    .hover-pointer {
        cursor: pointer;
    }
</style>
<script>
    import appConfig from "../../config";
    import axios from "axios";

    export default {
        name: "ApplicationImportForm",
        props: [ "applicationData"],
        data() {
            return {
                formData: {},
                applicationTypes: [],
                rules: {
                    required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
                    smallerThenApplicationDate: (value) => {
                        if (value >= this.formData.dateOfApplication) {
                            return true;
                        }
                        return "Das Datum des Eingangs muss nach dem Antragsdatum liegen";
                    },
                },
                checks: [
                    { name: "duplicateReference", label: "Duplikatspüfung: Aktenzeichen" },
                    { name: "duplicateFirstName", label: "Duplikatspüfung: Vorname" },
                    { name: "duplicateLastName", label: "Duplikatspüfung: Nachname" }
                ],
                checkState: {
                    duplicateReference: "loading",
                    duplicateFirstName: "loading",
                    duplicateLastName: "loading",
                },
                checkResult: "loading",
                duplicateCases: [],
                importLoading: false
            }
        },
        async mounted() {
            const requestConfig = {
                headers: {
                    sessiontoken: localStorage.getItem("token"),
                },
            };

            const applicationTypeMappingURL = `${appConfig.apiURL}/static/mappings/applicationtypemapping`;

            const response = await axios.get(applicationTypeMappingURL, requestConfig);

            this.applicationTypes = Object.keys(response.data).map((key) => {
                return {
                    text: key,
                    value: response.data[key],
                };
            });

            const sleep = ms => new Promise(r => setTimeout(r, ms));

            for(let check of this.checks) {
                const checkURL = this.getCheckUrl(check.name);
                try {
                    const response = await axios.get(checkURL, requestConfig);
                    await sleep(300);

                    if(response.data.count > 0) {
                        this.checkState[check.name] = response.data.data;
                    } else {
                        this.checkState[check.name] = "notfound";
                    }
                } catch(e) {
                    this.checkState[check.name] = "error";
                }
                
            }
        },

        computed: {
            importResult: function() {
                if(Object.values(this.checkState).some((check) => check == "loading")) {
                    return "loading";
                }

                if(Object.values(this.checkState).some((check) => Array.isArray(check))) {
                    return "duplicate";
                }

                return "success";
            }
        },

        methods: {
            close() {
                this.$emit('close');
            },
            safe() {
                this.$emit('safe');
            },
            resetForm() {
                this.formData = {};
            },
            getCheckUrl(checkName) {
                switch(checkName) {
                    case "duplicateReference":
                        return `${appConfig.apiURL}/sozialstaffel/cases?$search='${this.applicationData.caseData.reference}'`;
                    case "duplicateFirstName":
                        return `${appConfig.apiURL}/sozialstaffel/cases?$search='${this.applicationData.caseData.primaryApplicant.firstName}'`;
                    case "duplicateLastName":
                        return `${appConfig.apiURL}/sozialstaffel/cases?$search='${this.applicationData.caseData.primaryApplicant.lastName}'`;
                }
            },
            openCaseInNewTab(caseData) {
                let route = this.$router.resolve(`/sozialstaffel/cases/detail?id=${caseData._id}`);
                window.open(route.href, '_blank');
            },
            importCaseAsNew() {
                this.$emit('import');    
            },
            importCaseExisting() {
                let duplicateCases = [];

                //pass all duplicate cases for selection
                for(let check of this.checks) {
                    if(Array.isArray(this.checkState[check.name])) {
                        for(let caseData of this.checkState[check.name]) {
                            if(duplicateCases.every((c) => c._id != caseData._id)) {
                                duplicateCases.push(caseData);
                            }
                        }
                    }
                }

                this.$emit('importexist', duplicateCases);
            }
        }
    }
</script>