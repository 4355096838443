<template>
  <div>
    <v-dialog v-model="casesDialog" persistent max-width="800px">
      <CasesForm v-on:close="closeCasesForm()" :key="reloadCaseForm" mode="CREATE"/>
    </v-dialog>
    <div class="pa-4" style="background-color: #ffffff; min-height: 100vh">
        <v-tabs v-model="activeTab" class="mt-1">
          <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact>
            {{ tab.name }}
          </v-tab>
          <v-spacer></v-spacer>
          <v-btn elevation="0" color="primary" class="mt-2" @click="openCreateCaseForm()">Fall anlegen</v-btn>
        </v-tabs>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import CasesForm from '../../components/sozialstaffel/CaseForm.vue';

export default {
  name: "SozialstaffelCases",
  components: {
    CasesForm
  },
  data() {
    return {
      activeTab: "/sozialstaffel/cases/fav",
      tabs: [
        { id: 1, name: "Wiedervorlage", route: "/sozialstaffel/cases/fav" },
        { id: 2, name: "Meine Fälle", route: "/sozialstaffel/cases/my" },
        { id: 3, name: "Alle Fälle", route: "/sozialstaffel/cases/all" },
      ],
      casesDialog: false,
      reloadCaseForm: 0,
    };
  },
  methods: {
    openCreateCaseForm: function() {
      //simple trick to reload from on open
      this.reloadCaseForm += 1;
      this.casesDialog = true;
    },
    closeCasesForm: function() {
      this.casesDialog = false;
    }
  }
};
</script>
