<template>
  <v-card>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      :color="snackBarType == 'ERROR' ? 'red' : 'blue'"
      dark
      :absolute="true"
      :top="true"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-card-title>
      <span class="text-h5" v-if="mode == 'CREATE'">Fall erstellen</span>
      <span class="text-h5" v-else>Fall aktualisieren</span>
    </v-card-title>

    <v-tabs v-model="activeTab">
      <v-tab :disabled="activeTab < 0">
        <v-icon left> mdi-account </v-icon>
        Antragsteller
      </v-tab>
      <v-tab :disabled="activeTab < 1">
        <v-icon left> mdi-account-multiple </v-icon>
        Kinder
      </v-tab>
      <v-tab :disabled="activeTab < 2">
        <v-icon left> mdi-account-group </v-icon>
        Haushalt
      </v-tab>
      <v-tab :disabled="activeTab < 3">
        <v-icon left> mdi-folder </v-icon>
        Fall
      </v-tab>
      <v-tab-item>
        <v-card-text>
          <v-container>
            <v-form ref="form1" lazy-validation>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    label="Anzahl Antragsteller*"
                    :items="availableNumberOfApplicants"
                    v-model="numberOfApplicants"
                    @change="changedNumberOfApplicants()"
                    required
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h3>Erster Antragsteller</h3>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    label="Anrede*"
                    :items="['Herr', 'Frau', 'OHNE']"
                    v-model="formData.primaryApplicant.formOfAddress"
                    required
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6"> </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Vorname*"
                    v-model="formData.primaryApplicant.firstName"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Nachname*"
                    v-model="formData.primaryApplicant.lastName"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Geburtsdatum*"
                    v-model="formData.primaryApplicant.dateOfBirth"
                    type="date"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    label="Familienstand*"
                    :items="availableMaritalStatus"
                    v-model="selectedMaritalStatus"
                    @change="changedMaritalStatus()"
                    required
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="hasSecondaryApplicant">
                <v-col cols="12">
                  <h3>Zweiter Antragsteller</h3>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    label="Anrede*"
                    :items="['Herr', 'Frau', 'OHNE']"
                    v-model="formData.secondaryApplicant.formOfAddress"
                    required
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6"> </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Vorname*"
                    v-model="formData.secondaryApplicant.firstName"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Nachname*"
                    v-model="formData.secondaryApplicant.lastName"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Geburtsdatum*"
                    v-model="formData.secondaryApplicant.dateOfBirth"
                    type="date"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h3>Adresse</h3>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    label="Straße*"
                    v-model="formData.address.street"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Hausnummer*"
                    v-model="formData.address.number"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Postleitzahl*"
                    v-model="formData.address.postalCode"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    label="Ort*"
                    v-model="formData.address.city"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h3>Sozialleistungen</h3>
                </v-col>
                <v-col cols="12" md="8">
                  <v-select
                    label="Sozialleistungsbezug*"
                    :items="avialableSocialbenefits"
                    v-model="selectedSocialBenefits"
                    @change="changedSocialBenefits()"
                    required
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
            <small>* Pflichtfeld</small>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="closeCaseForm()">
            Schließen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="continueCase()">
            Fortfahren
          </v-btn>
        </v-card-actions>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <v-form ref="form2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-alert type="info" border="left" colored-border>
                  Hier sind nur die Kinder einzutragen, die folgende Bedingungen erfüllen: <br /><br />
                  <ul><li><i>Kinder eines Antragstellers sind</i></li></ul>
                  <b class="ml-3"> und </b><br />
                  <ul><li><i> mit dem/den Antragsteller(n) in diesem Haushalt leben. </i></li></ul><br />
                  Alle anderen Personen/Kinder sind unter „Weitere Haushaltsangehörige“ zu erfassen. <br />Dazu gehören z.B. die Kinder des (Lebens-)Partners.
                  Kinder des (Lebens-)Partners zählen zur Familie nach dem KiTaG, d.h. sie werden ggf. bei der Geschwistermäßigung berücksichtigt.
                </v-alert>
              </v-col>
              <template v-for="(child, index) in formData.children">
                <v-col cols="12" :key="index">
                  <v-card>
                    <v-card-title>Kind {{ index + 1 }}</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Vorname*"
                            v-model="child.firstName"
                            required
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Nachname*"
                            v-model="child.lastName"
                            required
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Geburtsdatum*"
                            v-model="child.dateOfBirth"
                            type="date"
                            required
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            label="Status des Kinds*"
                            :items="availableChildStatus"
                            v-model="child.status"
                            @change="childStatusChanged(index)"
                            required
                            :rules="[rules.required]"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-checkbox v-if="child.status == 'kita'"
                            v-model="child.isApplying"
                            label="Ermäßigung wird beantragt"
                          ></v-checkbox>
                        </v-col>
                         <v-col cols="12" xs="0" md="6"></v-col>
                         <v-col cols="12" xs="0" md="6"></v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="text-right"
                          v-if="index != 0"
                        >
                          <v-btn class="ma-2" color="red" dark @click="deleteChild(index)">
                            <v-icon dark>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </template>
              <v-col cols="12">
                <v-btn color="primary" @click="addChild()">
                  Kind hinzufügen
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="backCase()"> Zurück </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="continueCase()">
            Fortfahren
          </v-btn>
        </v-card-actions>
      </v-tab-item>
      <v-tab-item>
          <v-card-text>
            <v-form ref="form3" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-alert type="info" border="left" colored-border>
                    Hier sind alle anderen Personen/Kinder, die nicht bei „Antragsteller“ oder bei „Kinder“ eingetragen werden, zu erfassen. 
                    Dazu gehören z.B. die Kinder des (Lebens-)Partners oder andere Verwandte und Personen im Haushalt.
                  </v-alert>
                </v-col>
                <template v-for="(hmember, index) in formData.householdMembers">
                  <v-col cols="12" :key="`hh${index}`">
                    <v-card>
                       <v-card-title>Haushaltsmitglied {{ index + 1 }}</v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field
                                label="Vorname*"
                                v-model="hmember.firstName"
                                required
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                label="Nachname*"
                                v-model="hmember.lastName"
                                required
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                label="Geburtsdatum*"
                                v-model="hmember.dateOfBirth"
                                type="date"
                                required
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-select
                                label="Status des Haushaltsmitglieds*"
                                :items="availableChildStatus"
                                v-model="hmember.status"
                                required
                                :rules="[rules.required]"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" md="6"></v-col>
                            <v-col
                              cols="12"
                              md="6"
                              class="text-right"
                            >
                              <v-btn class="ma-2" color="red" dark @click="deleteHH(index)">
                                <v-icon dark>
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                    </v-card>
                  </v-col>
                </template>
                <v-col cols="12">
                  <v-btn color="primary" @click="addHH()">
                    Haushaltsmitglied hinzufügen
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="backCase()"> Zurück </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="continueCase()">
            Fortfahren
          </v-btn>
        </v-card-actions>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <v-container>
            <v-form ref="form4" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <h3>Kontaktinformationen</h3>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Email-Adresse"
                    v-model="formData.contactInformation.email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Telefonnummer"
                    v-model="formData.contactInformation.phone"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h3>Weitere Angaben</h3>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Aktenzeichen*"
                    v-model="formData.reference"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    label="Zuständiger Sachbearbeiter*"
                    :items="availableOfficials"
                    v-model="formData.owner"
                    required
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="mode == 'CREATE'">
                <v-col cols="12">
                  <h3>Daten zum Antrag</h3>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Antragsdatum*"
                    v-model="applicationFormData.dateOfApplication"
                    type="date"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Antrag eingegangen am*"
                    v-model="applicationFormData.dateOfReceipt"
                    type="date"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <small>* Pflichtfeld</small>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="backCase()"> Zurück </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="createCase()"
            v-if="mode == 'CREATE'"
          >
            Erstellen
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="updateCase()"
            v-if="mode == 'UPDATE'"
          >
            Aktualisieren
          </v-btn>
        </v-card-actions>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<style lang="css" scoped>
.hover-pointer {
  cursor: pointer;
}
</style>


<script>
import appConfig from "../../config";
import utils from "../../utils";
import axios from "axios";

export default {
  name: "CasesForm",
  props: ["mode", "caseData"],
  data() {
    return {
      formData: {
        primaryApplicant: {
          maritalStatus: appConfig.maritalStatusMapping[Object.keys(appConfig.maritalStatusMapping)[0]]
        },
        secondaryApplicant: {},
        address: {},
        children: [{}],
        householdMembers: [],
        receivingSocialBenefits: 0,
        reference: "",
        contactInformation: {}
      },
      applicationFormData: {},
      availableNumberOfApplicants: ["Ein Antragsteller", "Zwei Antragsteller"],
      numberOfApplicants: "Ein Antragsteller",
      snackbarText: "",
      snackBarType: "SUCCESS",
      snackbar: false,
      activeTab: 0,
      userUpdateId: "",
      avialableSocialbenefits: Object.keys(appConfig.socialBenefitsMapping),
      selectedSocialBenefits: Object.keys(appConfig.socialBenefitsMapping)[0],
      availableMaritalStatus: Object.keys(appConfig.maritalStatusMapping),
      selectedMaritalStatus: Object.keys(appConfig.maritalStatusMapping)[0],
      availableChildStatus: [
        { value: "none", text: "Sonstiges" },
        { value: "kita", text: "In der KITA betreut" },
        { value: "schoolOrTraining", text: "Ist in der Schule oder Ausbildung" }
      ],
      availableOfficials: [],
      rules: {
        required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
      },
    };
  },
  computed: {
    hasSecondaryApplicant() {
      return this.numberOfApplicants != "Ein Antragsteller";
    },
  },
  async mounted() {

    const getSocialBenefitsLabel = function(num) {
      for (let k of Object.keys(appConfig.socialBenefitsMapping)) {
        if (appConfig.socialBenefitsMapping[k] == num) {
          return k;
        }
      }
    }

    const getMaritalStatusLabel = function(num) {
      for (let k of Object.keys(appConfig.maritalStatusMapping)) {
        if (appConfig.maritalStatusMapping[k] == num) {
          return k;
        }
      }
    }

    const url = `${appConfig.apiURL}/sozialstaffel/officials/names`;

    const requestConfig = {
      headers: {
        sessiontoken: localStorage.getItem("token"),
      },
    };

    const officialNameResp = await axios.get(url, requestConfig);

    officialNameResp.data.forEach(official => {
      this.availableOfficials.push({
        value: official.userId,
        text: `${official.firstName} ${official.lastName}`
      });
    });

    //preset current user as owner
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.formData.owner = this.currentUser._id;

    if (this.mode == "UPDATE") {
      let data = JSON.parse(JSON.stringify(this.caseData));

      this.userUpdateId = data._id;
      delete data.resubmissionUsers;

      data.primaryApplicant.dateOfBirth = utils.formatDateForDateInput(
        data.primaryApplicant.dateOfBirth
      );

      if(data.primaryApplicant.formOfAddress == ' ') {
        data.primaryApplicant.formOfAddress = 'OHNE';
      }

      //check if secondaryApplicant exists
      if (data.secondaryApplicant?.firstName) {
        this.numberOfApplicants = "Zwei Antragsteller";

        data.secondaryApplicant.dateOfBirth = utils.formatDateForDateInput(
          data.secondaryApplicant.dateOfBirth
        );

        if(data.secondaryApplicant?.formOfAddress == ' ') {
          data.secondaryApplicant.formOfAddress = 'OHNE';
        }
      }

      //children
      data.children = data.children.map(child => {
        return {
          ...child,
          ...{
            dateOfBirth: utils.formatDateForDateInput(child.dateOfBirth)
          }
        }
      });

      //householdMember
      data.householdMembers = data.householdMembers.map(hh => {
        return {
          ...hh,
          ...{
            dateOfBirth: utils.formatDateForDateInput(hh.dateOfBirth)
          }
        }
      });

      if(typeof data.owner == 'object') {
        data.owner = data.owner._id;
      }

      this.formData = data;

      this.selectedSocialBenefits = getSocialBenefitsLabel(this.formData.receivingSocialBenefits);
      this.selectedMaritalStatus = getMaritalStatusLabel(data.primaryApplicant.maritalStatus);

      if(!data.contactInformation) {
        this.formData.contactInformation = {}
      }
    }

    if(this.mode == "CREATE" && this.caseData) {
      let data = JSON.parse(JSON.stringify(this.caseData));

      data.primaryApplicant.dateOfBirth = utils.formatDateForDateInput(
        data.primaryApplicant.dateOfBirth
      );

      if(data.primaryApplicant.formOfAddress == ' ') {
        data.primaryApplicant.formOfAddress = 'OHNE';
      }

      data.secondaryApplicant = {}

      //check if secondaryApplicant exists
      if (data.secondaryApplicant?.firstName) {
        this.numberOfApplicants = "Zwei Antragsteller";

        data.secondaryApplicant.dateOfBirth = utils.formatDateForDateInput(
          data.secondaryApplicant.dateOfBirth
        );

        if(data.secondaryApplicant?.formOfAddress == ' ') {
          data.secondaryApplicant.formOfAddress = 'OHNE';
        }
      }

      //children
      data.children = data.children.map(child => {
        return {
          ...child,
          ...{
            dateOfBirth: utils.formatDateForDateInput(child.dateOfBirth)
          }
        }
      });

      //householdMember
      data.householdMembers = data.householdMembers.map(hh => {
        return {
          ...hh,
          ...{
            dateOfBirth: utils.formatDateForDateInput(hh.dateOfBirth)
          }
        }
      });

      this.formData = data;
      this.selectedSocialBenefits = getSocialBenefitsLabel(this.formData.receivingSocialBenefits);
    }
  },
  methods: {
    changedMaritalStatus: function() {
      this.formData.primaryApplicant.maritalStatus = appConfig.maritalStatusMapping[this.selectedMaritalStatus];

      console.log(this.formData.primaryApplicant.maritalStatus)
    },
    changedSocialBenefits: function() {
      this.formData.receivingSocialBenefits = appConfig.socialBenefitsMapping[this.selectedSocialBenefits];
    },
    changedNumberOfApplicants: function() {
      if(this.numberOfApplicants == "Zwei Antragsteller") {
        this.formData.secondaryApplicant = {}
      }
    },
    resetForm: function () {
      this.formData = {
        primaryApplicant: {},
        secondaryApplicant: {},
        address: {},
        children: [{}],
        householdMembers: [],
        reference: "",
        contactInformation: {}
      };
      this.applicationFormData = {};
      this.numberOfApplicants = "Ein Antragsteller";
      this.activeTab = 0;
      this.userUpdateId = "";
    },
    showSnackbar(type, text) {
      if (type == "ERROR") {
        this.snackBarType = "ERROR";
        this.snackbarText = text;
      } else {
        this.snackBarType = "SUCCESS";
        this.snackbarText = text;
      }

      this.snackbar = true;
    },
    updateCase: async function(){
      for(let i=0; i<=this.activeTab; i++) {
        let form = "form" + (this.activeTab + 1);

        if (!this.$refs[form].validate()) {
          return;
        }
      }

      const url = `${appConfig.apiURL}/sozialstaffel/cases/${this.userUpdateId}`;

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      let body = {
        ...this.formData,
        ...{ primaryApplicant: this.formData.primaryApplicant },
      };

      if (this.numberOfApplicants == "Ein Antragsteller") {
        body.secondaryApplicant = null;
      } else {
        body = {
          ...body,
          ...{ secondaryApplicant: this.formData.secondaryApplicant },
        };
      }

      if(body.primaryApplicant.formOfAddress == 'OHNE') {
        body.primaryApplicant.formOfAddress = ' ';
      }

      if(body.secondaryApplicant?.formOfAddress == 'OHNE') {
        body.secondaryApplicant.formOfAddress = ' ';
      }

      axios
        .put(url, body, requestConfig)
        .then((resp) => {
          console.log(resp)
          this.$emit("closesuccess");
        })
        .catch((err) => {
          this.showSnackbar("ERROR", appConfig.defaultErrorText);
          console.log(err.response.data);
        });
    },
    createCase: async function () {
      if (!this.$refs.form4.validate()) {
        return;
      }

      const url = `${appConfig.apiURL}/sozialstaffel/cases`;

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      let primaryApplicant = {
        ...this.formData.primaryApplicant,
      };

      let body = {
        ...this.formData,
        ...{ primaryApplicant: primaryApplicant }
      };

      if (this.numberOfApplicants == "Ein Antragsteller") {
        delete body.secondaryApplicant;
      } else {
        //need to to conversions for secondaryapplicant
        let secondaryApplicant = {
          ...this.formData.secondaryApplicant,
          ...{ isBiologicalFather: true }
        };

        body = {
          ...body,
          ...{ secondaryApplicant: secondaryApplicant },
        };
      }

      if(body.primaryApplicant.formOfAddress == 'OHNE') {
        body.primaryApplicant.formOfAddress = ' ';
      }

      if(body.secondaryApplicant?.formOfAddress == 'OHNE') {
        body.secondaryApplicant.formOfAddress = ' ';
      }

      axios
        .post(url, body, requestConfig)
        .then(async (resp) => {
          const applicationBody = {
            ...this.applicationFormData,
            ...{ case: resp.data._id },
          };

          await axios.post(`${appConfig.apiURL}/sozialstaffel/applications`, applicationBody, requestConfig);

          this.$emit("closesuccess");
          this.$router.push(`/sozialstaffel/cases/detail?id=${resp.data._id}`);
        })
        .catch((err) => {
          this.showSnackbar("ERROR", appConfig.defaultErrorText);
          console.log(err.response.data);
        });
    },
    /* Form Controls */
    closeCaseForm: function () {
      this.resetForm();

      for(let i=0; i<=this.activeTab; i++) {
        let form = "form" + (this.activeTab + 1);

        this.$refs[form].resetValidation();
      }

      this.$emit("close");
    },
    continueCase: function () {
      //1 bis 4

      let form = "form" + (this.activeTab + 1);

      if (!this.$refs[form].validate()) {
        return;
      }

      
      this.activeTab += 1;
    },
    backCase: function () {
      this.activeTab -= 1;
    },

    /* Child form controls */
    addChild: function () {
      this.formData.children.push({});
    },
    deleteChild: function (i) {
      if (i == 0) {
        return;
      }

      this.formData.children.splice(i, 1);
    },
    childStatusChanged(index) {
      this.formData.children[index].isApplying = this.formData.children[index].status == 'kita';
    },

    /* HouseholdMembers form controls */
    addHH: function () {
      this.formData.householdMembers.push({});
    },
    deleteHH: function (i) {
      this.formData.householdMembers.splice(i, 1);
    },
  },
};
</script>
