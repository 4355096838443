<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">
                {{ formTitle }}
            </span>
        </v-card-title>
        <v-card-text>
            <v-form ref="applicationForm">
                <v-row>
                    <v-col md="6">
                        <v-select
                            label="Art des Antrags*"
                            v-model="formData.type"
                            :items="applicationTypes"
                            required
                            :rules="[rules.required]"
                        ></v-select>
                    </v-col>
                    <v-col sm="0" md="6"></v-col>
                </v-row>
                <v-row>
                    <v-col md="6">
                        <v-text-field
                            label="Antragsdatum*"
                            v-model="formData.dateOfApplication"
                            type="date"
                            required
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col md="6">
                        <v-text-field
                            label="Datum des Eingangs*"
                            v-model="formData.dateOfReceipt"
                            type="date"
                            required
                            :rules="[rules.required, rules.smallerThenApplicationDate]"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <slot name="formExtend"></slot>
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="close()"> Schließen </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="safe()">
            Speichern
          </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import appConfig from "../../../config";
    import axios from "axios";

    export default {
        name: "ApplicationForm",
        props: [ "caseData", "formTitle", "preset" ],
        data() {
            return {
                formData: {},
                applicationTypes: [],
                rules: {
                    required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
                    smallerThenApplicationDate: (value) => {
                        if (value >= this.formData.dateOfApplication) {
                            return true;
                        }
                        return "Das Datum des Eingangs muss nach dem Antragsdatum liegen";
                    },
                },
            }
        },
        async mounted() {
            const requestConfig = {
                headers: {
                    sessiontoken: localStorage.getItem("token"),
                },
            };

            const applicationTypeMappingURL = `${appConfig.apiURL}/static/mappings/applicationtypemapping`;

            const response = await axios.get(applicationTypeMappingURL, requestConfig);

            this.applicationTypes = Object.keys(response.data).map((key) => {
                return {
                    text: key,
                    value: response.data[key],
                };
            });

            if(this.preset) {
                console.log(this.preset)

                this.formData = this.preset;
            }
        },
        methods: {
            close() {
                this.$refs.applicationForm.resetValidation()
                this.$emit('close');
            },
            safe() {
                if (!this.$refs.applicationForm.validate()) {
                    return;
                }

                this.$emit('safe', { ...this.formData, case: this.caseData._id });
            },
            resetForm() {
                this.formData = {};
                this.$refs.applicationForm.resetValidation();
            }
        }
    }
</script>