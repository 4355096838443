<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">
                {{ title }}
            </span>
        </v-card-title>
        <v-card-text>
            <span v-html="text"></span>
        </v-card-text>
        <v-card-actions v-if="loadingState">
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-card-actions>
        <v-card-actions v-else-if="inverseButtons">
          <v-btn color="blue darken-1" text @click="safe()">
            {{ actionLabelContinue? actionLabelContinue: "Speichern" }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close()" v-if="!displayNoAbortButton"> {{ actionLabelAbort? actionLabelAbort: "Abbrechen" }} </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn color="red darken-1" text @click="close()" v-if="!displayNoAbortButton"> {{ actionLabelAbort? actionLabelAbort: "Abbrechen" }} </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="safe()">
            {{ actionLabelContinue? actionLabelContinue: "Speichern" }}
          </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    export default {
        name: "ConfirmDialog",
        props: [ "title", "text", "actionLabelAbort", "actionLabelContinue", "inverseButtons", "displayNoAbortButton" ],
        components: {},
        data() {
            return {
                loadingState: false
            }
        },
        methods: {
            close() {
                this.loadingState = false;
                this.$emit('close');
            },
            safe() {
                this.$emit('continue');
            },
            setLoadingState(state) {
                this.loadingState = state;
            }
        }
    }
</script>