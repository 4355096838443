import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/lib/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { de },
      current: 'de',
    },
    theme: {
      themes: {
        light: {
          primary: '#284470',
          secondary: '#D40C4D',
          anchor: '#B31342',
        },
      },
    },
  });
