<template>
    <div class="mx-4 mt-8">
      <v-row class="justify-center" v-if="!activeBillingRun">
        <v-col cols="12" sm="12" md="6">
            <v-card class="py-4 d-flex" elevation="2">
                <v-card-text style="text-align: center;">
                    <h1 class="mb-4">Kein Abrechnungslauf aktiv</h1>
                    <v-btn color="primary" elevation="0">Abrechnungslauf starten</v-btn>
                </v-card-text>
            </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" sm="12" md="4">
            <v-card class="py-4 d-flex" elevation="2">
                <v-avatar
                    color="primary"
                    class="mx-6"
                    size="60"
                >
                <v-icon dark x-large>mdi-traffic-light</v-icon>
                </v-avatar>
                <div>
                Abrechnungsstatus
                <h1 class="font-weight-bold">Aktiv</h1>
                (bis {{ formatDate(activeBillingRun.deadline) }})
                </div>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
            <v-card class="py-4 d-flex" elevation="2">
                <v-avatar
                    color="primary"
                    class="mx-6"
                    size="60"
                >
                <v-icon dark x-large>mdi-swap-horizontal</v-icon>
                </v-avatar>
                <div>
                Abrechnungen abgeschlossen
                <h1 class="font-weight-bold">10</h1><br/>
                </div>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
            <v-card class="py-4 d-flex" elevation="2">
                <v-avatar
                    color="primary"
                    class="mx-6"
                    size="60"
                >
                <v-icon dark x-large>mdi-send-clock</v-icon>
                </v-avatar>
                <div>
                Abrechnungen ausstehend
                <h1 class="font-weight-bold">5</h1><br/>
                </div>
            </v-card>
        </v-col>
      </v-row>
    </div>  
</template>
  
  <script>
  import axios from "axios";
  import appConfig from "../../config";
  import utils from "../../utils";

  export default {
    name: 'Kita',
    data() {
      return {
        activeBillingRun: null
      }
    },
    methods: {
        formatDate(d) {
            return utils.formatDate(d, false);
        },
    },  
    async mounted() {
      const requestConfig = {
          headers: {
            sessiontoken: localStorage.token,
          },
      };
  
      const url = `${appConfig.apiURL}/kitabilling/billingruns?$filter=status eq 'in_progress'&$limit=1`;
  
      try {
          const response = await axios.get(url, requestConfig);

          this.activeBillingRun = response.data.data[0];
      } catch(e) {
        this.showSnackbar("ERROR", "Es ist ein unerwarteter Fehler aufgetreten");
      }
    }
  }
  </script>
  