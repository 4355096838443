<template>
    <div>
        <v-card v-if="loading">
            <v-card-title class="justify-center">
                <span>Lade Daten...</span><br /><br />
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-card-title>
        </v-card>
        <ApplicationForm 
            v-else
            :caseData="caseData"
            formTitle="Antrag bearbeiten"
            :preset="applicationData"
            v-on:close="close()"
            v-on:safe="safe"
            ref="applicationFormChild"
            :key="applicationFormRefresh"
        >
            <template v-slot:formExtend>
                <v-row align="center" justify="center">
                    <v-col md="6">
                        <p style="font-size: 14pt">Antrag vollständig mit allen Nachweisen eingegangen?</p>
                    </v-col>
                    <v-col md="6">
                        <v-radio-group
                            row
                            class="pb-5"
                            v-model="applicationComplete"
                        >
                            <v-radio
                                label="JA"
                                :value="true"
                                class="pr-5"
                            ></v-radio>
                            <v-radio
                                label="NEIN"
                                :value="false"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row v-if="applicationComplete" align="center" justify="center">
                    <v-col md="6">
                        <v-form ref="dateOfCompletionForm">
                            <v-text-field
                                label="Datum des vollständigen Eingangs"
                                v-model="dateOfCompletion"
                                type="date"
                                :rules="[rules.dateOfCompletionRequired]"
                            ></v-text-field>
                        </v-form>
                    </v-col>
                    <v-col md="6"></v-col>
                </v-row>
            </template>
        </ApplicationForm>
    </div>
</template>
<script>
import appConfig from "../../../config";
import utils from "../../../utils";
import axios from "axios";
import ApplicationForm from "./ApplicationForm.vue";

export default {
  name: "ApplicationDisplay",
  props: [ "applicationId", "caseData" ],
  components: {
    ApplicationForm
  },
  data() {
    return {
        applicationData: {},
        loading: true,
        applicationFormRefresh: 0,
        rules: {
          required: (value) => !!value || "Pflichtfeld, bitte ausfüllen",
          dateOfCompletionRequired: (value) => (!!value || !this.applicationComplete) || "Pflichtfeld, bitte ausfüllen"
        },
        dateOfCompletion: null,
        applicationComplete: false,
    }
  },
  
  async mounted() {
    this.loading = true;

    const requestConfig = {
        headers: {
            sessiontoken: localStorage.getItem("token"),
        },
    };

    try {
        const applicationUrl = `${appConfig.apiURL}/sozialstaffel/applications/${this.applicationId}`;

        const applicationResp = await axios.get(applicationUrl, requestConfig);

        this.applicationData = {
            ...applicationResp.data,
            dateOfApplication: utils.formatDateForDateInput(applicationResp.data.dateOfApplication),
            dateOfReceipt: utils.formatDateForDateInput(applicationResp.data.dateOfReceipt),
        };

        this.applicationComplete = !!this.applicationData.dateOfCompletion;

        if(this.applicationComplete) {
            this.dateOfCompletion = utils.formatDateForDateInput(this.applicationData.dateOfCompletion);
        } else {
            this.dateOfCompletion = null;
        }
    } catch(e) {
        this.$emit('closeerror')
    }

    this.applicationFormRefresh += 1;
    
    this.loading = false;
  },
  
  methods: {
    close() {
        this.$emit('close');
    },
    async safe(data) {
        if(this.applicationComplete) {
            if (!this.$refs.dateOfCompletionForm.validate()) {
                return;
            }
        }

        try {
            const requestConfig = {
                headers: {
                    sessiontoken: localStorage.getItem("token"),
                },
            };

            const applicationUrl = `${appConfig.apiURL}/sozialstaffel/applications/${this.applicationId}`;

            const applicationData = {
                ...data
            };

            if(this.applicationComplete) {
                applicationData.dateOfCompletion = this.dateOfCompletion;
            } else {
                applicationData.dateOfCompletion = null;
            }

            await axios.put(applicationUrl, applicationData, requestConfig);

            this.$emit('closesuccess');
        } catch(e) {
            this.$emit('closeerror');
        }
    }
  }
}
</script>