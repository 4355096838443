<template>
    <v-card style="overflow-x: hidden">
        <v-card-action>
            <v-stepper v-model="step">
                <v-stepper-header elevation="0">
                    <v-stepper-step
                        class="mr-6"
                        v-for="(comptedFieldName, i) in fieldNames"
                        :key="i"
                        :step="`${i + 1}`">
                        {{ computedSchemas[comptedFieldName].title }}
                    </v-stepper-step>
                </v-stepper-header>
            </v-stepper>
            <v-row justify="left" class="mt-3">
                <v-col sm="8" class="text-left ml-6" v-if="showCloseButton">
                    <v-btn color="red darken-2" elevation="0" class="py-4 px-9" @click="closeComputedFieldDialog()" outlined>Schließen</v-btn>
                </v-col>
                <v-col sm="8" class="text-left ml-6" v-else>
                    <v-btn color="red darken-2" elevation="0" class="py-4 px-9" @click="backComputedFieldDialog()" outlined>Anischt verlassen</v-btn>
                </v-col>
            </v-row>
        </v-card-action>
        <v-card-text v-if="viewResult">
            <v-row justify="center" class="mt-6">
                <v-col cols="12" sm="10">
                    <h3>{{ currentComputedField.title }}</h3>
                    <span v-html="this.results[currentComputedFieldName].longHtml"></span>
                </v-col>
                <v-col cols="12" sm="10" v-if="results[currentComputedFieldName].otherFields && (Object.keys(results[currentComputedFieldName].otherFields).length > 0)">
                    <v-divider class="mb-3"></v-divider>
                    <v-alert type="info" border="left" colored-border>
                        Auf Basis der Berechnung wurden andere Felder ebenfalls aktualisiert.
                    </v-alert>
                    <div v-for="(otherFieldName, i) in Object.keys(results[currentComputedFieldName].otherFields)">
                        <v-divider class="mb-3"></v-divider>
                        <span v-html="results[currentComputedFieldName].otherFields[otherFieldName].longHtml"></span>
                    </div>
                </v-col>
                <v-col cols="12" sm="10">
                    <v-row>
                        <v-col cols="12">
                            <v-divider></v-divider>
                        </v-col>
                        <v-col sm="6" cols="12">
                            <v-btn outlined width="100%" @click="clickedRecalculate()">Neu Berechnen</v-btn>
                        </v-col>
                        <v-col sm="6" cols="12">
                            <v-btn color="primary" width="100%" @click="takeResult()">Übernehmen</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text v-else>
            <v-expansion-panels class="mt-6">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <h2>{{ currentComputedField.title }}</h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-alert type="info" border="left" colored-border>
                            <span v-html="currentComputedField.description"></span>
                        </v-alert>
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-row class="mt-6">
                <v-col 
                    v-for="(prop, i) in Object.keys(currentComputedField.properties)" 
                    :key="`prop${i}`"
                    cols="12"
                    :sm="currentComputedField.properties[prop].display.grid.size.sm || currentComputedField.properties[prop].display.grid.size.xs"
                    :md="currentComputedField.properties[prop].display.grid.size.md"
                >
                    <v-row v-if="!currentComputedField.properties[prop].type">
                        <v-col cols="12" class="ml-6">
                            <h3>{{ currentComputedField.properties[prop].display.name }}</h3>
                        </v-col>
                    </v-row>
                    <v-row v-if="currentComputedField.properties[prop].type === 'string' && currentComputedField.properties[prop].enum">
                        <v-col cols="12">
                            <v-select
                                v-model="inputs[currentComputedFieldName][prop]"
                                :items="currentComputedField.properties[prop].enum"
                                :label="currentComputedField.properties[prop].display.name"
                                :disabled="calculationLoading"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row cols="12" v-else-if="currentComputedField.properties[prop].type === 'string' && currentComputedField.properties[prop].display?.typeSpecificOptions?.enumWithDisplaynames" class="px-6">
                        <v-col cols="12">
                            <v-select
                                v-model="inputs[currentComputedFieldName][prop]"
                                :items="currentComputedField.properties[prop].display.typeSpecificOptions.enumWithDisplaynames"
                                :label="currentComputedField.properties[prop].display.name"
                                item-text="name"
                                :disabled="calculationLoading"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row cols="12" v-else-if="currentComputedField.properties[prop].type === 'string'" class="px-6">
                        <v-col cols="12">
                            <v-text-field
                                v-model="inputs[currentComputedFieldName][prop]"
                                :label="currentComputedField.properties[prop].display.name"
                                :disabled="calculationLoading"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row cols="12" v-else-if="
                        currentComputedField.properties[prop].type === 'number' 
                        && currentComputedField.properties[prop].format === 'float'
                    " class="px-6">
                        <v-col cols="12">
                            <v-text-field
                                v-model="inputs[currentComputedFieldName][prop]"
                                :label="currentComputedField.properties[prop].display.name"
                                type="number"
                                @focus="focusedFloatField(prop)"
                                @change="changedFloatField(prop)"
                                :disabled="calculationLoading"
                            >
                            <v-icon slot="append" color="primary" v-if="currentComputedField.properties[prop].display.typeSpecificOptions?.unitOfMeasure?.unitSymbol == '€'">
                                mdi-currency-eur
                            </v-icon>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row cols="12" v-else-if="
                        currentComputedField.properties[prop].type === 'number'
                    " class="px-6">
                        <v-col cols="12">
                            <v-text-field
                                v-model="inputs[currentComputedFieldName][prop]"
                                :label="currentComputedField.properties[prop].display.name"
                                type="number"
                                @change="changedNumberField(prop)"
                                :disabled="calculationLoading"
                            >
                            <p slot="append" color="primary" v-if="currentComputedField.properties[prop].display.typeSpecificOptions?.unitOfMeasure">
                                {{ currentComputedField.properties[prop].display.typeSpecificOptions?.unitOfMeasure?.unitSymbol }}
                            </p>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row cols="12" v-else-if="
                        currentComputedField.properties[prop].type === 'boolean'
                    " class="px-6">
                        <v-col cols="12">
                            <v-checkbox
                                v-model="inputs[currentComputedFieldName][prop]"
                                :label="currentComputedField.properties[prop].display.name"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" sm="10" class="text-center" v-if="calculationLoading">
                    <v-progress-linear
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                </v-col>
                <v-col cols="12" sm="12" v-else>
                    <v-row justify="center">
                        <v-col sm="10">
                            <v-btn color="primary" width="100%" elevation="0" class="py-4" @click="calculateResult()"><v-icon dark class="pr-3"> mdi-calculator </v-icon> Berechnen</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import axios from "axios";
import { calculateComputedField } from "./apiHelper";

import {
    computedPropertiesState,
    computedPropertiesMutations,
    calculationMutations,
    calculationContextState
} from "../../../store/sozialstaffel/CalculationState";
import ConfirmDialog from "../../ConfirmDialog.vue";

import appConfig from "../../../config";
import utils from "../../../utils";

export default {
    name: "ComputedFieldDialog",
    props: [ "fieldNames", "optional" ],
    data() {
        return {
            value: "0.00",
            step: 1,
            inputs: {},
            results: {},
            viewResult: false,
            calculationLoading: false
        }
    },
    methods: {
        closeComputedFieldDialog() {
            this.$emit("close");
        },
        backComputedFieldDialog() {
            this.$emit("back");
        },
        focusedFloatField(prop) {
            if(this.inputs[this.currentComputedFieldName][prop] == "0.00") {
                this.inputs[this.currentComputedFieldName][prop] = "";
            }
        },
        changedFloatField(prop) {
            if(this.inputs[this.currentComputedFieldName][prop] == "") {
                this.inputs[this.currentComputedFieldName][prop] = "0.00";
            } else {
                const parsedValue = parseFloat(this.inputs[this.currentComputedFieldName][prop]);

                if(parsedValue < 0 || isNaN(parsedValue)) {
                    this.inputs[this.currentComputedFieldName][prop] = "0.00";
                } else {
                    this.inputs[this.currentComputedFieldName][prop] = parsedValue.toFixed(2);
                }
            }
        },
        changedNumberField(prop) {
            if(this.inputs[this.currentComputedFieldName][prop] == "") {
                this.inputs[this.currentComputedFieldName][prop] = currentComputedField.properties[prop].minimum || 0;
            } else {
                const parsedNum = parseInt(this.inputs[this.currentComputedFieldName][prop]);
                const minimum = this.currentComputedField.properties[prop].minimum;
                const maximum = this.currentComputedField.properties[prop].maximum;

                if(minimum && parsedNum < minimum) {
                    this.inputs[this.currentComputedFieldName][prop] = minimum;
                } else if(maximum && parsedNum > maximum) {
                    this.inputs[this.currentComputedFieldName][prop] = maximum;
                }
            }
        },
        async calculateResult() {
            try {
                this.calculationLoading = true;

                const computedPropResult = await calculateComputedField(
                    this.$router.currentRoute.query?.case, 
                    this.currentComputedFieldName,
                    this.calculationTimespanAndApplication.calculationStartDate,
                    this.calculationTimespanAndApplication.calculationEndDate,
                    this.inputs[this.currentComputedFieldName],
                    computedPropertiesMutations.getCurrentComputedProperties()
                );

                this.$set(this.results, this.currentComputedFieldName, computedPropResult);

                this.viewResult = true;
            } catch(e) {
                console.log(e);
            } finally {
                this.calculationLoading = false;
            }
        },
        clickedRecalculate() {
            this.viewResult = false;
        },
        takeResult() {
            computedPropertiesMutations.setComputedPropertyResult(this.currentComputedFieldName, this.results[this.currentComputedFieldName]);
            computedPropertiesMutations.setComputedPropertyInput(this.currentComputedFieldName, this.inputs[this.currentComputedFieldName]);

            this.closeComputedFieldDialog();
        }
    },
    created() {
        //initalize inputs object
        this.fieldNames.forEach((computedFieldName) => {
            this.$set(this.inputs, computedFieldName, {});
        });

        this.fieldNames.forEach((computedFieldName) => {
            const computedField = this.computedSchemas[computedFieldName];

            Object.keys(computedField.properties).forEach((prop) => {
                if(computedField.properties[prop].default) {
                    if(computedField.properties[prop].type == 'number' && computedField.properties[prop].format == 'float') {
                        this.$set(this.inputs[computedFieldName], prop, parseFloat(computedField.properties[prop].default).toFixed(2));
                    } else {
                        this.$set(this.inputs[computedFieldName], prop, computedField.properties[prop].default);
                    }

                } else if(computedField.properties[prop].type == 'number' && computedField.properties[prop].format == 'float') {
                    this.$set(this.inputs[computedFieldName], prop, "0.00");
                } else if(computedField.properties[prop].type == 'number') {
                    this.$set(this.inputs[computedFieldName], prop, 0);
                } else if (computedField.properties[prop].type) {
                    this.$set(this.inputs[computedFieldName], prop, "");
                }
            });
        });
    },
    mounted() {
        if(this.computedPropertyResults[this.currentComputedFieldName]) {
            this.results[this.currentComputedFieldName] = this.computedPropertyResults[this.currentComputedFieldName];
            this.viewResult = true;
        }

        if(this.computedPropertyInputs[this.currentComputedFieldName]) {
            this.inputs[this.currentComputedFieldName] = this.computedPropertyInputs[this.currentComputedFieldName];
        }
    },
    computed: {
        currentComputedFieldName: function() {
            return this.fieldNames[this.step - 1];
        },
        currentComputedField: function() {
            return this.computedSchemas[this.currentComputedFieldName];
        },
        computedSchemas: function() {
            return computedPropertiesState.computedSchemas;
        },
        computedPropertyResults: function() {
            return computedPropertiesState.computedPropertyResults;
        },
        computedPropertyInputs: function() {
            return computedPropertiesState.computedPropertyInputs;
        },
        showCloseButton: function() {
            return Object.keys(this.computedPropertyResults).includes(this.currentComputedFieldName) || !!this.optional;
        },
        calculationTimespanAndApplication() {
            return {
                calculationStartDate: calculationContextState.calculationStartDate,
                calculationEndDate: calculationContextState.calculationEndDate,
                application: calculationContextState.application,
                presetSet: calculationContextState.presetSet
            };
        },
    }

}
</script>