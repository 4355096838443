<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      :color="snackBarType == 'ERROR' ? 'red' : 'blue'"
      dark
      :absolute="true"
      :top="true"
    >
      {{ snackbarText }}
    </v-snackbar>
    <!-- Begin Confirm Cancel Dialog -->
    <v-dialog v-model="cancelDialog" persistent max-width="600px">
        <ConfirmDialog
          title="Dokument wirklich stornieren?"
          text="Soll das Dokument wirklich storiniert werden? Diese Aktion kann nicht rückgängig gemacht werden."
          action-label-continue="Stornieren"
          action-label-abort="Schließen"
          ref="cancelDocumentConfirmDialog"
          :inverse-buttons="true"
          v-on:continue="cancelDocument"
          v-on:close="cancelDialog = false"
        />
    </v-dialog>
    <!-- End Confirm Cancel Dialog -->
    <!-- Begin Confirm Delete Dialog -->
    <v-dialog v-model="deleteDialog" persistent max-width="600px">
        <ConfirmDialog
          title="Dokument wirklich löschen?"
          text="Soll das Dokument wirklich gelöscht? Diese Aktion kann nicht rückgängig gemacht werden."
          action-label-continue="Bestätigen"
          action-label-abort="Schließen"
          ref="deleteDocumentConfirmDialog"
          v-on:continue="deleteDocument"
          :inverse-buttons="true"
          v-on:close="deleteDialog = false"
        />
    </v-dialog>
    <!-- End Confirm Cancel Dialog -->
    <!-- Begin Confirm finish Dialog -->
    <v-dialog v-model="confirmdocDialog" persistent max-width="600px">
        <ConfirmDialog
          title="Dokument abschließen?"
          text="Soll der Entwurf wirklich abgeschlossen werden? Das Dokument wird abgeschlossen und kann danach nicht mehr verändert werden."
          action-label-continue="Bestätigen"
          action-label-abort="Schließen"
          ref="confirmDocConfirmDialog"
          v-on:continue="finishDocument"
          :inverse-buttons="true"
          v-on:close="confirmdocDialog = false"
        />
    </v-dialog>
    <!-- End Confirm finish Dialog -->

      <!-- Begin Letter Update Dialog -->
    <v-dialog v-model="letterDraftUpdateDialog" persistent max-width="800px">
      <LetterForm
        v-on:close="closeUpdateLetterForm()"
        v-on:closesuccess="closeUpdateLetterFormSuccess()"
        mode="UPDATE"
        :caseData="caseData"
        :letterData="letterToUpdate"
        :key="reloadLetterUpdateForm"
      />
    </v-dialog>
    <!-- End Letter Update Dialog -->

    <v-dialog v-model="documentRequestDraftUpdateDialog" persistent max-width="800px">
      <DocumentRequestUpateForm
        v-on:close="closeUpdateDocumentRequestForm()"
        v-on:closesuccess="closeUpdateDocumentRequestFormSuccess()"
        v-on:closeerror="closeUpdateDocumentRequestFormError()"
        :document-request-id="documentRequestToUpdate"
        :key="reloadDocumentRequestUpdateForm" />
    </v-dialog>

      <v-row style="margin-bottom: 0; padding-bottom: 0;">
          <v-col cols="12" style="margin-bottom: 0; padding-bottom: 0;">
            <v-card class="pa-7" style="margin-bottom: 0;">
            <v-row>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon x-large v-bind="attrs" v-on="on" class="hover-pointer" @click="goback()"> mdi-arrow-left </v-icon>
                </template>
                <span>Zurück</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <div v-if="!pdfloading">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="icon-pointer mx-8"
                      @click="gotoCase()"
                    >
                      <v-icon dark> mdi-account </v-icon>
                    </v-avatar>
                  </template>
                  <span>Zum Fall</span>
                </v-tooltip>
                <v-tooltip top v-if="letterStatus == 'Festgeschrieben'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="icon-pointer mx-8"
                      @click="cancelDialog = true"
                    >
                      <v-icon dark> mdi-file-document-remove </v-icon>
                    </v-avatar>
                  </template>
                  <span>Dokument stornieren</span>
                </v-tooltip>
                <v-tooltip top v-if="letterStatus == 'Entwurf'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="icon-pointer mx-8"
                      @click="openDocumentUpdateForm()"
                    >
                      <v-icon dark> mdi-pencil </v-icon>
                    </v-avatar>
                  </template>
                  <span>Dokument bearbeiten</span>
                </v-tooltip>
                <v-tooltip top v-if="letterStatus == 'Entwurf'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="icon-pointer mx-8"
                      @click="confirmdocDialog = true"
                    >
                      <v-icon dark> mdi-file-document-check </v-icon>
                    </v-avatar>
                  </template>
                  <span>Dokument abschließen</span>
                </v-tooltip>
                <v-tooltip top v-if="letterStatus == 'Entwurf'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="icon-pointer mx-8"
                      @click="deleteDialog = true"
                    >
                      <v-icon dark> mdi-delete </v-icon>
                    </v-avatar>
                  </template>
                  <span>Löschen</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="icon-pointer mx-8"
                      @click="printLetter()"
                    >
                      <v-icon dark> mdi-printer </v-icon>
                    </v-avatar>
                  </template>
                  <span>Dokument drucken</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="icon-pointer mx-8"
                      @click="downloadLetter()"
                    >
                      <v-icon dark> mdi-download </v-icon>
                    </v-avatar>
                  </template>
                  <span>Dokument herunterladen</span>
                </v-tooltip>
              </div>
            </v-row>
          </v-card>
          </v-col>
      </v-row>
      <v-row style="margin-top: 0; padding-top: 0;" v-if="pdfloading">
          <v-col cols="12" align="center">
              <v-card class="mt-9" width="30%">
                <v-card-title style="text-align: left">
                    Lade Dokument ...
                </v-card-title>
                <v-card-text>
                    <span style="font-size: 12pt; text-align: left">Dies kann einige Sekunden dauern</span>
                    <v-progress-linear
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-col>
        </v-row>
        <v-row style="margin-top: 0; padding-top: 0;" v-else>
          <v-col cols="9" md="9" sm="12" align="center" style="margin-top: 0; padding-top: 0;">
            <v-alert type="info" style="margin: 0" v-if="letterStatus == 'Entwurf'">
              Entwurfsansicht - Bitte schließen Sie das Dokument nach Fertigstellung revisionssicher ab
              <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="icon-pointer mx-8"
                      @click="confirmdocDialog = true"
                    >
                      <v-icon dark> mdi-file-document-check </v-icon>
                    </v-avatar>
                  </template>
                  <span>Dokument abschließen</span>
                </v-tooltip>
            </v-alert>
            <iframe id="pdfiframe" :src="`${blobUrl}#toolbar=0&navpanes=0&zoom=110`" style="width: 100%;z-index:2; height: 75vh; margin-top: 0;" frameborder="0">
                Oops! an error has occurred.
            </iframe>    
          </v-col>
          <v-col cols="3" md="3" sm="12" style="margin-top: 0; padding-top: 0;" v-if="docType == 'letter'" class="pr-5">
              <v-card class="mr-2 mt-2">
                  <v-card-title>Bescheid {{documentData.verificationCode}}</v-card-title>
                  <v-card-text>
                      <h3 class="mb-1"><span style="color: black">Status:</span> {{ letterStatus }}</h3>
                      <h3 class="mb-1"><span style="color: black">Bescheidtyp:</span> {{ getLetterLabel(documentData) }}</h3>
                      <br />
                      <h3 class="mb-1"><span style="color: black">Erstellt am:</span> {{ formatDate(documentData.createdAt) }}</h3>
                      <br/>
                      <h3 class="mb-1"><span style="color: black; font-weight: bold">Gültigkeit:</span> {{ formatDate(documentData.letterStart) }} bis {{ formatDate(documentData.letterEnd) }}</h3>
                      <h3 class="mb-1"><span style="color: black; font-weight: bold">Datum des Antrags:</span> {{ formatDate(documentData.application.dateOfApplication) }}</h3>
                      <h3 class="mb-1"><span style="color: black; font-weight: bold">Datum des Bescheids:</span> {{ formatDate(documentData.dateOfIssue) }}</h3> 
                      <br />
                      <br />
                      <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Daten zum Fall
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="mb-1"><span style="color: black; font-weight: bold">Antragsteller:</span> {{ documentData.case.primaryApplicant.formOfAddress }} {{ documentData.case.primaryApplicant.firstName }} {{ documentData.case.primaryApplicant.lastName }}</p>
                                <p class="mb-1"><span style="color: black; font-weight: bold">Anzahl Kinder:</span> {{ documentData.case.children.length }}</p>
                                <p class="mb-1"><span style="color: black; font-weight: bold">Aktenzeichen:</span> {{ documentData.case.reference }}</p> 
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
              </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="12" style="margin-top: 0; padding-top: 0;" v-if="docType == 'calculation'" class="pr-5">
              <v-card class="mr-2 mt-2">
                  <v-card-title>Berechnung vom {{ formatDate(documentData.createdAt) }}</v-card-title>
                  <v-card-text>
                      <h3 class="mb-1"><span style="color: black">Status:</span> {{ letterStatus }}</h3>
                      <h3 class="mb-1"><span style="color: black">Einkommensüberhang:</span> {{ formatCurrency(documentData.incomeSurplus) }}€</h3>
                      <h3 class="mb-1"><span style="color: black">Davon einzusetzen:</span> {{ formatCurrency(documentData.incomeToBeUsed) }}€</h3>
                      <br />
                      <h3 class="mb-1"><span style="color: black">Erstellt am:</span> {{ formatDate(documentData.createdAt) }}</h3>
                      <br/>
                      <h3 class="mb-1"><span style="color: black; font-weight: bold">Berechnungszeitraum:</span> {{ formatDate(documentData.calculationStart) }} bis {{ formatDate(documentData.calculationEnd) }}</h3>
                      <br />
                      <br />
                      <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Daten zum Fall
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="mb-1"><span style="color: black; font-weight: bold">Antragsteller:</span> {{ documentData.case.primaryApplicant.formOfAddress }} {{ documentData.case.primaryApplicant.firstName }} {{ documentData.case.primaryApplicant.lastName }}</p>
                                <p class="mb-1"><span style="color: black; font-weight: bold">Anzahl Kinder:</span> {{ documentData.case.children.length }}</p>
                                <p class="mb-1"><span style="color: black; font-weight: bold">Aktenzeichen:</span> {{ documentData.case.reference }}</p> 
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
              </v-card>
          </v-col>
          <v-col cols="3" md="3" sm="12" style="margin-top: 0; padding-top: 0;" v-if="docType == 'documentrequest'" class="pr-5">
              <v-card class="mr-2 mt-2">
                  <v-card-title>Nachforderung vom <br />{{ formatDate(documentData.dateOfIssue) }}</v-card-title>
                  <v-card-text>
                      <h3 class="mb-1"><span style="color: black">Status:</span> {{ letterStatus }}</h3>
                      <h3 class="mb-1"><span style="color: black">Antragsdatum:</span> {{ formatDate(documentData.application.dateOfApplication) }}</h3>
                      <h3 class="mb-1"><span style="color: black">Frist zur Einreichung:</span> {{ formatDate(documentData.deadline) }}</h3>
                      <br />
                      <h3 class="mb-1"><span style="color: black">Angeforderte Dokumente:</span></h3>
                      <ul>
                        <li v-for="doc in documentData.requestedDocuments ">
                          <h3>{{ doc }}</h3>
                        </li>
                      </ul>
                      <br />
                      <h3 class="mb-1"><span style="color: black">Erstellt am:</span> {{ formatDate(documentData.createdAt) }}</h3>
                      <br />
                      <br />
                      <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Daten zum Fall
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="mb-1"><span style="color: black; font-weight: bold">Antragsteller:</span> {{ documentData.case.primaryApplicant.formOfAddress }} {{ documentData.case.primaryApplicant.firstName }} {{ documentData.case.primaryApplicant.lastName }}</p>
                                <p class="mb-1"><span style="color: black; font-weight: bold">Anzahl Kinder:</span> {{ documentData.case.children.length }}</p>
                                <p class="mb-1"><span style="color: black; font-weight: bold">Aktenzeichen:</span> {{ documentData.case.reference }}</p> 
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
              </v-card>
          </v-col>
        </v-row>
  </div>
</template>

<style lang="css" scoped>
.icon-pointer >>> :hover {
  cursor: pointer;
}
.hover-pointer {
  cursor: pointer;
}
</style>
<script>
import appConfig from "../../config";
import utils from "../../utils";
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import LetterForm from "../../components/sozialstaffel/LetterForm.vue";
import DocumentRequestUpateForm from "../../components/sozialstaffel/docRequestForm/DocRequestFormUpdate.vue";

export default {
  name: "SozialstaffelDocumentDetail",
  components: {
      LetterForm,
      ConfirmDialog,
      DocumentRequestUpateForm
  },
  data() {
    return {
      documentID: '',
      pdfdata: undefined,
      pdfloading: true,
      documentData: {},
      blobUrl: "",
      docType: "letter",
      cancelDialog: false,
      cancelLetterLoading: false,
      deleteDialog: false,
      confirmdocDialog: false,
      statics: {},

      /* update dialogs */
      //letter
      letterToUpdate: {},
      letterDraftUpdateDialog: false,
      caseData: {},
      reloadLetterUpdateForm: 0,

      //docRequest
      documentRequestDraftUpdateDialog: false,
      reloadDocumentRequestUpdateForm: 0,

      /* snackbar */
      snackbarText: "",
      snackBarType: "SUCCESS",
      snackbar: false
    };
  },
  async mounted() {
      if (!this.$router.currentRoute.query?.id) {
        this.showSnackbar("ERROR", "ID Parameter ist nicht gesetzt");
        return;
      }

      if (!this.$router.currentRoute.query?.type) {
        this.showSnackbar("ERROR", "Type Parameter ist nicht gesetzt");
        return;
      }

      this.statics = await this.fetchStatics();

      this.documentID = this.$router.currentRoute.query?.id;
      this.docType = this.$router.currentRoute.query?.type;

      this.getDocumentPDF();
      this.getDocumentData();

      //63a587b0735d8ed1b631c369
  },
  computed: {
      letterResult: {
          get: function() {
              let result;

              if(this.documentData.resultType == "full_without_calc") {
                  result = "Pauschalbefreiung wegen ";

                  for(let key of Object.keys(appConfig.socialBenefitsMapping)) {
                      if(appConfig.socialBenefitsMapping[key] == this.documentData.receivingSocialBenefits) {
                          result += key;
                      }
                  }
              } else if(this.documentData.resultType == "rejection") {
                result = "Ablehnung";
              } else if(this.documentData.resultType == "partial") {
                result = "Teilermäßigung";
              } else if(this.documentData.resultType == "full_with_calc") {
                result = "Vollermäßigung durch Berechnung";
              } 

              return result;
          }
      },
      letterStatus: {
          get: function() {
            let status = "";

            if(this.documentData.status == "finished") {
              status = "Festgeschrieben";
            } else if(this.documentData.status == "canceled") {
              status = "Storniert";
            } else if(this.documentData.status == "draft") {
              status = "Entwurf";
            }

            return status; 
          }
      }
  },
  methods: {
    goback: function() {
      this.$router.push(`/sozialstaffel/cases/detail?id=${this.documentData.case._id}&activeTab=2`);
    },
    formatDate(d) {
      return utils.formatDate(d, false);
    },
    formatCurrency(v) {
      return parseFloat(v).toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },

    /* Utils */
    showSnackbar(type, text) {
      if (type == "ERROR") {
        this.snackBarType = "ERROR";
        this.snackbarText = text;
      } else {
        this.snackBarType = "SUCCESS";
        this.snackbarText = text;
      }

      this.snackbar = true;
    },

    fetchStatics: async function() {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      const letterTypeMappingURL = `${appConfig.apiURL}/static/mappings/lettertypemapping`;
      const letterResultTypeMappingURL = `${appConfig.apiURL}/static/mappings/letterresulttypemapping`;

      const [letterTypeMappingResponse, letterResultTypeMappingResponse] = await Promise.all([
        axios.get(letterTypeMappingURL, requestConfig),
        axios.get(letterResultTypeMappingURL, requestConfig)
      ]);

      return {
        letterTypeMapping: letterTypeMappingResponse.data,
        letterResultTypeMapping: letterResultTypeMappingResponse.data
      };
    },

    getLetterLabel(letter) {
      const type = Object.keys(this.statics.letterTypeMapping).filter(
        (key) =>  this.statics.letterTypeMapping[key] == letter.letterType
      )[0];

      const resultType = Object.keys(this.statics.letterResultTypeMapping).filter(
        (key) =>  this.statics.letterResultTypeMapping[key] == letter.resultType
      )[0];

      return `${type} - (${resultType})`;
    },

    printLetter() {
        const iframeWindow = document.getElementById("pdfiframe").contentWindow;
        
        iframeWindow.print();
    },
    gotoCase() {
        this.$router.push(`/sozialstaffel/cases/detail?id=${this.documentData.case._id}`);
    },

    downloadLetter() {
        let fileName;

        if(this.docType == "letter") {
          fileName = `Bescheid_${this.documentData.verificationCode}.pdf`;
        } else if (this.docType == "calculation") {
          fileName = `Berechnung_${this.formatDate(this.documentData.calculationStart)}_bis_${this.formatDate(this.documentData.calculationEnd)}_${this.documentData.case.primaryApplicant.lastName}.pdf`
        } else {
          fileName = `Nachforderung_zum_Antrag_vom_${this.formatDate(this.documentData.application.dateOfApplication)}_${this.documentData.case.primaryApplicant.lastName}.pdf`
        }

        const downloadLink = document.createElement("a");
        
        downloadLink.href = this.pdfdata;
        downloadLink.download = fileName;
        downloadLink.click();
    },

    async cancelDocument() {
      this.$refs.cancelDocumentConfirmDialog.setLoadingState(true);

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      try {
        let url;

        if(this.docType == "letter") {
          url = `${appConfig.apiURL}/sozialstaffel/letters/${this.$router.currentRoute.query.id}/cancel`;
        } else if (this.docType == "calculation") {
          url = `${appConfig.apiURL}/sozialstaffel/calculations/${this.$router.currentRoute.query.id}/cancel`
        } else {
          url = `${appConfig.apiURL}/sozialstaffel/documentrequests/${this.$router.currentRoute.query.id}/cancel`;
        }

        await axios.put(url, {}, requestConfig);

        await this.getDocumentData();
        this.cancelDialog = false;

        this.$refs.cancelDocumentConfirmDialog.setLoadingState(false);
        this.showSnackbar("SUCCESS", "Dokument erfolgreich storniert")
      } catch(e) {
        console.log(e);
        this.showSnackbar("ERROR", "Fehler beim Abschließen des Dokuments")
      }
    },

    async deleteDocument() {
      this.$refs.deleteDocumentConfirmDialog.setLoadingState(true);

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      try {
        let url;

        if(this.docType == "letter") {
          url = `${appConfig.apiURL}/sozialstaffel/letters/${this.$router.currentRoute.query.id}`;
        } else if (this.docType == "calculation") {
          url = `${appConfig.apiURL}/sozialstaffel/calculations/${this.$router.currentRoute.query.id}`
        } else {
          url = `${appConfig.apiURL}/sozialstaffel/documentrequests/${this.$router.currentRoute.query.id}`;
        }

        await axios.delete(url, requestConfig);

        this.showSnackbar("SUCCESS", "Dokument erfolgreich gelöscht")
        this.gotoCase();
      } catch(e) {
        this.showSnackbar("ERROR", "Fehler beim Löschen des Dokuments")
      }
    },

    async finishDocument() {
      this.$refs.confirmDocConfirmDialog.setLoadingState(true);

      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      try {
        let url;

        if(this.docType == "letter") {
          url = `${appConfig.apiURL}/sozialstaffel/letters/${this.$router.currentRoute.query.id}/finish`;
        } else if(this.docType == "calculation") {
          url = `${appConfig.apiURL}/sozialstaffel/calculations/${this.$router.currentRoute.query.id}/finish`;
        } else {
          url = `${appConfig.apiURL}/sozialstaffel/documentrequests/${this.$router.currentRoute.query.id}/finish`;
        }

        await axios.put(url, {}, requestConfig);

        await this.getDocumentData();
        this.confirmdocDialog = false;
        this.$refs.confirmDocConfirmDialog.setLoadingState(false);
        this.showSnackbar("SUCCESS", "Dokument erfolgreich abgeschlossen")
      } catch(e) {
        console.log(e);
        this.showSnackbar("ERROR", "Fehler beim Abschließen des Dokuments")
      }
    },

    async getDocumentPDF() {
        this.pdfloading = true;

        const requestConfig = {
            headers: {
                sessiontoken: localStorage.getItem("token"),
            },
        };

        let url;

        switch(this.docType) {
          case "letter":
            url = `${appConfig.apiURL}/sozialstaffel/letters/${this.documentID}/render`;
            break;
          case "calculation":
            url = `${appConfig.apiURL}/sozialstaffel/calculations/${this.documentID}/render`;
            break;
          case "documentrequest":
            url = `${appConfig.apiURL}/sozialstaffel/documentrequests/${this.documentID}/render`;
            break;
          case "application":
            url = `${appConfig.apiURL}/sozialstaffel/ozgapplications/${this.documentID}/userinputs`
            break;
        }

        try {
            const pdfResponse = await axios.get(url, requestConfig);

            this.pdfdata = `data:application/pdf;base64, ${pdfResponse.data.base64}#toolbar=0&navpanes=0`;

            const blob = utils.b64toBlob(pdfResponse.data.base64, 'application/pdf');
            const blobUrl = URL.createObjectURL(blob);

            this.blobUrl = blobUrl;

            this.pdfloading = false;
        } catch(e) {
            this.showSnackbar("ERROR", "Beim Laden des Dokuments ist ein Fehler aufgetreten")
        }
    },
    async getDocumentData() {
      const requestConfig = {
        headers: {
          sessiontoken: localStorage.getItem("token"),
        },
      };

      let url;

      switch(this.docType) {
        case "letter":
          url = `${appConfig.apiURL}/sozialstaffel/letters?$filter=_id eq cast('${this.$router.currentRoute.query.id}',ObjectId)&$expand=case,application,calculation`;
          break;
        case "calculation":
          url = `${appConfig.apiURL}/sozialstaffel/calculations?$filter=_id eq cast('${this.$router.currentRoute.query.id}',ObjectId)&$expand=case`;
          break;
        case "documentrequest":
          url = `${appConfig.apiURL}/sozialstaffel/documentrequests?$filter=_id eq cast('${this.$router.currentRoute.query.id}',ObjectId)&$expand=case,application`;
          break;
        case "application":
          url = `${appConfig.apiURL}/sozialstaffel/applications?$filter=ozgApplication eq cast('${this.$router.currentRoute.query.id}',ObjectId)&$expand=case`;
          break;
      }

      try {
        const docDataResponse = await axios.get(url, requestConfig);

        this.documentData = docDataResponse.data.data[0];
      } catch(e) {
        this.showSnackbar("ERROR", "Beim Laden der Daten des Dokuments")
      }
    },

    openDocumentUpdateForm() {
      switch(this.docType) {
        case "letter":
          this.letterToUpdate =  {
            ...this.documentData,
            application: this.documentData.application._id,
            calculation: this.documentData.calculation?._id,
            case: this.documentData.case._id
          };

          this.caseData = this.documentData.case;
          this.reloadLetterUpdateForm += 1;
          this.letterDraftUpdateDialog = true;
          break;
        case "calculation":
            this.$router.push(
            `/sozialstaffel/incomecalc2?case=${this.documentData.case._id}&calculation=${this.documentData._id}&mode=UPDATE`
          );
          break;
        case "documentrequest":
          this.documentRequestToUpdate = this.documentData._id;
          this.reloadDocumentRequestUpdateForm += 1;
          this.documentRequestDraftUpdateDialog = true;
          break;
      }
    },

      closeUpdateLetterForm() {
        this.letterDraftUpdateDialog = false;
      },

      closeUpdateLetterFormSuccess() {
        this.letterDraftUpdateDialog = false;
        this.showSnackbar("SUCCESS", "Dokument erfolgreich aktualisiert")
        this.getDocumentData();
        this.getDocumentPDF();
      },

      closeUpdateDocumentRequestForm() {
        this.documentRequestDraftUpdateDialog = false;
      },

      closeUpdateDocumentRequestFormSuccess()  {
        this.documentRequestDraftUpdateDialog = false;
        this.showSnackbar("SUCCESS", "Dokument erfolgreich aktualisiert")
        this.getDocumentData();
        this.getDocumentPDF();
      },
  },
};
</script>